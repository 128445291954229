import { CountAnnotationBar, CountAnnotationBarDirection, ICountAnnotationProps } from "@m365-admin/count-annotation";
import React, { useMemo, useState } from "react";
import { getFacilityCounts } from "store/selectors/facilitiesSelector";
import { useAppSelector } from "store/store";

export const FacilityAggregateCountBar = () => {
  const [facilityCountProps, setFacilityCountProps] = useState<ICountAnnotationProps[]>([]);
  const facilityAggCounts = useAppSelector(getFacilityCounts);

  useMemo(() => {
    const facilityCounts = facilityAggCounts;
    setFacilityCountProps(facilityCounts);
  }, [facilityAggCounts]);

  return (
    <CountAnnotationBar
      countAnnotationProps={facilityCountProps}
      direction={CountAnnotationBarDirection.Vertical}
    />
  );
};
