import { IColumn, IGroup, Link, useTheme } from "@fluentui/react";
import { FontWeights, SelectionMode, Text } from "@fluentui/react";
import type { ICompositeListDetailsList } from "@m365-admin/composite-list";
import { CompositeList } from "@m365-admin/composite-list";
import * as React from "react";
import { useState } from "react";

import { IGeoResilienceTeamData } from "../Types";
import { buildStatus } from "./Helpers";

export interface DriGeoDataGridProps {
  currentDriGeoTeamData: IGeoResilienceTeamData[];
  groupingKey?: string;
}

export class BaseGroupHeaderItem {
  public location!: string;
  public teamCount!: number;
  public maxImpact!: string;
  public peopleCount!: number;
}

export const DriGeoDataGrid = (props: DriGeoDataGridProps) => {
  const theme = useTheme();

  const groupingOptions = {
    region: "driRegion",
    country: "driCountry",
    city: "driCity",
    facility: "driFacility",
    team: "teamName",
  };

  const defaultAttribute = groupingOptions["region"];
  const defaultGroupingNames: string[] = ["ASIA", "EMEA", "LATAM", "NORAM", "PUGETSOUND"];

  const columns: IColumn[] = [
    {
      key: "driLocation",
      name: "DRI Location",
      ariaLabel: "DRI Location",
      minWidth: 150,
      maxWidth: 250,
      isMultiline: true,
      isResizable: true,
      onRender: (x: IGeoResilienceTeamData) =>
        `${x.driFacility}, ${x.driCity}, ${
          x.driCountry?.length > 0 ? `${x.driCountry}, ${x.driRegion}` : `${x.driRegion}`
        }`,
    },
    {
      key: "driInCity",
      fieldName: "driInCity",
      name: "DRI in location",
      ariaLabel: "DRI in location",
      minWidth: 75,
      maxWidth: 100,
      isResizable: true,
      onRender: (x: IGeoResilienceTeamData) => x.driAliasInCity.length,
    },
    {
      key: "capacityImpact",
      fieldName: "capacityImpact",
      name: "Impact Risk",
      ariaLabel: "Impact Risk",
      minWidth: 75,
      maxWidth: 100,
      isResizable: true,
      onRender: (x: IGeoResilienceTeamData) => buildStatus(x.capacityImpact, theme),
    },
    {
      key: "teamName",
      fieldName: "teamName",
      name: "DRI Team",
      ariaLabel: "DRI Team",
      minWidth: 200,
      maxWidth: 300,
      isResizable: true,
      isMultiline: true,
      onRender: (x: IGeoResilienceTeamData) => (
        <Link
          aria-label={`Navigate to ${x.teamName}'s ICM on-call page`}
          href={`https://portal.microsofticm.com/imp/v3/oncall/current?teamIds=${x.teamId}`}
          target="_blank"
        >
          {x.teamName}
        </Link>
      ),
    },
    {
      key: "driAliasInCity",
      fieldName: "driAliasInCity",
      name: "DRI Info",
      ariaLabel: "DRI Info",
      minWidth: 200,
      isResizable: true,
      isMultiline: true,
      onRender: (x: IGeoResilienceTeamData) => `${x.driAliasInCity}`,
    },
  ];

  const headerColumns: IColumn[] = [
    {
      key: "driLocation",
      name: "Region",
      isResizable: true,
      minWidth: 100,
      maxWidth: 275,
      onRender: (item: BaseGroupHeaderItem) => {
        return <Text styles={{ root: { fontWeight: FontWeights.semibold } }}>{item.location}</Text>;
      },
    },
    {
      key: "peopleCount",
      name: "Total People",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      onRender: (item: BaseGroupHeaderItem) =>
        item.peopleCount > 0 ? <>{item.peopleCount}</> : <></>,
    },
    {
      key: "capacityImpact",
      name: "Area Impact",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      onRender: (item: BaseGroupHeaderItem) => {
        return buildStatus(item.maxImpact, theme);
      },
    },
    {
      key: "teamCount",
      name: "",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
    },
    {
      key: "driAliasInCity",
      fieldName: "driAliasInCity",
      name: "DRI Info",
      ariaLabel: "DRI Info",
      minWidth: 200,
      maxWidth: 400,
      isResizable: true,
      isMultiline: true,
    },
  ];

  const _getGroups = (
    groupItems: IGeoResilienceTeamData[],
    groupingNames: string[],
    groupingAttribute: string
  ) => {
    const dataGroups: IGroup[] = [];
    let groupsStartIndex = 0;

    groupingNames.forEach((groupedItem: string) => {
      const groupedData = groupItems.filter((item: IGeoResilienceTeamData) => {
        return item[groupingAttribute]?.toLowerCase() === groupedItem?.toLowerCase();
      });

      const rowCount = groupedData.length;
      const headerItem: BaseGroupHeaderItem = {
        location: groupedItem,
        teamCount: 0,
        maxImpact: "Unknown",
        peopleCount: 0,
      };

      if (rowCount > 0) {
        headerItem.teamCount = groupedData.length;

        headerItem.peopleCount = groupedData.reduce(
          (sum, curr) => (sum += curr.driAliasInCity.length),
          0
        );

        headerItem.maxImpact = groupedData
          .map((i: IGeoResilienceTeamData) => i.capacityImpact)
          .reduce((max, current) => {
            switch (current.toUpperCase()) {
              case "HIGH":
                return "HIGH";
              case "MODERATE":
                return max === "HIGH" ? "HIGH" : current.toUpperCase();
              case "LOW":
                return max === "MODERATE" ? "MODERATE" : current.toUpperCase();
              default:
                return current;
            }
          });
      }

      const group: IGroup = {
        key: groupedItem,
        name: groupedItem,
        startIndex: groupsStartIndex,
        count: rowCount,
        data: headerItem,
      };

      groupsStartIndex += rowCount;

      dataGroups.push(group);
    });

    return dataGroups;
  };

  const [list, setList] = useState<ICompositeListDetailsList>({
    listProps: {
      // NOTE: `checkButtonAriaLabel` will set the same aria-label for all row checkboxes.
      // If you want different aria-labels for each row checkbox,
      // please see `onRenderCheck` on `onRenderRow` in this example.
      // Fluent guidance: https://github.com/microsoft/fluentui/issues/16450#issuecomment-793201238
      // checkButtonAriaLabel: 'Select item',
      ariaLabelForSelectAllCheckbox: "Select all items",
      items: [],
      groups: _getGroups([], defaultGroupingNames, defaultAttribute),
      groupProps: {
        headerProps: {},
        showEmptyGroups: false,
      },
      groupHeaderColumns: headerColumns,
      columns: columns,
      selectionMode: SelectionMode.none,
    },
    key: "icm dri list",
  });

  React.useMemo(() => {
    let groupings: string[] = defaultGroupingNames;
    let filteringAttribute = defaultAttribute;

    if (props.groupingKey && filteringAttribute !== props.groupingKey) {
      filteringAttribute = groupingOptions[props.groupingKey];
      const uniqueGroup = Array.from(
        new Set(props.currentDriGeoTeamData.map((x) => x[filteringAttribute] as string))
      );
      groupings = uniqueGroup.sort();
    }
    const sortedData = props?.currentDriGeoTeamData.sort((a, b) =>
      a[filteringAttribute]?.toUpperCase().localeCompare(b[filteringAttribute].toUpperCase())
    );

    setList({
      listProps: {
        ariaLabelForSelectAllCheckbox: "Select all items",
        items: sortedData,
        groups: _getGroups(sortedData, groupings, filteringAttribute),
        groupProps: {
          headerProps: {
            selectAllButtonProps: {
              "aria-label": "Select all items in the group",
            },
          },
          showEmptyGroups: false,
        },
        groupHeaderColumns: headerColumns,
        columns: columns,
        selectionMode: SelectionMode.none,
      },
      key: "icm dri list",
    });
  }, [props.currentDriGeoTeamData, props.groupingKey]);

  return <CompositeList detailsListProps={list} commandBarProps={{ items: [] }} />;
};
