import {
  ComboBox,
  DefaultButton,
  IComboBoxOption,
  ISelectableOption,
  Label,
  Panel,
  PrimaryButton,
  Separator,
  Stack,
  Toggle,
} from "@fluentui/react";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { current } from "@reduxjs/toolkit";
import { buildFilterEventCustomTelemetry } from "components/datagrids/Helpers";
import { defaultStackTokens } from "pages/Page.styles";
import { useMemo, useState } from "react";
import {
  updateServiceCriticalityFilters,
  updateServiceRecoveryClassificationFilters,
  updateServiceSingleDcFilter,
} from "store/actions/servicesActions";
import {
  getServiceRtoCriticalityOptions,
  getServiceRtoCriticalityFilters,
  getServiceSingleDcFilter,
  getServiceRecoveryClassificationOptions,
  getServiceRecoveryClassificationFilters,
} from "store/selectors/servicesSelector";
import { useAppDispatch, useAppSelector } from "store/store";

export interface ServicesFilterProps {
  isServiceDataLoading: boolean;
  isPanelOpen: boolean;
  openPanel: () => void;
  dismissPanel: () => void;
}

const panelComboBoxStyle = { optionsContainerWrapper: { maxHeight: 400 } };

export const ServicesFilter = (props: ServicesFilterProps) => {
  const dispatch = useAppDispatch();
  const appInsights = useAppInsightsContext();

  // Abstract common logic for updating keys and resetting dependent filters
  const updateFilterKeys = (option, currentKeys, setKeys, resetKeys = []) => {
    const updatedKeys = option.selected
      ? [...currentKeys, option.key as string]
      : currentKeys.filter((k) => k !== option.key);
    setKeys(updatedKeys);
    resetKeys.forEach((resetKey) => resetKey([]));
  };

  // Abstract common logic for tracking filter usage
  const trackFilterUsage = (filterName, optionText) => {
    appInsights.trackEvent(
      { name: "Filter Used" },
      buildFilterEventCustomTelemetry(filterName, optionText)
    );
  };

  //single dc filter
  const serviceSingleDCFilterState = useAppSelector(getServiceSingleDcFilter);
  const [serviceSingleDCFilter, setServiceSingleDCFilter] = useState(serviceSingleDCFilterState);
  useMemo(() => {
    setServiceSingleDCFilter(serviceSingleDCFilterState);
  }, [serviceSingleDCFilterState]);

  //criticality filter setup
  const serviceCriticalityFiltersState = useAppSelector(getServiceRtoCriticalityFilters);
  const [serviceCriticalFilters, setServiceCriticalFilters] = useState<string[]>(
    serviceCriticalityFiltersState
  );
  useMemo(() => {
    setServiceCriticalFilters(serviceCriticalityFiltersState);
  }, [serviceCriticalityFiltersState]);

  const serviceCriticalityOptionsState = useAppSelector(getServiceRtoCriticalityOptions);
  const [serviceCriticalOptions, setServiceCriticalOptions] = useState<ISelectableOption[]>([]);
  useMemo(() => {
    setServiceCriticalOptions(serviceCriticalityOptionsState);
  }, [serviceCriticalityOptionsState]);

  //service recovery classification filter setup
  const activeRecoveryClassificationFilters = useAppSelector(
    getServiceRecoveryClassificationFilters
  );
  const [selectedRecoveryClassifications, setSelectedRecoveryClassifications] = useState<string[]>(
    activeRecoveryClassificationFilters
  );
  useMemo(() => {
    setSelectedRecoveryClassifications(activeRecoveryClassificationFilters);
  }, [activeRecoveryClassificationFilters]);
  const recoveryClassificationOptions = useAppSelector(getServiceRecoveryClassificationOptions);

  return (
    <Panel
      isLightDismiss
      isOpen={props.isPanelOpen}
      onDismiss={props.dismissPanel}
      hasCloseButton={true}
      headerText="Additional Service Filters"
      closeButtonAriaLabel="Close"
      isFooterAtBottom={true}
      onRenderFooter={() => {
        const buttonStyles = { root: { margin: 14 } };
        //metric setup
        const getFilterCount =
          serviceCriticalFilters.length + selectedRecoveryClassifications.length;
        const getCurrentFilterState = {
          "RTO criticality": [...serviceCriticalFilters],
          "Recovery Classification - Services": [...selectedRecoveryClassifications],
        };

        return (
          <div>
            <Stack horizontal>
              <PrimaryButton
                onClick={() => {
                  appInsights.trackEvent({ name: "Filter Used" }, getCurrentFilterState);
                  appInsights.trackMetric(
                    { name: "Filter Used", average: getFilterCount },
                    { Source: "Services" }
                  );
                  dispatch(updateServiceSingleDcFilter(serviceSingleDCFilter));
                  dispatch(updateServiceCriticalityFilters(serviceCriticalFilters));
                  dispatch(
                    updateServiceRecoveryClassificationFilters(selectedRecoveryClassifications)
                  );
                  props.dismissPanel();
                }}
                styles={buttonStyles}
                text="Apply"
              />
              <DefaultButton
                text="Clear"
                styles={buttonStyles}
                onClick={() => {
                  dispatch(updateServiceSingleDcFilter(false));
                  dispatch(updateServiceCriticalityFilters([]));
                  dispatch(updateServiceRecoveryClassificationFilters([]));
                  appInsights.trackMetric(
                    { name: "Filter Used", average: 0 },
                    { Source: "Services" }
                  );
                }}
              />
            </Stack>
          </div>
        );
      }}
    >
      <>
        <Stack tokens={defaultStackTokens}>
          <Label>Isolated Services</Label>
          <Toggle
            label="Single DC"
            inlineLabel
            onChange={(_, checked: boolean): void => {
              setServiceSingleDCFilter(checked);
            }}
            checked={serviceSingleDCFilter}
            role="checkbox"
          />
          <Separator />
          <ComboBox
            multiSelect
            label="Filter by RTO criticality rating"
            styles={panelComboBoxStyle}
            options={serviceCriticalOptions}
            onChange={(_, option?: IComboBoxOption): void => {
              if (option) {
                updateFilterKeys(option, serviceCriticalFilters, setServiceCriticalFilters);
                trackFilterUsage("Service RTO Rating", option.text);
              }
            }}
            selectedKey={serviceCriticalFilters}
          />
          <Separator />
          <ComboBox
            multiSelect
            styles={panelComboBoxStyle}
            label="Filter by service recovery classification"
            options={recoveryClassificationOptions}
            onChange={(_, option?: IComboBoxOption): void => {
              if (option) {
                updateFilterKeys(
                  option,
                  selectedRecoveryClassifications,
                  setSelectedRecoveryClassifications
                );
                trackFilterUsage("Service Recovery classification", option.text);
              }
            }}
            selectedKey={selectedRecoveryClassifications}
          />
        </Stack>
      </>
    </Panel>
  );
};
