import * as React from "react";
import {
  Dropdown,
  IDropdownOption,
  ScrollablePane,
  Stack,
  Text,
} from "@fluentui/react";
import { DriGeoDataGrid } from "../datagrids/DriGeoDataGrid";
import { _Styles } from "../../pages/Page.styles";
import { IDriTeamData, IGeoResilienceTeamData } from "../Types";

export interface ServiceDriGeoDataCardProps {
  currentDriGeoData: IDriTeamData[];
}

export const ServiceDriGeoDataCard = (props: ServiceDriGeoDataCardProps) => {
  const [driGeoTeamData, setDriGeoTeamData] = React.useState<
    IGeoResilienceTeamData[]
  >([]);
  React.useMemo(() => {
    const currentDriTeams =
      props.currentDriGeoData.flatMap((p) =>
        p.geoResilienceTeamData.map((c) => {
          return {
            ...c,
            teamId: p.teamId,
            teamName: p.teamName,
          };
        })
      ) ?? [];
    setDriGeoTeamData(currentDriTeams);
  }, [props.currentDriGeoData]);

  const driGeoDropdownOptions = [
    { key: "region", text: "Region" },
    { key: "country", text: "Country" },
    { key: "city", text: "City" },
    { key: "facility", text: "Facility" },
  ];

  const [selectedItem, setSelectedItem] = React.useState<IDropdownOption>(
    driGeoDropdownOptions[0]
  );

  const onChange = (
    event: React.FormEvent<HTMLDivElement>,
    item: IDropdownOption
  ): void => {
    setSelectedItem(item);
  };

  return (
    <Stack>
      <Dropdown
        label="Grouping Options"
        selectedKey={selectedItem ? selectedItem.key : undefined}
        onChange={onChange}
        placeholder="Select an option"
        options={driGeoDropdownOptions}
        className={_Styles.dropdownSmall}
      />
      <ScrollablePane className={_Styles.wrapper}>
        <DriGeoDataGrid
          currentDriGeoTeamData={driGeoTeamData}
          groupingKey={selectedItem.key as string}
        />
      </ScrollablePane>
    </Stack>
  );
};
