import { MessageBar, MessageBarButton, MessageBarType, ScrollablePane } from "@fluentui/react";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { FilterBase } from "components/filter/FilterBase";
import { ProcessPivot } from "components/pivots/ProcessPivot";
import { _Styles } from "pages/Page.styles";
import React, { useCallback, useEffect, useState } from "react";
import { fetchProcessDetails } from "store/actions/processesActions";
import { getProcessAssetDetailsError } from "store/selectors/processesSelector";
import {
  getSelectedPhysicalCountries,
  getSelectedPhysicalRegions,
} from "store/selectors/regionsSelector";
import { useAppDispatch, useAppSelector } from "store/store";

export interface ProcessesProps {
  isNavCollapsed: boolean;
}
export const Processes = ({ isNavCollapsed }: ProcessesProps): JSX.Element => {
  const appInsights = useAppInsightsContext();
  const dispatch = useAppDispatch();
  const currentRegions = useAppSelector(getSelectedPhysicalRegions);
  const currentSelectedCountries = useAppSelector(getSelectedPhysicalCountries);
  // error setup
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const processApiErrors = useAppSelector(getProcessAssetDetailsError);

  const retryApiCallback = useCallback(() => {
    const fetchProcessData = () => {
      if (currentRegions?.length > 0) {
        dispatch(
          fetchProcessDetails(
            currentRegions.map((r) => r.regionDescription),
            true
          )
        );
      } else if (currentSelectedCountries?.length > 0) {
        dispatch(fetchProcessDetails(currentSelectedCountries, false));
      }
    };
    if (processApiErrors) {
      appInsights.trackEvent({ name: "Process fetch retried" });
      fetchProcessData();
    }
  }, [appInsights, currentRegions, currentSelectedCountries, dispatch, processApiErrors]);

  useEffect(() => {
    const errors = processApiErrors ?? "";
    const errorString =
      errors.length > 0 ? errors.concat("\u000AClick retry to attempt again") : null;
    if (errorString !== null) {
      setErrorMessage(errorString);
      setShowErrorMessage(true);
    }
  }, [processApiErrors]);

  return (
    <ScrollablePane
      className={isNavCollapsed ? _Styles.scrollablePaneCollapsed : _Styles.scrollablePaneExpand}
    >
      {showErrorMessage && (
        <MessageBar
          style={{ whiteSpace: "pre-wrap" }}
          messageBarType={MessageBarType.error}
          isMultiline={true}
          onDismiss={() => setShowErrorMessage(false)}
          dismissButtonAriaLabel="Close"
          actions={
            <div>
              <MessageBarButton onClick={retryApiCallback}>Retry</MessageBarButton>
            </div>
          }
        >
          {errorMessage}
        </MessageBar>
      )}
      <h1 className={_Styles.header}>Process Dashboard</h1>
      <FilterBase needsDcFilter={false} />
      <ProcessPivot />
    </ScrollablePane>
  );
};
