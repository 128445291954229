import { MessageBar, MessageBarButton, MessageBarType, ScrollablePane } from "@fluentui/react";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { FilterBase } from "components/filter/FilterBase";
import { FacilityDashboard } from "components/dashboards/FacilityDashboard";
import { _Styles } from "pages/Page.styles";
import React, { useEffect, useState } from "react";
import { fetchFacilities } from "store/actions/facilitiesActions";
import { getFacilitiesError, getSelectedCountries } from "store/selectors/facilitiesSelector";
import { getSelectedPhysicalRegions } from "store/selectors/regionsSelector";
import { useAppDispatch, useAppSelector } from "store/store";

export interface FacilitiesProps {
  isNavCollapsed: boolean;
}

export const Facilities = ({ isNavCollapsed }: FacilitiesProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const appInsights = useAppInsightsContext();
  const currentRegions = useAppSelector(getSelectedPhysicalRegions);
  const currentSelectedCountries = useAppSelector(getSelectedCountries);
  // error setup
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const facilityApiErrors = useAppSelector(getFacilitiesError);
  useEffect(() => {
    const errors = facilityApiErrors ?? "";
    const errorString =
      errors.length > 0 ? errors.concat("\u000AClick retry to attempt again") : null;
    if (errorString !== null) {
      setErrorMessage(errorString);
      setShowErrorMessage(true);
    }
  }, [facilityApiErrors]);

  const retryApiCalls = () => {
    if (facilityApiErrors) {
      appInsights.trackEvent({ name: "Facility fetch retried" });
      if (currentRegions.length !== 0) {
        dispatch(
          fetchFacilities(
            currentRegions.map((reg) => reg.regionDescription),
            true
          )
        );
      } else {
        dispatch(fetchFacilities(currentSelectedCountries, false));
      }
    }
  };

  return (
    <ScrollablePane
      className={isNavCollapsed ? _Styles.scrollablePaneCollapsed : _Styles.scrollablePaneExpand}
    >
      {showErrorMessage && (
        <MessageBar
          style={{ whiteSpace: "pre-wrap" }}
          messageBarType={MessageBarType.error}
          isMultiline={true}
          onDismiss={() => setShowErrorMessage(false)}
          dismissButtonAriaLabel="Close"
          actions={
            <div>
              <MessageBarButton onClick={() => retryApiCalls()}>Retry</MessageBarButton>
            </div>
          }
        >
          {errorMessage}
        </MessageBar>
      )}
      <h1 className={_Styles.header}>Facility Dashboard</h1>
      <FilterBase needsDcFilter={false} />
      <FacilityDashboard />
    </ScrollablePane>
  );
};
