import { Separator, Stack, Text, getTheme } from "@fluentui/react";
import {
  AuthenticationType,
  BubbleLayerOptions,
  MapMouseEvent,
  PopupOptions,
  data,
} from "azure-maps-control";
import { IFacilityModel } from "components/Types";
import { _Styles } from "pages/Page.styles";
import React, { useEffect, useMemo, useState } from "react";
import {
  AzureMap,
  AzureMapDataSourceProvider,
  AzureMapFeature,
  AzureMapLayerProvider,
  AzureMapPopup,
  AzureMapsProvider,
  ControlOptions,
  IAzureDataSourceChildren,
  IAzureMapFeature,
  IAzureMapOptions,
} from "react-azure-maps";
import { getFacilitiesOnMap } from "store/selectors/facilitiesSelector";
import { getAzDCsInMSRegion } from "store/selectors/regionsSelector";
import {
  getSystemAzureMapKey,
  getSystemAzureMapKeyLoadingStatus,
} from "store/selectors/systemSelector";
import { useAppSelector } from "store/store";

const theme = getTheme();

const renderPoint = (fc: facData): IAzureMapFeature => {
  const pinColor = getColor(fc.data.headCount);
  return (
    <AzureMapFeature
      key={fc.facility}
      id={fc.facility}
      type="Point"
      coordinate={fc.coordinates}
      properties={{
        id: fc.facility,
        title: fc.facility,
        data: fc.data,
        total: fc.data.headCount ?? 0,
        point: true,
        color: pinColor,
      }}
      bbox={data.BoundingBox.fromPositions([fc?.coordinates])}
    />
  );
};

//event helpers
function mouseOn(e: any) {
  e.map.getCanvas().style.cursor = "pointer";
}

function mouseLeave(e: any) {
  e.map.getCanvas().style.cursor = "";
}

//types
type facData = {
  facility: string;
  coordinates: data.Position;
  data: IFacilityModel;
};

type FacPopupData = {
  title: string;
  id: string;
  data: IFacilityModel;
};

export interface MapControllerProps {
  activeRegion?: string;
}

const activeRegionCenter = {
  MSUS: [-85, 43],
  "PUGET SOUND": [-122.133, 47.636],
  EMEA: [50, 35],
  ASIA: [125, 25],
  CANADA: [-78.91, 51.83],
  LATAM: [-45, -10],
};

const activeRegionZoomStart = {
  MSUS: 2.6,
  "PUGET SOUND": 6.3,
  CANADA: 2.5,
  ASIA: 2.1,
  EMEA: 1.25,
  LATAM: 1.6,
};

const MapController = (props: MapControllerProps) => {
  //camera
  const [center, setCenter] = useState([-122.133, 47.636]);
  const [currentZoom, setCurrentZoom] = useState<number>(2.6);
  //layer stuff: markers/popups/settings
  const [markers, setMarkers] = useState<facData[]>([]);
  const [popupProperties, setPopupProperties] = useState<FacPopupData>();
  const [showPopup, setShowPopup] = React.useState<boolean>(false);
  const [popupOptions, setPopupOptions] = useState<PopupOptions>({});
  const [layerOptions, setLayerOptions] = useState<BubbleLayerOptions>({
    //Scale the size of the clustered bubble based on the number of points inthe cluster.
    radius: [
      "step",
      ["get", "point_count"],
      20,
      10,
      30,
      50,
      40, //If point_count >= 750, radius is 40 pixels.
    ],

    //Change the color of the cluster based on the value on the point_cluster property of the cluster.
    color: [
      "step",
      ["get", "point_count"],
      theme.palette.themePrimary,
      50,
      theme.palette.themeSecondary,
      150,
      theme.palette.themePrimary,
    ],
    strokeWidth: 0,
    filter: ["has", "point_count"], //Only rendered data points which have a point_count property, which clusters do.
  });
  const facilitiesList = useAppSelector(getFacilitiesOnMap);
  const dcList = useAppSelector(getAzDCsInMSRegion);

  const mapKeyLoading = useAppSelector(getSystemAzureMapKeyLoadingStatus);
  const mapKey = useAppSelector(getSystemAzureMapKey);
  //options
  const option: IAzureMapOptions = useMemo(() => {
    return {
      authOptions: {
        authType: AuthenticationType.subscriptionKey,
        subscriptionKey: mapKey,
      },
      zoom: currentZoom,
      view: "Auto",
      showLogo: true,
      autoResize: true,
      language: "en-US",
    };
  }, [currentZoom, mapKey]);

  // Util function to add pin
  const addFacilityMarkers = (facilities: IFacilityModel[]) => {
    const tempMarkers: facData[] = [];
    facilities.forEach((fac) => {
      if (fac.facilityName && fac.latitude && fac.longitude) {
        const longitude = fac.longitude;
        const latitude = fac.latitude;
        const facPoint = new data.Position(longitude, latitude);
        const facMarker = {
          facility: fac.facilityName,
          coordinates: facPoint,
          data: fac,
        };

        tempMarkers.push(facMarker);
      }
    });
    setMarkers(tempMarkers);
    setCenter(activeRegionCenter[props.activeRegion]);
    setCurrentZoom(activeRegionZoomStart[props.activeRegion]);
  };

  const memoizedMarkerRender: IAzureDataSourceChildren = useMemo(
    (): any => markers.map((marker) => renderPoint(marker)),
    [markers]
  );

  useEffect(() => {
    if (facilitiesList.length > 0) {
      addFacilityMarkers(facilitiesList);
    }
  }, [facilitiesList]);

  function clusterClicked(e: any) {
    if (e && e.shapes && e.shapes?.length > 0 && e.shapes[0]?.properties?.cluster) {
      //Get the clustered point from the event.
      const cluster = e.shapes[0];

      //Get the cluster expansion zoom level. This is the zoom level at which the cluster starts to break apart.
      e.map.sources
        .getById("msmap AzureMapDataSourceProvider")
        .getClusterExpansionZoom(cluster.properties.cluster_id)
        .then(function (zoom: any) {
          //Update the map camera to be centered over the cluster.
          setCurrentZoom(zoom);
          setCenter(cluster.geometry.coordinates);
          e.map.setCamera({
            center: cluster.geometry.coordinates,
            zoom: zoom,
            type: "ease",
          });
        });
    }
  }

  const popUpTemplate = (data: IFacilityModel) => {
    if (data) {
      const isDC = data.facilityType === "Datacenter";
      const azDC = dcList.filter((dc) => data.facilityName.includes(dc.datacenterCampusName))[0];

      return (
        <div className={_Styles.popupStyles}>
          <Stack>
            <Text>{data?.facilityName}</Text>
            <Text>{data?.facilityRegion}</Text>
            <Text>{data?.facilityType}</Text>
            {isDC && (
              <Text>
                {" "}
                {azDC
                  ? `Azure Region Served: ${azDC.azureCloudRegionName}`
                  : `Ownership: ${data.ownershipType}, ${data.owner}`}{" "}
              </Text>
            )}
            <Separator></Separator>
            <Text>Approxiamate Assigned Employees</Text>
            <Text>FT/Intern: {data?.housedFtecount}</Text>
            <Text>Contingent: {data?.housedNonFtecount}</Text>
            <Text>Mobile: {data?.mobileHeadCount}</Text>
            <Text>Total: {data?.headCount}</Text>
            <Separator></Separator>
            <Text>Data Source: FAC03, HeadTrax</Text>
          </Stack>
        </div>
      );
    }
  };

  return (
    <>
      {" "}
      {!mapKeyLoading && (
        <AzureMapsProvider>
          <div className={_Styles.map}>
            <AzureMap
              options={option}
              cameraOptions={{
                center: center,
                zoom: currentZoom,
                type: "ease",
              }}
              controls={[
                {
                  controlName: "StyleControl",
                  controlOptions: {
                    mapStyles: [
                      "road",
                      "grayscale_light",
                      "grayscale_dark",
                      "night",
                      "road_shaded_relief",
                      "high_contrast_dark",
                      "high_contrast_light",
                    ],
                  },
                  options: { position: "top-left" } as ControlOptions,
                },
                {
                  controlName: "ZoomControl",
                  options: { position: "top-left" } as ControlOptions,
                },
              ]}
            >
              <AzureMapDataSourceProvider
                id={"msmap AzureMapDataSourceProvider"}
                options={{
                  cluster: true,
                  clusterRadius: 50,
                }}
              >
                <AzureMapLayerProvider
                  id={"msmap AzureMapLayerProvider"}
                  options={layerOptions}
                  type="BubbleLayer"
                  events={{
                    mouseenter: mouseOn,
                    mouseleave: mouseLeave,
                    click: clusterClicked,
                  }}
                />
                <AzureMapLayerProvider
                  id={"msmap2 AzureMapLayerProvider"}
                  options={{
                    iconOptions: {
                      image: "none",
                    },
                    textOptions: {
                      textField: ["get", "point_count_abbreviated"],
                      offset: [0, 0.4],
                    },
                  }}
                  type="SymbolLayer"
                ></AzureMapLayerProvider>
                <AzureMapLayerProvider
                  id={"msmap3 AzureMapLayerProvider"}
                  options={{
                    filter: ["!", ["has", "point_count"]], //Filter out clustered points from this layer.
                    textOptions: {
                      textField: ["get", "title"], //Specify the property name that contains the text you want to appear with the symbol.
                      offset: [0, 1.2],
                      allowOverlap: true,
                      color: theme.palette.themeDark,
                    },
                    color: [
                      "interpolate",
                      ["linear"],
                      ["get", "total"],
                      "green",
                      50,
                      "yellow",
                      100,
                      "orange",
                      1000,
                      "red",
                    ],
                  }}
                  events={{
                    mouseenter: mouseOn,
                    mouseleave: mouseLeave,
                    click: (e: MapMouseEvent) => {
                      if (e.shapes && e.shapes.length > 0) {
                        const prop: any = e.shapes[0];
                        if (prop.data?.geometry) {
                          // Set popup options
                          setShowPopup(true);
                          setPopupOptions({
                            position: new data.Position(
                              prop.data.geometry.coordinates[0],
                              prop.data.geometry.coordinates[1]
                            ),
                            pixelOffset: [0, -18],
                          });
                          if (prop.data?.properties) {
                            setPopupProperties({
                              ...prop.data.properties,
                            });
                          }
                          setCenter([
                            prop.data.geometry.coordinates[0],
                            prop.data.geometry.coordinates[1],
                          ]);
                          e.map.setCamera({
                            center: [
                              prop.data.geometry.coordinates[0],
                              prop.data.geometry.coordinates[1],
                            ],
                            type: "ease",
                          });
                        }
                      }
                    },
                  }}
                  type="SymbolLayer"
                ></AzureMapLayerProvider>
                {memoizedMarkerRender}
                <AzureMapPopup
                  isVisible={showPopup}
                  options={popupOptions}
                  popupContent={popUpTemplate(popupProperties?.data)}
                  events={[
                    {
                      eventName: "close",
                      callback: () => {
                        setShowPopup(false);
                      },
                    },
                  ]}
                />
              </AzureMapDataSourceProvider>
            </AzureMap>
          </div>
        </AzureMapsProvider>
      )}
    </>
  );
};

const getColor = (totalHeadCount: number) => {
  if (totalHeadCount > 0) {
    return theme.palette.magenta;
  }
  return theme.palette.neutralPrimary;
};

export default MapController;
