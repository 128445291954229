export const FETCH_AZURE_REGIONS_BEGIN = "FETCH_AZURE_REGIONS_BEGIN";
export const FETCH_AZURE_REGIONS_FAILURE = "FETCH_AZURE_REGIONS_FAILURE";
export const FETCH_AZURE_REGIONS_SUCCESS = "FETCH_AZURE_REGIONS_SUCCESS";
export const SET_SELECTED_AZURE_REGIONS = "SET_SELECTED_AZURE_REGIONS";

export const FETCH_PHYSICAL_REGIONS_BEGIN = "FETCH_PHYSICAL_REGIONS_BEGIN";
export const FETCH_PHYSICAL_REGIONS_FAILURE = "FETCH_PHYSICAL_REGIONS_FAILURE";
export const FETCH_PHYSICAL_REGIONS_SUCCESS = "FETCH_PHYSICAL_REGIONS_SUCCESS";
export const SET_SELECTED_PHYSICAL_REGIONS = "SET_SELECTED_PHYSICAL_REGIONS";
export const SET_SELECTED_PHYSICAL_COUNTRIES = "SET_SELECTED_PHYSICAL_COUNTRIES";

export const FETCH_FACILITIES_BEGIN = "FETCH_FACILITIES_BEGIN";
export const FETCH_FACILITIES_FAILURE = "FETCH_FACILITIES_FAILURE";
export const FETCH_FACILITIES_SUCCESS = "FETCH_FACILITIES_SUCCESS";

export const FETCH_FACILITY_COUNTS_BEGIN = "FETCH_FACILITY_COUNTS_BEGIN";
export const FETCH_FACILITY_COUNTS_FAILURE = "FETCH_FACILITY_COUNTS_FAILURE";
export const FETCH_FACILITY_COUNTS_SUCCESS = "FETCH_FACILITY_COUNTS_SUCCESS";

export const SET_FACILITY_COUNTRY_FILTER = "SET_FACILITY_COUNTRY_FILTER";
export const SET_FACILITY_STATE_FILTER = "SET_FACILITY_STATE_FILTER";
export const SET_FACILITY_CITY_FILTER = "SET_FACILITY_CITY_FILTER";
export const SET_FACILITY_MAP_FILTER = "SET_FACILITY_MAP_FILTER";
export const CLEAR_FACILITY_MAP_FILTER = "CLEAR_FACILITY_MAP_FILTER";
export const SET_FACILITY_GWS_FILTER = "SET_FACILITY_GWS_FILTER";
export const SET_FACILITY_TYPE_FILTER = "SET_FACILITY_TYPE_FILTER";
export const SET_FACILITY_CLASSIFICATION_FILTER = "SET_FACILITY_CLASSIFICATION_FILTER";
export const SET_FACILITY_ID_FILTER = "SET_FACILITY_ID_FILTER";
export const SET_FACILITY_DIVISION_FILTER = "SET_FACILITY_DIVISION_FILTER";

export const SET_FACILITY_ASSET_SEARCH_TERM = "SET_FACILITY_ASSET_SEARCH_TERM";

export const FETCH_SERVICE_COUNTS_BEGIN = "FETCH_SERVICE_COUNTS_BEGIN";
export const FETCH_SERVICE_COUNTS_FAILURE = "FETCH_SERVICE_COUNTS_FAILURE";
export const FETCH_SERVICE_COUNTS_SUCCESS = "FETCH_SERVICE_COUNTS_SUCCESS";

export const FETCH_SERVICE_DETAILS_BEGIN = "FETCH_SERVICE_DETAILS_BEGIN";
export const FETCH_SERVICE_DETAILS_FAILURE = "FETCH_SERVICE_DETAILS_FAILURE";
export const FETCH_SERVICE_DETAILS_SUCCESS = "FETCH_SERVICE_DETAILS_SUCCESS";
export const CLEAR_SERVICE_DETAILS = "CLEAR_SERVICE_DETAILS";

export const FETCH_SERVICE_DETAILS_BY_IDS_BEGIN = "FETCH_SERVICE_DETAILS_BY_IDS_BEGIN";
export const FETCH_SERVICE_DETAILS_BY_IDS_FAILURE = "FETCH_SERVICE_DETAILS_BY_IDS_FAILURE";
export const FETCH_SERVICE_DETAILS_BY_IDS_SUCCESS = "FETCH_SERVICE_DETAILS_BY_IDS_SUCCESS";
export const CLEAR_SERVICE_DETAILS_BY_IDS = "CLEAR_SERVICE_DETAILS_BY_IDS";

export const FETCH_SERVICE_DRI_INSIGHTS_BEGIN = "FETCH_SERVICE_DRI_INSIGHTS_BEGIN";
export const FETCH_SERVICE_DRI_INSIGHTS_FAILURE = "FETCH_SERVICE_DRI_INSIGHTS_FAILURE";
export const FETCH_SERVICE_DRI_INSIGHTS_SUCCESS = "FETCH_SERVICE_DRI_INSIGHTS_SUCCESS";

export const FETCH_SERVICE_DRI_GEO_RESIL_BEGIN = "  FETCH_SERVICE_DRI_GEO_RESIL_BEGIN";
export const FETCH_SERVICE_DRI_GEO_RESIL_FAILURE = "FETCH_SERVICE_DRI_GEO_RESIL_FAILURE";
export const FETCH_SERVICE_DRI_GEO_RESIL_SUCCESS = "FETCH_SERVICE_DRI_GEO_RESIL_SUCCESS";

export const FETCH_SERVICE_DRI_GEO_RESIL_COUNT_BEGIN = "  FETCH_SERVICE_DRI_GEO_RESIL_COUNT_BEGIN";
export const FETCH_SERVICE_DRI_GEO_RESIL_COUNT_FAILURE =
  "FETCH_SERVICE_DRI_GEO_RESIL_COUNT_FAILURE";
export const FETCH_SERVICE_DRI_GEO_RESIL_COUNT_SUCCESS =
  "FETCH_SERVICE_DRI_GEO_RESIL_COUNT_SUCCESS";

export const CLEAR_SERVICE_FILTERS = "CLEAR_SERVICE_FILTERS";
export const SET_SERVICE_DC_DETECTED_FILTER = "SET_SERVICE_DC_DETECTED_FILTER";
export const SET_SERVICE_SINGLE_DC_FILTER = "SET_SERVICE_SINGLE_DC_FILTER";
export const SET_SERVICE_CRITICALITY_FILTER = "SET_SERVICE_CRITICALITY_FILTER";
export const SET_SERVICE_DIVISION_FILTER = "SET_SERVICE_DIVISION_FILTER";
export const SET_SERVICE_ORGANIZATION_FILTER = "SET_SERVICE_ORGANIZATION_FILTER";
export const SET_SERVICE_SERVICEGROUP_FILTER = "SET_SERVICE_SERVICEGROUP_FILTER";
export const SET_SERVICE_DATA_CENTER_FILTER = "SET_SERVICE_DATA_CENTER_FILTER";
export const SET_SERVICE_RECOVERY_CLASSIFICATION_FILTER =
  "SET_SERVICE_RECOVERY_CLASSIFICATION_FILTER";
export const SET_SERVICE_BCL_FILTER = "SET_SERVICE_BCL_FILTER";
export const SET_SERVICE_CLOUD_FILTER = "SET_SERVICE_CLOUD_FILTER";
export const SET_SERVICE_ASSET_SEARCH_TERM = "SET_SERVICE_ASSET_SEARCH_TERM";
export const RESET_DRI_INSIGHT_ASSETS = "RESET_DRI_INSIGHT_ASSETS";
export const RESET_DRI_GEO_ASSETS = "RESET_DRI_GEO_ASSETS";

export const FETCH_PROCESS_COUNTS_BEGIN = "FETCH_PROCESS_COUNTS_BEGIN";
export const FETCH_PROCESS_COUNTS_FAILURE = "FETCH_PROCESS_COUNTS_FAILURE";
export const FETCH_PROCESS_COUNTS_SUCCESS = "FETCH_PROCESS_COUNTS_SUCCESS";

export const SET_PROCESS_CRITICALITY_FILTER = "SET_PROCESS_CRITICALITY_FILTER";
export const SET_PROCESS_DIVISION_FILTER = "SET_PROCESS_DIVISION_FILTER";
export const SET_PROCESS_FACILITY_FILTER = "SET_PROCESS_FACILITY_FILTER";

export const FETCH_PROCESS_DETAILS_BEGIN = "FETCH_PROCESS_DETAILS_BEGIN";
export const FETCH_PROCESS_DETAILS_FAILURE = "FETCH_PROCESS_DETAILS_FAILURE";
export const FETCH_PROCESS_DETAILS_SUCCESS = "FETCH_PROCESS_DETAILS_SUCCESS";
export const CLEAR_PROCESS_DETAILS = "CLEAR_PROCESS_DETAILS";

export const SET_PROCESS_DETAILS_SEARCH_TERM = "SET_PROCESS_DETAILS_SEARCH_TERM";

export const FETCH_PEOPLE_REGION_BEGIN = "FETCH_PEOPLE_REGION_BEGIN";
export const FETCH_PEOPLE_REGION_FAILURE = "FETCH_PEOPLE_REGION_FAILURE";
export const FETCH_PEOPLE_REGION_SUCCESS = "FETCH_PEOPLE_REGION_SUCCESS";
export const SET_ACTIVE_MANAGER_ALIAS = "SET_ACTIVE_MANAGER_ALIAS";
export const SET_ACTIVE_FACILITY_ID = "SET_ACTIVE_FACILITY_ID";
export const FETCH_PEOPLE_COUNTS_BEGIN = "FETCH_PEOPLE_COUNTS_BEGIN";
export const FETCH_PEOPLE_COUNTS_FAILURE = "FETCH_PEOPLE_COUNTS_FAILURE";
export const FETCH_PEOPLE_COUNTS_SUCCESS = "FETCH_PEOPLE_COUNTS_SUCCESS";

export const APPLY_PEOPLE_FILTERS = "APPLY_PEOPLE_FILTERS";
export const CLEAR_UNAPPLIED_PEOPLE_FILTERS = "CLEAR_UNAPPLIED_PEOPLE_FILTERS";
export const SET_UNAPPLIED_PEOPLE_FILTERS = "SET_UNAPPLIED_PEOPLE_FILTERS";
export const SET_PEOPLE_CITY_FILTER = "SET_PEOPLE_CITY_FILTER";
export const SET_PEOPLE_STATE_FILTER = "SET_PEOPLE_STATE_FILTER";
export const SET_PEOPLE_DIVISION_FILTER = "SET_PEOPLE_DIVISION_FILTER";
export const SET_PEOPLE_ORGANIZATION_FILTER = "SET_PEOPLE_ORGANIZATION_FILTER";
export const SET_PEOPLE_SERVICE_GROUP_FILTER = "SET_PEOPLE_SERVICE_GROUP_FILTER";
export const SET_PEOPLE_TEAM_GROUP_FILTER = "SET_PEOPLE_TEAM_GROUP_FILTER";
export const SET_PEOPLE_FAC_NAME_FILTER = "SET_PEOPLE_FAC_NAME_FILTER";
export const SET_PEOPLE_JOB_TITLE_SUMMARY_DESC_FILTER = "SET_PEOPLE_JOB_TITLE_SUMMARY_DESC_FILTER";

export const RESET_FACILITY_ASSETS = "RESET_FACILITY_ASSETS";
export const RESET_PEOPLE_ASSETS = "RESET_PEOPLE_ASSETS";

export const SET_USER = "SET_USER";

export const SET_CACHE_REFRESH_DATA = "SET_CACHE_REFRESH_DATA";
export const SET_CACHE_REFRESH_ERROR = "SET_CACHE_REFRESH_ERROR";
export const FETCH_CACHE_REFRESH_DATA_BEGIN = "FETCH_CACHE_REFRESH_DATA_BEGIN";

export const SET_AZURE_MAP_KEY_DATA = "SET_AZURE_MAP_KEY_DATA";
export const SET_AZURE_MAP_KEY_ERROR = "SET_AZURE_MAP_KEY_ERROR";
export const FETCH_AZURE_MAP_KEY_BEGIN = "FETCH_AZURE_MAP_KEY_BEGIN";
