import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import facilities from "store/reducers/facilitiesReducer";
import people, { unappliedPeopleFilter } from "store/reducers/peopleReducer";
import processes from "store/reducers/processesReducer";
import regions from "store/reducers/regionsReducer";
import serviceDependencyMapping from "store/reducers/serviceDependencyMappingReducer";
import services from "store/reducers/servicesReducer";
import system from "store/reducers/systemReducer";
import users from "store/reducers/userReducer";

const store = configureStore({
  reducer: {
    regions,
    facilities,
    services,
    processes,
    people,
    unappliedPeopleFilter,
    users,
    serviceDependencyMapping,
    system,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true,
      serializableCheck: false,
      immutableCheck: false,
    }),
});

export { store };

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useAppDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
