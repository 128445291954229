import { AxiosError } from "axios";
import {
  APPLY_PEOPLE_FILTERS,
  CLEAR_UNAPPLIED_PEOPLE_FILTERS,
  FETCH_PEOPLE_COUNTS_BEGIN,
  FETCH_PEOPLE_COUNTS_FAILURE,
  FETCH_PEOPLE_COUNTS_SUCCESS,
  FETCH_PEOPLE_REGION_BEGIN,
  FETCH_PEOPLE_REGION_FAILURE,
  FETCH_PEOPLE_REGION_SUCCESS,
  RESET_PEOPLE_ASSETS,
  SET_ACTIVE_MANAGER_ALIAS,
  SET_PEOPLE_CITY_FILTER,
  SET_PEOPLE_DIVISION_FILTER,
  SET_PEOPLE_FAC_NAME_FILTER,
  SET_PEOPLE_JOB_TITLE_SUMMARY_DESC_FILTER,
  SET_PEOPLE_ORGANIZATION_FILTER,
  SET_PEOPLE_SERVICE_GROUP_FILTER,
  SET_PEOPLE_STATE_FILTER,
  SET_PEOPLE_TEAM_GROUP_FILTER,
  SET_UNAPPLIED_PEOPLE_FILTERS,
} from "../actions/actionTypes";
import { NormalizedEmployeeProfile, PeopleCount } from "../../components/Types";

export interface ReduxPeopleFilterState {
  peopleStateFilters?: string[];
  peopleCityFilters?: string[];
  peopleDivFilters?: string[];
  peopleOrgFilters?: string[];
  peopleServiceGroupFilters?: string[];
  peopleTeamGroupFilters?: string[];
  peopleFacNameFilters?: string[];
  peopleJobTitleSummaryDescFilters?: string[];
}

const PeopleFilterInitialState: ReduxPeopleFilterState = {};

export function unappliedPeopleFilter(
  state: ReduxPeopleFilterState = PeopleFilterInitialState,
  action: any
) {
  switch (action.type) {
    case CLEAR_UNAPPLIED_PEOPLE_FILTERS:
      return {};
    case SET_UNAPPLIED_PEOPLE_FILTERS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export interface ReduxPeopleState extends ReduxPeopleFilterState {
  normalizedEmployeeProfiles: NormalizedEmployeeProfile[];
  isNormalizedEmployeeProfilesLoading: boolean;
  normalizedEmployeeProfilesError: string | AxiosError;
  peopleCounts: PeopleCount;
  isPeopleCountLoading: boolean;
  activeManagerAlias: string;
}

const PeopleInitialState: ReduxPeopleState = {
  normalizedEmployeeProfiles: [],
  isNormalizedEmployeeProfilesLoading: false,
  normalizedEmployeeProfilesError: null,
  activeManagerAlias: null,
  peopleCounts: null,
  isPeopleCountLoading: false,
  peopleCityFilters: [],
  peopleStateFilters: [],
  peopleDivFilters: [],
  peopleOrgFilters: [],
  peopleServiceGroupFilters: [],
  peopleTeamGroupFilters: [],
  peopleFacNameFilters: [],
  peopleJobTitleSummaryDescFilters: [],
};

export default function people(state: ReduxPeopleState = PeopleInitialState, action: any) {
  switch (action.type) {
    case FETCH_PEOPLE_REGION_BEGIN:
      return {
        ...state,
        isNormalizedEmployeeProfilesLoading: true,
        normalizedEmployeeProfilesError: null,
      };
    case FETCH_PEOPLE_REGION_SUCCESS:
      return {
        ...state,
        normalizedEmployeeProfiles: action.payload,
        isNormalizedEmployeeProfilesLoading: false,
        normalizedEmployeeProfilesError: null,
        activeManagerAlias: "",
      };
    case FETCH_PEOPLE_REGION_FAILURE:
      return {
        ...state,
        isNormalizedEmployeeProfilesLoading: false,
        normalizedEmployeeProfilesError: action.payload,
      };
    case FETCH_PEOPLE_COUNTS_BEGIN:
      return {
        ...state,
        isPeopleCountLoading: true,
        error: null,
      };
    case FETCH_PEOPLE_COUNTS_SUCCESS:
      return {
        ...state,
        isPeopleCountLoading: false,
        peopleCounts: action.payload,
        error: null,
      };
    case FETCH_PEOPLE_COUNTS_FAILURE:
      return {
        ...state,
        isPeopleCountLoading: false,
        error: action.payload,
      };
    case SET_ACTIVE_MANAGER_ALIAS:
      return {
        ...state,
        activeManagerAlias: action.payload,
      };
    case APPLY_PEOPLE_FILTERS:
      return {
        ...state,
        ...action.payload,
      };
    case SET_PEOPLE_CITY_FILTER:
      return {
        ...state,
        peopleCityFilters: action.payload,
      };
    case SET_PEOPLE_STATE_FILTER:
      return {
        ...state,
        peopleStateFilters: action.payload,
      };
    case SET_PEOPLE_DIVISION_FILTER:
      return {
        ...state,
        peopleDivFilters: action.payload,
      };
    case SET_PEOPLE_ORGANIZATION_FILTER:
      return {
        ...state,
        peopleOrgFilters: action.payload,
      };
    case SET_PEOPLE_SERVICE_GROUP_FILTER:
      return {
        ...state,
        peopleServiceGroupFilters: action.payload,
      };
    case SET_PEOPLE_TEAM_GROUP_FILTER:
      return {
        ...state,
        peopleTeamGroupFilters: action.payload,
      };
    case SET_PEOPLE_FAC_NAME_FILTER:
      return {
        ...state,
        peopleFacNameFilters: action.payload,
      };
    case SET_PEOPLE_JOB_TITLE_SUMMARY_DESC_FILTER:
      return {
        ...state,
        peopleJobTitleSummaryDescFilters: action.payload,
      };
    case RESET_PEOPLE_ASSETS:
      return {
        ...state,
        activeManagerAlias: "",
        normalizedEmployeeProfiles: [],
        peopleStateFilters: [],
        peopleCityFilters: [],
        peopleDivFilters: [],
        peopleOrgFilters: [],
        peopleServiceGroupFilters: [],
        peopleTeamGroupFilters: [],
        peopleFacNamepFilters: [],
        peopleJobTitleSummaryDescFilters: [],
      };
    default:
      return state;
  }
}
