import { ScrollablePane, getTheme, PivotItem } from "@fluentui/react";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import React from "react";
import { _Styles } from "./Page.styles";
import { InPageNav } from "@m365-admin/in-page-nav";
import {
  makePivotContents,
  pivotItems,
} from "../components/InPageNav/WikiData";

export interface WikiProps {
  isNavCollapsed: boolean;
}

const theme = getTheme();

export const Wiki = (props: WikiProps) => {
  const appInsights = useAppInsightsContext();
  const handleNavItemClick = (source: string) =>
    appInsights.trackEvent({ name: "Wiki Nav Item" }, { source: source });
  const handleNavGroupClick = (source: string) =>
    appInsights.trackEvent({ name: "Wiki Group Item" }, { source: source });
  const wikiPivotContents = makePivotContents(
    handleNavItemClick,
    handleNavGroupClick
  );
  const [selectedKey, setSelectedKey] = React.useState<string>("people");
  const handlePivotClick = (item: PivotItem) => {
    if (item.props.itemKey) {
      setSelectedKey(item.props.itemKey);
    }
  };

  return (
    <ScrollablePane
      className={
        props.isNavCollapsed
          ? _Styles.scrollablePaneCollapsed
          : _Styles.scrollablePaneExpand
      }
    >
      <h1 className={_Styles.header}>Wiki</h1>
      <InPageNav
        navAriaLabel="navigation for frequently asked questions"
        onPivotClick={(item) => {
          appInsights.trackEvent(
            { name: "Wiki Pivot" },
            { pivot: item?.props.headerText }
          );
          handlePivotClick(item);
        }}
        pivotContents={wikiPivotContents}
        pivotItems={pivotItems}
        pivotProps={{ selectedKey: selectedKey }}
        styles={{
          leftSection: {
            width: "25%",
            borderRight: `1px solid ${theme.semanticColors.bodyDivider}`,
          },
          rightSection: {
            width: "75%",
            paddingLeft: 20,
            paddingRight: 20,
          },
          pivotWrapper: {
            borderTop: `1px solid ${theme.semanticColors.bodyDivider}`,
          },
        }}
      />
    </ScrollablePane>
  );
};
