import * as React from "react";
import {
  CountAnnotationBar,
  CountAnnotationBarDirection,
  ICountAnnotationProps,
} from "@m365-admin/count-annotation";

import { ServiceBcdrDetailedMetadata } from "../../types/DependencyMappingTypes";
import { pieChartColorOptions } from "../charts/InfoDonutChart";
import { tryBuildDetailString } from "./CardHelpers";

export interface ServiceBcdrAnnotationCardProps {
  currentServiceMetadata: ServiceBcdrDetailedMetadata;
}

export const ServiceBcdrAnnotationCard = (
  props: ServiceBcdrAnnotationCardProps
) => {
  const bcdrSummaryData: ICountAnnotationProps[] = [
    {
      annotationText: "Approved RTO",
      count: tryBuildDetailString(
        props?.currentServiceMetadata?.approvedRto,
        "Unknown"
      ),
      annotationColor: pieChartColorOptions[4],
      size: 1,
    },
    {
      annotationText: "Approved RTC",
      count: tryBuildDetailString(
        props?.currentServiceMetadata?.coreServiceData?.approvedRtc,
        "Unknown"
      ),
      annotationColor: pieChartColorOptions[0],
      size: 1,
    },
    {
      annotationText: "Approved RPO",
      count: tryBuildDetailString(
        props.currentServiceMetadata?.approvedRpo,
        "Unknown"
      ),
      annotationColor: pieChartColorOptions[2],
      size: 1,
    },
    {
      annotationText: "Approved RPC",
      count: tryBuildDetailString(
        props?.currentServiceMetadata?.approvedRpc,
        "Unknown"
      ),
      annotationColor: pieChartColorOptions[3],
      size: 1,
    },
    {
      annotationText: "In Canary Central",
      count: convertBooleanToString(
        props?.currentServiceMetadata?.inCanaryCentral
      ),
      annotationColor: pieChartColorOptions[5],
      size: 1,
    },
    {
      annotationText: "In Canary East",
      count: convertBooleanToString(
        props?.currentServiceMetadata?.inCanaryEast
      ),
      annotationColor: pieChartColorOptions[6],
      size: 1,
    },
  ];

  return (
    <CountAnnotationBar
      countAnnotationProps={bcdrSummaryData}
      direction={CountAnnotationBarDirection.Horizontal}
    />
  );
};

function convertBooleanToString(input: boolean): string {
  switch (input) {
    case true:
      return "Yes";
    case false:
      return "No";
    default:
      return "Unknown";
  }
}
