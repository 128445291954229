import { ITag, TagItemSuggestion, TagPicker } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { fetchServiceDependencyDriData, fetchServiceDependencyGraphLinksData, fetchServiceDependencyMetadata } from "store/actions/serviceDependencyMappingActions";
import { getServiceTreeOptionsData, getServiceTreeOptionsLoading } from "store/selectors/serviceDependencyMappingSelector";
import { useAppDispatch, useAppSelector } from "store/store";
import { ServiceTreeObj } from "types/DependencyMappingTypes";
import { largeSearchBoxStyles } from "../pages/Page.styles";

export interface ServiceDepdencyPickerProps { }

export const ServiceDependencyPicker = () => {
  const dispatch = useAppDispatch();
  const [selectedTags, setSelectedTags] = useState<ITag[]>([]);
  const [serviceTreeOptions, setServiceTreeOptions] = useState<ITag[]>([]);
  const serviceTreeOptionsLoading = useAppSelector(getServiceTreeOptionsLoading);
  const serviceTreeOptionData = useAppSelector(getServiceTreeOptionsData);

  useEffect(() => {
    const formatServiceTreeObjToTag = (
      serviceTreeObj: ServiceTreeObj
    ): ITag => {
      return {
        key: serviceTreeObj.serviceOid,
        name: `${serviceTreeObj.serviceName} (${serviceTreeObj.serviceOid})`,
      };
    };
    const formattedTagOptions = serviceTreeOptionData.map((sto) =>
      formatServiceTreeObjToTag(sto)
    );
    setServiceTreeOptions(formattedTagOptions);
  }, [serviceTreeOptionData]);

  const onSearchChanged = (
    filterText: string,
    tagList: ITag[] | undefined
  ): ITag[] | PromiseLike<ITag[]> => {
    return filterText ? _getTags(filterText, tagList) : [];
  };

  const listContainsTagList = (tag: ITag, tagList?: ITag[] | undefined) => {
    if (!tagList || !tagList.length || tagList.length === 0) {
      return false;
    }
    return tagList.some((compareTag) => compareTag.key === tag.key);
  };

  const _getTags = (
    searchTerm: string,
    tagList: ITag[] | undefined
  ): ITag[] => {
    const lowerSearchTerm: string = searchTerm.toLowerCase();
    const matchedTags = serviceTreeOptions.filter((tag: ITag) => {
      return (
        tag.name.toLowerCase().includes(lowerSearchTerm) &&
        !listContainsTagList(tag, tagList)
      );
    });
    return matchedTags;
  };

  const onTagsChange = (items?: ITag[] | undefined): void => {
    // Items includes entire list of selected tags, not just the item that was updated
    if (!items || items.length === 0) return;
    setSelectedTags(items);
    dispatch(fetchServiceDependencyMetadata(items[0].key.toString()));
    dispatch(fetchServiceDependencyDriData(items[0].key.toString()));
    dispatch(fetchServiceDependencyGraphLinksData(items[0].key.toString()));
  };

  const onRenderSuggestionsItem = ({ name }: ITag) => <TagItemSuggestion>{name}</TagItemSuggestion>;

  return (
    <>
      <TagPicker
        styles={largeSearchBoxStyles}
        onResolveSuggestions={onSearchChanged}
        resolveDelay={200}
        pickerSuggestionsProps={{
          suggestionsHeaderText: "Suggested Services",
          noResultsFoundText: "No results found",
          loadingText: "Loading...",
        }}
        itemLimit={1}
        getTextFromItem={_getTextFromItem}
        defaultSelectedItems={selectedTags}
        removeButtonAriaLabel="Remove"
        inputProps={{
          placeholder: "Search by Service Tree Name",
          "aria-labelledby": "Service Tree Name or ID",
        }}
        disabled={serviceTreeOptionsLoading}
        onChange={onTagsChange}
        onRenderSuggestionsItem={onRenderSuggestionsItem}
      />
    </>
  );
};

function _getTextFromItem({ name }: ITag) { return name; }
