import * as React from "react";
import {
  DefaultButton,
  Dropdown,
  IDropdownOption,
  PrimaryButton,
  Slider,
  Stack,
} from "@fluentui/react";
import { ILegend, Legends } from "@fluentui/react-charting";
import {
  DownstreamDependencyData,
  UpstreamDependencyData,
} from "../../types/DependencyMappingTypes";
import { ServiceDependencyGraph } from "../charts/dependencyGraph/ServiceDependencyGraph";
import { _Styles, defaultStackTokens } from "../../pages/Page.styles";
import {
  Link,
  defaultDependencyLegend,
} from "../charts/dependencyGraph/ServiceDependencyGraph.types";
export interface ServiceDependencyGraphCardProps {
  mapHeight: number;
  mapWidth: number;
  currentServiceDependencyGraphData: { links: Link[]; nodes: any[] };
  legend?: ILegend[];
  onSelectedDownstreamItem: (selectedItem: DownstreamDependencyData) => void;
  onSelectedUpstreamItem: (selectedItem: UpstreamDependencyData) => void;
}

export const ServiceDependencyGraphCard = (
  props: ServiceDependencyGraphCardProps
) => {
  const defaultMaxLinks = 50;
  const [graphData, setGraphData] = React.useState<{
    links: Link[];
    nodes: any[];
  }>({ links: [], nodes: [] });

  const [graphLayerOptions, setGraphLayerOptions] =
    React.useState<IDropdownOption[]>();
  const [selectedKeys, setSelectedKeys] = React.useState<string[]>([]);
  const onDropdownChange = (
    event: React.FormEvent<HTMLDivElement>,
    item: IDropdownOption
  ): void => {
    if (item) {
      const newKeys = item.selected
        ? [...selectedKeys, item.key as string]
        : selectedKeys.filter((key) => key !== item.key);
      setSelectedKeys(newKeys);
    }
  };

  const [dependencyLevelValue, setDependencyLevelValue] = React.useState(2);
  const onSliderChange = (value: number) => setDependencyLevelValue(value);

  React.useEffect(() => {
    if (
      props.currentServiceDependencyGraphData?.links.length > defaultMaxLinks &&
      props.currentServiceDependencyGraphData.nodes.length > 0
    ) {
      setDependencyLevelValue(1);
      setSelectedKeys(["upstream"]);
      const filteredLinks =
        props.currentServiceDependencyGraphData.links.filter(
          (l) => l.relationshipLevel === 0 && l.direction === "upstream"
        );
      const filteredNodes =
        props.currentServiceDependencyGraphData.nodes.filter((d) =>
          filteredLinks?.some((l) => l.source === d.id || l.target === d.id)
        );
      setGraphData({
        links: filteredLinks ?? [],
        nodes: filteredNodes,
      });
    } else {
      setGraphData({
        links: props.currentServiceDependencyGraphData.links ?? [],
        nodes: props.currentServiceDependencyGraphData.nodes ?? [],
      });
    }

    setGraphLayerOptions(
      Array.from(
        new Set(
          props.currentServiceDependencyGraphData.links.map((d) => d.direction)
        )
      ).map((d) => ({ key: d, text: d }))
    );
  }, [props.currentServiceDependencyGraphData]);

  const updateGraphData = (
    dependencyLevelValue: number,
    selectedKeys: string[],
    graphDataToFilter: {
      links: Link[];
      nodes: any[];
    }
  ) => {
    // filter links based on selected keys
    let filteredLinks: Link[] =
      selectedKeys.length > 0
        ? graphDataToFilter.links.filter((d) =>
            selectedKeys.includes(d.direction)
          )
        : graphDataToFilter.links;
    // filter links based on dependency level
    filteredLinks = filteredLinks.filter(
      (d) => d.relationshipLevel < dependencyLevelValue
    );

    // filtered nodes based on the links that are left
    const filteredNodes = graphDataToFilter.nodes.filter((d) =>
      filteredLinks.some((l) =>
        l.source?.id
          ? l.source.id === d.id || l.target.id === d.id
          : l.source === d.id || l.target === d.id
      )
    );
    // reset the graph data
    setGraphData({ links: filteredLinks, nodes: filteredNodes });
  };

  const handleClick = (e, node: any) => {
    e.preventDefault();
    if (node.data) {
      const upstream =
        (node.data as UpstreamDependencyData)?.criticalDataRecoveryRpo?.length >
          0 ?? undefined;

      if (upstream) {
        props.onSelectedUpstreamItem(node.data);
      } else {
        props.onSelectedDownstreamItem(node.data);
      }
    }
  };

  const legends = props.legend ?? defaultDependencyLegend;

  return (
    <div>
      <Stack
        tokens={defaultStackTokens}
        className={_Styles.stackStyleDefault}
        horizontal
        verticalAlign="end"
        horizontalAlign="start"
        aria-label="Service dependency graph controls. Use apply to update the graph. Use reset to reset the graph to the default state."
      >
        <Slider
          className={_Styles.sliderSmall}
          label="Dependency level"
          max={2}
          value={dependencyLevelValue}
          showValue
          onChange={onSliderChange}
        />
        <Dropdown
          placeholder="Select layers to view"
          label="Dependency type"
          options={graphLayerOptions}
          selectedKeys={selectedKeys}
          onChange={onDropdownChange}
          multiSelect
          className={_Styles.dropdownSmall}
          disabled={graphLayerOptions?.length === 0}
        />
        <PrimaryButton
          text="Apply"
          onClick={() =>
            updateGraphData(
              dependencyLevelValue,
              selectedKeys,
              props.currentServiceDependencyGraphData
            )
          }
        />
        <DefaultButton
          text="Reset"
          onClick={() => {
            setSelectedKeys([]);
            if (
              props.currentServiceDependencyGraphData.links.length >
              defaultMaxLinks
            ) {
              setDependencyLevelValue(1);
              updateGraphData(
                1,
                ["upstream"],
                props.currentServiceDependencyGraphData
              );
            } else {
              setDependencyLevelValue(2);
              setGraphData(props.currentServiceDependencyGraphData);
            }
          }}
        />
      </Stack>
      <ServiceDependencyGraph
        links={graphData.links}
        nodes={graphData.nodes}
        height={props.mapHeight}
        width={props.mapWidth}
        legend={props.legend ?? defaultDependencyLegend}
        handleOnClick={handleClick}
      />
      <Legends
        legends={legends}
        canSelectMultipleLegends={false}
        aria-describedby={"legendTooltip"}
      />
    </div>
  );
};
