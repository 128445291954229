import {
  IColumn,
  IColumnDragDropDetails,
  IColumnReorderOptions,
  IDetailsListCheckboxProps,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
} from "@fluentui/react";
import { IFacilityModel } from "components/Types";
import { convertIntBooleanToString, copyAndSort } from "components/datagrids/Helpers";
import React from "react";
import { getFacilitiesLoadingStatus } from "store/selectors/facilitiesSelector";
import { getPeopleDirectsLoadingStatus } from "store/selectors/peopleSelector";
import { useAppSelector } from "store/store";

export interface FacilityDataGridProps {
  filteredFacilityData: IFacilityModel[];
  openGridPanel: (selectedFacility: IFacilityModel) => void;
}

export default function FacilityDataGrid({
  filteredFacilityData,
  openGridPanel,
}: FacilityDataGridProps) {
  const getFacilityLoadingStatus = useAppSelector(getFacilitiesLoadingStatus);
  const getPeopleDataLoadingStatus = useAppSelector(getPeopleDirectsLoadingStatus);
  const [tableData, setTableData] = React.useState<IFacilityModel[]>(filteredFacilityData);

  React.useEffect(() => {
    setTableData(filteredFacilityData);
  }, [filteredFacilityData]);

  const selection = React.useMemo(
    () =>
      new Selection({
        getKey: (item) => item["facilityId"],
        onSelectionChanged: () => {
          const selectedFacility = selection.getSelection()[0] as IFacilityModel;
          openGridPanel(selectedFacility);
        },
      }),
    [openGridPanel]
  );

  const onFacilityPeopleColumnClick = React.useCallback(
    (_ev, column: IColumn): void => {
      const columns = dataGridColumns;
      const newColumns: IColumn[] = columns.slice();
      const currColumn: IColumn = newColumns.filter((currCol) => column.key === currCol.key)[0];
      newColumns.forEach((newCol: IColumn) => {
        if (newCol === currColumn) {
          currColumn.isSortedDescending = !currColumn.isSortedDescending;
          currColumn.isSorted = true;
        } else {
          newCol.isSorted = false;
          newCol.isSortedDescending = true;
        }
      });

      const newItems = copyAndSort(tableData, currColumn.key!, currColumn.isSortedDescending);
      setDataGridColumns(newColumns);
      setTableData([...newItems]);
    },
    [tableData]
  );

  const fullColumns: IColumn[] = [
    {
      key: "facilityName",
      name: "Facility Name",
      ariaLabel: "Facility Name",
      fieldName: "facilityName",
      data: "string",
      minWidth: 150,
      isResizable: true,
      isRowHeader: true,
      onColumnClick: onFacilityPeopleColumnClick,
    },
    {
      key: "facilityType",
      name: "Facility Type",
      ariaLabel: "Facility Type",
      fieldName: "facilityType",
      data: "string",
      minWidth: 100,
      isResizable: true,
      onRender: (item: IFacilityModel) => {
        if (item.source === "COI") {
          return "CO+I DC";
        } else if (item.source === "FAC03") {
          return "RE&F DC";
        } else {
          return item.facilityType;
        }
      },
      onColumnClick: onFacilityPeopleColumnClick,
    },
    {
      key: "cityState",
      name: "City/State",
      ariaLabel: "City and state",
      data: "string",
      minWidth: 150,
      isResizable: true,
      onRender: (x: IFacilityModel) => `${x.city} ${x.state ? `, ${x.state}` : ``}`,
      onColumnClick: onFacilityPeopleColumnClick,
    },
    {
      key: "country",
      name: "Country",
      fieldName: "country",
      data: "string",
      minWidth: 100,
      isResizable: true,
      onColumnClick: onFacilityPeopleColumnClick,
    },
    {
      key: "containsEssentials",
      name: "Essential Employees",
      ariaLabel: "Essential Employees",
      fieldName: "containsEssentials",
      data: "string",
      minWidth: 150,
      isResizable: true,
      onRender: (x: IFacilityModel) => `${convertIntBooleanToString(x?.containsEssentials)}`,
      onColumnClick: onFacilityPeopleColumnClick,
    },
    {
      key: "housedHeadcount",
      name: "Assigned Headcount",
      fieldName: "housedHeadcount",
      data: "number",
      minWidth: 150,
      isResizable: true,
      onColumnClick: onFacilityPeopleColumnClick,
    },
    {
      key: "mobileHeadCount",
      name: "Mobile Headcount",
      ariaLabel: "Mobile Headcount",
      fieldName: "mobileHeadCount",
      data: "string",
      minWidth: 150,
      isResizable: true,
      onColumnClick: onFacilityPeopleColumnClick,
    },
    {
      key: "buildingClassification",
      name: "GWS Facility Criticality",
      ariaLabel: "GWS Facility Criticality",
      fieldName: "buildingClassification",
      data: "string",
      minWidth: 150,
      isResizable: true,
      onColumnClick: onFacilityPeopleColumnClick,
    },
    {
      key: "sysLastModifiedDateTime",
      name: "Last Modified Time",
      ariaLabel: "Last Modified Time",
      fieldName: "sysLastModifiedDateTime",
      data: "date",
      minWidth: 150,
      isResizable: true,
      onRender: (x: IFacilityModel) => {
        return new Date(x.sysLastModifiedDateTime).toLocaleDateString("en-US", {
          day: "numeric",
          month: "short",
          year: "numeric",
        });
      },
    },
  ];

  const [dataGridColumns, setDataGridColumns] = React.useState<IColumn[]>(fullColumns);

  const columnReorderOptions: IColumnReorderOptions = {
    frozenColumnCountFromStart: 1,
    onColumnDrop: (dragDropDetails: IColumnDragDropDetails) => {
      const tempColumns = [...dataGridColumns];
      tempColumns.splice(
        dragDropDetails.targetIndex,
        0,
        tempColumns.splice(dragDropDetails.draggedIndex, 1)[0]
      );
      setDataGridColumns(tempColumns);
    },
  };

  return (
    <ShimmeredDetailsList
      columns={dataGridColumns}
      items={tableData}
      compact={true}
      selection={selection}
      selectionMode={SelectionMode.single}
      columnReorderOptions={columnReorderOptions}
      onRenderCheckbox={({ checked }: IDetailsListCheckboxProps) => (
        <input type="radio" checked={checked} aria-label="radio" />
      )}
      ariaLabel={"Facilities by selected region and any applied filters."}
      getRowAriaLabel={(facility: IFacilityModel) => `${facility.facilityName} details`}
      enableShimmer={getFacilityLoadingStatus || getPeopleDataLoadingStatus}
    />
  );
}
