import { AxiosError } from "axios";
import { stringify } from "querystring";
import { Dispatch } from "redux";
import { ServiceAssetDetail, ServiceCount } from "../../components/Types";
import { appInsights } from "../../services/AppInsights";
import apiBase from "../../services/apiBase";
import {
  CLEAR_SERVICE_DETAILS,
  CLEAR_SERVICE_DETAILS_BY_IDS,
  CLEAR_SERVICE_FILTERS,
  FETCH_SERVICE_COUNTS_BEGIN,
  FETCH_SERVICE_COUNTS_FAILURE,
  FETCH_SERVICE_COUNTS_SUCCESS,
  FETCH_SERVICE_DETAILS_BEGIN,
  FETCH_SERVICE_DETAILS_BY_IDS_BEGIN,
  FETCH_SERVICE_DETAILS_BY_IDS_FAILURE,
  FETCH_SERVICE_DETAILS_BY_IDS_SUCCESS,
  FETCH_SERVICE_DETAILS_FAILURE,
  FETCH_SERVICE_DETAILS_SUCCESS,
  SET_SERVICE_ASSET_SEARCH_TERM,
  SET_SERVICE_BCL_FILTER,
  SET_SERVICE_CLOUD_FILTER,
  SET_SERVICE_CRITICALITY_FILTER,
  SET_SERVICE_DATA_CENTER_FILTER,
  SET_SERVICE_DC_DETECTED_FILTER,
  SET_SERVICE_DIVISION_FILTER,
  SET_SERVICE_ORGANIZATION_FILTER,
  SET_SERVICE_RECOVERY_CLASSIFICATION_FILTER,
  SET_SERVICE_SERVICEGROUP_FILTER,
  SET_SERVICE_SINGLE_DC_FILTER,
} from "./actionTypes";

const serviceCountsApiEndpoint: string = "api/Services/GetAggregatedServiceCount";
const serviceDetailsByCountryApiEndpoint: string = "api/Services/GetServiceAssetsByCountry";
const serviceDetailsByIdsApiEndpoint: string = "api/Services/ServiceAssetsByIds";

export const fetchServiceCountsBegin = () => ({
  type: FETCH_SERVICE_COUNTS_BEGIN,
});

export const fetchServiceCountsSuccess = (payload: ServiceCount[]) => ({
  type: FETCH_SERVICE_COUNTS_SUCCESS,
  payload,
});

export const fetchServiceCountsFailure = (error: string | AxiosError) => ({
  type: FETCH_SERVICE_COUNTS_FAILURE,
  payload: error,
});

export const fetchServiceCounts = () => {
  return async (dispatch: Dispatch) => {
    dispatch(fetchServiceCountsBegin());
    try {
      const res = await apiBase.get(serviceCountsApiEndpoint);
      if (res.data) {
        dispatch(fetchServiceCountsSuccess(res.data));
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        dispatch(
          fetchServiceCountsFailure(
            "Error calling Service API due to your permissions. Please reach out to watchtower support to confirm your access."
          )
        );
      } else {
        dispatch(fetchServiceCountsFailure(err));
      }
      appInsights.trackException(err);
    }
  };
};

export const fetchServiceDetailsBegin = () => ({
  type: FETCH_SERVICE_DETAILS_BEGIN,
});

export const fetchServiceDetailsSuccess = (payload: ServiceAssetDetail[]) => ({
  type: FETCH_SERVICE_DETAILS_SUCCESS,
  payload,
});

export const fetchServiceDetailsFailure = (error: string | AxiosError) => ({
  type: FETCH_SERVICE_DETAILS_FAILURE,
  payload: error,
});

export const fetchServiceDetails = (countryLocationsToFetch: string[]) => {
  return async (dispatch: Dispatch) => {
    dispatch(clearServiceDetails());
    dispatch(fetchServiceDetailsBegin());

    try {
      const res = await apiBase.get(serviceDetailsByCountryApiEndpoint, {
        params: {
          countryList: countryLocationsToFetch.length > 0 ? countryLocationsToFetch : "UNKNOWN",
        },
        paramsSerializer: (params) => {
          return stringify(params);
        },
      });
      if (res.data) {
        dispatch(fetchServiceDetailsSuccess(res.data));
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        dispatch(
          fetchServiceDetailsFailure(
            "Error calling Service Asset API due to your permissions. Please reach out to watchtower support to confirm your access."
          )
        );
      } else {
        dispatch(fetchServiceDetailsFailure(err));
      }
      appInsights.trackException(err);
    }
  };
};

export const clearServiceDetails = () => ({
  type: CLEAR_SERVICE_DETAILS,
});

export const fetchServiceDetailsByIdsBegin = () => ({
  type: FETCH_SERVICE_DETAILS_BY_IDS_BEGIN,
});

export const fetchServiceDetailsByIdsSuccess = (payload: ServiceAssetDetail[]) => ({
  type: FETCH_SERVICE_DETAILS_BY_IDS_SUCCESS,
  payload,
});

export const fetchServiceDetailsByIdsFailure = (error: string | AxiosError) => ({
  type: FETCH_SERVICE_DETAILS_BY_IDS_FAILURE,
  payload: error,
});

export const fetchServiceDetailsByIds = (idsToFetch: string[]) => {
  return async (dispatch: Dispatch) => {
    dispatch(clearServiceDetailsByIds());
    dispatch(fetchServiceDetailsByIdsBegin());

    try {
      if (idsToFetch.length > 0) {
        const res = await apiBase.get(serviceDetailsByIdsApiEndpoint, {
          params: {
            serviceIdList: idsToFetch,
          },
          paramsSerializer: (params) => {
            return stringify(params);
          },
        });
        if (res.data) {
          dispatch(fetchServiceDetailsByIdsSuccess(res.data));
        }
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        dispatch(
          fetchServiceDetailsByIdsFailure(
            "Error calling Service Asset API due to your permissions. Please reach out to watchtower support to confirm your access."
          )
        );
      } else {
        dispatch(fetchServiceDetailsByIdsFailure(err));
      }
      appInsights.trackException(err);
    }
  };
};

export const clearServiceDetailsByIds = () => ({
  type: CLEAR_SERVICE_DETAILS_BY_IDS,
});

export const clearAllServiceFilters = () => ({
  type: CLEAR_SERVICE_FILTERS,
});

export const updateServiceCriticalityFilters = (payload: string[]) => ({
  type: SET_SERVICE_CRITICALITY_FILTER,
  payload,
});

export const updateServiceSingleDcFilter = (payload: boolean) => ({
  type: SET_SERVICE_SINGLE_DC_FILTER,
  payload,
});

export const updateServiceDivisionFilters = (payload: string[]) => ({
  type: SET_SERVICE_DIVISION_FILTER,
  payload,
});

export const updateServiceOrganizationFilters = (payload: string[]) => ({
  type: SET_SERVICE_ORGANIZATION_FILTER,
  payload,
});

export const updateServiceServiceGroupFilters = (payload: string[]) => ({
  type: SET_SERVICE_SERVICEGROUP_FILTER,
  payload,
});

export const updateServiceDataCenterFilters = (payload: number[]) => ({
  type: SET_SERVICE_DATA_CENTER_FILTER,
  payload,
});

export const updateServiceRecoveryClassificationFilters = (payload: string[]) => ({
  type: SET_SERVICE_RECOVERY_CLASSIFICATION_FILTER,
  payload,
});

export const updateServiceBclFilters = (payload: string[]) => ({
  type: SET_SERVICE_BCL_FILTER,
  payload,
});

export const updateServiceCloudFilters = (payload: string[]) => ({
  type: SET_SERVICE_CLOUD_FILTER,
  payload,
});

export const updateServiceAssetSearchTerm = (payload: string) => ({
  type: SET_SERVICE_ASSET_SEARCH_TERM,
  payload,
});

export const updateDcDetectedFilter = (payload: DcDetected) => ({
  type: SET_SERVICE_DC_DETECTED_FILTER,
  payload,
});

export enum DcDetected {
  "ALL",
  "DC_DETECTED",
  "DC_NOT_DETECTED",
}
