import { CountAnnotationBar, CountAnnotationBarDirection, ICountAnnotationProps } from '@m365-admin/count-annotation';
import React from 'react';
import { getServiceCounts } from 'store/selectors/servicesSelector';
import { useAppSelector } from 'store/store';

export interface ServiceAggregateCountBarProps {}
export const ServiceAggregateCountBar = () => {
  const [serviceCountProps, setServiceCountProps] = React.useState<ICountAnnotationProps[]>([]);
  const serviceAggCounts: ICountAnnotationProps[] = useAppSelector(getServiceCounts());

  React.useMemo(() => {
    const serviceCounts = serviceAggCounts;
    if (serviceAggCounts[0]?.count !== serviceCountProps[0]?.count)
      setServiceCountProps(serviceCounts);
  }, [serviceAggCounts]);

  return (
    <CountAnnotationBar
      countAnnotationProps={serviceCountProps}
      direction={CountAnnotationBarDirection.Vertical}
    />
  );
};
