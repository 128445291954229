import { AxiosError } from "axios";
import { Dispatch } from "redux";
import apiBase from "../../services/apiBase";
import {
  FETCH_AZURE_MAP_KEY_BEGIN,
  FETCH_CACHE_REFRESH_DATA_BEGIN,
  SET_AZURE_MAP_KEY_DATA,
  SET_CACHE_REFRESH_DATA,
  SET_CACHE_REFRESH_ERROR,
} from "./actionTypes";

const systemEndPoint: string = "/api/System";

export const fetchCacheRefreshDataBegin = () => ({
  type: FETCH_CACHE_REFRESH_DATA_BEGIN,
});

export const setSystemCacheData = (data: any) => ({
  type: SET_CACHE_REFRESH_DATA,
  payload: data,
});

export const setSystemCacheDataFailure = (error: AxiosError | string) => ({
  type: SET_CACHE_REFRESH_ERROR,
  payload: error,
});

export const fetchSystemCacheData = () => {
  return async (dispatch: Dispatch) => {
    dispatch(fetchCacheRefreshDataBegin());
    try {
      const res = await apiBase.get(systemEndPoint);
      if (res.data) {
        dispatch(setSystemCacheData(res.data));
      }
    } catch (err) {
      dispatch(setSystemCacheDataFailure(err));
    }
  };
};

export const fetchAzureMapKeyDataBegin = () => ({
  type: FETCH_AZURE_MAP_KEY_BEGIN,
});

export const setAzureMapKeyData = (data: any) => ({
  type: SET_AZURE_MAP_KEY_DATA,
  payload: data,
});

export const setAzureMapKeyFailure = (error: AxiosError | string) => ({
  type: SET_CACHE_REFRESH_ERROR,
  payload: error,
});

export const fetchAzureMapKeyData = () => {
  return async (dispatch: Dispatch) => {
    dispatch(fetchAzureMapKeyDataBegin());
    try {
      const res = await apiBase.get(systemEndPoint + "/GetAzureMapKey");
      if (res.data) {
        dispatch(setAzureMapKeyData(res.data));
      }
    } catch (err) {
      dispatch(setAzureMapKeyFailure(err));
    }
  };
};
