import * as React from "react";
import { Stack, Label, Link, Text, useTheme } from "@fluentui/react";
import { ServiceBcdrDetailedMetadata } from "../../types/DependencyMappingTypes";
import {
  buildComplianceStatusIcons,
  tryBuildDetailString,
} from "./CardHelpers";

export interface ServiceBcdrDetailCardProps {
  currentServiceMetadata: ServiceBcdrDetailedMetadata;
}

export const ServiceBcdrDetailCard = (props: ServiceBcdrDetailCardProps) => {
  let theme = useTheme();

  return (
    <Stack horizontal tokens={{ childrenGap: 16 }}>
      <Stack>
        <Label>RTO Criticality</Label>
        <Text>
          {props?.currentServiceMetadata?.criticalityRating ?? "No data"}
        </Text>
      </Stack>
      <Stack>
        <Label>Recovery Classification</Label>
        <Text>
          {tryBuildDetailString(
            props?.currentServiceMetadata?.bcdrRecoveryClassification,
            "No data"
          )}
        </Text>
      </Stack>
      <Stack>
        <Label>Compliance Status</Label>
        {buildComplianceStatusIcons(
          props?.currentServiceMetadata?.complianceStatus,
          theme
        )}
      </Stack>
      <Stack>
        <Label>Lifecylce Status</Label>
        <Text>
          {tryBuildDetailString(
            props?.currentServiceMetadata?.maxLifecycle,
            "No data"
          )}
        </Text>
      </Stack>
      <Stack>
        <Label>Criticality Criteria</Label>
        <Text>
          {tryBuildDetailString(
            props?.currentServiceMetadata?.criticalityCriteria?.join(","),
            "No data"
          )}
        </Text>
      </Stack>
      <Stack tokens={{ childrenGap: 8 }}>
        <Link
          href={props?.currentServiceMetadata?.coreServiceData?.serviceTreeLink}
          target="_blank"
        >
          See Service Tree Entry
        </Link>
        <Link
          href={props?.currentServiceMetadata?.assessmentLink}
          target="_blank"
        >
          See BCDR Assessment Entry
        </Link>
      </Stack>
    </Stack>
  );
};
