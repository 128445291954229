import * as React from "react";
import { CoherenceNav, INavLinkGroup } from "@coherence-design-system/controls";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useLocation, useNavigate } from "react-router-dom";

import { buildPageViewTelemetry } from "../datagrids/Helpers";

export interface LeftNavProps {
  onNavCollapsed: (isCollapsed: boolean) => void;
}

export const LeftNav = (props: LeftNavProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  let url = location.pathname.split("/")[1];
  const [currentPage, setCurrentPage] = React.useState<string>(url);
  const appInsights = useAppInsightsContext();
  const [defaultDependenciesExpanded, setDefaultDependenciesExpanded] = React.useState<boolean>(false);

  const iconSpacingWithoutIcon = { text: { marginLeft: "32px" } }
  const navGroups: INavLinkGroup[] = [
    {
      key: "LeftNavMenu1",
      links: [
        {
          name: "Overview",
          key: "HomeLink1",
          ariaLabel: "Overview Dashboard",
          icon: "Home",
          target: "_blank",
          onClick: () => {
            const telemetry = buildPageViewTelemetry("Overview", "/", currentPage ?? "/");
            setCurrentPage("");
            appInsights.trackPageView(telemetry);
            navigate("/");
          },
          isSelected: currentPage === "" ? true : false,
        },

        {
          name: "People",
          key: "People",
          styles: iconSpacingWithoutIcon,
          ariaLabel: "People Dashboard",
          target: "_blank",
          onClick: () => {
            const telemetry = buildPageViewTelemetry(
              "People Dashboard",
              "/people",
              currentPage
            );
            appInsights.trackPageView(telemetry);
            setCurrentPage("people");
            navigate("/people");
          },
          isSelected: currentPage === "people" ? true : false,
        },
        {
          name: "Facilities",
          key: "Facilities",
          styles: iconSpacingWithoutIcon,
          ariaLabel: "Facilities Dashboard",
          target: "_blank",
          onClick: () => {
            const telemetry = buildPageViewTelemetry(
              "Facilities Dashboard",
              "/facilities",
              currentPage
            );
            appInsights.trackPageView(telemetry);
            setCurrentPage("facilities");
            navigate("/facilities");
          },
          isSelected: currentPage === "facilities" ? true : false,
        },
        {
          name: "Services",
          key: "Services",
          ariaLabel: "Services Dashboard",
          styles: iconSpacingWithoutIcon,
          target: "_blank",
          onClick: () => {
            const telemetry = buildPageViewTelemetry(
              "Services Dashboard",
              "/services",
              currentPage
            );
            appInsights.trackPageView(telemetry);
            setCurrentPage("services");
            navigate("/services");
          },
          isSelected: currentPage === "services" ? true : false,
          links: [
            {
              name: "Dependency Overview",
              key: "Dependencies",
              styles: iconSpacingWithoutIcon,
              ariaLabel: "Dependency Overview Dashboard",
              target: "_blank",
              onClick: () => {
                const telemetry = buildPageViewTelemetry(
                  "Dependency Overview Dashboard",
                  "/dependencyOverview",
                  currentPage
                );
                appInsights.trackPageView(telemetry);
                setCurrentPage("dependencyOverview");
                navigate("/dependencyOverview");
              },
              isSelected: currentPage === "dependencyOverview" ? true : false,
            },
            {
              name: "Service Dependencies",
              key: "ServiceDeps",
              styles: iconSpacingWithoutIcon,
              ariaLabel: "Service Dependency Dashboard",
              target: "_blank",
              onClick: () => {
                const telemetry = buildPageViewTelemetry(
                  "Service Dependency Dashboard",
                  "/serviceDependencies",
                  currentPage
                );
                appInsights.trackPageView(telemetry);
                setCurrentPage("serviceDependencies");
                navigate("/serviceDependencies");
              },
              isSelected: currentPage === "serviceDependencies" ? true : false,
            },
          ],
          isExpanded: defaultDependenciesExpanded,
          onExpanded: () => setDefaultDependenciesExpanded(!defaultDependenciesExpanded),
        },
        {
          name: "Processes",
          key: "Processes",
          styles: iconSpacingWithoutIcon,
          ariaLabel: "Processes Dashboard",
          target: "_blank",
          onClick: () => {
            const telemetry = buildPageViewTelemetry(
              "Process Dashboard",
              "/processes",
              currentPage
            );
            appInsights.trackPageView(telemetry);
            setCurrentPage("processes");
            navigate("/processes");
          },
          isSelected: currentPage === "processes" ? true : false,
        },
        {
          name: "Wiki",
          key: "Wiki",
          ariaLabel: "Wiki Page",
          icon: "Unknown",
          target: "_blank",
          onClick: () => {
            const telemetry = buildPageViewTelemetry("Wiki Page", "/Wiki", currentPage);
            appInsights.trackPageView(telemetry);
            setCurrentPage("wiki");
            navigate("/wiki");
          },
          isSelected: currentPage === "wiki" ? true : false,
        },
      ],
    },
  ];
  return (
    <CoherenceNav
      appName={"Watchtower"}
      groups={navGroups}
      toggleNavAriaLabel={"Watchtower site navigation"}
      toggleNavTooltip={{
        expand: "Expand navigation",
        collapse: "Collapse navigation",
      }}
      onNavCollapsed={props.onNavCollapsed}
    />
  );
};
