import { IDriTeamData } from "../components/Types";

export enum ComplianceStatus {
  RA = "RA",
  RAExpired = "RAExpired",
  PostApprovalRejected = "PostApprovalRejected",
  NonCompliant = "NonCompliant",
  CompliantWithGap = "CompliantWithGap",
  Compliant = "Compliant",
  Unknown = "Unknown",
}

export interface ServiceDepAggCount {
  top10ServicesWithMostUpstreamDependencies: DependencyCount[];
  top10ServicesWithMostDownstreamDependencies: DependencyCount[];
  servicesWithCircularDependencies: DependencyCount[];
  totalServices: number;
  totalBcdrCompliantServices: number;
  totalBcdrCompliantWithGapServices: number;
  totalBcdrUnknownServices: number;
  totalBcdrRaServices: number;
  totalBcdrRaExpiredServices: number;
  totalBcdrPostApprovalRejectedServices: number;
  totalBcdrNonCompliantServices: number;
  totalServicesWithDownstreamDependencyGaps: number;
  totalServicesWithUpstreamDependencyGaps: number;
  totalServicesWithCircularDependencies: number;
  totalServicesWithSelfReferencingDependencies: number;
  totalServicesWithNoDependenciesUpstream: number;
}

export interface DependencyCount {
  serviceGuidNamePairs: ServiceTreeObj;
  criticalityRating: string;
  dependencyCount: number;
}

export interface ComplianceStatusCount {
  totalServices: number;
  totalBcdrCompliantServices: number;
  totalBcdrCompliantWithGapServices: number;
  totalBcdrUnknownServices: number;
  totalBcdrRaServices: number;
  totalBcdrRaExpiredServices: number;
  totalBcdrPostApprovalRejectedServices: number;
  totalBcdrNonCompliantServices: number;
}
export interface DependencyGapCount {
  totalServicesWithDownstreamDependencyGaps: number;
  totalServicesWithUpstreamDependencyGaps: number;
  totalServicesWithCircularDependencies: number;
  totalServicesWithSelfReferencingDependencies: number;
  totalServicesWithNoDependenciesUpstream: number;
}

export enum AssessmentStatus {
  Unknown = "Unknown",
  Approved = "Approved",
  PostApprovalRejected = "PostApprovalRejected",
  ReadyForSubmission = "ReadyForSubmission",
  AwaitingApproval = "AwaitingApproval",
  Rejected = "Rejected",
}

export interface ServiceBcdrMetadataQueryResponse {
  enhancedServiceDataDTO: ServiceBcdrDetailedMetadata[];
  guidNotFound: string[];
  serviceNameNotFound: string[];
}

export interface CoreServiceData {
  approvedRtc: string;
  criticalityRating: string;
  divisionName: string;
  organizationName: string;
  serviceGroupName: string;
  serviceName: string;
  serviceTreeLink: string;
}

export interface DownstreamDependencyData {
  dependencyClassification: string;
  desiredDependencyServiceRtc: string;
  serviceName: string;
  serviceOid: string;
  isUpstreamViolatingDownstreamRtcRequirements: boolean;
}

export interface IDownstreamDependencyDataDownload {
  dependencyClassification: string;
  desiredDependencyServiceRtc: string;
  downstreamServiceName: string;
  downstreamServiceOid: string;
  isUpstreamViolatingDownstreamRtcRequirements: boolean;
  currentServiceName: string;
  currentServiceOid: string;
  datetimeStamp: string;
}

export interface UpstreamDependencyData {
  criticalDataRecoveryRpo: string;
  dependencyClassification: string;
  dependencyResiliencyType: string;
  desiredDependencyServiceRtc: string;
  isViolatingDownstreamRtcRequirements: boolean;
  resiliencyWorkaroundDescription: string;
  resiliencyWorkaroundRtc: string;
  storesServiceData: boolean;
  upstreamApprovedRpc: string;
  upstreamApprovedRtc: string;
  upstreamDetectionType: string;
  upstreamInCanaryCentral: boolean;
  upstreamInCanaryEast: boolean;
  upstreamLastTestDate: string;
  upstreamRecoveryAutomation: string;
  upstreamRecoveryDecision: string;
  serviceName: string;
  serviceOid: string;
}

export interface IUpstreamDependencyDataDownload {
  criticalDataRecoveryRpo: string;
  dependencyClassification: string;
  dependencyResiliencyType: string;
  desiredDependencyServiceRtc: string;
  isViolatingDownstreamRtcRequirements: boolean;
  resiliencyWorkaroundDescription: string;
  resiliencyWorkaroundRtc: string;
  storesServiceData: boolean;
  upstreamApprovedRpc: string;
  upstreamApprovedRtc: string;
  upstreamDetectionType: string;
  upstreamInCanaryCentral: boolean;
  upstreamInCanaryEast: boolean;
  upstreamLastTestDate: string;
  upstreamRecoveryAutomation: string;
  upstreamRecoveryDecision: string;
  upstreamServiceName: string;
  upstreamServiceOid: string;
  currentServiceName: string;
  currentServiceOid: string;
  datetimeStamp: string;
}

export interface ServiceBcdrDetailedMetadata {
  approvedRpc: string;
  approvedRpo: string;
  approvedRto: string;
  assessmentDate: string;
  assessmentLink: string;
  assessmentStatus: AssessmentStatus;
  bcdrContact: string;
  bcdrContactEmail: string;
  bcdrRecoveryClassification: string;
  complianceStatus: ComplianceStatus;
  containsCustomerData: boolean;
  coreServiceData: CoreServiceData;
  criticalityCriteria: string[];
  criticalityRating: string;
  criticalityRatingReason: string;
  detectionType: string;
  devContacts: string[];
  devContactsEmail: string[];
  downstreamDependencies: DownstreamDependencyData[];
  driTeams: IDriTeamData[] | undefined;
  hasCircularDependency: boolean;
  hasDownstreamRecoveryGap: boolean;
  hasUpstreamRecoveryGap: boolean;
  inCanaryCentral: boolean;
  inCanaryEast: boolean;
  lastTestDate: string;
  maxLifecycle: string;
  pmContacts: string[];
  pmContactsEmail: string[];
  recoveryAutomation: string;
  recoveryDecision: string;
  resiliencyDescription: string;
  serviceOid: string;
  serviceRing: string;
  serviceType: string;
  testEnvironment: string;
  testScenario: string;
  upstreamDependencies: UpstreamDependencyData[];
  willingnessToFailover: string;
}

export interface IServiceBcdrDetailedDataDownload {
  approvedRpc: string;
  approvedRpo: string;
  approvedRto: string;
  assessmentDate: string;
  assessmentLink: string;
  assessmentStatus: AssessmentStatus;
  bcdrContact: string;
  bcdrContactEmail: string;
  bcdrRecoveryClassification: string;
  complianceStatus: ComplianceStatus;
  containsCustomerData: boolean;
  approvedRtc: string;
  divisionName: string;
  organizationName: string;
  serviceGroupName: string;
  serviceName: string;
  serviceTreeLink: string;
  criticalityCriteria: string[];
  criticalityRating: string;
  criticalityRatingReason: string;
  detectionType: string;
  devContacts: string[];
  devContactsEmail: string[];
  hasCircularDependency: boolean;
  hasDownstreamRecoveryGap: boolean;
  hasUpstreamRecoveryGap: boolean;
  inCanaryCentral: boolean;
  inCanaryEast: boolean;
  lastTestDate: string;
  maxLifecycle: string;
  pmContacts: string[];
  pmContactsEmail: string[];
  recoveryAutomation: string;
  recoveryDecision: string;
  resiliencyDescription: string;
  serviceOid: string;
  serviceRing: string;
  serviceType: string;
  testEnvironment: string;
  testScenario: string;
  willingnessToFailover: string;
  datetimeStamp: string;
}

export type ServiceTreeObj = {
  serviceOid: string;
  serviceName: string;
};
