import React, { useRef } from "react";
import * as d3 from "d3";
import { getTheme } from "@fluentui/react";
import {
  DependencyGraphBaseProps,
  defaultDependencyLegend,
} from "./ServiceDependencyGraph.types";
import { getClassNames, getStyles } from "./ServiceDependencyGraph.styles";
import { DependencyGraphNode } from "./DependencyGraphNode";
import { DependencyGraphLink } from "./DependencyGraphLink";

export const DependencyGraphBase = (
  props: DependencyGraphBaseProps
): JSX.Element => {
  //setting up the state to store nodes and links
  let nodes = props.nodes.map((d) => Object.create(d));
  let links = props.links.map((d) => Object.create(d));
  const graphSvg = useRef(null);
  //setting up the canvas and default settings
  const theme = props.theme || getTheme();
  const styleClassNames =
    props.styleClassNames || getClassNames(getStyles(theme));
  const rectangle = props.rectangle || {
    width: 250,
    height: 50,
    textMargin: 10,
  };
  const legend = props.legend || defaultDependencyLegend;
  // if there isn't a handle on click passed, do nothing by default.
  const handleOnClick = props.handleOnClick || (() => {});

  let simulation;
  const simulationRef = React.useRef(simulation);

  const [animatedNodes, setAnimatedNodes] = React.useState([]);
  const [animatedLinks, setAnimatedLinks] = React.useState([]);
  // creates the simulation with the updated nodes and links
  React.useEffect(() => {
    if (props.nodes?.length > 0 && props.links?.length > 0) {
      // merge new nodes in with existing ones to preserve position
      const prev = new Map(animatedNodes.map((node) => [node.id, node]));
      const mergedNodes = nodes.map((node) =>
        Object.assign(node, prev?.get(node.id))
      );
      // update the simulation based on new nodes
      // draw the links between the nodes
      // charge controls the attraction between nodes. Negative charge = more repulsion
      // x and y are forced to be on the graph. can use to control where the nodes are drawn
      // center controls where the nodes are centered around
      simulationRef.current = d3.forceSimulation([...mergedNodes]);
      simulationRef.current
        .force(
          "link",
          d3.forceLink(links).id((d: any) => {
            return d.id;
          })
        )
        .force(
          "charge",
          d3
            .forceManyBody()
            .strength(-props.rectangle.width * props.rectangle.height)
        )
        .force("x", d3.forceX())
        .force("y", d3.forceY())
        .force(
          "center",
          d3.forceCenter(props.chartWidth / 2, props.chartHeight / 2)
        );

      // copy nodes into simulation
      simulationRef.current.nodes([...mergedNodes]);
      // update state on every frame
      simulationRef.current.on("tick", () => {
        setAnimatedNodes([...simulationRef.current.nodes()]);
        setAnimatedLinks([...links]);
      });
      // restart
      simulationRef.current.alpha(0.5).restart();
      // bail!
      return () => {
        simulationRef.current.stop();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.nodes,
    props.links,
    props.rectangle.width,
    props.chartWidth,
    props.chartHeight,
    props.rectangle.height,
  ]);

  // enable zoom
  let svg = d3.select(graphSvg.current);
  svg.call(
    d3
      .zoom()
      .scaleExtent([1 / 2, 8])
      .extent([
        [0, 0],
        [
          props.chartWidth - props.margin.left - props.margin.right,
          props.chartHeight - props.margin.top - props.margin.bottom,
        ],
      ])
      .on("zoom", zoomed)
  );

  const g = d3.selectAll("g");
  function zoomed({ transform }) {
    g.attr("transform", transform);
  }
  // enable drag - leveraging the ref to ensure that the nodes are re-rendered
  let drag = () => {
    function dragstarted(event, d) {
      if (!event.active) simulationRef.current.alphaTarget(0.3).restart();
      d.fx = d.x;
      d.fy = d.y;
    }

    function dragged(event, d) {
      d.fx = event.x;
      d.fy = event.y;
    }

    function dragended(event, d) {
      if (!event.active) simulationRef.current.alphaTarget(0);
      d.x = event.x;
      d.y = event.y;
      d.fx = null;
      d.fy = null;
    }

    return d3
      .drag()
      .on("start", dragstarted)
      .on("drag", dragged)
      .on("end", dragended);
  };
  // grab the nodes and call drag.
  d3.selectAll("g.rectNode")?.data(animatedNodes)?.call(drag());

  return (
    <figure>
      <svg ref={graphSvg} height={props.chartHeight} width={props.chartWidth}>
        <defs>
          {props.linkTypes.map((directionType) => (
            <marker
              key={directionType}
              id={directionType}
              viewBox="0 -5 10 10"
              refX={10}
              refY={0}
              markerHeight={8}
              markerWidth={8}
              orient={"auto"}
              fill={`${theme.palette.black}`}
              stroke={`${theme.palette.black}`}
            >
              <path d="M0,-5L10,0L0,5 z" />
            </marker>
          ))}
        </defs>
        <g className="svgLink">
          {animatedLinks.map((link, index) =>
            DependencyGraphLink({
              link,
              index,
              rectangle,
              styleClassNames,
              theme,
              legend,
            })
          )}
        </g>
        <g className="svgNode">
          {animatedNodes.flatMap((node) =>
            DependencyGraphNode({
              node,
              styleClassNames,
              rectangle,
              theme,
              handleOnClick,
            })
          )}
        </g>
      </svg>
      <figcaption>Fig: Interactive dependency graph</figcaption>
    </figure>
  );
};
