import { AxiosError } from "axios";
import { FacilityAggregate, IFacilityModel } from "../../components/Types";
import {
  CLEAR_FACILITY_MAP_FILTER,
  FETCH_FACILITIES_BEGIN,
  FETCH_FACILITIES_FAILURE,
  FETCH_FACILITIES_SUCCESS,
  FETCH_FACILITY_COUNTS_BEGIN,
  FETCH_FACILITY_COUNTS_FAILURE,
  FETCH_FACILITY_COUNTS_SUCCESS,
  RESET_FACILITY_ASSETS,
  SET_ACTIVE_FACILITY_ID,
  SET_FACILITY_ASSET_SEARCH_TERM,
  SET_FACILITY_CITY_FILTER,
  SET_FACILITY_CLASSIFICATION_FILTER,
  SET_FACILITY_COUNTRY_FILTER,
  SET_FACILITY_DIVISION_FILTER,
  SET_FACILITY_GWS_FILTER,
  SET_FACILITY_ID_FILTER,
  SET_FACILITY_MAP_FILTER,
  SET_FACILITY_STATE_FILTER,
  SET_FACILITY_TYPE_FILTER,
} from "../actions/actionTypes";

export interface ReduxFacilitiesState {
  facilities: IFacilityModel[];
  isLoading: boolean;
  error: string | AxiosError;
  facilityCounts: FacilityAggregate;
  isFacilityCountsLoading: boolean;
  facilityCountryFilter: string[];
  facilityStateFilter: string[];
  facilityCityFilter: string[];
  facilityGwsFilter: string[];
  facilityTypeFilter: string[];
  facilityClassificationFilter: string[];
  facilityIdFilter: number[];
  facilityDivisionFilter: string[];
  facilitiesOnMap: IFacilityModel[];
  facilityAssetSearchTerm: string;
  countryCodeMapping: object;
  activeFacilityId: number;
}

const facilitiesInitialState: ReduxFacilitiesState = {
  facilities: [],
  isLoading: false,
  error: null,
  facilityCounts: null,
  isFacilityCountsLoading: false,
  facilityCountryFilter: [],
  facilityStateFilter: [],
  facilityCityFilter: [],
  facilityGwsFilter: [],
  facilityTypeFilter: [],
  facilityClassificationFilter: [],
  facilityIdFilter: [],
  facilityDivisionFilter: [],
  facilitiesOnMap: [],
  facilityAssetSearchTerm: null,
  activeFacilityId: -1,
  countryCodeMapping: {},
};

const makeUniqueFacilityList = (originalList: IFacilityModel[]) => {
  return originalList.filter(
    (fac) => !fac.facilityType.includes("Parking Garage") && !fac.facilityType.includes("Cafeteria")
  );
};

const mapCountryCodes = (originalList: IFacilityModel[]): object => {
  return originalList.reduce(
    (acc, current) => ({
      ...acc,
      [current.country?.toUpperCase() ?? "Unknown"]: current.countryShortCode?.toUpperCase() ?? "",
    }),
    {}
  );
};

export default function facilities(
  state: ReduxFacilitiesState = facilitiesInitialState,
  action: any
) {
  switch (action.type) {
    case FETCH_FACILITIES_BEGIN:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case FETCH_FACILITIES_SUCCESS:
      return {
        ...state,
        facilities: makeUniqueFacilityList(action.payload),
        isLoading: false,
        error: null,
        facilitiesOnMap: makeUniqueFacilityList(action.payload),
        countryCodeMapping: mapCountryCodes(action.payload),
      };
    case FETCH_FACILITIES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case FETCH_FACILITY_COUNTS_BEGIN:
      return {
        ...state,
        isFacilityCountsLoading: true,
        error: null,
      };
    case FETCH_FACILITY_COUNTS_SUCCESS:
      return {
        ...state,
        isFacilityCountsLoading: false,
        error: null,
        facilityCounts: action.payload,
      };
    case FETCH_FACILITY_COUNTS_FAILURE:
      return {
        ...state,
        isFacilityCountsLoading: false,
        error: action.payload,
      };
    case SET_FACILITY_COUNTRY_FILTER:
      return {
        ...state,
        facilityCountryFilter: action.payload?.map((i) => i.toUpperCase().trim()) ?? [],
      };
    case SET_FACILITY_STATE_FILTER:
      return {
        ...state,
        facilityStateFilter: action.payload?.map((i) => i.toUpperCase().trim()) ?? [],
      };
    case SET_FACILITY_CITY_FILTER:
      return {
        ...state,
        facilityCityFilter: action.payload?.map((i) => i.toUpperCase().trim()) ?? [],
      };
    case SET_FACILITY_GWS_FILTER:
      return {
        ...state,
        facilityGwsFilter: action.payload,
      };
    case SET_FACILITY_TYPE_FILTER:
      return {
        ...state,
        facilityTypeFilter: action.payload,
      };
    case SET_FACILITY_CLASSIFICATION_FILTER:
      return {
        ...state,
        facilityClassificationFilter: action.payload,
      };
    case SET_FACILITY_ID_FILTER:
      return {
        ...state,
        facilityIdFilter: action.payload,
      };
    case SET_FACILITY_DIVISION_FILTER:
      return {
        ...state,
        facilityDivisionFilter: action.payload,
      };
    case SET_FACILITY_MAP_FILTER:
      return {
        ...state,
        facilitiesOnMap: state.facilities.filter((i) => action.payload.includes(i.facilityId)),
      };
    case CLEAR_FACILITY_MAP_FILTER:
      return {
        ...state,
        facilitiesOnMap: [...state.facilities],
      };
    case RESET_FACILITY_ASSETS:
      return {
        ...state,
        facilities: [],
        facilitiesOnMap: [],
        facilityCountryFilter: [],
        facilityStateFilter: [],
        facilityCityFilter: [],
        facilityGwsFilter: [],
        facilityTypeFilter: [],
        facilityClassificationFilter: [],
        facilityIdFilter: [],
        facilityDivisionFilter: [],
        facilityAssetSearchTerm: null,
        activeFacilityId: null,
      };
    case SET_FACILITY_ASSET_SEARCH_TERM:
      return {
        ...state,
        facilityAssetSearchTerm: action.payload.toLowerCase(),
      };
    case SET_ACTIVE_FACILITY_ID:
      return {
        ...state,
        activeFacilityId: action.payload,
      };
    default:
      return state;
  }
}
