import { ICountAnnotationProps } from "@m365-admin/count-annotation";
import { AxiosError } from "axios";
import { IComboBoxOption, IDropdownOption } from "@fluentui/react";
import { createSelector } from "reselect";
import { pieChartColorOptions } from "../../components/charts/InfoDonutChart";
import {
  IFacilityModel,
  NormalizedEmployeeProfile,
  PeopleCount,
} from "../../components/Types";
import { ReduxFacilitiesState } from "../reducers/facilitiesReducer";
import { ReduxPeopleFilterState, ReduxPeopleState } from "../reducers/peopleReducer";
import { facilityFilterHelper } from "./facilitiesSelector";
import { PeopleFilterData } from "../../components/panels/filters/PeopleFilter";

const facilities = (state: any): ReduxFacilitiesState => state.facilities;
const unappliedPeopleFilters = (state: any): ReduxPeopleFilterState => state.unappliedPeopleFilter;
const people = (state: any): ReduxPeopleState => state.people;
const peopleError = (state: any): string | AxiosError => state.people.error;

export const getUnappliedPeopleFilters = createSelector(
  unappliedPeopleFilters,
  (unappliedPeopleFilters: ReduxPeopleFilterState): {} => {
    return unappliedPeopleFilters
  }
);

enum StandardBuildingCodes {
  NoWorkspaceBuildingId = 99997,
  MobileBuildingId = 99998,
  HomeOfficeBuildingId = 99999,
}

export const getPeopleDirects = createSelector(
  people,
  facilities,
  (
    people: ReduxPeopleState,
    facilities: ReduxFacilitiesState
  ): NormalizedEmployeeProfile[] => {
    if (people) {
      let peopleData = people.normalizedEmployeeProfiles ?? [];
      peopleData = normalizedLocationFilterHelper(
        peopleData,
        facilities.facilityCountryFilter.slice(),
        people.peopleStateFilters.slice(),
        people.peopleCityFilters.slice(),
        people.peopleFacNameFilters
      );

      return peopleData;
    }
    return [];
  }
);

export const getFilteredPeopleDirects = createSelector(
  people,
  facilities,
  (
    people: ReduxPeopleState,
    facilities: ReduxFacilitiesState
  ): NormalizedEmployeeProfile[] => {
    if (people) {
      let peopleData = people.normalizedEmployeeProfiles ?? [];

      peopleData = normalizedLocationFilterHelper(
        peopleData,
        facilities.facilityCountryFilter,
        people.peopleStateFilters,
        people.peopleCityFilters,
        people.peopleFacNameFilters
      );

      peopleData = normalizedHierarchyFilterHelper( peopleData, people);

      return peopleData;
    }
    return [];
  }
);

export const getPeopleHierarchyByFacility = createSelector(
  people,
  facilities,
  (
    people: ReduxPeopleState,
    facilities: ReduxFacilitiesState
  ): NormalizedEmployeeProfile[] => {
    if (people) {
      const activeFacilityId = facilities.activeFacilityId ?? -1;
      if (activeFacilityId > 0) {
        return people.normalizedEmployeeProfiles
          .slice()
          .filter((p) => p.buildingId === activeFacilityId)
          .sort((a, b) => a?.alias?.localeCompare(b?.alias));
      }
    }
    return [];
  }
);

export const getPeopleCounts = createSelector(
  people,
  facilities,
  (
    people: ReduxPeopleState,
    facilities: ReduxFacilitiesState
  ): ICountAnnotationProps[] => {
    if (people) {
      let peopleData = people.normalizedEmployeeProfiles;

      if (peopleData.length === 0 && people.peopleCounts) {
        return buildAggregateCounts(people.peopleCounts);
      }
      peopleData = normalizedLocationFilterHelper(
        peopleData,
        facilities.facilityCountryFilter,
        people.peopleStateFilters,
        people.peopleCityFilters,
        people.peopleFacNameFilters
      );

      peopleData = normalizedHierarchyFilterHelper( peopleData, people);

      const tempFac: IFacilityModel[] = facilityFilterHelper(facilities);
      const totalFilteredPeopleCount = peopleData.length ?? 0;
      const internalFilteredActiveCount = peopleData.filter(
        (pd) => pd.isActiveFte
      ).length;

      const validEmployeeStates = ["ACTIVE CONTINGENT", "ACTIVE"];
      const externalFilteredActiveCount = peopleData.filter(
        (pd) =>
          !pd.isActiveFte &&
          pd.employeeStatusState.toUpperCase() === validEmployeeStates[0]
      ).length;

      const otherFilteredActiveCount = peopleData.filter(
        (pd) =>
          !pd.isActiveFte &&
          !validEmployeeStates.includes(pd.employeeStatusState.toUpperCase())
      ).length;

      const newPeopleCounts: PeopleCount = {
        totalPeopleCount: totalFilteredPeopleCount,
        essentialBadgeInsCount: 0,
        essentialOnsiteCount: 0,
        internalActiveCount: internalFilteredActiveCount,
        externalActiveCount: externalFilteredActiveCount,
        otherActiveCount: otherFilteredActiveCount,
        badgeInsCount: tempFac
          .map((fac) => fac.buildingAccessedHc)
          .reduce((total, acc) => total + acc, 0),
      };
      return buildAggregateCounts(newPeopleCounts);
    }
  }
);

export const getWorkforceAllocationCounts = createSelector(
  people,
  facilities,
  (
    people: ReduxPeopleState,
    facilities: ReduxFacilitiesState
  ): ICountAnnotationProps[] => {
    if (people) {
      let peopleData =
        normalizedLocationFilterHelper(
          people.normalizedEmployeeProfiles,
          facilities.facilityCountryFilter,
          people.peopleStateFilters,
          people.peopleCityFilters,
          people.peopleFacNameFilters
        ) ?? [];

      peopleData = normalizedHierarchyFilterHelper( peopleData, people);

      const buildingsWithNoLocation: number[] = [
        StandardBuildingCodes.HomeOfficeBuildingId,
        StandardBuildingCodes.MobileBuildingId,
        StandardBuildingCodes.NoWorkspaceBuildingId,
      ];

      const buildingAssignedHeadcount =
        peopleData.filter(
          (person) => !buildingsWithNoLocation.includes(person.buildingId)
        ).length ?? -1;

      const mobileAssignedHeadcount = peopleData.filter(
        (person) => person.buildingId === StandardBuildingCodes.MobileBuildingId
      ).length;

      const homeOfficeAssignedHeadcount =
        peopleData.filter(
          (person) =>
            person.buildingId === StandardBuildingCodes.HomeOfficeBuildingId
        ).length ?? -1;

      const noWorkspaceAssignedHeadcount =
        peopleData.filter(
          (person) =>
            person.buildingId === StandardBuildingCodes.NoWorkspaceBuildingId
        ).length ?? -1;

      const workforceAllocationCounts: ICountAnnotationProps[] = [
        {
          annotationText: "Total Assigned to Buildings",
          count: buildingAssignedHeadcount,
          color: pieChartColorOptions[4],
          size: 1,
        },
        {
          annotationText: "Total Assigned to Mobile",
          count: mobileAssignedHeadcount,
          color: pieChartColorOptions[0],
          size: 1,
        },
        {
          annotationText: "Total Assigned to Home Office",
          count: homeOfficeAssignedHeadcount,
          color: pieChartColorOptions[2],
          size: 1,
        },
        {
          annotationText: "Total Assigned to No Workspace",
          count: noWorkspaceAssignedHeadcount,
          color: pieChartColorOptions[1],
          size: 1,
        },
      ];

      return workforceAllocationCounts;
    }
    return [];
  }
);

export const getPeopleCountsLoadingStatus = createSelector(
  people,
  (people: ReduxPeopleState) =>
    people.isNormalizedEmployeeProfilesLoading || people.isPeopleCountLoading
);

export const getPeopleDirectsLoadingStatus = createSelector(
  people,
  (people: ReduxPeopleState) => people.isNormalizedEmployeeProfilesLoading
);

export const getPeopleDirectsError = createSelector(
  peopleError,
  (peopleError: string | AxiosError) => {
    if (peopleError) {
      return `Error retrieving People Data. ${peopleError}`;
    }

    return null;
  }
);

export const getPeopleCityOptions = createSelector(
  people,
  facilities,
  (
    people: ReduxPeopleState,
    facilities: ReduxFacilitiesState
  ): IDropdownOption[] => {
    if (people) {
      let peopleData = people.normalizedEmployeeProfiles;
      const currentCountryFilters = facilities.facilityCountryFilter ?? [];
      const currentPeopleStateFilter = people.peopleStateFilters ?? [];
      peopleData = normalizedLocationFilterHelper(
        peopleData,
        currentCountryFilters,
        currentPeopleStateFilter
      );
      var cityOptions = buildOptionsSetHelper(peopleData, "city").sort();

      if (cityOptions.length === 1)
        cityOptions[0].selected = true;

      return cityOptions;
    }
    return [];
  }
);

export const getPeopleCityFilters = createSelector(
  people,
  (people: ReduxPeopleState): string[] => people?.peopleCityFilters ?? []
);

export const getPeopleStateOptions = createSelector(
  people,
  facilities,
  (
    people: ReduxPeopleState,
    facilities: ReduxFacilitiesState
  ): IComboBoxOption[] => {
    if (people) {
      let peopleData = people.normalizedEmployeeProfiles;
      const currentCountryFilters = facilities.facilityCountryFilter ?? [];
      peopleData = filterByLocation(
        peopleData,
        currentCountryFilters,
        "country"
      );

      var sortedOptions = buildOptionsSetHelper(peopleData, "state").sort();
      sortedOptions.length === 1 && (sortedOptions[0].selected = true);

      return sortedOptions;
    }
  }
);

export const getPeopleStateFilters = createSelector(
  people,
  (people: ReduxPeopleState): string[] => people?.peopleStateFilters ?? []
);

export const getPeopleDivisionOptions = createSelector(
  people,
  facilities,
  (
    people: ReduxPeopleState,
    facilities: ReduxFacilitiesState
  ): IDropdownOption[] => {
    if (people) {
      let peopleData = normalizedLocationFilterHelper(
        people.normalizedEmployeeProfiles,
        facilities.facilityCountryFilter,
        people.peopleStateFilters,
        people.peopleCityFilters,
        people.peopleFacNameFilters
      );

      return buildManagerOptionsSetHelper(
        peopleData,
        "l1ManagerEmailName",
        "l1Manager"
      );
    }
    return [];
  }
);

export const getPeopleDivFilters = createSelector(
  people,
  (people: ReduxPeopleState): string[] => people?.peopleDivFilters ?? []
);

export const getPeopleOrganizationOptions = createSelector(
  people,
  facilities,
  (
    people: ReduxPeopleState,
    facilities: ReduxFacilitiesState
  ): IDropdownOption[] => {
    if (people) {
      let peopleData = normalizedLocationFilterHelper(
        people.normalizedEmployeeProfiles,
        facilities.facilityCountryFilter,
        people.peopleStateFilters,
        people.peopleCityFilters,
        people.peopleFacNameFilters
      );

      peopleData = normalizedHierarchyFilterHelper( peopleData.slice(), people);

      return buildManagerOptionsSetHelper(
        peopleData,
        "l2ManagerEmailName",
        "l2Manager"
      );
    }
    return [];
  }
);

export const getPeopleOrgFilters = createSelector(
  people,
  (people: ReduxPeopleState): string[] => people?.peopleOrgFilters ?? []
);

export const getPeopleServiceGroupOptions = createSelector(
  people,
  facilities,
  (
    people: ReduxPeopleState,
    facilities: ReduxFacilitiesState
  ): IDropdownOption[] => {
    if (people) {
      let peopleData = normalizedLocationFilterHelper(
        people.normalizedEmployeeProfiles,
        facilities.facilityCountryFilter,
        people.peopleStateFilters,
        people.peopleCityFilters,
        people.peopleFacNameFilters
      );

      peopleData = normalizedHierarchyFilterHelper( peopleData.slice(), people);

      return buildManagerOptionsSetHelper(
        peopleData,
        "l3ManagerEmailName",
        "l3Manager"
      );
    }
    return [];
  }
);

export const getPeopleServiceGroupFilters = createSelector(
  people,
  (people: ReduxPeopleState): string[] =>
    people?.peopleServiceGroupFilters ?? []
);

export const getPeopleJobTitleSummaryDescOptions = createSelector(
  people,
  (people: ReduxPeopleState): IDropdownOption[] => {
    return Object.keys(
      people.normalizedEmployeeProfiles.reduce((prev, p,) => ({ ...prev, [p.jobTitleSummaryDesc]: p.jobTitleSummaryDesc }), {})
    )
      .sort()
      .map((r) => ({ key: r, text: r }))
  }
);

export const getPeopleTeamGroupOptions = createSelector(
  people,
  facilities,
  (
    people: ReduxPeopleState,
    facilities: ReduxFacilitiesState
  ): IDropdownOption[] => {
    if (people) {
      let peopleData = normalizedLocationFilterHelper(
        people.normalizedEmployeeProfiles,
        facilities.facilityCountryFilter,
        people.peopleStateFilters,
        people.peopleCityFilters,
        people.peopleFacNameFilters
      );

      peopleData = normalizedHierarchyFilterHelper( peopleData.slice(), people);

      return buildManagerOptionsSetHelper(
        peopleData,
        "l4ManagerEmailName",
        "l4Manager"
      );
    }
    return [];
  }
);

export const getPeopleTeamGroupFilters = createSelector(
  people,
  (people: ReduxPeopleState): string[] => people?.peopleTeamGroupFilters ?? []
);

export const getPeopleFacNameOptions = createSelector(
  people,
  facilities,
  (
    people: ReduxPeopleState,
    facilities: ReduxFacilitiesState
  ): IDropdownOption[] => {
    if (people) {
      let peopleData = normalizedLocationFilterHelper(
        people.normalizedEmployeeProfiles,
        facilities.facilityCountryFilter.slice(),
        people.peopleStateFilters.slice(),
        people.peopleCityFilters.slice(),
        []
      );
      return buildOptionsSetHelper(peopleData, "buildingName").sort();
    }
    return [];
  }
);

export const getPeopleFacNameFilters = createSelector(
  people,
  (people: ReduxPeopleState): string[] => people?.peopleFacNameFilters ?? []
);

export const getPeopleJobTitleSummaryDescFilters = createSelector(
  people,
  (people: ReduxPeopleState): string[] => people?.peopleJobTitleSummaryDescFilters ?? []
);

export const peopleFilterDataSelector = createSelector(
  getPeopleStateOptions,
  getPeopleStateFilters,
  getPeopleCityOptions,
  getPeopleCityFilters,
  getPeopleDivisionOptions,
  getPeopleDivFilters,
  getPeopleOrganizationOptions,
  getPeopleOrgFilters,
  getPeopleServiceGroupOptions,
  getPeopleServiceGroupFilters,
  getPeopleTeamGroupOptions,
  getPeopleTeamGroupFilters,
  getPeopleFacNameOptions,
  getPeopleFacNameFilters,
  getPeopleJobTitleSummaryDescOptions,
  getPeopleJobTitleSummaryDescFilters,
  (
    peopleStateOptions,
    peopleStateFilters,
    peopleCityOptions,
    peopleCityFilters,
    peopleDivisionOptions,
    peopleDivFilters,
    peopleOrganizationOptions,
    peopleOrgFilters,
    peopleServiceGroupOptions,
    peopleServiceGroupFilters,
    peopleTeamGroupOptions,
    peopleTeamGroupFilters,
    peopleFacNameOptions,
    peopleFacNameFilters,
    peopleJobTitleSummaryDescOptions,
    peopleJobTitleSummaryDescFilters
  ): PeopleFilterData => ({
    peopleStateOptions,
    peopleStateFilters,
    peopleCityOptions,
    peopleCityFilters,
    peopleDivisionOptions,
    peopleDivFilters,
    peopleOrganizationOptions,
    peopleOrgFilters,
    peopleServiceGroupOptions,
    peopleServiceGroupFilters,
    peopleTeamGroupOptions,
    peopleTeamGroupFilters,
    peopleFacNameOptions,
    peopleFacNameFilters,
    peopleJobTitleSummaryDescOptions,
    peopleJobTitleSummaryDescFilters
  })
)

const buildAggregateCounts = (
  peopleCounts: PeopleCount
): ICountAnnotationProps[] => {
  if (peopleCounts) {
    return [
      {
        annotationText: "Total Employees",
        count: peopleCounts?.totalPeopleCount ?? -1,
        annotationColor: pieChartColorOptions[4],
        size: 1,
      },
      {
        annotationText: "Internal Active Employees",
        count: peopleCounts?.internalActiveCount ?? -1,
        annotationColor: pieChartColorOptions[0],
        size: 1,
      },
      {
        annotationText: "External Active Employees",
        count: peopleCounts?.externalActiveCount ?? -1,
        annotationColor: pieChartColorOptions[2],
        size: 1,
      },
      {
        annotationText: "Other Active Employees",
        count: peopleCounts?.otherActiveCount ?? -1,
        annotationColor: pieChartColorOptions[0],
        size: 1,
      },
      {
        annotationText: "Badge Ins (180 day avg)",
        count: peopleCounts?.badgeInsCount ?? 0,
        annotationColor: pieChartColorOptions[1],
        size: 1,
      },
    ];
  }
};

const buildOptionsSetHelper = (
  listToReduce: any[],
  attributeToFilter: string
) => {
  let resultSet = listToReduce.reduce(function (resultList, item) {
    if (item[attributeToFilter]) {
      if (
        resultList == null ||
        !resultList.includes(item[attributeToFilter]?.toUpperCase())
      ) {
        resultList.push(item[attributeToFilter]?.toUpperCase());
      }
    }

    return resultList;
  }, []);
  return resultSet.sort().map((r) => ({ key: r, text: r }));
};

const buildManagerOptionsSetHelper = (
  listToReduce: any[],
  attributeForKey: string,
  attributeForText: string
) => {
  const resultSet = listToReduce.reduce(function (resultList, item) {
    if (item[attributeForKey] && item[attributeForKey] !== "") {
      if (
        resultList == null ||
        !resultList.includes(item[attributeForKey]?.toUpperCase())
      ) {
        resultList.push(item[attributeForKey]?.toUpperCase());
      }
    }
    return resultList;
  }, []);
  return resultSet.sort().map((r) => ({
    key: r,
    text: listToReduce.find((e) => e[attributeForKey]?.toUpperCase() === r)[
      attributeForText
    ],
  }));
};

const normalizedLocationFilterHelper = (
  peopleData: NormalizedEmployeeProfile[],
  currentCountryFilters: string[] = [],
  currentStateFilters: string[] = [],
  currentCityFilters: string[] = [],
  currentFacilityNameList: string[] = []
): NormalizedEmployeeProfile[] => {
  let filteredPeopleData = peopleData.slice() ?? [];

  filteredPeopleData = filterByLocation(
    filteredPeopleData,
    currentCountryFilters,
    "country"
  );

  filteredPeopleData = filterByLocation(
    filteredPeopleData,
    currentStateFilters,
    "state"
  );
  filteredPeopleData = filterByLocation(
    filteredPeopleData,
    currentCityFilters,
    "city"
  );

  filteredPeopleData = filterByLocation(
    filteredPeopleData,
    currentFacilityNameList,
    "buildingName"
  );

  return filteredPeopleData;
};

const filterByLocation = (
  employeeDataList: NormalizedEmployeeProfile[],
  locationList: string[],
  attributeToFilter: string
) => {
  if (locationList.length > 0) {
    return employeeDataList
      .slice()
      .filter((e) =>
        locationList.some(
          (c) => e[attributeToFilter]?.toUpperCase() === c.toUpperCase()
        )
      );
  }
  return employeeDataList;
};

const normalizedHierarchyFilterHelper = (
  employeeData: NormalizedEmployeeProfile[],
  people: ReduxPeopleState,
) => {

  const {
    peopleDivFilters: currentL1ManagerEmailNames = [],
    peopleOrgFilters: currentL2ManagerEmailNames = [],
    peopleServiceGroupFilters: currentL3ManagerEmailNames = [],
    peopleTeamGroupFilters: currentL4ManagerEmailNames = [],
    peopleJobTitleSummaryDescFilters = []
  } = people;

  if (employeeData) {
    let filteredPeopleData = employeeData.slice();
    filteredPeopleData = filterByManagerEmailNames(
      filteredPeopleData,
      currentL1ManagerEmailNames,
      "l1ManagerEmailName"
    );

    filteredPeopleData = filterByManagerEmailNames(
      filteredPeopleData,
      currentL2ManagerEmailNames,
      "l2ManagerEmailName"
    );

    filteredPeopleData = filterByManagerEmailNames(
      filteredPeopleData,
      currentL3ManagerEmailNames,
      "l3ManagerEmailName"
    );

    filteredPeopleData = filterByManagerEmailNames(
      filteredPeopleData,
      currentL4ManagerEmailNames,
      "l4ManagerEmailName"
    );

    filteredPeopleData = filterByManagerEmailNames(
      filteredPeopleData,
      peopleJobTitleSummaryDescFilters,
      "jobTitleSummaryDesc"
    );

    return filteredPeopleData;
  }
};

const filterByManagerEmailNames = (
  employeeData: NormalizedEmployeeProfile[] = [],
  currentEmailNameList: string[] = [],
  attributeToFilter: string = ""
) => {
  if (currentEmailNameList.length > 0) {
    return employeeData
      .slice()
      .filter((e) =>
        currentEmailNameList.some(
          (m) => e[attributeToFilter]?.toUpperCase() === m.toUpperCase()
        )
      );
  } else return employeeData;
};
