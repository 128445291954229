import { IComboBoxOption } from "@fluentui/react";
import { ICountAnnotationProps } from "@m365-admin/count-annotation";
import { createSelector } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { IFacilityModel, FacilityAggregate } from "components/Types";
import { pieChartColorOptions } from "components/charts/InfoDonutChart";
import { ReduxFacilitiesState } from "store/reducers/facilitiesReducer";
import { ReduxPeopleState } from "store/reducers/peopleReducer";
import { ReduxRegionsState } from "store/reducers/regionsReducer";

const facilities = (state: any): ReduxFacilitiesState => state.facilities;
const people = (state: any): ReduxPeopleState => state.people;
const regions = (state: any): ReduxRegionsState => state.regions;
const facilitiesError = (state: any): string | AxiosError => {
  return state.facilities.error;
};

export const getFacilities = createSelector(facilities, (facilities: ReduxFacilitiesState) => {
  if (!facilities) {
    return [];
  }

  const tempFac: IFacilityModel[] = facilityFilterHelper(facilities);
  const criticalityFilters: string[] = facilities.facilityGwsFilter ?? [];
  const buildingTypeFilters: string[] = facilities.facilityTypeFilter ?? [];
  const buildingClassFilters: string[] = facilities.facilityClassificationFilter ?? [];
  const buildingIdFilters: number[] = facilities.facilityIdFilter ?? [];

  const filteredFacilities: IFacilityModel[] = tempFac.filter((fac) => {
    return (
      filterIncludesPropertyHelper(criticalityFilters, fac.buildingClassification) &&
      filterIncludesPropertyHelper(buildingTypeFilters, fac.facilityType) &&
      filterIncludesPropertyHelper(buildingClassFilters, fac.coiDatacenter?.operationalTaxonomy) &&
      filterIncludesPropertyHelper(buildingIdFilters, fac.facilityId)
    );
  });

  return filteredFacilities;
});

export const getFacilitiesOnMap = createSelector(facilities, (facilities: ReduxFacilitiesState) => {
  if (!facilities) {
    return [];
  }

  return facilityFilterHelper(facilities);
});

export const getFacilitiesError = createSelector(
  facilitiesError,
  (facilitiesError: AxiosError): string => {
    if (facilitiesError) {
      return `Error loading facilities. ${facilitiesError}`;
    }
    return null;
  }
);

export const getFacilitiesLoadingStatus = createSelector(
  facilities,
  (facilities: ReduxFacilitiesState) => facilities.isLoading
);

export const getFacilityTypeCounts = createSelector(
  facilities,
  (facilities: ReduxFacilitiesState) => {
    let facilityTypeList: { type: string; count: any }[];
    let tempFac: IFacilityModel[] = facilityFilterHelper(facilities);
    let temp = tempFac.reduce(function (ftList, fac) {
      if (
        fac.source === "FAC03" &&
        !["Retail", "Cafeteria", "Parking Garage"].includes(fac.facilityType)
      ) {
        ftList[fac.facilityType] = (ftList[fac.facilityType] || 0) + 1;
      }

      return ftList;
    }, {});
    facilityTypeList = Object.entries(temp).map(([key, value]) => {
      return {
        type: key,
        count: value,
      };
    });
    return facilityTypeList;
  }
);

export const getFacilityCounts = createSelector(
  facilities,
  (facilities: ReduxFacilitiesState): ICountAnnotationProps[] => {
    let facilityCounts = facilities.facilityCounts;
    let tempFac: IFacilityModel[] = facilityFilterHelper(facilities);
    if (tempFac.length > 0) {
      facilityCounts = buildFacilityCounts(tempFac);
    }
    if (facilityCounts) {
      return [
        {
          annotationText: "Total Facilities",
          count: facilityCounts.facilityCount,
          annotationColor: pieChartColorOptions[4],
          size: 1,
        },
        {
          annotationText: "Allocated",
          count: facilityCounts.allocatedFacilityCount,
          annotationColor: pieChartColorOptions[0],
          size: 1,
        },
        {
          annotationText: "Unallocated",
          count: facilityCounts.unAllocatedFacilityCount,
          annotationColor: pieChartColorOptions[2],
          size: 1,
        },
        {
          annotationText: "GWS Critical Classified",
          count: facilityCounts.gwsCriticalFacilityCount,
          annotationColor: pieChartColorOptions[3],
          size: 1,
        },
        {
          annotationText: "Data Centers",
          count: facilityCounts.dataCenterCount,
          annotationColor: pieChartColorOptions[1],
          size: 1,
        },
      ];
    }
    return [];
  }
);

export const getFacilityCountLoading = createSelector(
  facilities,
  (facilities: ReduxFacilitiesState) => facilities.isFacilityCountsLoading || facilities.isLoading
);

export const getFacilityCountries = createSelector(
  facilities,
  (facilities: ReduxFacilitiesState) => {
    return facilities.facilities
      .reduce(function (ftList, fac) {
        if (ftList == null || !ftList.includes(fac.country)) {
          ftList.push(fac.country);
        }
        return ftList;
      }, [])
      .sort();
  }
);

export const getFacilityStates = createSelector(
  facilities,
  (facilities: ReduxFacilitiesState): string[] => {
    let facilityCountryFilter =
      facilities?.facilityCountryFilter?.map((country) => country.toUpperCase()) ?? [];
    let tempFac: IFacilityModel[] = facilities.facilities;

    const uniqueStates = tempFac.reduce((stateSet, fac) => {
      if (filterIncludesPropertyHelper(facilityCountryFilter, fac.country)) {
        stateSet.add(fac.state?.toUpperCase().trim());
      }
      return stateSet;
    }, new Set());

    return Array.from(uniqueStates).sort() as string[];
  }
);

export const getFacilityCities = createSelector(
  facilities,
  (facilities: ReduxFacilitiesState): string[] => {
    const facilityCountryFilter = facilities?.facilityCountryFilter ?? [];
    const facilityStateFilter = facilities?.facilityStateFilter ?? [];

    const uniqueCities = new Set<string>();

    facilities.facilities.forEach((fac) => {
      if (
        filterIncludesPropertyHelper(facilityCountryFilter, fac.country?.toUpperCase()) &&
        filterIncludesPropertyHelper(facilityStateFilter, fac.state?.toUpperCase())
      ) {
        uniqueCities.add(fac.city?.toUpperCase().trim());
      }
    });

    return Array.from(uniqueCities).sort();
  }
);

export const getDcFacilityCountries = createSelector(
  regions,
  (regions: ReduxRegionsState): IComboBoxOption[] => {
    let regionCountries =
      regions?.selectedPhysicalRegions?.flatMap((r) => r.countries) ?? regions?.countries ?? [];

    let mappedData: IComboBoxOption[] = regionCountries.map((country) => ({
      key: country,
      text: country,
    }));

    return mappedData;
  }
);

export const getDcFacilityStates = createSelector(
  facilities,
  (facilities: ReduxFacilitiesState) => {
    const facilityCountryFilter = facilities.facilityCountryFilter;

    const uniqueStates = facilities.facilities.reduce((stateSet, fac) => {
      if (
        fac.facilityType === "Datacenter" &&
        filterIncludesPropertyHelper(facilityCountryFilter, fac.country)
      ) {
        stateSet.add(fac.state?.toUpperCase());
      }
      return stateSet;
    }, new Set());

    return Array.from(uniqueStates).sort() as string[];
  }
);

export const getDcFacilityCities = createSelector(
  facilities,
  (facilities: ReduxFacilitiesState) => {
    const facilityCountryFilter = facilities.facilityCountryFilter;
    const facilityStateFilter = facilities.facilityStateFilter;

    const uniqueCities = facilities.facilities.reduce((citySet, fac) => {
      if (
        fac.facilityType === "Datacenter" &&
        filterIncludesPropertyHelper(facilityCountryFilter, fac.country?.toUpperCase()) &&
        filterIncludesPropertyHelper(facilityStateFilter, fac.state?.toUpperCase())
      ) {
        citySet.add(fac.city?.toUpperCase().trim().normalize());
      }
      return citySet;
    }, new Set());

    return Array.from(uniqueCities).sort() as string[];
  }
);

export const getDcsInScope = createSelector(facilities, (facilities: ReduxFacilitiesState) => {
  let countryFilter = facilities.facilityCountryFilter;
  let tempFac: IFacilityModel[] = facilities.facilities;
  if (countryFilter.length > 0) {
    tempFac = facilities.facilities.filter((f) => countryFilter.includes(f.country?.toUpperCase()));
  }

  return Array.from(
    tempFac.reduce((set, fac) => {
      if (fac.facilityType === "Datacenter") {
        set.add(fac.facilityName.toUpperCase());
      }
      return set;
    }, new Set())
  );
});

export const getSelectedCountries = createSelector(
  facilities,
  (facilities: ReduxFacilitiesState) => {
    if (facilities) {
      return facilities.facilityCountryFilter;
    }
  }
);

export const getSelectedStates = createSelector(facilities, (facilities: ReduxFacilitiesState) => {
  if (facilities) {
    return facilities.facilityStateFilter;
  }
  return [];
});

export const getSelectedCities = createSelector(facilities, (facilities: ReduxFacilitiesState) => {
  if (facilities) {
    return facilities.facilityCityFilter;
  }
  return [];
});

export const getFacilityGwsCriticalities = createSelector(
  facilities,
  (facilities: ReduxFacilitiesState) => {
    if (facilities) {
      let filteredFacilities = facilityFilterHelper(facilities);
      return filteredFacilities.reduce(function (gwsList, fac) {
        if (gwsList == null || !gwsList.includes(fac.buildingClassification)) {
          gwsList.push(fac.buildingClassification);
        }
        return gwsList;
      }, []);
    }
    return [];
  }
);

export const getSelectedFacilityGwsCriticalities = createSelector(
  facilities,
  (facilities: ReduxFacilitiesState) => {
    if (facilities) {
      return facilities.facilityGwsFilter;
    }
    return [];
  }
);

export const getFacilityTypes = createSelector(facilities, (facilities: ReduxFacilitiesState) => {
  if (facilities) {
    const activeGwsFilter = facilities?.facilityGwsFilter ?? [];
    const uniqueFacilityTypes = facilities.facilities.reduce((typeSet, fac) => {
      if (filterIncludesPropertyHelper(activeGwsFilter, fac.buildingClassification)) {
        typeSet.add(fac.facilityType);
      }
      return typeSet;
    }, new Set());

    return Array.from(uniqueFacilityTypes).sort() as string[];
  }
  return [];
});

export const getFac03FacilityTypes = createSelector(
  facilities,
  (facilities: ReduxFacilitiesState) => {
    if (facilities) {
      const activeGwsFilter = facilities?.facilityGwsFilter ?? [];
      const uniqueFacilityTypes = facilities.facilities.reduce((typeSet, fac) => {
        if (
          fac.source === "FAC03" &&
          filterIncludesPropertyHelper(activeGwsFilter, fac.buildingClassification)
        ) {
          typeSet.add(fac.facilityType);
        }
        return typeSet;
      }, new Set());

      return Array.from(uniqueFacilityTypes).sort() as string[];
    }
    return [];
  }
);

export const getFacilityClassifications = createSelector(
  facilities,
  (facilities: ReduxFacilitiesState) => {
    if (facilities) {
      const activeGwsFilter = facilities?.facilityGwsFilter ?? [];

      const uniqueClassifications = facilities.facilities.reduce((classSet, fac) => {
        if (
          filterIncludesPropertyHelper(activeGwsFilter, fac.buildingClassification) &&
          fac.coiDatacenter?.operationalTaxonomy
        ) {
          classSet.add(fac.coiDatacenter.operationalTaxonomy);
        }
        return classSet;
      }, new Set());

      return Array.from(uniqueClassifications).sort() as string[];
    }
    return [];
  }
);

export const getSelectedFacilityDivisions = createSelector(
  facilities,
  (facilities: ReduxFacilitiesState): string[] => facilities?.facilityDivisionFilter ?? []
);

export const getSelectedFacilityTypes = createSelector(
  facilities,
  (facilities: ReduxFacilitiesState) => {
    if (facilities) {
      return facilities.facilityTypeFilter;
    }
    return [];
  }
);

export const getSelectedFacilityClassifications = createSelector(
  facilities,
  (facilities: ReduxFacilitiesState) => {
    if (facilities) {
      return facilities.facilityClassificationFilter;
    }
    return [];
  }
);

export const getFacilityAssetSearchTerm = createSelector(
  facilities,
  (facilities: ReduxFacilitiesState) => facilities?.facilityAssetSearchTerm ?? ""
);

export const getDivsByBuilding = createSelector(
  people,
  facilities,
  (people: ReduxPeopleState, facilities: ReduxFacilitiesState) => {
    if (people && facilities) {
      let peopleData = people.normalizedEmployeeProfiles;
      let activeBuildings = facilityFilterHelper(facilities);

      const activeGwsFilters = facilities.facilityGwsFilter ?? [];
      const activeTypeFilters = facilities.facilityTypeFilter ?? [];
      const activeClassFilters = facilities.facilityClassificationFilter ?? [];

      const activeBuildingNames: Set<string> = activeBuildings.reduce((buildingNames, fac) => {
        if (
          filterIncludesPropertyHelper(activeGwsFilters, fac.buildingClassification) &&
          filterIncludesPropertyHelper(activeTypeFilters, fac.facilityType) &&
          filterIncludesPropertyHelper(activeClassFilters, fac.coiDatacenter?.operationalTaxonomy)
        ) {
          buildingNames.add(fac.facilityName);
        }
        return buildingNames;
      }, new Set<string>());

      let filteredPeopleData = peopleData.filter((man) =>
        activeBuildingNames.has(man.buildingName)
      );

      const divsByBuilding = Array.from(
        filteredPeopleData.reduce((orgSet, p) => {
          orgSet.add(p?.l1ManagerEmailName ?? "Unknown");
          return orgSet;
        }, new Set<string>())
      );

      return divsByBuilding.map((div) => {
        const divPeople = filteredPeopleData.reduce(
          (acc, p) => {
            if (p.l1ManagerEmailName === div) {
              acc.fteCount += p.isActiveFte ? 1 : 0;
              acc.buildingIds.push(p.buildingId);
            }
            return acc;
          },
          { fteCount: 0, buildingIds: [] }
        );

        return {
          name: div,
          fte: divPeople.fteCount,
          nonFte: divPeople.buildingIds.length - divPeople.fteCount,
          buildingIds: divPeople.buildingIds,
        };
      });
    }
  }
);

export const getCoiDcTypeCountByTaxonomyClass = createSelector(
  facilities,
  (facilities: ReduxFacilitiesState) => {
    if (facilities) {
      const activeGwsFilters = facilities.facilityGwsFilter ?? [];
      const activeTypeFilters = facilities.facilityTypeFilter ?? [];
      const activeClassFilters = facilities.facilityClassificationFilter ?? [];

      const operationalFacilities = facilityFilterHelper(facilities).filter(
        (fac) =>
          filterIncludesPropertyHelper(activeGwsFilters, fac.buildingClassification) &&
          filterIncludesPropertyHelper(activeTypeFilters, fac.facilityType) &&
          filterIncludesPropertyHelper(
            activeClassFilters,
            fac.coiDatacenter?.operationalTaxonomy
          ) &&
          fac.coiDatacenter != null &&
          fac.coiDatacenter.phaseName === "Operational"
      );

      const classList = operationalFacilities.reduce((countMap, f) => {
        const coiDcTaxonomy = f.coiDatacenter.operationalTaxonomy;
        const coiFacType = f.coiDatacenter.facilityTypeName;
        if (!countMap.has(coiDcTaxonomy)) {
          countMap.set(coiDcTaxonomy, new Map());
        }
        const taxonomyMap = countMap.get(coiDcTaxonomy);
        const count = taxonomyMap.get(coiFacType) || 0;
        taxonomyMap.set(coiFacType, count + 1);
        return countMap;
      }, new Map());

      const results = Array.from(classList.entries()).map(([taxonomy, countsMap]) => {
        const counts = Array.from(countsMap.entries()).map(([name, count]) => ({ name, count }));
        return { name: taxonomy, counts };
      });

      return results;
    }
  }
);

export const buildFacilityCounts = (originalList: IFacilityModel[]): FacilityAggregate => {
  const facilityAggregate: FacilityAggregate = originalList.reduce(
    (aggregate, f) => {
      aggregate.facilityCount++;
      if (f.headCount > 0) {
        aggregate.allocatedFacilityCount++;
      }
      if (f.facilityType === "Datacenter") {
        aggregate.dataCenterCount++;
      }
      if (f.buildingClassification === "Critical") {
        aggregate.gwsCriticalFacilityCount++;
      }
      return aggregate;
    },
    {
      facilityCount: 0,
      allocatedFacilityCount: 0,
      unAllocatedFacilityCount: 0,
      dataCenterCount: 0,
      gwsCriticalFacilityCount: 0,
    }
  );
  facilityAggregate.unAllocatedFacilityCount =
    facilityAggregate.facilityCount - facilityAggregate.allocatedFacilityCount;
  return facilityAggregate;
};

//helper functions
export const facilityFilterHelper = (facilities: ReduxFacilitiesState): IFacilityModel[] => {
  let facilityCountryFilter = facilities.facilityCountryFilter ?? [];
  let facilityStateFilter = facilities.facilityStateFilter ?? [];
  let facilityCityFilter = facilities.facilityCityFilter ?? [];

  let tempFac: IFacilityModel[] = facilities.facilities.filter((f) => {
    const { country, state, city } = f;
    return (
      filterIncludesPropertyHelper(facilityCountryFilter, country?.toUpperCase()) &&
      filterIncludesPropertyHelper(facilityStateFilter, state?.toUpperCase()) &&
      filterIncludesPropertyHelper(facilityCityFilter, city?.toUpperCase().trim())
    );
  });

  return tempFac;
};

const filterIncludesPropertyHelper = <T>(filter: T[], property: T): boolean => {
  return filter.length === 0 || filter.includes(property);
};
