import {
  IButtonProps,
  IColumn,
  IDetailsRowProps,
  IGroup,
  IRenderFunction,
} from "@fluentui/react";
import { FontWeights, SelectionMode, Text } from "@fluentui/react";
import {
  CompositeListRow,
  IActionButtonProps,
  ICompositeListDetailsList,
} from "@m365-admin/composite-list";
import { CompositeList } from "@m365-admin/composite-list";
import * as React from "react";
import { useState } from "react";
import { DependencyCount } from "../../types/DependencyMappingTypes";

export class BaseGroupHeaderItem {
  public criticalityRating!: string;
  public criticalityRatingCount!: number;
}

export interface Top10ServiceDownstreamDepDataGridProps {
  top10ServiceDownstreamDepData: DependencyCount[];
  onSelectedItem: (serviceOid: string) => void;
}

export const Top10ServiceDownstreamDepDataGrid = (
  props: Top10ServiceDownstreamDepDataGridProps
) => {
  const defaultGroupingNames: string[] = [
    "Critical",
    "Non-critical",
    "Critical For Recovery",
  ];

  const columns: IColumn[] = [
    {
      key: "serviceName",
      name: "Service Name",
      ariaLabel: "Service Name",
      minWidth: 150,
      maxWidth: 250,
      isMultiline: true,
      isResizable: true,
      onRender: (x: DependencyCount) =>
        `${x?.serviceGuidNamePairs?.serviceName ?? "Unknown"}`,
    },
    {
      key: "criticalityRating",
      fieldName: "criticalityRating",
      name: "Criticality Rating",
      ariaLabel: "Criticality Rating",
      minWidth: 75,
      maxWidth: 100,
      isResizable: true,
      onRender: (x: DependencyCount) => `${x.criticalityRating}`,
    },
    {
      key: "dependencyCount",
      fieldName: "dependencyCount",
      name: "Dependency Count",
      ariaLabel: "Dependency Count",
      minWidth: 75,
      maxWidth: 100,
      isResizable: true,
      onRender: (x: DependencyCount) => `${x.dependencyCount}`,
    },
    {
      key: "serviceOid",
      name: "Service Id",
      ariaLabel: "Downstream Service Id",
      minWidth: 150,
      maxWidth: 250,
      isMultiline: true,
      isResizable: true,
      onRender: (x: DependencyCount) =>
        `${x?.serviceGuidNamePairs?.serviceOid ?? "Unknown"}`,
    },
  ];

  const headerColumns: IColumn[] = [
    {
      key: "criticalityRating",
      name: "Criticality Rating",
      isResizable: true,
      minWidth: 100,
      maxWidth: 275,
      onRender: (item: BaseGroupHeaderItem) => {
        return (
          <Text styles={{ root: { fontWeight: FontWeights.semibold } }}>
            {item.criticalityRating}
          </Text>
        );
      },
    },
    {
      key: "criticalityRatingCount",
      name: "Total Count",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      onRender: (item: BaseGroupHeaderItem) =>
        item.criticalityRatingCount > 0 ? (
          <>{item.criticalityRatingCount}</>
        ) : (
          <></>
        ),
    },
  ];

  const getMoreActionItemProps: IButtonProps = {
    name: "details",
    iconProps: { iconName: "Link" },
    title: "Click icon to view dependency details",
    ariaLabel: "Click icon to view dependency details",
  };

  function getActionItems<T>(rowItem: T): IActionButtonProps<T>[] {
    const actionItems = [];

    actionItems.push({
      key: "more",
      buttonProps: getMoreActionItemProps,
      item: rowItem,
      onClick: (item: T): void => {
        if (item) {
          const serviceOid = (item as any).serviceGuidNamePairs?.serviceOid;
          const serviceName = (item as any).serviceGuidNamePairs?.serviceName;
          if (serviceOid && serviceName) {
            props.onSelectedItem(serviceOid);
          }
        }
      },
    });

    return actionItems;
  }

  const onRenderRow = (
    rowProps: IDetailsRowProps,
    defaultRender: IRenderFunction<IDetailsRowProps>
  ): JSX.Element => {
    return (
      <CompositeListRow
        actionKey="serviceName"
        renderFunction={defaultRender}
        rowProps={rowProps}
        actionItems={getActionItems(rowProps.item)}
      />
    );
  };

  const _getGroups = (
    groupItems: DependencyCount[],
    groupingNames: string[]
  ) => {
    const dataGroups: IGroup[] = [];
    let groupsStartIndex = 0;

    groupingNames.forEach((groupedItem: string) => {
      const groupedData = groupItems.filter((item: DependencyCount) => {
        return (
          item.criticalityRating?.toLowerCase() === groupedItem?.toLowerCase()
        );
      });

      const rowCount = groupedData.length;
      const headerItem: BaseGroupHeaderItem = {
        criticalityRating: groupedItem,
        criticalityRatingCount: 0,
      };

      if (rowCount > 0) {
        headerItem.criticalityRatingCount = groupedData.length;
      }

      const group: IGroup = {
        key: groupedItem,
        name: groupedItem,
        startIndex: groupsStartIndex,
        count: rowCount,
        data: headerItem,
      };

      groupsStartIndex += rowCount;

      dataGroups.push(group);
    });

    return dataGroups;
  };

  const [list, setList] = useState<ICompositeListDetailsList>({
    listProps: {
      // NOTE: `checkButtonAriaLabel` will set the same aria-label for all row checkboxes.
      // If you want different aria-labels for each row checkbox,
      // please see `onRenderCheck` on `onRenderRow` in this example.
      // Fluent guidance: https://github.com/microsoft/fluentui/issues/16450#issuecomment-793201238
      // checkButtonAriaLabel: 'Select item',
      ariaLabelForSelectAllCheckbox: "Select all items",
      items: [],
      groups: _getGroups([], defaultGroupingNames),
      groupProps: {
        headerProps: {},
        showEmptyGroups: false,
      },
      groupHeaderColumns: headerColumns,
      columns: columns,
      selectionMode: SelectionMode.none,
    },
    key: "top 10 services with most Downstream dependencies list",
  });

  React.useMemo(() => {
    const uniqueGroup = Array.from(
      new Set(
        props.top10ServiceDownstreamDepData.map((x) => x.criticalityRating)
      )
    );
    let groupings = uniqueGroup.sort();

    const sortedData = props?.top10ServiceDownstreamDepData.sort((a, b) =>
      a.criticalityRating
        ?.toUpperCase()
        .localeCompare(b.criticalityRating.toUpperCase())
    );

    setList({
      listProps: {
        ariaLabelForSelectAllCheckbox: "Select all items",
        items: sortedData,
        groups: _getGroups(sortedData, groupings),
        groupProps: {
          headerProps: {
            selectAllButtonProps: {
              "aria-label": "Select all items in the group",
            },
          },
          showEmptyGroups: false,
        },
        groupHeaderColumns: headerColumns,
        columns: columns,
        selectionMode: SelectionMode.none,
        onRenderRow: onRenderRow,
      },
      key: "top 10 services with most Downstream dependency list",
    });
  }, [props.top10ServiceDownstreamDepData]);

  return (
    <CompositeList detailsListProps={list} commandBarProps={{ items: [] }} />
  );
};
