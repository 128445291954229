import { Stack, TagItem } from "@fluentui/react";
import React from "react";
import {
  updateServiceBclFilters,
  updateServiceCriticalityFilters,
  updateServiceDataCenterFilters,
  updateServiceDivisionFilters,
  updateServiceOrganizationFilters,
  updateServiceRecoveryClassificationFilters,
  updateServiceServiceGroupFilters,
  updateServiceSingleDcFilter,
} from "store/actions/servicesActions";
import {
  getServiceBclFilters,
  getServiceRtoCriticalityFilters,
  getServiceDataCenterFilters,
  getServiceDataCenterFiltersWithNames,
  getServiceDivisionFilters,
  getServiceOrganizationFilters,
  getServiceRecoveryClassificationFilters,
  getServiceSgFilters,
  getServiceSingleDcFilter,
} from "store/selectors/servicesSelector";
import { useAppDispatch, useAppSelector } from "store/store";

export const ServiceFilterTags = () => {
  const dispatch = useAppDispatch();
  const singleDcFilters = useAppSelector(getServiceSingleDcFilter);
  const criticalityFilters = useAppSelector(getServiceRtoCriticalityFilters);
  const dcFilters = useAppSelector(getServiceDataCenterFilters);
  const divFilters = useAppSelector(getServiceDivisionFilters);
  const orgFilters = useAppSelector(getServiceOrganizationFilters);
  const serviceGroupFilters = useAppSelector(getServiceSgFilters);
  const recoveryFilters = useAppSelector(getServiceRecoveryClassificationFilters);
  const bclFilters = useAppSelector(getServiceBclFilters);
  const dcFiltersWithNames = useAppSelector(getServiceDataCenterFiltersWithNames);

  return (
    <Stack
      horizontal
      aria-label="Active Filters"
      horizontalAlign="start"
      verticalAlign="start"
      tokens={{ childrenGap: 10, padding: 4 }}
      role="list"
    >
      {singleDcFilters === true && (
        <TagItem
          maxLength={250}
          removeButtonAriaLabel="Remove"
          item={{
            key: "singleDcFilters",
            name: `Single DC Filter`,
          }}
          index={0}
          onRemoveItem={() => dispatch(updateServiceSingleDcFilter(false))}
        >
          Single DC Services
        </TagItem>
      )}
      {criticalityFilters.length > 0 && (
        <TagItem
          maxLength={250}
          removeButtonAriaLabel="Remove"
          item={{
            key: "criticalityFilters",
            name: `Active Criticality Filters: ${criticalityFilters.join(", ")}`,
          }}
          index={1}
          onRemoveItem={() => dispatch(updateServiceCriticalityFilters([]))}
        >
          Criticality: {criticalityFilters.join(", ")}
        </TagItem>
      )}
      {divFilters.length > 0 && (
        <TagItem
          removeButtonAriaLabel="Remove"
          item={{
            key: "divGroup",
            name: `Active Division Filters: ${divFilters
              .map((alias) => (alias === "" ? "Unknown" : alias))
              .join(", ")} `,
          }}
          index={2}
          onRemoveItem={() => dispatch(updateServiceDivisionFilters([]))}
        >
          Division: {divFilters.map((alias) => (alias === "" ? "Unknown" : alias)).join(", ")}
        </TagItem>
      )}
      {orgFilters.length > 0 && (
        <TagItem
          removeButtonAriaLabel="Remove"
          item={{
            key: "orgGroup",
            name: `Active Organization Filters ${orgFilters
              .map((alias) => (alias === "" ? "Unknown" : alias))
              .join(", ")}`,
          }}
          index={3}
          onRemoveItem={() => dispatch(updateServiceOrganizationFilters([]))}
        >
          Org: {orgFilters.map((alias) => (alias === "" ? "Unknown" : alias)).join(", ")}
        </TagItem>
      )}
      {serviceGroupFilters.length > 0 && (
        <TagItem
          removeButtonAriaLabel="Remove"
          item={{
            key: "serviceGroup",
            name: `Active Service Group Filters: ${serviceGroupFilters
              .map((alias) => (alias === "" ? "Unknown" : alias))
              .join(", ")}`,
          }}
          index={4}
          onRemoveItem={() => dispatch(updateServiceServiceGroupFilters([]))}
        >
          Service Group:{" "}
          {serviceGroupFilters.map((alias) => (alias === "" ? "Unknown" : alias)).join(", ")}
        </TagItem>
      )}
      {dcFilters.length > 0 && (
        <TagItem
          removeButtonAriaLabel="Remove"
          item={{
            key: "dcFilters",
            name: `Active DC Filters: ${dcFiltersWithNames.map((item) => item.name).join(", ")}`,
          }}
          index={5}
          onRemoveItem={() => dispatch(updateServiceDataCenterFilters([]))}
        >
          Data Center:{dcFiltersWithNames.map((item) => item.name).join(", ")}
        </TagItem>
      )}
      {recoveryFilters.length > 0 && (
        <TagItem
          removeButtonAriaLabel="Remove"
          item={{
            key: "recoveryClassification",
            name: `Active Recovery Classification Filters: ${recoveryFilters.join(", ")}`,
          }}
          index={6}
          onRemoveItem={() => dispatch(updateServiceRecoveryClassificationFilters([]))}
        >
          Recovery Classification: {recoveryFilters.join(", ")}
        </TagItem>
      )}
      {bclFilters.length > 0 && (
        <TagItem
          removeButtonAriaLabel="Remove"
          item={{
            key: "businessContinuityLead",
            name: `Active BCL Filters: ${bclFilters.join(", ")}`,
          }}
          index={7}
          onRemoveItem={() => dispatch(updateServiceBclFilters([]))}
        >
          BCL: {bclFilters.join(", ")}
        </TagItem>
      )}
    </Stack>
  );
};
