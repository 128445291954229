import React from "react";
import { Icon, Theme } from "@fluentui/react";
import {
  ICustomProperties,
  IPageViewTelemetry,
} from "@microsoft/applicationinsights-web";
import * as XLSX from "xlsx";

import { ServiceAssetDetail, AzureRegion, IDownloadDetails } from "../Types";
import { _Styles } from "../../pages/Page.styles";

export function copyAndSort<T>(
  items: T[],
  columnKey: string,
  isSortedDescending?: boolean
): T[] {
  const key = columnKey as keyof T;
  return items.sort((a: T, b: T) =>
    (isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1
  );
}

export const convertIntBooleanToString = (i: number): string => {
  let convertValue = isNaN(i) ? -1 : i;
  if (convertValue > 0) return "Yes";
  else if (convertValue === 0) return "No";
  return "Unknown";
};

export const convertStringBooleanToString = (i: string): string => {
  let convertValue = Number.parseInt(i) ?? -1;
  if (convertValue > 0) return "Yes";
  else if (convertValue === 0) return "No";
  return "Unknown";
};

export const getFilteredAssetData = (
  rawAssetData: ServiceAssetDetail[],
  divisionFilter: string[],
  organizationFilter: string[],
  serviceGroupFilter: string[],
  criticalityFilter: string[]
): ServiceAssetDetail[] => {
  let filteredAssets = rawAssetData;
  if (divisionFilter && divisionFilter.length > 0) {
    filteredAssets = filteredAssets.filter((s) =>
      divisionFilter.includes(s.divisionName)
    );
  }
  if (organizationFilter && organizationFilter.length > 0) {
    filteredAssets = filteredAssets.filter((s) =>
      organizationFilter.includes(s.organizationName)
    );
  }
  if (serviceGroupFilter && serviceGroupFilter.length > 0) {
    filteredAssets = filteredAssets.filter((s) =>
      serviceGroupFilter.includes(s.serviceGroupName)
    );
  }
  if (criticalityFilter && criticalityFilter.length > 0) {
    filteredAssets = filteredAssets.filter((s) =>
      criticalityFilter.includes(s.rtoCriticalRating)
    );
  }
  return filteredAssets;
};

export const buildFilterEventCustomTelemetry = (
  filterName: string,
  selectedItem: string
): ICustomProperties => {
  return {
    filterUsed: filterName,
    selectedItem: selectedItem,
  };
};

export const buildPageViewTelemetry = (
  pageName: string,
  pageUri: string,
  currentPageUri: string
): IPageViewTelemetry => {
  return {
    name: pageName,
    uri: pageUri,
    refUri: currentPageUri,
    isLoggedIn: true,
  };
};

export const buildExportEventCustomTelemetry = (
  tableName: "DriInsights" | "Facilities" | "People" | "Processes" | "Services",
  rowsDownloaded: number,
  userDownloadPermission: boolean
): ICustomProperties => {
  return {
    tableName: tableName,
    rowsDownloaded: rowsDownloaded,
    hasDownloadPermission: userDownloadPermission,
  };
};

export const buildIcon = (
  statusString: string,
  dotColor: string,
  iconToUse?: string
) => {
  return (
    <span className={_Styles.statusIcon} key={statusString}>
      <Icon
        iconName={iconToUse ?? "StatusCircleInner"}
        style={{ color: dotColor, marginRight: "4px" }}
      />
      {statusString}
    </span>
  );
};

export const buildStatus = (impactScore: string, theme: Theme) => {
  switch (impactScore?.toUpperCase()) {
    case "HIGH":
      return buildIcon("High", theme.palette.redDark);
    case "MODERATE":
      return buildIcon("Moderate", theme.palette.orangeLight);
    case "LOW":
      return buildIcon("Low", theme.palette.yellow);
    case "NO IMPACT":
      return buildIcon("No Impact", theme.palette.green);
    case "NO TEAM HISTORY":
      return undefined;
    default:
      return undefined;
  }
};

//pulled this from the microsoft/FluentUIEditableDetailsList code as we couldn't
//get the editable grid to work at all
export const ExportToCSVUtil = (
  exportData: any[],
  columnHeaderNames: any[],
  fileName: string
): void => {
  if (!exportData || !exportData.length) {
    return;
  }
  //set current date to append to data columns
  const timeStamp = new Date(Date.now()).toLocaleDateString("en-US");
  const separator = ",";
  const keys = columnHeaderNames;

  const csvContent =
    keys.join(separator) +
    ",download date\n" +
    exportData
      .map((row) => {
        return keys
          .map((k) => {
            let cell = row[k] === null || row[k] === undefined ? "" : row[k];
            if (cell instanceof Date) {
              cell = cell.toLocaleString();
            } else if (k === "azureRegionData") {
              cell = (cell as AzureRegion[])
                .map(
                  (azr) =>
                    `${azr.azureCloudName}-${azr.azureCloudRegionName}-${azr.datacenterCampusName}-${azr.countryRegion}`
                )
                .toString();
            } else {
              cell = cell.toString().replace(/"/g, '""');
            }
            if (cell.search(/("|,|\n)/g) >= 0) {
              cell = `"${cell}"`;
            }
            return cell;
          })
          .join(separator);
      })
      .join(`,${timeStamp}\n`);

  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  if (link.download !== undefined) {
    // Browsers that support HTML5 download attribute
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", fileName + timeStamp);
    link.style.visibility = "hidden";
    link.dataset.interception = "off";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export const ExportToExcelUtil = (exportData: IDownloadDetails): void => {
  let fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  let sheetMap = new Map<string, any>();
  exportData.data.forEach((entry) => {
    const ws = XLSX.utils.json_to_sheet(entry.dataToExport);
    sheetMap.set(entry.sheetName, ws);
  });

  const wb = {
    Sheets: Object.fromEntries(sheetMap),
    SheetNames: exportData.data.map((d) => d.sheetName),
  };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

  const data = new Blob([excelBuffer], { type: fileType });
  const link = document.createElement("a");
  if (link.download !== undefined) {
    // Browsers that support HTML5 download attribute
    const url = URL.createObjectURL(data);
    link.setAttribute("href", url);
    link.setAttribute("download", exportData.fileName);
    link.style.visibility = "hidden";
    link.dataset.interception = "off";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};
