import {
  DefaultPalette,
  IProcessedStyleSet,
  IStyle,
  ITheme,
} from "@fluentui/react";
import { ILegend } from "@fluentui/react-charting";

export interface IDependencyGraphChartDataPoint {
  /**
   * Node main text
   */
  name: string;
  /**
   * Subtext value (optional)
   */
  subname?: string;
  /**
   * Bodytext value (optional)
   */
  bodytext?: string;
  /**
   * Metric text value (optional)
   */
  metric?: string;
  /**
   * Color of the rectangular box
   */
  fill: string;
}

export interface Link {
  source: any;
  target: any;
  direction: "upstream" | "downstream" | "circular";
  relationshipLevel: number;
}

export interface Node {
  id: string;
}

export interface ServiceDependencyGraphProps {
  links: any[];
  nodes: any[];
  height: number;
  width: number;
  legend?: ILegend[];
  handleOnClick?: (event: React.MouseEvent<SVGGElement>, node: any) => void;
}

export interface DependencyGraphBaseProps {
  nodes: any[];
  links: any[];
  rectangle: {
    width: number;
    height: number;
    textMargin: number;
  };
  styleClassNames: IProcessedStyleSet<IDependencyGraphStyles>;
  theme: ITheme;
  chartHeight: number;
  chartWidth: number;
  margin: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
  linkTypes: string[];
  legend?: ILegend[];
  handleOnClick?: (event: React.MouseEvent<SVGGElement>, node: any) => void;
}

export interface DependencyGraphNodeProps {
  node: any;
  rectangle: {
    width: number;
    height: number;
    textMargin: number;
  };
  styleClassNames: IProcessedStyleSet<IDependencyGraphStyles>;
  theme: ITheme;
  handleOnClick?: (event: React.MouseEvent<SVGGElement>, node: any) => void;
}

export interface DependencyGraphLinkProps {
  link: any;
  index: number;
  rectangle: {
    width: number;
    height: number;
    textMargin: number;
  };
  styleClassNames: IProcessedStyleSet<IDependencyGraphStyles>;
  theme: ITheme;
  legend?: ILegend[];
}

export interface IDependencyGraphStyleProps {
  /**
   * Theme (provided through customization.)
   */
  theme: ITheme;
  /**
   * Additional CSS class(es) to apply to the Dependency Graph Chart.
   */
  className?: string;
}

export interface IDependencyGraphStyles {
  /**
   *  Style for the root element.
   */
  root: IStyle;
  /**
   *  Style for the Link/Path element.
   */
  link: IStyle;
  /**
   *  Style for rectangular Node
   */
  rectNode: IStyle;
  /**
   *  Style for the node main Text
   */
  rectText: IStyle;
  /**
   *  Style for the node sub Text
   */
  rectSubText: IStyle;
  /**
   *  Style for the node body Text
   */
  rectBodyText: IStyle;
  /**
   *  Style for the node metric value Text
   */
  rectMetricText: IStyle;
}
export const defaultDependencyLegend: ILegend[] = [
  {
    title: "First Level Downstream",
    color: DefaultPalette.tealLight,
  },
  {
    title: "Second Level Downstream",
    color: DefaultPalette.green,
  },
  {
    title: "Circular",
    color: DefaultPalette.blue,
  },
  {
    title: "First Level Upstream",
    color: DefaultPalette.purpleLight,
  },
  {
    title: "Second Level Upstream",
    color: DefaultPalette.magenta,
  },
];
