import {
  HighContrastSelectorBlack,
  ITheme,
  classNamesFunction,
} from "@fluentui/react";
import {
  IDependencyGraphStyleProps,
  IDependencyGraphStyles,
} from "./ServiceDependencyGraph.types";

export const getClassNames = classNamesFunction<
  IDependencyGraphStyleProps,
  IDependencyGraphStyles
>();

export const getStyles = (props: ITheme): IDependencyGraphStyles => {
  return {
    root: [
      "ms-depGraph-root",
      props.name,
      {
        overflow: "scroll",
      },
    ],
    link: {
      fill: "none",
      strokeWidth: "4px",
      "&:hover": {
        position: "absolute",
        stroke: props.palette.black,
        padding: "14px",
      },
    },
    rectNode: {
      strokeWidth: "2px",
      fill: props.semanticColors.bodyBackground,
      padding: "10px",
      rx: "2px",
      selectors: {
        "&:hover": {
          position: "absolute",
          fill: props.semanticColors.bodyBackgroundHovered,
          padding: "14px",
        },
        [HighContrastSelectorBlack]: {
          fill: props.semanticColors.bodyBackgroundHovered,
        },
      },
    },
    rectText: {
      fill: "black",
      ...props!.fonts.large,
    },
    rectSubText: {
      fill: "#484644",
      ...props!.fonts.mediumPlus,
    },
    rectBodyText: {
      fill: "#808080",
      ...props!.fonts.small,
    },
    rectMetricText: {
      fill: "#000000",
      ...props!.fonts.xLarge,
    },
  };
};
