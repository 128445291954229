import { CoherenceModal } from "@coherence-design-system/controls";
import { CommandBar, IContextualMenuItem, IContextualMenuProps, ScrollablePane, Text, mergeStyles, } from "@fluentui/react";
import { DashboardCard } from "@m365-admin/card";
import { useM365Theme } from "@m365-admin/customizations";
import { Dashboard } from "@m365-admin/dashboard";
import { IRearrangeableGridDefinition, IRearrangeableGridGap, } from "@m365-admin/rearrangeable-grid";
import { CardSizeValues, CardSizes } from "components/Cards.constants";
import { bcdrRecoveryTerms } from "components/InPageNav/WikiData";
import { IDownloadDetails, IGeoResilienceTeamDataDownload } from "components/Types";
import { ServiceBcdrAnnotationCard } from "components/cards/SericeBcdrAnnotationCard";
import { ServiceBcdrAssessmentCard } from "components/cards/ServiceBcdrAssessmentCard";
import { ServiceBcdrContactCard } from "components/cards/ServiceBcdrContactCard";
import { ServiceBcdrDetailCard } from "components/cards/ServiceBcdrDetailCard";
import { ServiceDepAnnotationCard } from "components/cards/ServiceDependencyDetailCard";
import { ServiceDependencyGraphCard } from "components/cards/ServiceDependencyGraphCard";
import { ServiceDriGeoDataCard } from "components/cards/ServiceDriGeoDataCard";
import { ExportToExcelUtil } from "components/datagrids/Helpers";
import { ServiceDownstreamDepDataGrid } from "components/datagrids/ServiceDownstreamDepDataGrid";
import { ServiceUpstreamDepDataGrid } from "components/datagrids/ServiceUpstreamDepDataGrid";
import { EmailPeoplePicker } from "components/modals/EmailPeoplePicker";
import { makeGenericTooltipCallout } from "components/modals/InsightCallout";
import ServiceDependencyDetailPanel from "components/panels/details/ServiceDependencyDetailPanel";
import { mapToDownstreamDependencyView, mapToDriDataView, mapToServiceDependencyView, mapToUpstreamDependencyView } from "components/pivots/helpers/downloadMappingHelpers";
import { _Styles } from "pages/Page.styles";
import React from "react";
import { appInsights } from "services/AppInsights";
import { clearSecondaryServiceDependencyDriData, clearServiceDependencyPanelMetadata, fetchSecondaryServiceDependencyDriData, fetchServiceDependencyPanelMetadata } from "store/actions/serviceDependencyMappingActions";
import { clearServiceDetailsByIds, fetchServiceDetailsByIds } from "store/actions/servicesActions";
import { getCurrentServiceDependencyDriData, getCurrentServiceDependencyGraphData, getCurrentServiceDependencyMappingMetadata, getCurrentServiceDependencyPeopleData } from "store/selectors/serviceDependencyMappingSelector";
import { getUserDownloadPermissions, getUserIsRestricted } from "store/selectors/usersSelector";
import { useAppDispatch, useAppSelector } from "store/store";
import { DownstreamDependencyData, IDownstreamDependencyDataDownload, IServiceBcdrDetailedDataDownload, IUpstreamDependencyDataDownload, UpstreamDependencyData } from "types/DependencyMappingTypes";

export interface ServiceDependencyDashboardProps { }

export function ServiceDependencyDashboard() {
  const theme = useM365Theme();
  // data
  const currentServiceMetadata = useAppSelector(getCurrentServiceDependencyMappingMetadata);
  const currentPeopleToEmail = useAppSelector(getCurrentServiceDependencyPeopleData);
  const currentServiceDriData = useAppSelector(getCurrentServiceDependencyDriData);
  const currentServiceGraphData = useAppSelector(getCurrentServiceDependencyGraphData);

  // download perms
  const userCanDownload = useAppSelector(getUserDownloadPermissions);
  const userHasViewRestriction = useAppSelector(getUserIsRestricted);
  // download function
  const logAndDownloadXlsx = () => {
    const fileName = `WatchtowerServiceDependencyDataExport_${currentServiceMetadata.serviceOid}`;
    const currentServiceData = currentServiceMetadata;
    const currentUpstreamServiceDepData = currentServiceData.upstreamDependencies;
    const currentDownstreamServiceData = currentServiceData.downstreamDependencies;

    appInsights.trackEvent(
      { name: "Download Used" },
      {
        Source: "Service Dependencies",
        "restricted user": userHasViewRestriction,
        count:
          currentDownstreamServiceData.length +
          currentUpstreamServiceDepData.length,
      }
    );

    const timeStamp = new Date(Date.now()).toLocaleDateString("en-US");
    const refServiceName = currentServiceData.coreServiceData.serviceName;
    const refServiecOid = currentServiceData.serviceOid;
    const serviceData: IServiceBcdrDetailedDataDownload[] = [
      { ...currentServiceData },
    ].map((d) => mapToServiceDependencyView(d, timeStamp));
    const upstreamData: IUpstreamDependencyDataDownload[] =
      currentUpstreamServiceDepData.map<IUpstreamDependencyDataDownload>((d) =>
        mapToUpstreamDependencyView(d, refServiceName, refServiecOid, timeStamp)
      );
    const downstreamData: IDownstreamDependencyDataDownload[] =
      currentDownstreamServiceData.map((d) =>
        mapToDownstreamDependencyView(
          d,
          refServiceName,
          refServiecOid,
          timeStamp
        )
      );
    const serviceDriData: IGeoResilienceTeamDataDownload[] =
      currentServiceDriData.flatMap((d) =>
        d.geoResilienceTeamData.map<IGeoResilienceTeamDataDownload>((geoData) =>
          mapToDriDataView(
            geoData,
            d.teamId,
            d.teamName,
            refServiecOid,
            timeStamp
          )
        )
      );
    const downloadData: IDownloadDetails = {
      fileName: fileName,
      data: [
        {
          dataToExport: serviceData,
          sheetName: "Service Details",
        },
        {
          dataToExport: upstreamData,
          sheetName: "Upstream Dependency Details",
        },
        {
          dataToExport: downstreamData,
          sheetName: "Dowonstream Dependency Details",
        },
        {
          dataToExport: serviceDriData,
          sheetName: "Service DRI Details",
        },
      ],
    };

    ExportToExcelUtil(downloadData);
  };

  //handle table modal & headers
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const hideModal = () => {
    setShowModal(false);
  };

  const dispatch = useAppDispatch()

  // store the state of the Dashboard and handle changes within it
  const [map, setMap] = React.useState<IRearrangeableGridDefinition>({
    itemSummaryCard: { cellHeight: 1, cellWidth: 4, row: 0, col: 0 },
    contactCard: { cellHeight: 1, cellWidth: 3, row: 0, col: 4 },
    dataVizCard: { cellHeight: 1, cellWidth: 1, row: 1, col: 0 },
    gapCard: { cellHeight: 1, cellWidth: 1, row: 1, col: 1 },
    itemDetailsCard: { cellHeight: 1, cellWidth: 2, row: 1, col: 2 },
    driDataCard: { cellHeight: 1, cellWidth: 3, row: 1, col: 4 },
    upstreamCard: { cellHeight: 1, cellWidth: 3, row: 2, col: 0 },
    downstreamCard: { cellHeight: 1, cellWidth: 3, row: 2, col: 3 },
    depMapCard: { cellHeight: 5, cellWidth: 6, row: 3, col: 0 },
  });

  // default row/column sizing
  const defaultDashboardGridSize = {
    minCellWidth: 200,
    minCellHeight: 150,
  };

  //handle resizing in the dashboard grid
  const resizeCallback =
    React.useRef<
      (
        width: number,
        height: number,
        itemKey: string,
        position?: { col: number; row: number }
      ) => void
    >();

  const generateResizeCallback = (key: string) => {
    return (
      ev:
        | React.MouseEvent<HTMLElement, MouseEvent>
        | React.KeyboardEvent<HTMLElement>,
      item: IContextualMenuItem
    ) => {
      const dimension = CardSizeValues[item.key as CardSizes];

      resizeCallback.current?.(dimension.cellWidth, dimension.cellHeight, key);
    };
  };

  const columnResizeRef =
    React.useRef<
      (width: number, height: number, gap: IRearrangeableGridGap) => void
    >();

  const columnResize = (
    width: number,
    height: number,
    gap: IRearrangeableGridGap
  ) => {
    columnResizeRef.current?.(width, height, gap);
  };

  const onChange = (newMap: IRearrangeableGridDefinition) => {
    setMap(newMap);
  };
  const resizeText = "Resize";

  const generateItemMenu = (
    key: string,
    calloutName: string = "none"
  ): IContextualMenuProps => {
    let items: IContextualMenuItem[] = [
      {
        iconProps: { iconName: "MiniExpand" },
        key: resizeText,
        text: resizeText,
        subMenuProps: {
          items: [
            { key: CardSizes.small, text: CardSizes.small },
            { key: CardSizes.smallWide, text: CardSizes.smallWide },
            { key: CardSizes.medium, text: CardSizes.medium },
            { key: CardSizes.mediumTall, text: CardSizes.mediumTall },
            { key: CardSizes.mediumWide, text: CardSizes.mediumWide },
            { key: CardSizes.large, text: CardSizes.large },
            { key: CardSizes.extraLarge, text: CardSizes.extraLarge },
          ],
          onItemClick: generateResizeCallback(key),
        },
      },
    ];
    if (calloutName === "dependenciesDataGrid" && userCanDownload) {
      items = [
        ...items,
        {
          key: "downloadFacilityDataExcel",
          iconProps: { iconName: "ExcelDocument" },
          text: "Download all to Excel",
          onClick: () => logAndDownloadXlsx(),
          disabled:
            currentServiceMetadata.upstreamDependencies.length === 0 &&
            currentServiceMetadata.downstreamDependencies.length === 0,
        },
      ];
    }

    return { items };
  };

  const [selectedUpstreamDependency, setSelectedUpstreamDependency] =
    React.useState<UpstreamDependencyData | undefined>(null);
  const [selectedDownstreamDependency, setSelectedDownstreamDependency] =
    React.useState<DownstreamDependencyData | undefined>(null);

  const onSelectedDownstreamItem = (
    serviceDependency: DownstreamDependencyData
  ) => {
    setSelectedUpstreamDependency(null);
    setSelectedDownstreamDependency(serviceDependency);

    if (serviceDependency) {
      dispatch(
        fetchServiceDependencyPanelMetadata(serviceDependency.serviceOid)
      );
      dispatch(fetchServiceDetailsByIds([serviceDependency.serviceOid]));
      dispatch(
        fetchSecondaryServiceDependencyDriData(serviceDependency.serviceOid)
      );
    }
  };

  const onSelectedUpstreamItem = (
    serviceDependency: UpstreamDependencyData
  ) => {
    setSelectedDownstreamDependency(null);
    setSelectedUpstreamDependency(serviceDependency);

    if (serviceDependency) {
      dispatch(
        fetchServiceDependencyPanelMetadata(serviceDependency.serviceOid)
      );
      dispatch(fetchServiceDetailsByIds([serviceDependency.serviceOid]));
      dispatch(
        fetchSecondaryServiceDependencyDriData(serviceDependency.serviceOid)
      );
    }
  };

  return (
    <div className={_Styles.defaultDashboard}>
      <h2>
        {`${currentServiceMetadata?.coreServiceData?.serviceName} (${currentServiceMetadata?.serviceOid})` ??
          "Unknown Service Name"}
      </h2>
      <div
        className={mergeStyles({
          backgroundColor: theme.semanticColors.navBackground,
          padding: 24,
          marginRight: 16,
        })}
      >
        {(selectedDownstreamDependency || selectedUpstreamDependency) && (
          <ServiceDependencyDetailPanel
            upstreamDependency={selectedUpstreamDependency}
            downstreamDependency={selectedDownstreamDependency}
            isServiceDependencyDetailPanelOpen={
              !!selectedDownstreamDependency || !!selectedUpstreamDependency
            }
            dismissServiceDependencyDetailPanel={() => {
              setSelectedDownstreamDependency(null);
              setSelectedUpstreamDependency(null);
              dispatch(clearServiceDependencyPanelMetadata());
              dispatch(clearServiceDetailsByIds());
              dispatch(clearSecondaryServiceDependencyDriData());
            }}
          />
        )}
        <Dashboard
          map={map}
          onChange={onChange}
          ariaLabel="Dependency Mapping Dashboard"
          ariaDescription="Use the arrow keys to navigate to a card. Press enter key to enter each item. Press escape to return to the grid when within an item."
          rearrangeableGridProps={{
            resizeHandler: resizeCallback,
            onColumnResize: columnResize,
            minCellWidth: defaultDashboardGridSize.minCellWidth,
            minCellHeight: defaultDashboardGridSize.minCellHeight,
            gridGap: 24,
          }}
        >
          <DashboardCard
            key={"itemSummaryCard"}
            titleText={"Service Details"}
            body={
              <ServiceBcdrDetailCard
                currentServiceMetadata={currentServiceMetadata}
              />
            }
            moreIconButtonProps={{
              ariaLabel: "More Actions",
              menuProps: generateItemMenu("itemSummaryCard"),
            }}
          />
          <DashboardCard
            key={"dataVizCard"}
            titleText={"Recovery Details"}
            body={
              <ServiceBcdrAnnotationCard
                currentServiceMetadata={currentServiceMetadata}
              />
            }
            moreIconButtonProps={{ iconProps: { iconName: "Info" } }}
            moreIconButtonTooltipHostProps={{
              content: makeGenericTooltipCallout(bcdrRecoveryTerms),
            }}
          />
          <DashboardCard
            key={"downstreamCard"}
            titleText={`Downstream Dependency Details (${currentServiceMetadata.downstreamDependencies.length})`}
            body={
              currentServiceMetadata.downstreamDependencies.length > 0 ? (
                <div>
                  <ScrollablePane className={_Styles.wrapper}>
                    <ServiceDownstreamDepDataGrid
                      currentServiceDepDownMetadata={
                        currentServiceMetadata.downstreamDependencies
                      }
                      onSelectedItem={onSelectedDownstreamItem}
                    />
                  </ScrollablePane>
                </div>
              ) : (
                <div>
                  <Text> No downstream dependency data found. </Text>
                </div>
              )
            }
            moreIconButtonProps={{
              ariaLabel: "More Actions",
              menuProps: generateItemMenu(
                "downstreamCard",
                "dependenciesDataGrid"
              ),
            }}
          />
          <DashboardCard
            key={"upstreamCard"}
            titleText={`Upstream Dependency Details (${currentServiceMetadata.upstreamDependencies.length})`}
            body={
              currentServiceMetadata.upstreamDependencies.length > 0 ? (
                <div>
                  <ScrollablePane className={_Styles.wrapper}>
                    <ServiceUpstreamDepDataGrid
                      currentServiceDepUpMetadata={
                        currentServiceMetadata.upstreamDependencies
                      }
                      onSelectedItem={onSelectedUpstreamItem}
                    />
                  </ScrollablePane>
                </div>
              ) : (
                <div>
                  <Text> No upstream dependency data found. </Text>
                </div>
              )
            }
            moreIconButtonProps={{
              ariaLabel: "More Actions",
              menuProps: generateItemMenu(
                "upstreamCard",
                "dependenciesDataGrid"
              ),
            }}
          />
          <DashboardCard
            key={"gapCard"}
            titleText={"Dependency Details"}
            body={
              <ServiceDepAnnotationCard
                currentServiceMetadata={currentServiceMetadata}
              />
            }
            moreIconButtonProps={{
              ariaLabel: "More Actions",
              menuProps: generateItemMenu("gapCard"),
            }}
          />
          <DashboardCard
            key={"contactCard"}
            titleText={"Contact Details"}
            body={
              <ServiceBcdrContactCard
                currentServiceMetadata={currentServiceMetadata}
              />
            }
            moreIconButtonProps={{
              ariaLabel: "More Actions",
              menuProps: generateItemMenu("contactCard"),
            }}
            footer={
              <CommandBar
                items={[
                  {
                    key: "emailMessage",
                    text: "Send email",
                    iconProps: { iconName: "Mail" },
                    onClick: () => setShowModal(true),
                  },
                ]}
              />
            }
          />
          <DashboardCard
            key={"itemDetailsCard"}
            titleText={"Assessment Details"}
            body={
              <ServiceBcdrAssessmentCard
                currentServiceMetadata={currentServiceMetadata}
              />
            }
            moreIconButtonProps={{
              ariaLabel: "More Actions",
              menuProps: generateItemMenu("itemDetailsCard"),
            }}
          />
          <DashboardCard
            key={"depMapCard"}
            titleText={"Service Dependencies"}
            body={
              <ServiceDependencyGraphCard
                mapHeight={
                  map.depMapCard.cellHeight *
                  defaultDashboardGridSize.minCellHeight
                }
                mapWidth={
                  map.depMapCard.cellWidth *
                  defaultDashboardGridSize.minCellWidth
                }
                currentServiceDependencyGraphData={currentServiceGraphData}
                onSelectedUpstreamItem={onSelectedUpstreamItem}
                onSelectedDownstreamItem={onSelectedDownstreamItem}
              />
            }
          />
          <DashboardCard
            key={"driDataCard"}
            titleText={"DRI Team Details"}
            body={
              <ServiceDriGeoDataCard
                currentDriGeoData={currentServiceDriData}
              />
            }
            moreIconButtonProps={{
              ariaLabel: "More Actions",
              menuProps: generateItemMenu("driDataCard"),
            }}
          />
        </Dashboard>
        <CoherenceModal
          isOpen={showModal}
          title={"Send email"}
          modalWidth={"small"}
          onDismiss={hideModal}
          height={"responsive"}
        >
          <EmailPeoplePicker personasToEmail={currentPeopleToEmail} />
        </CoherenceModal>
      </div>
    </div>
  );
}
