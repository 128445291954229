import {
  FontWeights,
  IContextualMenuItem,
  IContextualMenuProps,
  IIconProps,
  Label,
  MessageBar,
  ScrollablePane,
  SearchBox,
  Stack,
  Text,
  ThemeProvider,
  memoizeFunction,
  mergeStyles,
} from "@fluentui/react";
import { IChartProps } from "@fluentui/react-charting";
import { useBoolean } from "@fluentui/react-hooks";
import { ActionButton } from "@fluentui/react/lib/Button";
import { DashboardCard } from "@m365-admin/card";
import { CountAnnotationBar, ICountAnnotationProps } from "@m365-admin/count-annotation";
import { M365LightTheme, useM365Theme } from "@m365-admin/customizations";
import { Dashboard } from "@m365-admin/dashboard";
import {
  IRearrangeableGridDefinition,
  IRearrangeableGridGap,
} from "@m365-admin/rearrangeable-grid";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { CardSizeValues, CardSizes } from "components/Cards.constants";
import { EmptyCardContent } from "components/EmptyCardContent";
import {
  IFacilityDataDownload,
  IFacilityModel,
  IRestrictedFacilityDataDownload,
} from "components/Types";
import InfoDonutChart, {
  coiDcClassColorOptions,
  facTypeColorOptions,
  pieChartColorOptions,
} from "components/charts/InfoDonutChart";
import { WorkforceByEvpCountBar } from "components/charts/people/WorkforceByEvpCountBar";
import FacilityDataGrid from "components/datagrids/FacilityDataGrid";
import { ExportToExcelUtil } from "components/datagrids/Helpers";
import {
  AssignedWorkforce,
  EssentialEmployees,
  FacilitiesBreakdown,
  FacilitiesGeneral,
  FacilityTypesCallout,
  LocationWorkforce,
} from "components/modals/InsightCallout";
import FacilityDetailPanel from "components/panels/details/FacilityDetailPanel";
import { FacilityFilter } from "components/panels/filters/FacilityFilter";
import { FacilityFilterTags } from "components/panels/filters/FacilityFilterTags";
import {
  mapToRestrictedFacView,
  mapToRestrictedPeopleView,
  mapToUnrestrictedFacView,
  mapToUnrestrictedPeopleView,
} from "components/pivots/helpers/downloadMappingHelpers";
import { defaultStackTokens, panelScrollablePaneStyles, searchBoxStyles } from "pages/Page.styles";
import React from "react";
import {
  updateActiveFacilityId,
  updateFacilityAssetSearchTerm,
  updateFacilityClassificationFilter,
  updateFacilityDivisionFilter,
  updateFacilityIdFilter,
  updateFacilityTypeFilter,
} from "store/actions/facilitiesActions";
import {
  buildFacilityCounts,
  getFacilities,
  getFacilitiesLoadingStatus,
} from "store/selectors/facilitiesSelector";
import {
  getFilteredPeopleDirects,
  getPeopleDirectsLoadingStatus,
  getPeopleHierarchyByFacility,
} from "store/selectors/peopleSelector";
import { getUserDownloadPermissions, getUserIsRestricted } from "store/selectors/usersSelector";
import { useAppDispatch, useAppSelector } from "store/store";
import { IPeopleDataDownload, IRestrictedPeopleDataDownload } from "types/PeopleTypes";

export interface FacilityDashboardProps {}

export function FacilityDashboard() {
  const dispatch = useAppDispatch();
  const theme = useM365Theme();
  const appInsights = useAppInsightsContext();

  const blockDcsFromUser = useAppSelector(getUserIsRestricted);
  const facilityDataLoading = useAppSelector(getFacilitiesLoadingStatus);

  const facilityData: IFacilityModel[] = useAppSelector(getFacilities);
  const [filteredFacData, setFilteredFacData] = React.useState<IFacilityModel[]>([]);

  const managerDataLoading = useAppSelector(getPeopleDirectsLoadingStatus);
  const peopleDataByActiveFacilities = useAppSelector(getPeopleHierarchyByFacility);
  const peopleDataState = useAppSelector(getFilteredPeopleDirects);
  const userCanDownload = useAppSelector(getUserDownloadPermissions);
  const userHasViewRestriction = useAppSelector(getUserIsRestricted);

  const logAndDownloadXlsx = () => {
    const timeStamp = new Date(Date.now()).toLocaleDateString("en-US");
    const fileName = `WatchtowerFacilityDataExport-${timeStamp}`;
    appInsights.trackEvent(
      { name: "Download Used" },
      {
        Source: "Facilities",
        "restricted user": userHasViewRestriction,
        "Row Count": filteredFacData.length,
      }
    );
    const activeFacilitiesSet = new Set(filteredFacData.map((f) => f.facilityName));
    let fac03FacilityData, coiDcData, peopleData;

    if (userHasViewRestriction) {
      const restrictedFacilityData = filteredFacData.map<IRestrictedFacilityDataDownload>((d) =>
        mapToRestrictedFacView(d, timeStamp)
      );

      fac03FacilityData = restrictedFacilityData.filter((d) => d.source === "FAC03");
      coiDcData = restrictedFacilityData.filter((d) => d.source === "COI");
      peopleData = peopleDataByActiveFacilities.reduce((acc, p) => {
        if (activeFacilitiesSet.has(p.buildingName)) {
          acc.push(mapToRestrictedPeopleView(p, timeStamp));
        }
        return acc;
      }, [] as IRestrictedPeopleDataDownload[]);
    } else {
      const unrestrictedFacilityData = filteredFacData.map<IFacilityDataDownload>((d) =>
        mapToUnrestrictedFacView(d, timeStamp)
      );
      fac03FacilityData = unrestrictedFacilityData.filter((d) => d.source === "FAC03");
      coiDcData = unrestrictedFacilityData.filter((d) => d.source === "COI");
      peopleData = peopleDataByActiveFacilities.reduce((acc, p) => {
        if (activeFacilitiesSet.has(p.buildingName)) {
          acc.push(mapToUnrestrictedPeopleView(p, timeStamp));
        }
        return acc;
      }, [] as IPeopleDataDownload[]);
    }

    const downloadData = {
      fileName: fileName,
      data: [
        { dataToExport: fac03FacilityData, sheetName: "RE&F Facility Details" },
        { dataToExport: coiDcData, sheetName: "CO+I DC Details" },
        { dataToExport: peopleData, sheetName: "Facility Employee Details" },
      ],
    };
    ExportToExcelUtil(downloadData);
  };

  //set up annotation count data
  const [facCountData, setFacCountData] = React.useState<IChartProps>(undefined);
  const [workforceAggCountData, setWorkforceAggCountData] = React.useState<ICountAnnotationProps[]>(
    []
  );
  const [workforceTypeCountData, setWorkforceTypeCountData] = React.useState<
    ICountAnnotationProps[]
  >([]);
  const [facilityAggCounts, setFacilityAggCounts] = React.useState<ICountAnnotationProps[]>([]);
  const [coiFacClassData, setCoiFacClassData] = React.useState<IChartProps>(undefined);

  //create annotation data for display
  const createFacCountData = (facilityData: IFacilityModel[]): void => {
    const typeData = facilityData
      .filter((fac) => fac.source === "FAC03")
      .map((f) => f.facilityType);
    const tempFacData = typeData.reduce(function (facTypeList, fac) {
      let type = fac || "Unknown";
      facTypeList[type] = (facTypeList[type] || 0) + 1;
      return facTypeList;
    }, {});

    //Sort the facility types in alphabetical order
    const sortedKeys = Object.keys(tempFacData).sort();

    let index = 0;
    const dataPoints: IChartProps = {
      chartTitle: "Facility Type Data",
      chartData: sortedKeys.map((key) => {
        return {
          legend: key,
          data: tempFacData[key] ?? 0,
          //rolling through the pie chart color options to get rid of twinkle on hover
          color: pieChartColorOptions[index++ % pieChartColorOptions.length],
        };
      }),
    };

    const facilityAggregateCounts = buildFacilityCounts(facilityData);
    const facCountBarData = [
      {
        annotationText: "Total Facilities",
        count: facilityAggregateCounts.facilityCount,
        annotationColor: pieChartColorOptions[4],
        size: 1,
      },
      {
        annotationText: "Facilities with allocated headcount",
        count: facilityAggregateCounts.allocatedFacilityCount,
        annotationColor: pieChartColorOptions[0],
        size: 1,
      },
      {
        annotationText: "Facilities with no allocated headcount",
        count: facilityAggregateCounts.unAllocatedFacilityCount,
        annotationColor: pieChartColorOptions[2],
        size: 1,
      },
      {
        annotationText: "Buildings with essential employees",
        count: facilityData.filter((f) => f.containsEssentials).length,
        annotationColor: pieChartColorOptions[5],
        size: 1,
      },
    ];
    setFacilityAggCounts(facCountBarData);
    setFacCountData(dataPoints);
  };

  const createCoiFacClassData = (facilityData: IFacilityModel[]): void => {
    const classData = facilityData
      .filter((fac) => fac.source === "COI")
      .map((f) => f.coiDatacenter?.operationalTaxonomy);

    const tempCoiDcData = classData.reduce(function (coiDcClassList, dc) {
      let taxonomy = dc || "Unknown";
      coiDcClassList[taxonomy] = (coiDcClassList[taxonomy] || 0) + 1;
      return coiDcClassList;
    }, {});
    //Sort the facility types in alphabetical order
    const sortedKeys = Object.keys(tempCoiDcData).sort();

    let index = 0;
    const DataPoints: IChartProps = {
      chartTitle: "CO+I DC Class Data",
      chartData: sortedKeys.map((key) => {
        return {
          legend: key,
          data: tempCoiDcData[key] ?? 0,
          //rolling through the pie chart color options to get rid of twinkle on hover
          color: pieChartColorOptions[index++ % pieChartColorOptions.length],
        };
      }),
    };
    setCoiFacClassData(DataPoints);
  };

  //create annotation data for display
  const createPeopleCountData = (facilityData: IFacilityModel[]): void => {
    const mobileHeadcount = facilityData
      .map((f) => f.mobileHeadCount)
      .reduce(function (previousValue, currentValue) {
        return previousValue + currentValue;
      }, 0);
    const assignedHeadcount = facilityData
      .map((f) => f.housedHeadcount)
      .reduce(function (previousValue, currentValue) {
        return previousValue + currentValue;
      }, 0);
    const assignedFteHeadcount = facilityData
      .map((f) => f.housedFtecount)
      .reduce(function (previousValue, currentValue) {
        return previousValue + currentValue;
      }, 0);
    const assignedNonFteHeadcount = facilityData
      .map((f) => f.housedNonFtecount)
      .reduce(function (previousValue, currentValue) {
        return previousValue + currentValue;
      }, 0);

    const workforceCountAnnotations: ICountAnnotationProps[] = [
      {
        annotationText: "Total active employees",
        count: mobileHeadcount + assignedHeadcount,
        annotationColor: pieChartColorOptions[4],
        size: 1,
      },
      {
        annotationText: "Assigned to building",
        count: assignedHeadcount,
        annotationColor: pieChartColorOptions[0],
        size: 1,
      },
      {
        annotationText: "Mobile",
        count: mobileHeadcount,
        annotationColor: pieChartColorOptions[1],
        size: 1,
      },
    ];
    const workforceTypeCountAnnotations: ICountAnnotationProps[] = [
      {
        annotationText: "FTE in buildings",
        count: assignedFteHeadcount,
        annotationColor: pieChartColorOptions[2],
        size: 1,
      },
      {
        annotationText: "Contingent in buildings",
        count: assignedNonFteHeadcount,
        annotationColor: pieChartColorOptions[3],
        size: 1,
      },
    ];
    setWorkforceAggCountData(workforceCountAnnotations);
    setWorkforceTypeCountData(workforceTypeCountAnnotations);
  };

  React.useEffect(() => {
    createFacCountData(facilityData);
    createCoiFacClassData(facilityData);
    createPeopleCountData(facilityData);
    setFilteredFacData(facilityData);
  }, [facilityData]);

  //facility panel & data setup
  const [
    isFacilityDataPanelOpen,
    { setTrue: openFacilityDetailPanel, setFalse: dismissFacilityDetailPanel },
  ] = useBoolean(false);

  const [selectedFacility, setSelectedFacility] = React.useState<IFacilityModel>(null);
  const [isDcFacility, setIsDcFacility] = React.useState<boolean>(false);
  const buildFacilityDetailPanelData = (facility: IFacilityModel) => {
    setSelectedFacility(facility);
    const facilityId = facility?.facilityId ?? -1;
    if (facilityId > 0) {
      setIsDcFacility(facility.facilityType.toUpperCase() === "DATACENTER");
      dispatch(updateActiveFacilityId(facilityId));
      openFacilityDetailPanel();
    } else {
      setIsDcFacility(false);
    }
  };

  const filterIcon: IIconProps = { iconName: "Filter" };
  const [isFilterPanelOpen, { setTrue: openFilterPanel, setFalse: dismissFilterPanel }] =
    useBoolean(false);

  // store the state of the Dashboard and handle changes within it
  const [map, setMap] = React.useState<IRearrangeableGridDefinition>({
    facilityCountBar: { cellHeight: 1, cellWidth: 1, row: 0, col: 0 },
    workforceAggCountBar: { cellHeight: 1, cellWidth: 1, row: 0, col: 1 },
    workforceTypeCountBar: { cellHeight: 1, cellWidth: 1, row: 0, col: 2 },
    facTypeCard: { cellHeight: 1, cellWidth: 2, row: 0, col: 3 },
    facDataGridCard: { cellHeight: 2, cellWidth: 3, row: 1, col: 0 },
    workforceAggCountbyDivCard: { cellHeight: 1, cellWidth: 2, row: 1, col: 3 },
    coiDcClassCountCard: {
      cellHeight: 1,
      cellWidth: 2,
      row: 2,
      col: 0,
    },
  });
  //handle resizing in the dashboard grid
  const resizeCallback =
    React.useRef<
      (
        width: number,
        height: number,
        itemKey: string,
        position?: { col: number; row: number }
      ) => void
    >();

  const generateResizeCallback = (key: string) => {
    return (
      ev: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>,
      item: IContextualMenuItem
    ) => {
      const dimension = CardSizeValues[item.key as CardSizes];

      resizeCallback.current?.(dimension.cellWidth, dimension.cellHeight, key);
    };
  };

  const columnResizeRef =
    React.useRef<(width: number, height: number, gap: IRearrangeableGridGap) => void>();

  const columnResize = (width: number, height: number, gap: IRearrangeableGridGap) => {
    columnResizeRef.current?.(width, height, gap);
  };

  const onChange = (newMap: IRearrangeableGridDefinition) => {
    setMap(newMap);
  };
  const resizeText = "Resize";
  const moreDetailsText = "Info";

  const generateCallout = (calloutName: string) => {
    switch (calloutName) {
      case "assignedWorkforce":
        return (
          <div data-is-focusable>
            <AssignedWorkforce />
            <Text>Info</Text>
          </div>
        );
      case "facTypes":
        return (
          <div data-is-focusable>
            <FacilityTypesCallout />
            <Text>Info</Text>
          </div>
        );
      case "essentialEmployees":
        return (
          <div data-is-focusable>
            <EssentialEmployees />
            <Text>Info</Text>
          </div>
        );
      case "workforceDetails":
        return (
          <div data-is-focusable>
            <LocationWorkforce />
            <Text>Info</Text>
          </div>
        );
      case "assignedWorkforceCounts":
        return (
          <div data-is-focusable>
            <AssignedWorkforce />
            <Text>Info</Text>
          </div>
        );
      case "facilitiesBreakdown":
        return (
          <div data-is-focusable>
            <FacilitiesBreakdown />
            <Text>Info</Text>
          </div>
        );
      default:
        return (
          <div data-is-focusable>
            <FacilitiesGeneral />
            <Text>Info</Text>
          </div>
        );
    }
  };

  const generateItemMenu = (key: string, calloutName: string): IContextualMenuProps => {
    let items: IContextualMenuItem[] = [
      {
        iconProps: { iconName: "MiniExpand" },
        key: resizeText,
        text: resizeText,
        subMenuProps: {
          items: [
            { key: CardSizes.small, text: CardSizes.small },
            { key: CardSizes.smallWide, text: CardSizes.smallWide },
            { key: CardSizes.medium, text: CardSizes.medium },
            { key: CardSizes.mediumTall, text: CardSizes.mediumTall },
            { key: CardSizes.mediumWide, text: CardSizes.mediumWide },
            { key: CardSizes.large, text: CardSizes.large },
            { key: CardSizes.extraLarge, text: CardSizes.extraLarge },
          ],
          onItemClick: generateResizeCallback(key),
        },
      },
    ];
    if (calloutName) {
      items = [
        ...items,
        {
          iconProps: { iconName: "Info" },
          key: moreDetailsText,
          text: moreDetailsText,
          data: generateCallout(calloutName),
          onRender: (item) => item.data,
        },
      ];
      if (userCanDownload && calloutName === "facDataGrid") {
        items = [
          ...items,
          {
            key: "downloadFacilityDataExcel",
            iconProps: { iconName: "ExcelDocument" },
            text: "Download to Excel",
            onClick: () => logAndDownloadXlsx(),
            disabled: filteredFacData.length === 0,
          },
        ];
      }
    }

    return {
      items,
    };
  };

  const headerStyles = memoizeFunction(mergeStyles);
  const getCardTitle = (title: string) => {
    const titleClassName = headerStyles([
      theme.fonts.medium,
      { padding: 0, margin: 0, fontWeight: FontWeights.semibold },
    ]);

    return <h3 className={titleClassName}>{title}</h3>;
  };

  const backgroundStyles = mergeStyles({
    background: theme.semanticColors.navBackground,
    paddingTop: 24,
    paddingBottom: 24,
    paddingRight: 16,
    paddingLeft: 16,
  });

  return (
    <ThemeProvider theme={M365LightTheme.settings.theme}>
      <FacilityFilter
        isFacilityDataLoading={facilityDataLoading}
        isPanelOpen={isFilterPanelOpen}
        dismissPanel={dismissFilterPanel}
        openPanel={openFilterPanel}
      />
      <Stack horizontal tokens={defaultStackTokens}>
        <ActionButton
          iconProps={filterIcon}
          onClick={openFilterPanel}
          disabled={facilityDataLoading || filteredFacData.length === 0}
        >
          More Filters
        </ActionButton>
        <FacilityFilterTags />
      </Stack>
      <FacilityDetailPanel
        filteredFacilityData={selectedFacility}
        isFacilityDetailPanelOpen={isFacilityDataPanelOpen}
        dismissFacilityDetailPanel={dismissFacilityDetailPanel}
        hidePeopleGrid={isDcFacility && blockDcsFromUser}
      />
      <div className={backgroundStyles}>
        <Dashboard
          map={map}
          onChange={onChange}
          ariaLabel="Crisis Response Facilities Dashboard"
          ariaDescription="Use the arrow keys to navigate to a card. Press alt-shift-arrow to move a card. Press enter key to enter each item. Press escape to return to the grid when within an item."
          rearrangeableGridProps={{
            resizeHandler: resizeCallback,
            onColumnResize: columnResize,
            minCellWidth: 250,
            minCellHeight: 50,
          }}
        >
          <DashboardCard
            key={"facilityCountBar"}
            titleText={getCardTitle("Facilities Breakdown")}
            isLoading={facilityDataLoading}
            body={<CountAnnotationBar countAnnotationProps={facilityAggCounts} />}
            moreIconButtonProps={{
              ariaLabel: "More Actions",
              menuProps: generateItemMenu("facilityCountBar", "facilitiesBreakdown"),
            }}
          />
          <DashboardCard
            key="workforceAggCountBar"
            titleText={getCardTitle("FAC03 Workforce Details")}
            isLoading={facilityDataLoading}
            body={<CountAnnotationBar countAnnotationProps={workforceAggCountData} />}
            moreIconButtonProps={{
              ariaLabel: "More Actions",
              menuProps: generateItemMenu("workforceAggCountBar", "workforceDetails"),
            }}
          >
            <>
              <div role="status">
                {userHasViewRestriction && (
                  <MessageBar
                    delayedRender={false}
                    // IMPORTANT: Set role="none" to prevent nested status regions
                    role="none"
                  >
                    Due to security requirements, data center headcount numbers have been removed
                    for your assigned role.
                  </MessageBar>
                )}
              </div>
              <CountAnnotationBar countAnnotationProps={workforceAggCountData} />
            </>
          </DashboardCard>
          <DashboardCard
            key="workforceTypeCountBar"
            titleText={getCardTitle("Workforce assigned to buildings by Employee type")}
            isLoading={facilityDataLoading}
            body={<CountAnnotationBar countAnnotationProps={workforceTypeCountData} />}
            moreIconButtonProps={{
              ariaLabel: "More Actions",
              menuProps: generateItemMenu("workforceTypeCountBar", "assignedWorkforceCounts"),
            }}
          />
          <DashboardCard
            key={"facDataGridCard"}
            titleText={getCardTitle("Facilities")}
            isLoading={managerDataLoading}
            moreIconButtonProps={{
              ariaLabel: "More Actions",
              menuProps: generateItemMenu("facDataGridCard", "facDataGrid"),
            }}
          >
            <Label>Search:</Label>
            <SearchBox
              styles={searchBoxStyles}
              placeholder="Search By Facility Name"
              onChange={(_, newValue: string) => dispatch(updateFacilityAssetSearchTerm(newValue))}
              onClear={() => dispatch(updateFacilityAssetSearchTerm(""))}
            />
            {filteredFacData.length > 0 && (
              <ScrollablePane styles={panelScrollablePaneStyles} data-is-scrollable>
                <FacilityDataGrid
                  filteredFacilityData={filteredFacData}
                  openGridPanel={buildFacilityDetailPanelData}
                />
              </ScrollablePane>
            )}
            {filteredFacData.length === 0 && <EmptyCardContent />}
          </DashboardCard>
          <DashboardCard
            key={"facTypeCard"}
            titleText={getCardTitle("RE&F Facilities")}
            isLoading={managerDataLoading}
            moreIconButtonProps={{
              ariaLabel: "More Actions",
              menuProps: generateItemMenu("facTypeCard", "facTypes"),
            }}
            body={
              facCountData && (
                <InfoDonutChart
                  chartTitle={facCountData.chartTitle}
                  chartData={
                    facCountData?.chartData.map((item) => ({
                      ...item,
                      legend: `${item.legend === "Data Center" ? "RE&F DCs" : item.legend} (${
                        item.data
                      })`,
                      onClick: () => {
                        dispatch(updateFacilityTypeFilter([item.legend]));
                      },
                      color:
                        facTypeColorOptions.find((f) => f.label === item.legend)?.color ??
                        pieChartColorOptions[0],
                    })) ?? []
                  }
                  parentRef={undefined}
                  showLabels={true}
                />
              )
            }
          />
          <DashboardCard
            key={"workforceAggCountbyDivCard"}
            titleText={getCardTitle("Workforce assigned to buildings by Division")}
            isLoading={managerDataLoading}
            moreIconButtonProps={{
              ariaLabel: "More Actions",
              menuProps: generateItemMenu("workforceAggCountbyDivCard", "assignedWorkforce"),
            }}
            body={
              <WorkforceByEvpCountBar
                filteredPeopleData={peopleDataState}
                onBarClick={(evpName: string, buildIdList: number[]) => {
                  dispatch(updateFacilityDivisionFilter([evpName]));
                  dispatch(updateFacilityIdFilter(buildIdList));
                }}
              />
            }
          />
          <DashboardCard
            key={"coiDcClassCountCard"}
            titleText={getCardTitle("CO+I Operational DCs")}
            isLoading={managerDataLoading}
            moreIconButtonProps={{
              ariaLabel: "More Actions",
              menuProps: generateItemMenu("coiDcClassCountCard", "coiDcClass"),
            }}
            body={
              coiFacClassData && (
                <InfoDonutChart
                  chartTitle={coiFacClassData.chartTitle}
                  chartData={
                    coiFacClassData?.chartData.map((item) => ({
                      ...item,
                      legend: `${item.legend} (${item.data})`,
                      onClick: () => {
                        dispatch(updateFacilityClassificationFilter([item.legend]));
                      },
                      color:
                        coiDcClassColorOptions.find((f) => f.label === item.legend)?.color ??
                        pieChartColorOptions[0],
                    })) ?? []
                  }
                  parentRef={undefined}
                  showLabels={true}
                />
              )
            }
          />
        </Dashboard>
      </div>
    </ThemeProvider>
  );
}
