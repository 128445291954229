import { AxiosError } from "axios";
import { AzureRegion, PhysicalRegion } from "../../components/Types";
import {
  FETCH_AZURE_REGIONS_BEGIN,
  FETCH_AZURE_REGIONS_FAILURE,
  FETCH_AZURE_REGIONS_SUCCESS,
  FETCH_PHYSICAL_REGIONS_BEGIN,
  FETCH_PHYSICAL_REGIONS_FAILURE,
  FETCH_PHYSICAL_REGIONS_SUCCESS,
  SET_SELECTED_AZURE_REGIONS,
  SET_SELECTED_PHYSICAL_COUNTRIES,
  SET_SELECTED_PHYSICAL_REGIONS
} from "../actions/actionTypes";

export interface ReduxRegionsState {
  physicalRegions: PhysicalRegion[];
  countries: string[];
  azureRegions: AzureRegion[];
  selectedPhysicalRegions: PhysicalRegion[];
  selectedAzureRegions: AzureRegion[];
  selectedCountries: string[];
  isLoading: boolean;
  error: string | AxiosError;
}

const regionsInitialState: ReduxRegionsState = {
  physicalRegions: [],
  azureRegions: [],
  countries: [],
  selectedPhysicalRegions: [],
  selectedAzureRegions: [],
  selectedCountries: [],
  isLoading: false,
  error: null,
};

export default function regions(
  state: ReduxRegionsState = regionsInitialState,
  action
) {
  switch (action.type) {
    case FETCH_PHYSICAL_REGIONS_BEGIN:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case FETCH_PHYSICAL_REGIONS_SUCCESS:
      return {
        ...state,
        physicalRegions: action.payload,
        countries: action.payload
          .flatMap((pr) => pr.countries)
          .reduce(function (countryList, country: string) {
            const currentCountry = country.trim();
            if (countryList == null || !countryList.includes(currentCountry)) {
              countryList.push(currentCountry);
            }
            return countryList;
          }, [])
          .sort(),
        isLoading: false,
        error: null,
      };
    case FETCH_PHYSICAL_REGIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case FETCH_AZURE_REGIONS_BEGIN:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case FETCH_AZURE_REGIONS_SUCCESS:
      return {
        ...state,
        azureRegions: action.payload,
        isLoading: false,
        error: null,
      };
    case FETCH_AZURE_REGIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case SET_SELECTED_PHYSICAL_REGIONS:
      return {
        ...state,
        selectedPhysicalRegions: action.payload,
      };
    case SET_SELECTED_AZURE_REGIONS:
      return {
        ...state,
        selectedAzureRegions: action.payload,
      };
    case SET_SELECTED_PHYSICAL_COUNTRIES:
      return {
        ...state,
        selectedCountries: action.payload,
      };
    default:
      return state;
  }
}
