import { ScrollablePane, Stack, Image, Text, IImageProps, Link } from '@fluentui/react';
import React from 'react';
import {_Styles} from './Page.styles';

export interface ErrorProps {
  errorMessage: string | null
  isNavCollapsed?: boolean
}

const imageProps: Partial<IImageProps> = {
  src: require('./../assets/GoFishCat.png'),
}

export function Error(props: ErrorProps):JSX.Element {

  return (
    <ScrollablePane className={props?.isNavCollapsed ? _Styles.scrollablePaneCollapsed : _Styles.scrollablePaneExpand}>
      <div className={_Styles.centeredDiv}>
        <Stack horizontalAlign="center">
          <Image
            {...imageProps}
            alt="cat holding cards"
            width={200}
            height={200}
          />
          <h1>{props?.errorMessage ? props.errorMessage : "Page not found."}</h1>
          <Text block style={{ fontSize: '16px' }}>
            Please reach out to the <Link href="mailto:watchtowersupport@microsoft.com" title="Email Watchtower Engineering">Watchtower Support team</Link> for further assistance.
          </Text>
        </Stack>
      </div>
    </ScrollablePane>
  );
}