import { CoherenceModal } from "@coherence-design-system/controls";
import { mergeStyles } from "@fluentui/merge-styles";
import {
  ActionButton,
  IContextualMenuItem,
  IContextualMenuProps,
  IIconProps,
  ISearchBoxStyles,
  Label,
  ScrollablePane,
  SearchBox,
  Stack,
  Text,
  ThemeProvider,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { DefaultPalette, FontWeights } from "@fluentui/theme";
import { memoizeFunction } from "@fluentui/utilities";
import { DashboardCard } from "@m365-admin/card";
import {
  CountAnnotationBar,
  CountAnnotationBarDirection,
  ICountAnnotationProps,
} from "@m365-admin/count-annotation";
import { M365LightTheme, useM365Theme } from "@m365-admin/customizations";
import { Dashboard } from "@m365-admin/dashboard";
import {
  IRearrangeableGridDefinition,
  IRearrangeableGridGap,
} from "@m365-admin/rearrangeable-grid";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { CardSizeValues, CardSizes } from "components/Cards.constants";
import { EmptyCardContent } from "components/EmptyCardContent";
import {
  AzureRegion,
  IAzureRegionDataDownload,
  IDownloadDetails,
  IGeoResilienceTeamData,
  IGeoResilienceTeamDataDownload,
  IServiceAssetDetailDownload,
  ServiceAssetDetail,
} from "components/Types";
import InfoDonutChart, {
  driProjectionColorOptions,
  pieChartColorOptions,
} from "components/charts/InfoDonutChart";
import { ComplianceStatusByDivStackedBarChart } from "components/charts/service/ComplianceStatusByDivStackedBar";
import { ExportToExcelUtil } from "components/datagrids/Helpers";
import ServiceDataGrid from "components/datagrids/ServiceDataGrid";
import {
  PotentialDriTeamImpactLevel,
  ServiceBreakdown,
  ServiceGeneral,
} from "components/modals/InsightCallout";
import ServiceDetailGrid from "components/panels/details/serviceDetailPanel";
import { ServiceFilterTags } from "components/panels/filters/ServiceFilterTags";
import { ServicesFilter } from "components/panels/filters/ServicesFilter";
import { mapToDriDataView } from "components/pivots/helpers/downloadMappingHelpers";
import { buildAggregateCounts, buildServiceCounts } from "helpers/serviceAssetMappingHelpers";
import { _Styles } from "pages/Page.styles";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  updateDcDetectedFilter,
  updateServiceAssetSearchTerm,
} from "store/actions/servicesActions";
import { getDcsInScope, getFacilities } from "store/selectors/facilitiesSelector";
import {
  getDriInsightsByRegionLoadingStatus,
  getServiceAssetAzureCounts,
  getServiceAssetDetails,
  getServiceAssetDetailsLoadingStatus,
  getServiceAssetSearchTerm,
  getServiceBclFilters,
  getServiceCloudFilters,
  getServiceRtoCriticalityFilters,
  getServiceDataCenterFilters,
  getServiceDivisionFilters,
  getServiceOrganizationFilters,
  getServiceRecoveryClassificationFilters,
  getServiceSgFilters,
  getServiceSingleDcFilter,
  getServiceCountsLoadingStatus,
  getDCDetectedCount,
} from "store/selectors/servicesSelector";
import { getUserDownloadPermissions, getUserIsRestricted } from "store/selectors/usersSelector";
import { useAppDispatch, useAppSelector } from "store/store";

export function ServicePivot() {
  const theme = useM365Theme();
  const appInsights = useAppInsightsContext();
  const dispatch = useAppDispatch();
  const { dcDetectedTotal, dcDetectedCounts } = useAppSelector(getDCDetectedCount);

  // data setup
  const serviceDataLoading = useAppSelector(getServiceAssetDetailsLoadingStatus);
  const driDataLoading = useAppSelector(getDriInsightsByRegionLoadingStatus);
  const [isServiceLoading, setIsServiceLoading] = useState<boolean>(false);

  useEffect(
    () => setIsServiceLoading(serviceDataLoading || driDataLoading),
    [serviceDataLoading, driDataLoading]
  );

  const rawServiceData = useAppSelector(getServiceAssetDetails);
  const [currentFilteredServiceData, setCurrentFilteredServiceData] = useState<
    ServiceAssetDetail[]
  >([]);

  const currentServiceAzureRegionCount = useAppSelector(getServiceAssetAzureCounts);

  //filtering
  const serviceRtoCriticalFiltersState = useAppSelector(getServiceRtoCriticalityFilters);
  const [criticalFilters, setCriticalFilters] = useState<string[]>([]);

  useEffect(() => {
    setCriticalFilters(serviceRtoCriticalFiltersState);
  }, [serviceRtoCriticalFiltersState]);

  const serviceSingleDCFilterState = useAppSelector(getServiceSingleDcFilter);
  const [singleDCFilter, setSingleDCFilter] = useState<boolean>();
  useEffect(() => {
    setSingleDCFilter(serviceSingleDCFilterState);
  }, [serviceSingleDCFilterState]);

  //bcl filter setup
  const serviceBclFiltersState = useAppSelector(getServiceBclFilters);
  const [bclFilters, setBclFilters] = useState<string[]>([]);
  useEffect(() => {
    setBclFilters(serviceBclFiltersState);
  }, [serviceBclFiltersState]);

  //data center filter setup
  const serviceDataCenterFiltersState = useAppSelector(getServiceDataCenterFilters);
  const [dcFilters, setDcFilters] = useState<number[]>([]);
  useEffect(() => {
    setDcFilters(serviceDataCenterFiltersState);
  }, [serviceDataCenterFiltersState]);

  //get DC list
  const dcFacilityList = useAppSelector(getDcsInScope);

  //division filter setup
  const serviceDivisionFiltersState = useAppSelector(getServiceDivisionFilters);
  const [divFilters, setDivFilters] = useState<string[]>([]);
  useEffect(() => {
    setDivFilters(serviceDivisionFiltersState);
  }, [serviceDivisionFiltersState]);

  //organization filter setup
  const serviceOrganizationFitersState = useAppSelector(getServiceOrganizationFilters);

  const [orgFilters, setOrgFilters] = useState<string[]>([]);
  useEffect(() => {
    setOrgFilters(serviceOrganizationFitersState);
  }, [serviceOrganizationFitersState]);

  //service group filter setup
  const serviceCloudFilters = useAppSelector(getServiceCloudFilters);
  const serviceSgFiltersState = useAppSelector(getServiceSgFilters);
  const [sgFilters, setSgFilters] = useState<string[]>([]);
  useEffect(() => {
    setSgFilters(serviceSgFiltersState);
  }, [serviceSgFiltersState]);

  const serviceRecoveryClassificationFiltersState = useAppSelector(
    getServiceRecoveryClassificationFilters
  );
  const [classificationFilters, setClassificationFilters] = useState<string[]>([]);
  useEffect(() => {
    setClassificationFilters(serviceRecoveryClassificationFiltersState);
  }, [serviceRecoveryClassificationFiltersState]);

  //service search filter setup
  const serviceAssetSearchFiltersState = useAppSelector(getServiceAssetSearchTerm);
  const [searchFilters, setSearchFilters] = useState<string>("");
  useEffect(() => {
    setSearchFilters(serviceAssetSearchFiltersState);
  }, [serviceAssetSearchFiltersState]);

  //annotation chart setup
  const [serviceInfoData, setServiceInfoData] = useState<ICountAnnotationProps[]>([
    { annotationText: "No data", count: null },
  ]);
  const [driInfoData, setDriInfoData] = useState<ICountAnnotationProps[]>([
    { annotationText: "No data", count: null },
  ]);
  const setDriCountData = (filteredDriData: IGeoResilienceTeamData[]) => {
    const projectionList = new Map<string, number>([
      ["High", 0],
      ["Moderate", 0],
      ["Low", 0],
      ["No Impact", 0],
      ["No Team History", 0],
    ]);
    projectionList.forEach((value, key) => {
      const sum = filteredDriData.filter((s) => s?.capacityImpact?.includes(key)).length;
      projectionList.set(key, sum);
    });

    const countAnnotations: ICountAnnotationProps[] = [];
    countAnnotations.push({
      annotationText: "Total",
      count: filteredDriData.length,
      size: 1, //set to large
      annotationColor: DefaultPalette.purpleLight,
    });
    projectionList.forEach((value, key) => {
      countAnnotations.push({
        annotationText: key,
        count: value,
        size: 1, //set to large
        annotationColor: driProjectionColorOptions.find((i) => i.label === key).color,
      });
    });
    setDriInfoData(countAnnotations);
  };

  //set chart data based on active filters & region.
  const buildServiceCountData = (
    filteredServiceData: ServiceAssetDetail[],
    dcFacilityList: number[]
  ) => {
    const updatedServiceCountData = buildServiceCounts(filteredServiceData, dcFacilityList);
    const countAnnotations: ICountAnnotationProps[] = buildAggregateCounts(updatedServiceCountData);
    setServiceInfoData(countAnnotations);
  };

  const [filteredDataEmpty, setFilteredDataEmpty] = useState<boolean>(false);

  const buildServiceDashboardData = (
    serviceAssetDataList: ServiceAssetDetail[],
    dcFacilityList: number[]
  ) => {
    buildServiceCountData(serviceAssetDataList, dcFacilityList);
    setFilteredDataEmpty(serviceAssetDataList?.length === 0 ?? true);
    setDriCountData(
      serviceAssetDataList
        .filter((d) => d?.driGeoTeamData?.length > 0)
        .flatMap((d) => d.driGeoTeamData.flatMap((g) => g.geoResilienceTeamData))
    );
  };

  // trigger update on load
  useMemo(() => {
    if (rawServiceData?.length >= 0) {
      const newFilterResults = rawServiceData.filter((service) => {
        if (
          searchFilters.length > 0 &&
          !service.serviceName.toLowerCase().includes(searchFilters.toLowerCase())
        ) {
          return false;
        }
        if (singleDCFilter === true && service.azureRegionData.length !== 1) {
          return false;
        }
        if (bclFilters.length > 0 && !service.bclNames.some((bcl) => bclFilters.includes(bcl))) {
          return false;
        }
        if (criticalFilters.length > 0 && !criticalFilters.includes(service.rtoCriticalRating)) {
          return false;
        }
        if (
          dcFilters.length > 0 &&
          !service.azureRegionData.some((dc) => dcFilters.includes(dc.datacenterCampusId))
        ) {
          return false;
        }
        if (
          serviceCloudFilters.length &&
          !service.azureRegionData.some((dc) => serviceCloudFilters.includes(dc.azureCloudName))
        )
          return false;
        if (divFilters.length > 0 && !divFilters.includes(service.divisionName)) {
          return false;
        }
        if (orgFilters.length > 0 && !orgFilters.includes(service.organizationName)) {
          return false;
        }
        if (sgFilters.length > 0 && !sgFilters.includes(service.serviceGroupName)) {
          return false;
        }
        if (
          classificationFilters.length > 0 &&
          !classificationFilters.includes(service.bcdrRecoveryClassification)
        ) {
          return false;
        }
        if (dcFacilityList.length > 0) {
          return service.azureRegionData.length > 0;
        }
        if (dcFacilityList.length === 0) {
          return service.azureRegionData.length === 0;
        }
        return true;
      });

      buildServiceDashboardData(newFilterResults, dcFacilityList as number[]);
      setCurrentFilteredServiceData(newFilterResults);
    }
  }, [
    rawServiceData,
    searchFilters,
    singleDCFilter,
    bclFilters,
    criticalFilters,
    dcFilters,
    divFilters,
    orgFilters,
    sgFilters,
    classificationFilters,
    serviceCloudFilters,
  ]);

  //handle table modal & headers
  const [showModal, setShowModal] = useState<boolean>(false);
  const hideModal = () => {
    setShowModal(false);
  };
  const facilityData = useAppSelector(getFacilities);
  //permissions & download setup
  const userCanDownload = useAppSelector(getUserDownloadPermissions);
  const userHasViewRestriction = useAppSelector(getUserIsRestricted);
  const logAndDownloadXlsx = React.useCallback(() => {
    const fileName = "WatchtowerServiceDataExport";
    const timeStamp = new Date(Date.now()).toLocaleDateString("en-US");
    const isRestrictedUser = userHasViewRestriction;

    let downloadData: IDownloadDetails;
    // map the service data to get timestamps, exclude the azure region data as it's on a separate tab
    const serviceData: IServiceAssetDetailDownload[] =
      currentFilteredServiceData.map<IServiceAssetDetailDownload>((d) => ({
        serviceId: d.serviceId,
        serviceName: d.serviceName,
        serviceType: d.serviceType,
        serviceTreeUrl: d.serviceTreeUrl,
        serviceGroupName: d.serviceGroupName,
        organizationName: d.organizationName,
        divisionName: d.divisionName,
        bclNames: d.bclNames,
        pmOwner: d.pmOwner,
        devOwner: d.devOwner,
        deploymentScope: d.deploymentScope,
        bcdrAssessmentId: d.bcdrAssessmentId,
        maxLifecycle: d.maxLifecycle,
        resiliencyDescription: d.resiliencyDescription,
        rtoCriticalRating: d.rtoCriticalRating,
        rtoCriticalityInHours: d.rtoCriticalityInHours,
        rpoCriticalityInHours: d.rpoCriticalityInHours,
        assessmentStatus: d.assessmentStatus,
        bcdrRecoveryClassification: d.bcdrRecoveryClassification,
        bcdrContact: d.bcdrContact,
        bcdrContactAlias: d.bcdrContactAlias,
        availabilityZoneEnabled: d.availabilityZoneEnabled,
        azureCloudRegionName: d.azureRegionData.map((x) => x.azureCloudRegionName).join(", "),
        dcsDetected: d.azureRegionData.map((x) => x.datacenterCampusName).join(", "),
        externalFacing: d.externalFacing,
        containsCustomerData: d.containsCustomerData,
        datetimeStamp: timeStamp,
      }));

    const facilityDataMap = facilityData.reduce((map, data) => {
      if (data.facilityType === "Datacenter") {
        map[data.facilityName] = data;
      }
      return map;
    }, {});

    const createAzureRegionDownloadObject = (azureRegionDataRow, facility, fsd, timeStamp) => ({
      azureCloudName: azureRegionDataRow.azureCloudName,
      azureCloudRegionName: azureRegionDataRow.azureCloudRegionName,
      azureNonProdSubscriptionCount: azureRegionDataRow.azureNonProdSubscriptionCount,
      azureProdSubscriptionCount: azureRegionDataRow.azureProdSubscriptionCount,
      city: userHasViewRestriction ? "RESTRICTED" : facility?.city ?? "UNKNOWN",
      state: facility?.state ?? "UNKNOWN",
      countryRegion: azureRegionDataRow.countryRegion ?? "UNKNOWN",
      datacenterCampusName: azureRegionDataRow.datacenterCampusName,
      serviceId: fsd.serviceId,
      serviceName: fsd.serviceName,
      datetimeStamp: timeStamp,
    });

    // map the service data to create normalized azure region data per service
    // but only download the locations relevant to current filtering or you will crash the download. :)
    const azureRegionData = currentFilteredServiceData.reduce<IAzureRegionDataDownload[]>(
      (acc, fsd) => {
        const azureRegionData: AzureRegion[] = fsd.azureRegionData;
        azureRegionData.forEach((azureRegionDataRow) => {
          const facility = facilityDataMap[azureRegionDataRow.datacenterCampusName];
          const downloadObject = createAzureRegionDownloadObject(
            azureRegionDataRow,
            facility,
            fsd,
            timeStamp
          );

          // trying this optimization per copilot suggestion
          if (singleDCFilter && azureRegionData.length === 1) {
            acc.push(downloadObject);
          } else if (
            dcFilters.length > 0 &&
            dcFilters.includes(azureRegionDataRow.datacenterCampusId)
          ) {
            acc.push(downloadObject);
          } else if (
            dcFacilityList.includes(azureRegionDataRow.datacenterCampusName.toUpperCase())
          ) {
            acc.push(downloadObject);
          }
        });
        return acc;
      },
      []
    );

    //map the service data to create normalized dri data per service.
    const driGeoData = currentFilteredServiceData.reduce<IGeoResilienceTeamDataDownload[]>(
      (acc, fsd) => {
        const driGeoTeamData = fsd.driGeoTeamData;
        if (driGeoTeamData?.length > 0) {
          driGeoTeamData.forEach((d) =>
            d.geoResilienceTeamData.forEach((geoData) =>
              acc.push(mapToDriDataView(geoData, d.teamId, d.teamName, fsd.serviceId, timeStamp))
            )
          );
        }
        return acc;
      },
      []
    );
    downloadData = {
      fileName,
      data: [
        { dataToExport: serviceData, sheetName: "Service Details" },
        { dataToExport: driGeoData, sheetName: "Service DRI Geo Data" },
        { dataToExport: azureRegionData, sheetName: "Service Azure Region Data" },
      ],
    };

    ExportToExcelUtil(downloadData);
    const eventProperties = {
      Source: "Services",
      "User Has View Restriction": isRestrictedUser,
      "User Can Download": userCanDownload,
      "Download service count": currentFilteredServiceData.length,
      "Download dri count": driGeoData.length,
      "Download azure region count": azureRegionData.length,
    };
    appInsights.trackEvent({ name: "Service Data Exported" }, eventProperties);
  }, [
    dcFilters,
    dcFacilityList,
    singleDCFilter,
    facilityData,
    currentFilteredServiceData,
    userHasViewRestriction,
    userCanDownload,
    appInsights,
  ]);

  const [isDriDataGridOpen, { setTrue: openDriDataGridPanel, setFalse: dismissDriDataGridPanel }] =
    useBoolean(false);
  const [selectedService, setSelectedService] = useState<ServiceAssetDetail>(null);
  const buildDriPanelData = (service: ServiceAssetDetail) => {
    setSelectedService(service);
    openDriDataGridPanel();
  };

  const searchBoxStyles: Partial<ISearchBoxStyles> = {
    root: { width: 300, marginBottom: 20 },
  };

  const filterIcon: IIconProps = { iconName: "Filter" };
  const [isFilterPanelOpen, { setTrue: openFilterPanel, setFalse: dismissFilterPanel }] =
    useBoolean(false);

  // store the state of the Dashboard and handle changes within it
  const [map, setMap] = useState<IRearrangeableGridDefinition>({
    dcDected: { cellHeight: 1, cellWidth: 2, row: 0, col: 0 },
    serviceInfoDataCard: { cellHeight: 1, cellWidth: 6, row: 0, col: 0 },
    serviceDriInfoDataCard: { cellHeight: 2, cellWidth: 1, row: 1, col: 0 },
    serviceComplianceStatusDataCard: {
      cellHeight: 2,
      cellWidth: 4,
      row: 1,
      col: 1,
    },
    serviceDataGridCard: { cellHeight: 5, cellWidth: 6, row: 2, col: 0 },
  });

  //handle resizing in the dashboard grid
  const resizeCallback =
    useRef<
      (
        width: number,
        height: number,
        itemKey: string,
        position?: { col: number; row: number }
      ) => void
    >();

  const generateResizeCallback = (key: string) => {
    return (_ev: never, item: IContextualMenuItem) => {
      const dimension = CardSizeValues[item.key as CardSizes];

      resizeCallback.current?.(dimension.cellWidth, dimension.cellHeight, key);
    };
  };

  const columnResizeRef =
    useRef<(width: number, height: number, gap: IRearrangeableGridGap) => void>();

  const columnResize = (width: number, height: number, gap: IRearrangeableGridGap) => {
    columnResizeRef.current?.(width, height, gap);
  };

  const onChange = (newMap: IRearrangeableGridDefinition) => {
    setMap(newMap);
  };
  const resizeText = "Resize";
  const moreDetailsText = "Info";

  const generateCallout = (calloutName: string) => {
    switch (calloutName) {
      case "serviceInfo":
        return (
          <div data-is-focusable>
            <ServiceBreakdown />
            <Text>Info</Text>
          </div>
        );
      case "driInfo":
        return (
          <div data-is-focusable>
            <PotentialDriTeamImpactLevel />
            <Text>Info</Text>
          </div>
        );
      case "serviceGrid":
        return (
          <div data-is-focusable>
            <ServiceGeneral />
            <Text>Info</Text>
          </div>
        );
      default:
        return;
    }
  };

  const generateItemMenu = (key: string, calloutName: string): IContextualMenuProps => {
    let items: IContextualMenuItem[] = [
      {
        iconProps: { iconName: "MiniExpand" },
        key: resizeText,
        text: resizeText,
        subMenuProps: {
          items: [
            { key: CardSizes.small, text: CardSizes.small },
            { key: CardSizes.smallWide, text: CardSizes.smallWide },
            { key: CardSizes.medium, text: CardSizes.medium },
            { key: CardSizes.mediumTall, text: CardSizes.mediumTall },
            { key: CardSizes.mediumWide, text: CardSizes.mediumWide },
            { key: CardSizes.large, text: CardSizes.large },
            { key: CardSizes.extraLarge, text: CardSizes.extraLarge },
          ],
          onItemClick: generateResizeCallback(key),
        },
      },
    ];
    if (calloutName) {
      items = [
        ...items,
        {
          iconProps: { iconName: "Info" },
          key: moreDetailsText,
          text: moreDetailsText,
          data: generateCallout(calloutName),
          onRender: (item) => item.data,
        },
      ];
      if (userCanDownload && calloutName === "serviceGrid") {
        items = [
          ...items,
          {
            key: "downloadServiceDataExcel",
            iconProps: { iconName: "ExcelDocument" },
            text: "Download to Excel",
            onClick: () => logAndDownloadXlsx(),
            disabled: rawServiceData.length === 0 || currentServiceAzureRegionCount > 200000,
          },
        ];
      }
    }

    return {
      items,
    };
  };

  const headerStyles = memoizeFunction(mergeStyles);
  const getCardTitle = (title: string) => {
    const titleClassName = headerStyles([
      theme.fonts.medium,
      { padding: 0, margin: 0, fontWeight: FontWeights.semibold },
    ]);

    return <h3 className={titleClassName}>{title}</h3>;
  };

  const backgroundStyles = mergeStyles({
    background: theme.semanticColors.navBackground,
    paddingTop: 24,
    paddingBottom: 24,
    paddingRight: 16,
    paddingLeft: 16,
  });

  return (
    <ThemeProvider theme={M365LightTheme.settings.theme}>
      <ServicesFilter
        isServiceDataLoading={false}
        isPanelOpen={isFilterPanelOpen}
        openPanel={openFilterPanel}
        dismissPanel={dismissFilterPanel}
      />

      <ServiceDetailGrid
        filteredServiceData={selectedService}
        isServiceDetailPanelOpen={isDriDataGridOpen}
        dismissServiceDetailPanel={dismissDriDataGridPanel}
      />

      <Stack horizontal tokens={{ childrenGap: 10, padding: 8 }}>
        <ActionButton
          iconProps={filterIcon}
          onClick={openFilterPanel}
          disabled={isServiceLoading || rawServiceData.length === 0}
        >
          More Filters
        </ActionButton>
        <ServiceFilterTags />
      </Stack>
      <div className={backgroundStyles}>
        <Dashboard
          map={map}
          onChange={onChange}
          ariaLabel="Crisis Response Services Dashboard"
          ariaDescription="Use the arrow keys to navigate to a card. Press alt-shift-arrow to move a card. Press enter key to enter each item. Press escape to return to the grid when within an item."
          rearrangeableGridProps={{
            resizeHandler: resizeCallback,
            onColumnResize: columnResize,
            minCellWidth: 250,
            minCellHeight: 150,
          }}
        >
          <DashboardCard
            key={"dcDected"}
            titleText={getCardTitle("DC Detected")}
            isLoading={useAppSelector(getServiceCountsLoadingStatus)}
            moreIconButtonProps={{
              ariaLabel: "More Actions",
              menuProps: generateItemMenu("facTypeCard", "facTypes"),
            }}
            body={
              <>
                {!dcDetectedTotal && (
                  <Stack horizontalAlign="center">
                    {" "}
                    <Text>No Results Found</Text>{" "}
                  </Stack>
                )}
                {!!dcDetectedTotal && (
                  <InfoDonutChart
                    chartTitle={"DC Detected"}
                    chartData={dcDetectedCounts.map((item, index) => {
                      return {
                        ...item,
                        color: pieChartColorOptions[index++],
                        onClick: (...r) => {
                          dispatch(updateDcDetectedFilter(item.id));
                        },
                      };
                    })}
                    parentRef={undefined}
                  />
                )}
              </>
            }
          />
          <DashboardCard
            key={"serviceDataGridCard"}
            titleText={getCardTitle("Service Assets")}
            isLoading={isServiceLoading}
            data-is-scrollable="true"
            moreIconButtonProps={{
              ariaLabel: "More Actions",
              menuProps: generateItemMenu("serviceDataGridCard", "serviceGrid"),
            }}
          >
            <Label>Search:</Label>
            <SearchBox
              styles={searchBoxStyles}
              placeholder="Search By Service Name"
              onChange={(_, newValue: string) => dispatch(updateServiceAssetSearchTerm(newValue))}
              onClear={() => dispatch(updateServiceAssetSearchTerm(""))}
            />
            {filteredDataEmpty && (
              <Stack horizontalAlign="center">
                <Text>No Results Found</Text>
              </Stack>
            )}
            {!filteredDataEmpty && (
              <ScrollablePane className={_Styles.smallPane}>
                <ServiceDataGrid
                  filteredServiceData={currentFilteredServiceData}
                  openGridPanel={buildDriPanelData}
                />
              </ScrollablePane>
            )}
            {dcFacilityList?.length > 0 && filteredDataEmpty && <EmptyCardContent />}
          </DashboardCard>
          <DashboardCard
            key={"serviceInfoDataCard"}
            titleText={getCardTitle("Service Breakdown")}
            body={
              <CountAnnotationBar
                countAnnotationProps={serviceInfoData}
                direction={CountAnnotationBarDirection.Horizontal}
              />
            }
            isLoading={isServiceLoading}
            moreIconButtonProps={{
              ariaLabel: "More Actions",
              menuProps: generateItemMenu("serviceInfoDataCard", "serviceInfo"),
            }}
          />
          <DashboardCard
            key={"serviceDriInfoDataCard"}
            titleText={getCardTitle("Potential DRI Team Impact Level")}
            body={
              <CountAnnotationBar
                countAnnotationProps={driInfoData}
                direction={CountAnnotationBarDirection.Vertical}
              />
            }
            isLoading={isServiceLoading}
            moreIconButtonProps={{
              ariaLabel: "More Actions",
              menuProps: generateItemMenu("serviceDriInfoDataCard", "driInfo"),
            }}
          />
          <DashboardCard
            key={"serviceComplianceStatusDataCard"}
            titleText={getCardTitle("Compliance Status by Division")}
            body={
              <ComplianceStatusByDivStackedBarChart filteredServices={currentFilteredServiceData} />
            }
            isLoading={isServiceLoading}
            moreIconButtonProps={{
              ariaLabel: "More Actions",
              menuProps: generateItemMenu(
                "serviceComplianceStatusDataCard",
                "serviceComplianceStatusByDiv"
              ),
            }}
          />
        </Dashboard>
        <CoherenceModal
          isOpen={showModal}
          title={"Service Asset Data"}
          modalWidth={"large"}
          height={"fixed"}
          onDismiss={hideModal}
        >
          <ScrollablePane>
            <ServiceDataGrid
              filteredServiceData={currentFilteredServiceData}
              openGridPanel={buildDriPanelData}
            />
          </ScrollablePane>
        </CoherenceModal>
      </div>
    </ThemeProvider>
  );
}
