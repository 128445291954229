import atlas, { AuthenticationType } from "azure-maps-control";
import { _Styles } from "pages/Page.styles";
import React, { useEffect, useMemo, useState } from "react";
import { AzureMap, AzureMapDataSourceProvider, AzureMapLayerProvider, AzureMapsProvider, ControlOptions, IAzureMapOptions } from "react-azure-maps";
import { getFilteredPeopleDirects } from "store/selectors/peopleSelector";
import { getSystemAzureMapKey, getSystemAzureMapKeyLoadingStatus } from "store/selectors/systemSelector";
import { useAppSelector } from "store/store";

type PEOPLEHEATDATA = {
  type: "Feature";
  properties: { mag: number };
  geometry: { type: "Point"; coordinates: [number, number] };
};

export interface HeatMapControllerProps { }

const HeatMapController = () => {
  //camera
  const [center] = useState([-122.133, 47.636]);
  const mapKeyLoading = useAppSelector(getSystemAzureMapKeyLoadingStatus);
  const mapKey = useAppSelector(getSystemAzureMapKey);

  //options
  const option: IAzureMapOptions = useMemo(() => {
    return {
      authOptions: {
        authType: AuthenticationType.subscriptionKey,
        subscriptionKey: mapKey,
      },
      zoom: 2,
      view: "Auto",
      showLogo: true,
      autoResize: true,
      language: "en-US",
    };
  }, [mapKey]);

  const peopleHeatDataState = useAppSelector(getFilteredPeopleDirects);
  const [peopleHeatData, setPeopleHeatData] = useState<atlas.data.FeatureCollection>();

  useEffect(() => {
    const peopleData = peopleHeatDataState;
    const mappedData = peopleData.map(
      (person): PEOPLEHEATDATA => ({
        type: "Feature",
        properties: {
          mag: 1,
        },
        geometry: {
          type: "Point",
          coordinates: [person.longitude, person.latitude],
        },
      })
    );
    const HeatData: atlas.data.FeatureCollection = {
      type: "FeatureCollection",
      features: mappedData,
    };

    setPeopleHeatData(HeatData);
  }, [peopleHeatDataState]);

  return (
    <>
      {!mapKeyLoading && (
        <AzureMapsProvider>
          <div className={_Styles.weatherMap}>
            <AzureMap
              options={option}
              cameraOptions={{ center: center }}
              controls={[
                {
                  controlName: "StyleControl",
                  controlOptions: {
                    mapStyles: [
                      "road",
                      "grayscale_light",
                      "grayscale_dark",
                      "night",
                      "road_shaded_relief",
                      "high_contrast_dark",
                      "high_contrast_light",
                    ],
                  },
                  options: { position: "top-left" } as ControlOptions,
                },
                {
                  controlName: "ZoomControl",
                  options: { position: "top-left" } as ControlOptions,
                },
              ]}
            >
              <AzureMapDataSourceProvider
                id={"msheatmap AzureMapDataSourceProvider"}
                collection={peopleHeatData}
                options={{}}
              >
                <AzureMapLayerProvider
                  id={"msheatmap1 AzureMapLayerProvider"}
                  options={{ opacity: 0.8, radius: 10 }}
                  events={{}}
                  type="HeatLayer"
                ></AzureMapLayerProvider>
              </AzureMapDataSourceProvider>
            </AzureMap>
          </div>
        </AzureMapsProvider>
      )}
    </>
  );
};

export default HeatMapController;
