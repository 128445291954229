import { IButtonProps, IColumn, IDetailsRowProps, IGroup, IRenderFunction } from "@fluentui/react";
import { FontWeights, SelectionMode, Text } from "@fluentui/react";
import { 
    CompositeListRow,
    IActionButtonProps,
    ICompositeListDetailsList,
  } from "@m365-admin/composite-list";
import { CompositeList } from "@m365-admin/composite-list";
import * as React from "react";
import { useState } from "react";
import { UpstreamDependencyData } from "../../types/DependencyMappingTypes";

export class BaseGroupHeaderItem {
  public classification!: string;
  public classificationCount!: number;
}

export interface ServiceUpstreamDepDataGridProps {
  currentServiceDepUpMetadata: UpstreamDependencyData[];
  onSelectedItem(item: UpstreamDependencyData): void;
}

export const ServiceUpstreamDepDataGrid = (
  props: ServiceUpstreamDepDataGridProps
) => {
  const defaultGroupingNames: string[] = [
    "Critical",
    "Non-critical",
    "Critical For Recovery",
  ];

  const columns: IColumn[] = [
    {
      key: "serviceName",
      name: "Service Name",
      ariaLabel: "Upstream Service Name",
      minWidth: 150,
      maxWidth: 250,
      isMultiline: true,
      isResizable: true,
      onRender: (x: UpstreamDependencyData) => `${x.serviceName}`,
    },
    {
      key: "dependencyClassification",
      fieldName: "dependencyClassification",
      name: "Classification",
      ariaLabel: "Dependency classification",
      minWidth: 75,
      maxWidth: 100,
      isResizable: true,
      onRender: (x: UpstreamDependencyData) => `${x.dependencyClassification}`,
    },
    {
      key: "desiredDependencyServiceRtc",
      fieldName: "desiredDependencyServiceRtc",
      name: "Desired RTC",
      ariaLabel: "Desired RTC",
      minWidth: 75,
      maxWidth: 100,
      isResizable: true,
      onRender: (x: UpstreamDependencyData) =>
        `${x.desiredDependencyServiceRtc}`,
    },
    {
      key: "approvedDependencyServiceRtc",
      fieldName: "approvedDependencyServiceRtc",
      name: "Approved RTC",
      ariaLabel: "Approved RTC",
      minWidth: 75,
      maxWidth: 100,
      isResizable: true,
      onRender: (x: UpstreamDependencyData) => `${x.upstreamApprovedRtc}`,
    },
    {
      key: "isViolatingDownstreamRtcRequirements",
      fieldName: "isViolatingDownstreamRtcRequirements",
      name: "Dependency Gap Flag",
      ariaLabel: "Upstream Dependency Gap Flag",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      onRender: (x: UpstreamDependencyData) =>
        `${x.isViolatingDownstreamRtcRequirements === true ? "yes" : "no"}`,
    },
    {
      key: "storesServiceData",
      fieldName: "storesServiceData",
      name: "Stores Service Data",
      ariaLabel: "Stores Service Data",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      onRender: (x: UpstreamDependencyData) =>
        `${x.storesServiceData === true ? "yes" : "no"}`,
    },
    {
      key: "serviceOid",
      name: "Service Id",
      ariaLabel: "Upstream Service Id",
      minWidth: 150,
      maxWidth: 250,
      isMultiline: true,
      isResizable: true,
      onRender: (x: UpstreamDependencyData) => `${x.serviceOid}`,
    },
  ];

  const headerColumns: IColumn[] = [
    {
      key: "classification",
      name: "Classification",
      isResizable: true,
      minWidth: 100,
      maxWidth: 275,
      onRender: (item: BaseGroupHeaderItem) => {
        return (
          <Text styles={{ root: { fontWeight: FontWeights.semibold } }}>
            {item.classification}
          </Text>
        );
      },
    },
    {
      key: "classificationCount",
      name: "Total Classification",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      onRender: (item: BaseGroupHeaderItem) =>
        item.classificationCount > 0 ? <>{item.classificationCount}</> : <></>,
    },
  ];

  const getMoreActionItemProps: IButtonProps = {
    name: 'details',
    iconProps: { iconName: 'ExploreData' },
    title: 'Click icon to view details',
    ariaLabel: 'Click icon to view details',  
  };

  function getActionItems<T>(rowItem: T): IActionButtonProps<T>[] {
    const actionItems = [];
  
    actionItems.push({
      key: 'more',
      buttonProps: getMoreActionItemProps,
      item: rowItem,
      onClick: (item: T): void => {
        if (item) {
          const serviceOid = (item as any).serviceOid;
          const serviceName = (item as any).serviceName;
          if (serviceOid && serviceName) {
            let upstreamDepData = props.currentServiceDepUpMetadata.find(
              (service) => service.serviceOid === serviceOid && service.serviceName === serviceName);
            props.onSelectedItem(upstreamDepData);
          }
        }
      },  
    });

    return actionItems;
  };
  
  const onRenderRow = (
    rowProps: IDetailsRowProps,
    defaultRender: IRenderFunction<IDetailsRowProps>,
  ): JSX.Element => {
    return (
      <CompositeListRow
        actionKey="serviceName"
        renderFunction={defaultRender}
        rowProps={rowProps}
        actionItems={getActionItems(rowProps.item)}
      />
    );
  };

  const _getGroups = (
    groupItems: UpstreamDependencyData[],
    groupingNames: string[]
  ) => {
    const dataGroups: IGroup[] = [];
    let groupsStartIndex = 0;

    groupingNames.forEach((groupedItem: string) => {
      const groupedData = groupItems.filter((item: UpstreamDependencyData) => {
        return (
          item.dependencyClassification?.toLowerCase() ===
          groupedItem?.toLowerCase()
        );
      });

      const rowCount = groupedData.length;
      const headerItem: BaseGroupHeaderItem = {
        classification: groupedItem,
        classificationCount: 0,
      };

      if (rowCount > 0) {
        headerItem.classificationCount = groupedData.length;
      }

      const group: IGroup = {
        key: groupedItem,
        name: groupedItem,
        startIndex: groupsStartIndex,
        count: rowCount,
        data: headerItem,
      };

      groupsStartIndex += rowCount;

      dataGroups.push(group);
    });

    return dataGroups;
  };

  const [list, setList] = useState<ICompositeListDetailsList>({
    listProps: {
      // NOTE: `checkButtonAriaLabel` will set the same aria-label for all row checkboxes.
      // If you want different aria-labels for each row checkbox,
      // please see `onRenderCheck` on `onRenderRow` in this example.
      // Fluent guidance: https://github.com/microsoft/fluentui/issues/16450#issuecomment-793201238
      // checkButtonAriaLabel: 'Select item',
      ariaLabelForSelectAllCheckbox: "Select all items",
      items: [],
      groups: _getGroups([], defaultGroupingNames),
      groupProps: {
        headerProps: {},
        showEmptyGroups: false,
      },
      groupHeaderColumns: headerColumns,
      columns: columns,
      selectionMode: SelectionMode.none,
    },
    key: "upstream dependency list",
  });

  React.useMemo(() => {
    const uniqueGroup = Array.from(
      new Set(
        props.currentServiceDepUpMetadata.map((x) => x.dependencyClassification)
      )
    );
    let groupings = uniqueGroup.sort();

    const sortedData = props?.currentServiceDepUpMetadata.sort((a, b) =>
      a.dependencyClassification
        ?.toUpperCase()
        .localeCompare(b.dependencyClassification.toUpperCase())
    );

    setList({
      listProps: {
        ariaLabelForSelectAllCheckbox: "Select all items",
        items: sortedData,
        groups: _getGroups(sortedData, groupings),
        groupProps: {
          headerProps: {
            selectAllButtonProps: {
              "aria-label": "Select all items in the group",
            },
          },
          showEmptyGroups: false,
        },
        groupHeaderColumns: headerColumns,
        columns: columns,
        selectionMode: SelectionMode.none,
        onRenderRow: onRenderRow,
      },
      key: "upstream dependency list",
    });
  }, [props.currentServiceDepUpMetadata]);

  return (
    <CompositeList 
      detailsListProps={list}
      commandBarProps={{ items: [] }} />
  );
};
