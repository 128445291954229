import * as React from "react";
import { Pivot, PivotItem, useTheme } from "@fluentui/react";
import { MetaDataItem, MetaDataList } from "@m365-admin/metadata";

import { ServiceBcdrDetailedMetadata } from "../../types/DependencyMappingTypes";
import {
  buildAssessmentStatusIcons,
  tryBuildDetailString,
} from "./CardHelpers";

export interface ServiceBcdrAssessmentCardProps {
  currentServiceMetadata: ServiceBcdrDetailedMetadata;
}

export const ServiceBcdrAssessmentCard = (
  props: ServiceBcdrAssessmentCardProps
) => {
  let theme = useTheme();
  const metadataListStyles = {
    root: { paddingBottom: 16, paddingRight: 8 },
    itemContiner: { maxWidth: "40%" },
  };

  return (
    <>
      <Pivot aria-label="Additional BCDR Assessment Details">
        <PivotItem headerText="Basic Details">
          <MetaDataList verticalSpacing={8} styles={metadataListStyles}>
            <MetaDataItem
              header="Assessment Status"
              body={buildAssessmentStatusIcons(
                props?.currentServiceMetadata?.assessmentStatus,
                theme
              )}
            />
            <MetaDataItem
              header="Service Ring"
              body={tryBuildDetailString(
                props?.currentServiceMetadata?.serviceRing
              )}
            />
            <MetaDataItem
              header="Assessment Date"
              body={tryBuildDetailString(
                props?.currentServiceMetadata?.assessmentDate
              )}
            />
            <MetaDataItem
              header="Service Type"
              body={tryBuildDetailString(
                props?.currentServiceMetadata?.serviceType
              )}
            />
          </MetaDataList>
        </PivotItem>

        <PivotItem headerText="Failover">
          <MetaDataList verticalSpacing={8} styles={metadataListStyles}>
            <MetaDataItem
              header="Willingness to Failover"
              body={tryBuildDetailString(
                props?.currentServiceMetadata?.willingnessToFailover
              )}
            />
            <MetaDataItem
              header="Detection Type"
              body={tryBuildDetailString(
                props?.currentServiceMetadata?.detectionType
              )}
            />
            <MetaDataItem
              header="Recovery Automation"
              body={tryBuildDetailString(
                props?.currentServiceMetadata?.recoveryAutomation
              )}
            />
            <MetaDataItem
              header="Recovery Decision"
              body={tryBuildDetailString(
                props?.currentServiceMetadata?.recoveryDecision
              )}
            />
          </MetaDataList>
        </PivotItem>
        <PivotItem headerText="Testing">
          <MetaDataList verticalSpacing={8} styles={metadataListStyles}>
            <MetaDataItem
              header="Test Environment"
              body={tryBuildDetailString(
                props?.currentServiceMetadata?.testEnvironment
              )}
            />
            <MetaDataItem
              header="Last Test Date"
              body={tryBuildDetailString(
                props?.currentServiceMetadata?.lastTestDate
              )}
            />
          </MetaDataList>
          <MetaDataItem
            header="Test Scenario"
            body={tryBuildDetailString(
              props?.currentServiceMetadata?.testScenario
            )}
          />
        </PivotItem>
      </Pivot>
    </>
  );
};
