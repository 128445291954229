import { AxiosError } from "axios";
import {
  IDriGeoResilienceData,
  IDriGeoTeamCount,
  ServiceAssetDetail,
  ServiceCount,
} from "../../components/Types";
import {
  CLEAR_SERVICE_DETAILS,
  CLEAR_SERVICE_DETAILS_BY_IDS,
  CLEAR_SERVICE_FILTERS,
  FETCH_SERVICE_COUNTS_BEGIN,
  FETCH_SERVICE_COUNTS_FAILURE,
  FETCH_SERVICE_COUNTS_SUCCESS,
  FETCH_SERVICE_DETAILS_BEGIN,
  FETCH_SERVICE_DETAILS_BY_IDS_BEGIN,
  FETCH_SERVICE_DETAILS_BY_IDS_FAILURE,
  FETCH_SERVICE_DETAILS_BY_IDS_SUCCESS,
  FETCH_SERVICE_DETAILS_FAILURE,
  FETCH_SERVICE_DETAILS_SUCCESS,
  FETCH_SERVICE_DRI_GEO_RESIL_BEGIN,
  FETCH_SERVICE_DRI_GEO_RESIL_COUNT_BEGIN,
  FETCH_SERVICE_DRI_GEO_RESIL_COUNT_FAILURE,
  FETCH_SERVICE_DRI_GEO_RESIL_COUNT_SUCCESS,
  FETCH_SERVICE_DRI_GEO_RESIL_FAILURE,
  FETCH_SERVICE_DRI_GEO_RESIL_SUCCESS,
  RESET_DRI_GEO_ASSETS,
  RESET_DRI_INSIGHT_ASSETS,
  SET_SERVICE_ASSET_SEARCH_TERM,
  SET_SERVICE_BCL_FILTER,
  SET_SERVICE_CLOUD_FILTER,
  SET_SERVICE_CRITICALITY_FILTER,
  SET_SERVICE_DATA_CENTER_FILTER,
  SET_SERVICE_DC_DETECTED_FILTER,
  SET_SERVICE_DIVISION_FILTER,
  SET_SERVICE_ORGANIZATION_FILTER,
  SET_SERVICE_RECOVERY_CLASSIFICATION_FILTER,
  SET_SERVICE_SERVICEGROUP_FILTER,
  SET_SERVICE_SINGLE_DC_FILTER,
} from "../actions/actionTypes";
import { DcDetected } from "store/actions/servicesActions";

export interface ReduxServicesState {
  serviceCounts: ServiceCount;
  isServiceCountsLoading: boolean;
  serviceCountError: AxiosError | string;
  serviceAssetDetails: ServiceAssetDetail[];
  isServiceAssetDetailsLoading: boolean;
  serviceAssetError: AxiosError;
  serviceAssetSearchTerm: string;
  serviceCriticalityFilters: string[];
  serviceSingleDcFilter: boolean;
  serviceDcDetectedFilter: DcDetected;
  serviceDivisionFilters: string[];
  serviceOrganizationFilters: string[];
  serviceSgFilters: string[];
  serviceRecoveryClassificationFilters: string[];
  serviceDcFilters: number[];
  serviceDriCityFilters: string[];
  serviceBclFilters: string[];
  serviceCloudFilters: string[];
  driGeoResilData: IDriGeoResilienceData[];
  isDriGeoResilDataLoading: boolean;
  driGeoResilLoadError: AxiosError | Error | string;
  isDriGeoResilTeamCountLoading: boolean;
  driGeoResilTeamCountData: IDriGeoTeamCount;
  driGeoResilTeamCountLoadError: AxiosError | Error | string;
}

const defaultDriResilTeamCount: IDriGeoTeamCount = {
  totalDriCount: 0,
  totalServicesWithDriCount: 0,
  totalServicesWithNoDriCount: 0,
  totalTeamsWithoutServiceCount: 0,
  totalTeamsWithServiceCount: 0,
};

const servicesInitialState: ReduxServicesState = {
  serviceCounts: null,
  isServiceCountsLoading: false,
  serviceCountError: null,
  serviceAssetDetails: [],
  isServiceAssetDetailsLoading: false,
  serviceAssetError: null,
  serviceAssetSearchTerm: null,
  serviceCriticalityFilters: [],
  serviceSingleDcFilter: false,
  serviceDcDetectedFilter: DcDetected.ALL,
  serviceDivisionFilters: [],
  serviceOrganizationFilters: [],
  serviceSgFilters: [],
  serviceRecoveryClassificationFilters: [],
  serviceDcFilters: [],
  serviceDriCityFilters: [],
  serviceBclFilters: [],
  serviceCloudFilters: [],
  driGeoResilData: [],
  isDriGeoResilDataLoading: false,
  driGeoResilLoadError: null,
  driGeoResilTeamCountData: defaultDriResilTeamCount,
  isDriGeoResilTeamCountLoading: false,
  driGeoResilTeamCountLoadError: null,
};

const buildServiceHasSingleAzureRegionSubscriptionsResult = (x: ServiceAssetDetail): string => {
  if (x.azureRegionData?.length > 0) {
    return new Set(x.azureRegionData?.map((y) => y.azureCloudRegionName)).size > 1 ? "No" : "Yes";
  }
  return "Unknown";
};

const serviceMappingHelper = (
  driGeoResilList: IDriGeoResilienceData[],
  serviceAssetList: ServiceAssetDetail[]
) => {
  const serviceAssetMapping = serviceAssetList.map((service) => {
    const standardServiceId = service.serviceId.toLowerCase();
    const driGeoResilData = driGeoResilList
      .filter((s) => s.serviceId.toLowerCase() === standardServiceId)
      .flatMap((s) => s.driTeamData);
    const serviceHasSingleAzureRegionSubscriptions: string =
      buildServiceHasSingleAzureRegionSubscriptionsResult(service);
    return {
      ...service,
      serviceId: standardServiceId,
      driGeoTeamData: driGeoResilData,
      serviceHasSingleAzureRegionSubs: serviceHasSingleAzureRegionSubscriptions,
    };
  });
  return serviceAssetMapping;
};

export default function services(
  state: ReduxServicesState = servicesInitialState,
  action
): ReduxServicesState {
  switch (action.type) {
    case FETCH_SERVICE_COUNTS_BEGIN:
      return {
        ...state,
        isServiceCountsLoading: true,
        serviceCountError: null,
      };
    case FETCH_SERVICE_COUNTS_SUCCESS:
      return {
        ...state,
        serviceCounts: action.payload,
        isServiceCountsLoading: false,
        serviceCountError: null,
      };
    case FETCH_SERVICE_COUNTS_FAILURE:
      return {
        ...state,
        isServiceCountsLoading: false,
        serviceCountError: action.payload,
      };
    case FETCH_SERVICE_DETAILS_BEGIN:
      return {
        ...state,
        isServiceAssetDetailsLoading: true,
        serviceAssetError: null,
      };
    case FETCH_SERVICE_DETAILS_SUCCESS:
      return {
        ...state,
        serviceAssetDetails: serviceMappingHelper(state.driGeoResilData, action.payload),
        isServiceAssetDetailsLoading: false,
        serviceAssetError: null,
      };
    case FETCH_SERVICE_DETAILS_FAILURE:
      return {
        ...state,
        isServiceAssetDetailsLoading: false,
        serviceAssetError: action.payload,
      };
    case CLEAR_SERVICE_DETAILS:
      return {
        ...state,
        serviceAssetDetails: [],
        serviceCounts: null,
        isServiceAssetDetailsLoading: false,
        serviceAssetError: null,
      };
    case FETCH_SERVICE_DETAILS_BY_IDS_BEGIN:
      return {
        ...state,
        isServiceAssetDetailsLoading: true,
        serviceAssetError: null,
      };
    case FETCH_SERVICE_DETAILS_BY_IDS_SUCCESS:
      return {
        ...state,
        serviceAssetDetails: serviceMappingHelper(state.driGeoResilData, action.payload),
        isServiceAssetDetailsLoading: false,
        serviceAssetError: null,
      };
    case FETCH_SERVICE_DETAILS_BY_IDS_FAILURE:
      return {
        ...state,
        isServiceAssetDetailsLoading: false,
        serviceAssetError: action.payload,
      };
    case CLEAR_SERVICE_DETAILS_BY_IDS:
      return {
        ...state,
        serviceAssetDetails: [],
        isServiceAssetDetailsLoading: false,
        serviceAssetError: null,
      };
    case FETCH_SERVICE_DRI_GEO_RESIL_BEGIN:
      return {
        ...state,
        isDriGeoResilDataLoading: true,
        driGeoResilData: [],
        driGeoResilLoadError: null,
      };
    case FETCH_SERVICE_DRI_GEO_RESIL_FAILURE:
      return {
        ...state,
        isDriGeoResilDataLoading: false,
        driGeoResilLoadError: action.payload,
      };
    case FETCH_SERVICE_DRI_GEO_RESIL_SUCCESS:
      return {
        ...state,
        isDriGeoResilDataLoading: false,
        driGeoResilData: action.payload,
        driGeoResilLoadError: null,
        serviceAssetDetails: serviceMappingHelper(action.payload, state.serviceAssetDetails),
      };
    case FETCH_SERVICE_DRI_GEO_RESIL_COUNT_BEGIN:
      return {
        ...state,
        isDriGeoResilTeamCountLoading: true,
        driGeoResilTeamCountData: null,
        driGeoResilTeamCountLoadError: null,
      };
    case FETCH_SERVICE_DRI_GEO_RESIL_COUNT_SUCCESS:
      return {
        ...state,
        isDriGeoResilTeamCountLoading: false,
        driGeoResilTeamCountData: action.payload,
        driGeoResilTeamCountLoadError: null,
      };
    case FETCH_SERVICE_DRI_GEO_RESIL_COUNT_FAILURE:
      return {
        ...state,
        isDriGeoResilTeamCountLoading: false,
        driGeoResilTeamCountLoadError: action.payload,
      };
    case RESET_DRI_GEO_ASSETS:
      return {
        ...state,
        driGeoResilData: [],
      };
    case RESET_DRI_INSIGHT_ASSETS:
      return {
        ...state,
        driGeoResilData: [],
      };
    case SET_SERVICE_CRITICALITY_FILTER:
      return {
        ...state,
        serviceCriticalityFilters: action.payload,
      };
    case SET_SERVICE_SINGLE_DC_FILTER:
      return {
        ...state,
        serviceSingleDcFilter: action.payload,
      };
    case SET_SERVICE_DIVISION_FILTER:
      return {
        ...state,
        serviceDivisionFilters: action.payload,
      };
    case SET_SERVICE_ORGANIZATION_FILTER:
      return {
        ...state,
        serviceOrganizationFilters: action.payload,
      };
    case SET_SERVICE_SERVICEGROUP_FILTER:
      return {
        ...state,
        serviceSgFilters: action.payload,
      };
    case SET_SERVICE_RECOVERY_CLASSIFICATION_FILTER:
      return {
        ...state,
        serviceRecoveryClassificationFilters: action.payload,
      };
    case SET_SERVICE_DATA_CENTER_FILTER:
      return {
        ...state,
        serviceDcFilters: action.payload,
      };
    case SET_SERVICE_BCL_FILTER:
      return {
        ...state,
        serviceBclFilters: action.payload,
      };
    case SET_SERVICE_CLOUD_FILTER:
      return {
        ...state,
        serviceCloudFilters: action.payload,
      };
    case SET_SERVICE_ASSET_SEARCH_TERM:
      return {
        ...state,
        serviceAssetSearchTerm: action.payload,
      };
    case SET_SERVICE_DC_DETECTED_FILTER:
      return {
        ...state,
        serviceDcDetectedFilter: action.payload,
      };
    case CLEAR_SERVICE_FILTERS:
      return {
        ...state,
        serviceCriticalityFilters: [],
        serviceSingleDcFilter: false,
        serviceDivisionFilters: [],
        serviceOrganizationFilters: [],
        serviceSgFilters: [],
        serviceRecoveryClassificationFilters: [],
        serviceDcFilters: [],
        serviceBclFilters: [],
        serviceCloudFilters: [],
        serviceDriCityFilters: [],
        serviceAssetSearchTerm: null,
      };
    default:
      return state;
  }
}
