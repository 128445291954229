import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { store } from "store/store";
import { App, AppWithoutAuth } from "./App";
import { msalConfig } from "./authProvider";

const rootElement = document.getElementById("root");
export const msalInstance = new PublicClientApplication(msalConfig);
const cypress = (window as any).Cypress;

if (cypress) {
  (window as any).store = store;

  ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
          <AppWithoutAuth />
        </BrowserRouter>
    </Provider>,
    rootElement
  );
} else {
  ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
          <MsalProvider instance={msalInstance}>
            <App />
          </MsalProvider>
        </BrowserRouter>
    </Provider>,
    rootElement
  );
}
