import React from "react";
import {
  Depths,
  Facepile,
  Link,
  OverflowButtonType,
  Panel,
  PanelType,
  PersonaSize,
  ScrollablePane,
  Stack,
} from "@fluentui/react";
import { MetaDataItem, MetaDataList } from "@m365-admin/metadata";
import { Collapsible } from "@m365-admin/collapsible";

import { IDriTeamData, ServiceAssetDetail } from "../../Types";
import { defaultStackTokens, panelScrollablePaneStyles } from "../../../pages/Page.styles";
import { DriGeoDataGrid } from "../../datagrids/DriGeoDataGrid";
import { M365Breadcrumb } from "@m365-admin/m365-breadcrumb";
import { convertStringBooleanToString } from "../../datagrids/Helpers";
import { GroupedAzureRegionDataGrid } from "components/datagrids/GroupedAzureRegionDataGrid";

export interface ServiceDetailGridProps {
  filteredServiceData: ServiceAssetDetail;
  isServiceDetailPanelOpen: boolean;
  dismissServiceDetailPanel: () => void;
}

export default function ServiceDetailGrid(props: ServiceDetailGridProps) {
  const [currentServiceData, setCurrentServiceData] = React.useState<ServiceAssetDetail>(null);
  const [currentDriGeoData, setCurrentDriGeoData] = React.useState<IDriTeamData[]>([]);
  React.useEffect(() => {
    let serviceData = props.filteredServiceData;
    if (serviceData) {
      setCurrentServiceData(serviceData);
      setCurrentDriGeoData(serviceData.driGeoTeamData ?? []);
    }
  }, [props.filteredServiceData]);

  return (
    <Panel
      type={PanelType.largeFixed}
      isLightDismiss
      isOpen={props.isServiceDetailPanelOpen}
      onDismiss={props.dismissServiceDetailPanel}
      headerText={`${currentServiceData?.serviceName}`}
      closeButtonAriaLabel="Close"
      style={{ boxShadow: Depths.depth64 }}
    >
      <Stack tokens={defaultStackTokens}>
        <M365Breadcrumb
          items={[
            {
              text: currentServiceData?.divisionName,
              key: currentServiceData?.divisionName,
            },
            {
              text: currentServiceData?.organizationName,
              key: currentServiceData?.organizationName,
            },
            {
              text: currentServiceData?.serviceGroupName,
              key: currentServiceData?.serviceGroupName,
            },
            {
              text: currentServiceData?.serviceId,
              key: currentServiceData?.serviceId,
              isCurrentItem: true,
            },
          ]}
        />
        <Link href={currentServiceData?.serviceTreeUrl}>See Service Tree Entry</Link>
        <MetaDataList verticalSpacing={8} styles={{ root: { paddingBottom: 16 } }}>
          <MetaDataItem
            header="RTO Criticality"
            body={currentServiceData?.rtoCriticalRating}
            links={[
              {
                href: `${
                  "https://global.azure.com/bcdr/assessment/" + currentServiceData?.serviceId
                }`,
                children: `See ${currentServiceData?.serviceName} BCDR assessment`,
              },
            ]}
          />
          <MetaDataItem
            header="PM Owners"
            body={
              <Facepile
                personaSize={PersonaSize.size24}
                personas={currentServiceData?.pmOwner?.split(";").map((user) => {
                  return {
                    personaName: user,
                    initialsColor: 3,
                  };
                })}
                overflowButtonType={OverflowButtonType.descriptive}
                ariaDescription="To move through the items use left and right arrow keys."
                ariaLabel={`List of Facepile personas representing service PM owners`}
              />
            }
          />
          <MetaDataItem header="Service Type" body={currentServiceData?.serviceType} />
          <MetaDataItem
            header="Dev Owners"
            body={
              <Facepile
                personaSize={PersonaSize.size24}
                personas={currentServiceData?.devOwner?.split(";").map((user) => {
                  return {
                    personaName: user,
                    initialsColor: 3,
                  };
                })}
                overflowButtonType={OverflowButtonType.descriptive}
                ariaDescription="To move through the items use left and right arrow keys."
                ariaLabel={`List of Facepile personas representing service dev owners`}
              />
            }
          />
          <MetaDataItem header="Lifecycle Status" body={currentServiceData?.maxLifecycle} />

          <MetaDataItem
            header="Service Classification"
            body={currentServiceData?.bcdrRecoveryClassification}
          />
        </MetaDataList>
        <Collapsible title="Resiliency Details">
          <MetaDataList>
            <MetaDataItem
              header="External Facing "
              body={`${convertStringBooleanToString(currentServiceData?.externalFacing)}`}
            />
            <MetaDataItem
              header="Contains Customer Data"
              body={`${convertStringBooleanToString(currentServiceData?.containsCustomerData)}`}
            />
            <MetaDataItem
              header="BCDR Resiliency Description"
              body={currentServiceData?.resiliencyDescription ?? "No resiliency description found."}
            />
          </MetaDataList>
        </Collapsible>
        <Collapsible
          title="Known Azure Region Locations (Prod & Non-Prod)"
          defaultIsExpanded={true}
        >
          <div>
            <GroupedAzureRegionDataGrid
              azureRegionData={currentServiceData?.azureRegionData ?? []}
            />
          </div>
        </Collapsible>
        <Collapsible title="DRI Team Geo Displacement Risk">
          <ScrollablePane styles={panelScrollablePaneStyles} data-is-scrollable="true">
            <DriGeoDataGrid
              currentDriGeoTeamData={currentDriGeoData.flatMap((x) =>
                x.geoResilienceTeamData.map((c) => {
                  return {
                    ...c,
                    teamId: x.teamId,
                    teamName: x.teamName,
                  };
                })
              )}
            />
          </ScrollablePane>
        </Collapsible>
      </Stack>
    </Panel>
  );
}
