import { IComboBoxOption, SelectableOptionMenuItemType } from "@fluentui/react";
import { AxiosError } from "axios";
import { createSelector } from "reselect";

import { ReduxFacilitiesState } from "../reducers/facilitiesReducer";
import { ReduxRegionsState } from "../reducers/regionsReducer";

const regions = (state: any): ReduxRegionsState => state.regions;
const regionsError = (state: any): string | AxiosError => state.regions.error;
const facilities = (state: any): ReduxFacilitiesState => state.facilities;

export const getMSRegions = createSelector(
  regions,
  (regions: ReduxRegionsState) => regions.physicalRegions
);

export const getMSRegionOptions = createSelector(
  regions,
  (regions: ReduxRegionsState): IComboBoxOption[] => {
    if (regions?.physicalRegions?.length > 0) {
      const regionOptions = regions.physicalRegions.map((region) => ({
        key: region.regionDescription,
        text: region.regionDescription,
      }));
      return [
        {
          key: "selectAll",
          text: "Select All",
          itemType: SelectableOptionMenuItemType.SelectAll,
        },
        ...regionOptions,
      ];
    } else {
      return [];
    }
  }
);

export const getMSCountryOptions = createSelector(
  regions,
  (regions: ReduxRegionsState): IComboBoxOption[] => {
    if (regions) {
      if (regions.selectedPhysicalRegions.length > 0) {
        return regions.selectedPhysicalRegions.flatMap((region) =>
          region.countries.map((country) => ({
            key: country,
            text: country,
          }))
        );
      } else {
        return regions.physicalRegions
          .filter((r) => r.regionDescription.toUpperCase() !== "PUGET SOUND")
          .sort((a, b) =>
            a.regionDescription.localeCompare(b.regionDescription)
          )
          .flatMap((r) => [
            {
              key: r.regionDescription,
              text: r.regionDescription,

              itemType: SelectableOptionMenuItemType.Header,
            },
            ...r.countries.map((country) => ({
              key: country,
              text: country,
            })).sort((a, b) => a.key.localeCompare(b.key)),
            {
              key: `${r.regionDescription}-divider`,
              text: "-",
              itemType: SelectableOptionMenuItemType.Divider,
            },
          ]);
      }
    }
    return [];
  }
);

export const getAzRegions = createSelector(
  regions,
  (regions: ReduxRegionsState) => regions.azureRegions
);

export const getMSRegionsSimple = createSelector(
  regions,
  (regions: ReduxRegionsState) =>
    regions.physicalRegions.map((r) => r.regionDescription)
);

export const getAzDCsInMSRegion = createSelector(
  regions,
  facilities,
  (regions: ReduxRegionsState) => {
    if (regions) {
      let filteredAZRegions = regions.azureRegions;
      const currentCountrySelections = regions.selectedCountries ?? [];
      if (currentCountrySelections?.length > 0) {
        filteredAZRegions = filteredAZRegions.filter((azr) =>
          currentCountrySelections.includes(azr.countryRegion)
        );
      } else {
        const allRegionalCountries = regions.countries;
        filteredAZRegions = filteredAZRegions.filter((azr) =>
          allRegionalCountries.includes(azr.countryRegion)
        );
      }

      return filteredAZRegions;
    }
    return [];
  }
);

export const getMSRegionsError = createSelector(
  regionsError,
  (regionsError: AxiosError): string => {
    if (regionsError) {
      return `Error loading regional options. ${regionsError}`;
    } else return null;
  }
);

export const getMSRegionsLoadingStatus = createSelector(
  regions,
  (regions: ReduxRegionsState) => regions.isLoading
);

export const getSelectedAzureRegions = createSelector(
  regions,
  (regions: ReduxRegionsState) => {
    if (regions) {
      return regions.selectedAzureRegions;
    }
    return [];
  }
);

export const getSelectedPhysicalCountries = createSelector(
  regions,
  (regions: ReduxRegionsState) => regions?.selectedCountries ?? []
);

export const getSelectedPhysicalRegions = createSelector(
  regions,
  (regions: ReduxRegionsState) => {
    if (regions) {
      return regions.selectedPhysicalRegions;
    }
    return [];
  }
);
