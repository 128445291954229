/* eslint-disable @typescript-eslint/no-explicit-any */
import { ComboBox, Dropdown, IComboBoxOption, IDropdownOption } from "@fluentui/react";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useAppDispatch } from "store/store";
import { _Styles } from "../../../pages/Page.styles";
import { updateUnappliedPeopleFilter, } from "../../../store/actions/peopleActions";
import { buildFilterEventCustomTelemetry } from "../../datagrids/Helpers";

export interface PeopleFilterProps {
  isPeopleDataLoading: boolean;
  peopleFilterData: PeopleFilterData;
  unappliedPeopleFilters: any;
}

export type PeopleFilterData = {
  peopleStateOptions: any;
  peopleStateFilters: any;
  peopleCityOptions: any;
  peopleCityFilters: any;
  peopleDivisionOptions: any;
  peopleDivFilters: any;
  peopleOrganizationOptions: any;
  peopleOrgFilters: any;
  peopleServiceGroupOptions: any;
  peopleServiceGroupFilters: any;
  peopleTeamGroupOptions: any;
  peopleTeamGroupFilters: any;
  peopleFacNameOptions: any;
  peopleFacNameFilters: any;
  peopleJobTitleSummaryDescOptions: any;
  peopleJobTitleSummaryDescFilters: any;
};

export const PeopleFilter = ({
  peopleFilterData: {
    peopleStateOptions,
    peopleStateFilters,
    peopleCityOptions,
    peopleCityFilters,
    peopleDivisionOptions,
    peopleDivFilters,
    peopleOrganizationOptions,
    peopleOrgFilters,
    peopleServiceGroupOptions,
    peopleServiceGroupFilters,
    peopleTeamGroupOptions,
    peopleTeamGroupFilters,
    peopleFacNameOptions,
    peopleFacNameFilters,
    peopleJobTitleSummaryDescOptions,
    peopleJobTitleSummaryDescFilters
  },
  unappliedPeopleFilters
}: PeopleFilterProps) => {

  const dispatch = useAppDispatch()
  const appInsights = useAppInsightsContext();
  const isStaging = process.env.REACT_APP_OCV_APP_ENV.toLocaleUpperCase() === "INT";
  const getCombinedFilter = (filter, filterName: string) => [...filter, ...unappliedPeopleFilters[filterName] ?? []];
  const getFilters = (filter, filterName: string, selected: boolean, key: string | number) => {
    const combinedFilters = getCombinedFilter(filter, filterName);
    return (selected
      ? [...combinedFilters.filter(f => f != key), `${key}`]
      : [...combinedFilters.filter(f => f != key)]);
  }

  return (
    <>
      <ComboBox
        className={_Styles.dropdownSmall}
        label="Select a state/province"
        options={peopleStateOptions}
        multiSelect={true}
        onChange={(_event, option?: IComboBoxOption): void => {
          if (option) {
            dispatch(updateUnappliedPeopleFilter({
              peopleStateFilters: getFilters(peopleStateFilters, 'peopleStateFilters', option.selected, option.key),
              peopleCityFilters: []
            }));
            appInsights.trackEvent({ name: "Filter Used" }, buildFilterEventCustomTelemetry("State - People", option.text));
          }
        }}
        selectedKey={unappliedPeopleFilters['peopleStateFilters']}
        defaultSelectedKey={peopleStateFilters}
      />
      <ComboBox
        className={_Styles.dropdownSmall}
        label="Select a city"
        options={peopleCityOptions}
        multiSelect={true}
        onChange={(_event: any, option?: IComboBoxOption): void => {
          if (option) {
            dispatch(updateUnappliedPeopleFilter({
              peopleCityFilters: getFilters(peopleCityFilters, 'peopleCityFilters', option.selected, option.key),
            }));
            appInsights.trackEvent({ name: "Filter Used" }, buildFilterEventCustomTelemetry("City - People", option.text));
          }
        }}
        selectedKey={unappliedPeopleFilters['peopleCityFilters']}
        defaultSelectedKey={peopleCityFilters}
      />
      <Dropdown
        styles={{
          dropdown: _Styles.dropdownDefault,
          dropdownItem: { padding: "5px" },
        }}
        multiSelect
        label="Filter by EVP"
        options={peopleDivisionOptions}
        onChange={(
          _event,
          option: IDropdownOption
        ): void => {
          if (option) {
            dispatch(updateUnappliedPeopleFilter({
              peopleDivFilters: getFilters(peopleDivFilters, 'peopleDivFilters', option.selected, option.key),
            }));
          }
        }}
        selectedKeys={getCombinedFilter(peopleDivFilters, 'peopleDivFilters')}
      />
      <Dropdown
        styles={{ dropdown: _Styles.dropdownDefault }}
        multiSelect
        label="Filter by Organization LT"
        options={peopleOrganizationOptions}
        onChange={(
          _event,
          option: IDropdownOption
        ): void => {
          if (option) {
            dispatch(updateUnappliedPeopleFilter({
              peopleOrgFilters: getFilters(peopleOrgFilters, 'peopleOrgFilters', option.selected, option.key),
            }));
          }
        }}
        selectedKeys={unappliedPeopleFilters['peopleOrgFilters']}
        defaultSelectedKeys={peopleOrgFilters}
      />
      <Dropdown
        styles={{ dropdown: _Styles.dropdownDefault }}
        multiSelect
        label="Filter by Service Group LT"
        options={peopleServiceGroupOptions}
        onChange={(
          _event,
          option: IDropdownOption
        ): void => {
          if (option) {
            dispatch(updateUnappliedPeopleFilter({
              peopleServiceGroupFilters: getFilters(peopleServiceGroupFilters, 'peopleServiceGroupFilters', option.selected, option.key),
            }));
          }
        }}
        selectedKeys={unappliedPeopleFilters['peopleServiceGroupFilters']}
        defaultSelectedKeys={peopleServiceGroupFilters}
      />
      <Dropdown
        styles={{ dropdown: _Styles.dropdownDefault }}
        multiSelect
        label="Filter by Team Group LT"
        options={peopleTeamGroupOptions}
        onChange={(
          _event,
          option: IDropdownOption
        ): void => {
          if (option) {
            dispatch(updateUnappliedPeopleFilter({
              peopleTeamGroupFilters: getFilters(peopleTeamGroupFilters, 'peopleTeamGroupFilters', option.selected, option.key),
            }));
          }
        }}
        selectedKeys={unappliedPeopleFilters['peopleTeamGroupFilters']}
        defaultSelectedKeys={peopleTeamGroupFilters}
      />
      <Dropdown
        styles={{ dropdown: _Styles.dropdownDefault }}
        multiSelect
        label="Filter by Job Title Summary Desc"
        options={peopleJobTitleSummaryDescOptions}
        onChange={(
          _event,
          option: IDropdownOption
        ): void => {
          if (option) {
            dispatch(updateUnappliedPeopleFilter({
              peopleJobTitleSummaryDescFilters: getFilters(peopleJobTitleSummaryDescFilters, 'peopleJobTitleSummaryDescFilters', option.selected, option.key),
            }));
          }
        }}
        selectedKeys={unappliedPeopleFilters['peopleJobTitleSummaryDescFilters']}
        defaultSelectedKeys={peopleJobTitleSummaryDescFilters}
      />
      {
        /*feature flight - user story 9568427*/
        isStaging && (
          <Dropdown
            styles={{ dropdown: _Styles.dropdownDefault }}
            multiSelect
            label="Filter by Facility Name"
            options={peopleFacNameOptions}
            onChange={(
              _event,
              option: IDropdownOption
            ): void => {
              if (option) {
                dispatch(updateUnappliedPeopleFilter({
                  peopleFacNameFilters: getFilters(peopleFacNameFilters, 'peopleFacNameFilters', option.selected, option.key),
                }));
              }
            }}
            selectedKeys={unappliedPeopleFilters['peopleFacNameFilters']}
            defaultSelectedKeys={peopleFacNameFilters}
          />
        )
      }
    </>
  );
};
