import {
  ActionButton,
  Checkbox,
  DefaultButton,
  ISelectableOption,
  Label,
  Panel,
  PrimaryButton,
  Separator,
  Stack,
} from "@fluentui/react";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import React from "react";
import { useSelector } from "react-redux";
import {
  updateFacilityClassificationFilter,
  updateFacilityGwsCriticalityFilter,
  updateFacilityTypeFilter,
} from "store/actions/facilitiesActions";
import {
  getFac03FacilityTypes,
  getFacilityClassifications,
  getFacilityGwsCriticalities,
  getSelectedFacilityClassifications,
  getSelectedFacilityGwsCriticalities,
  getSelectedFacilityTypes,
} from "store/selectors/facilitiesSelector";
import { useAppDispatch } from "store/store";

export interface FacilityFilterProps {
  isFacilityDataLoading: boolean;
  isPanelOpen: boolean;
  openPanel: () => void;
  dismissPanel: () => void;
}

export const FacilityFilter = (props: FacilityFilterProps) => {
  const dispatch = useAppDispatch();
  const appInsights = useAppInsightsContext();

  //GWS filter setup
  const facilityGwsFilterState = useSelector(getSelectedFacilityGwsCriticalities);
  const facilityGwsOptions = useSelector(getFacilityGwsCriticalities);
  const [facGwsOptions, setFacGwsOptions] = React.useState<ISelectableOption[]>([]);

  const [facGwsSelectedKeys, setFacGwsSelectedKeys] =
    React.useState<string[]>(facilityGwsFilterState);

  React.useEffect(() => {
    if (facilityGwsFilterState !== facGwsSelectedKeys) {
      setFacGwsSelectedKeys(facilityGwsFilterState);
    }
  }, [facilityGwsFilterState]);

  React.useEffect(() => {
    const typeOptions = facilityGwsOptions.map((type) => ({
      key: type,
      text: type,
    }));
    setFacGwsOptions(typeOptions);
  }, [facilityGwsOptions]);

  const gwsOnClearButtonClicked = () => {
    setFacGwsSelectedKeys([]);
  };

  const onFacilityGwsChange = React.useCallback(
    (ev, checked, facGws: ISelectableOption<any>) => {
      if (facGwsOptions[facGws.key]) facGwsOptions[facGws.key].selected = checked;
      if (checked === true) {
        setFacGwsSelectedKeys([...facGwsSelectedKeys, facGws.key as string]);
      } else {
        setFacGwsSelectedKeys([
          ...facGwsSelectedKeys.filter((filterKey) => filterKey !== facGws.key),
        ]);
      }
    },
    [facGwsOptions, facGwsSelectedKeys]
  );

  //facility type filter setup
  const facilityTypeFilterState = useSelector(getSelectedFacilityTypes);
  const facilityTypeOptions = useSelector(getFac03FacilityTypes);
  const [facTypeOptions, setFacTypeOptions] = React.useState<ISelectableOption[]>([]);
  const [facTypeSelectedKeys, setFacTypeSelectedKeys] =
    React.useState<string[]>(facilityTypeFilterState);

  React.useEffect(() => {
    if (facilityTypeFilterState !== facTypeSelectedKeys) {
      setFacTypeSelectedKeys(facilityTypeFilterState);
    }
  }, [facilityTypeFilterState]);

  React.useEffect(() => {
    const typeOptions = facilityTypeOptions.map((type) => ({
      key: type,
      text: `${type === "Data Center" ? "RE&F DCs" : type}`,
    }));
    setFacTypeOptions(typeOptions);
  }, [facilityTypeOptions]);

  const typeOnClearButtonClicked = () => {
    setFacTypeSelectedKeys([]);
  };

  const onFacilityTypeChange = React.useCallback(
    (ev, checked, facType: ISelectableOption<any>) => {
      if (facTypeOptions[facType.key]) facTypeOptions[facType.key].selected = checked;
      if (checked === true) {
        setFacTypeSelectedKeys([...facTypeSelectedKeys, facType.key as string]);
      } else {
        setFacTypeSelectedKeys([
          ...facTypeSelectedKeys.filter((filterKey) => filterKey !== facType.key),
        ]);
      }
    },
    [facTypeSelectedKeys, facTypeOptions]
  );

  // classification filter setup
  const classificationFilterState = useSelector(getSelectedFacilityClassifications);
  const classificationOptions = useSelector(getFacilityClassifications);
  const [classOptions, setClassOptions] = React.useState<ISelectableOption[]>([]);
  const [classSelectedKeys, setClassSelectedKeys] =
    React.useState<string[]>(classificationFilterState);

  React.useEffect(() => {
    if (classificationFilterState !== classSelectedKeys) {
      setClassSelectedKeys(classificationFilterState);
    }
  }, [classificationFilterState]);

  React.useEffect(() => {
    const options = classificationOptions
      .filter((classification) => classification !== null && classification !== undefined)
      .map((classification) => ({
        key: classification,
        text: classification,
      }));
    setClassOptions(options);
  }, [classificationOptions]);

  const classOnClearButtonClicked = () => {
    setClassSelectedKeys([]);
  };

  const onClassificationChange = React.useCallback(
    (ev, checked, classOption: ISelectableOption<any>) => {
      if (classOptions[classOption.key]) classOptions[classOption.key].selected = checked;
      if (checked === true) {
        setClassSelectedKeys([...classSelectedKeys, classOption.key as string]);
      } else {
        setClassSelectedKeys([
          ...classSelectedKeys.filter((filterKey) => filterKey !== classOption.key),
        ]);
      }
    },
    [classSelectedKeys, classOptions]
  );

  const onRenderFooterContent = React.useCallback(() => {
    const buttonStyles = { root: { marginRight: 8 } };

    const applyFacilityFilters = () => {
      const getCurrentFilterState = {
        "Gws criticality": [...facGwsSelectedKeys],
        "Division - Services": [...facTypeSelectedKeys],
        "CO+I DC Facility Class": [...classSelectedKeys],
      };

      appInsights.trackEvent({ name: "Filter Used" }, getCurrentFilterState);
      appInsights.trackMetric(
        {
          name: "Filter Used",
          average:
            facGwsSelectedKeys.length + facTypeSelectedKeys.length + classSelectedKeys.length,
        },
        { Source: "Facility Panel" }
      );
      dispatch(updateFacilityGwsCriticalityFilter(facGwsSelectedKeys));
      dispatch(updateFacilityTypeFilter(facTypeSelectedKeys));
      dispatch(updateFacilityClassificationFilter(classSelectedKeys));
    };

    const clearFacilityFilters = () => {
      setFacGwsSelectedKeys([]);
      dispatch(updateFacilityGwsCriticalityFilter([]));
      setFacTypeSelectedKeys([]);
      dispatch(updateFacilityTypeFilter([]));
      setClassSelectedKeys([]);
      dispatch(updateFacilityClassificationFilter([]));
    };

    return (
      <div>
        <Stack horizontal>
          <PrimaryButton
            onClick={() => {
              applyFacilityFilters();
              props.dismissPanel();
            }}
            styles={buttonStyles}
            text="Apply"
          />
          <DefaultButton
            text="Clear All"
            styles={buttonStyles}
            onClick={() => {
              clearFacilityFilters();
            }}
          />
        </Stack>
      </div>
    );
  }, [appInsights, dispatch, facGwsSelectedKeys, facTypeSelectedKeys, classSelectedKeys, props]);

  return (
    <Panel
      isLightDismiss
      isOpen={props.isPanelOpen}
      onDismiss={props.dismissPanel}
      hasCloseButton={true}
      headerText="Facility Filters"
      closeButtonAriaLabel="Close"
      aria-label="Additional Facility Filters Panel"
      onRenderFooterContent={onRenderFooterContent}
      isFooterAtBottom={true}
    >
      <>
        <Stack>
          <Label>GWS criticality</Label>
          {facGwsOptions.map((facGws) => (
            <Checkbox
              styles={{ root: { margin: 8 } }}
              title={facGws.text}
              id={facGws.text}
              label={facGws.text}
              checked={facGwsSelectedKeys.some((k) => k === facGws.key)}
              onChange={(ev, checked) => onFacilityGwsChange(ev, checked, facGws)}
            />
          ))}
          <ActionButton style={{ fontWeight: "bold" }} onClick={gwsOnClearButtonClicked}>
            Clear
          </ActionButton>
          <Separator />
          <Label id="facType">Facility type</Label>
          {facTypeOptions.map((facType) => (
            <Checkbox
              title={facType.text}
              styles={{ root: { margin: 8 } }}
              id={facType.text}
              label={facType.text}
              checked={facTypeSelectedKeys.some((k) => k === facType.key)}
              onChange={(ev, checked) => onFacilityTypeChange(ev, checked, facType)}
            />
          ))}
          <ActionButton style={{ fontWeight: "bold" }} onClick={typeOnClearButtonClicked}>
            Clear
          </ActionButton>
        </Stack>
        <Separator />
        <Label id="coiDcFacClass">CO+I DC Facility Class</Label>
        {classOptions.map((classification) => (
          <Checkbox
            title={classification.text}
            styles={{ root: { margin: 8 } }}
            id={classification.text}
            label={classification.text}
            checked={classSelectedKeys.some((k) => k === classification.key)}
            onChange={(ev, checked) => onClassificationChange(ev, checked, classification)}
          />
        ))}
        <ActionButton style={{ fontWeight: "bold" }} onClick={classOnClearButtonClicked}>
          Clear
        </ActionButton>
      </>
    </Panel>
  );
};
