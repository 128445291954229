import { CoherenceDataGrid, CoherenceDataGridColumn } from "@coherence-design-system/controls";
import {
  IColumnDragDropDetails,
  IColumnReorderOptions,
  Link,
  Selection,
  SelectionMode,
  Text,
} from "@fluentui/react";
import { ServiceAssetDetail } from "components/Types";
import { convertStringBooleanToString } from "components/datagrids/Helpers";
import React from "react";
import {
  getDriInsightsByRegionLoadingStatus,
  getServiceAssetDetailsLoadingStatus,
} from "store/selectors/servicesSelector";
import { useAppSelector } from "store/store";

export interface ServiceDataGridProps {
  filteredServiceData: ServiceAssetDetail[];
  openGridPanel: (selectedService: ServiceAssetDetail) => void;
}

export default function ServiceDataGrid({
  filteredServiceData,
  openGridPanel,
}: ServiceDataGridProps) {
  const getServiceAssetDetailsLoading = useAppSelector(getServiceAssetDetailsLoadingStatus);
  const getDriDataLoading = useAppSelector(getDriInsightsByRegionLoadingStatus);
  const currentSelection = React.useMemo(
    () =>
      new Selection({
        onSelectionChanged: () => {
          const selectedService = currentSelection
            .getSelection()
            .map((s) => s as ServiceAssetDetail)[0];
          openGridPanel(selectedService);
        },
      }),
    []
  );

  const [currentServiceData, setCurrentServiceData] = React.useState<ServiceAssetDetail[]>([]);

  React.useEffect(() => {
    const serviceData = filteredServiceData;
    setCurrentServiceData(serviceData);
  }, [filteredServiceData]);

  const buildDcsDetectedString = (x: ServiceAssetDetail): string => {
    if (x.azureRegionData?.length > 0) {
      return x.azureRegionData
        ?.map((y) => y.datacenterCampusName)
        .sort()
        .join(", ");
    }
    return "Unknown";
  };

  const fullColumns: CoherenceDataGridColumn<ServiceAssetDetail>[] = [
    {
      key: "serviceName",
      name: "Service Name",
      ariaLabel: "Service Name",
      fieldName: "serviceName",
      type: "text",
      minColumnWidth: 125,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      isMultiline: true,
      onRender: (x: ServiceAssetDetail) => (
        <Link aria-label={`Navigate to ${x.serviceName}`} href={x.serviceTreeUrl} target="_blank">
          {x.serviceName}
        </Link>
      ),
    },
    {
      key: "serviceType",
      name: "Service Type",
      ariaLabel: "Service Type",
      fieldName: "serviceType",
      type: "text",
      minColumnWidth: 100,
      maxWidth: 150,
      isResizable: true,
    },
    {
      key: "rtoCriticalRating",
      name: "Recovery Time Capability",
      ariaLabel: "Recovery Time Capability",
      fieldName: "rtoCriticalRating",
      type: "text",
      minColumnWidth: 150,
      maxWidth: 175,
      isResizable: true,
      onRender: (x: ServiceAssetDetail) => (
        <Link
          aria-label={`Navigate to ${x.serviceName} BCDR assessment`}
          href={"https://global.azure.com/bcdr/assessment/" + x.serviceId}
          target="_blank"
        >
          {x.rtoCriticalRating}
        </Link>
      ),
    },
    {
      key: "driGeoTeamData",
      name: "DRI Team Count",
      ariaLabel: "DRI Team Count",
      fieldName: "driGeoTeamData",
      type: "text",
      minColumnWidth: 125,
      maxWidth: 150,
      isResizable: true,
      onRender: (x: ServiceAssetDetail) => x.driGeoTeamData?.length ?? 0,
      sort: (a: ServiceAssetDetail, b: ServiceAssetDetail) =>
        b?.driGeoTeamData?.length - a?.driGeoTeamData?.length,
    },
    {
      key: "azureRegionDataDcsDetected",
      name: "DCs detected",
      fieldName: "azureRegionData",
      ariaLabel: "DCs detected",
      type: "text",
      minColumnWidth: 225,
      isMultiline: true,
      isResizable: true,
      onRender: (x: ServiceAssetDetail) => <Text>{buildDcsDetectedString(x)}</Text>,
      sort: (a: ServiceAssetDetail, b: ServiceAssetDetail) =>
        b?.azureRegionData?.length - a?.azureRegionData?.length,
      sortAscendingAriaLabel: "Sorted by list size ascending",
      sortDescendingAriaLabel: "Sorted by list size descending",
    },
    {
      key: "azureRegionDataSingleRegion",
      name: "Subs in Single Azure Region?",
      fieldName: "azureRegionData",
      ariaLabel: "Subs in Single Azure Region?",
      type: "text",
      minColumnWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onRender: (x: ServiceAssetDetail) => <Text>{x.serviceHasSingleAzureRegionSubs}</Text>,
      sort: (a: ServiceAssetDetail, b: ServiceAssetDetail) =>
        b.serviceHasSingleAzureRegionSubs.length - a.serviceHasSingleAzureRegionSubs.length,
      sortAscendingAriaLabel: "Sorted Z to A",
      sortDescendingAriaLabel: "Sorted A to Z",
    },
    {
      key: "maxLifecycle",
      name: "Service Lifecycle",
      ariaLabel: "Service Lifecycle",
      fieldName: "maxLifecycle",
      type: "text",
      minColumnWidth: 125,
      maxWidth: 150,
      isResizable: true,
    },
    {
      key: "bcdrRecoveryClassification",
      name: "Service Classification",
      ariaLabel: "Service Classification",
      fieldName: "bcdrRecoveryClassification",
      type: "text",
      minColumnWidth: 150,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: "externalFacing",
      name: "External Facing",
      ariaLabel: "External Facing",
      fieldName: "externalFacing",
      type: "text",
      minColumnWidth: 100,
      maxWidth: 125,
      isResizable: true,
      getValue: (x: ServiceAssetDetail) => `${convertStringBooleanToString(x.externalFacing)}`,
    },
  ];

  const [dataGridColumns, setDataGridColumns] =
    React.useState<CoherenceDataGridColumn<ServiceAssetDetail>[]>(fullColumns);

  const columnReorderOptions: IColumnReorderOptions = {
    frozenColumnCountFromStart: 1,
    onColumnDrop: (dragDropDetails: IColumnDragDropDetails) => {
      const tempColumns = [...dataGridColumns];
      tempColumns.splice(
        dragDropDetails.targetIndex,
        0,
        tempColumns.splice(dragDropDetails.draggedIndex, 1)[0]
      );
      setDataGridColumns(tempColumns);
    },
  };

  return (
    <CoherenceDataGrid
      isLoading={getServiceAssetDetailsLoading || getDriDataLoading}
      listConfig={dataGridColumns}
      data={currentServiceData}
      compactMode={"compact"}
      selectionMode={SelectionMode.single}
      selection={currentSelection}
      isScrollable={true}
      isSortable={true}
      columnReorderOptions={columnReorderOptions}
      ariaLabel={"Services by selected region and any applied filters."}
      getRowAriaLabel={(s: ServiceAssetDetail) => `${s.serviceName} details`}
    />
  );
}
