import { AxiosError } from "axios";
import { IDriGeoResilienceData } from "../../components/Types";
import {
  ServiceBcdrDetailedMetadata,
  ServiceBcdrMetadataQueryResponse,
  ServiceDepAggCount,
  ServiceTreeObj,
} from "../../types/DependencyMappingTypes";
import {
  FETCH_SERVICE_DEPENDENCY_DRI_DATA_BEGIN,
  FETCH_SERVICE_DEPENDENCY_DRI_DATA_FAILURE,
  FETCH_SERVICE_DEPENDENCY_DRI_DATA_SUCCESS,
  FETCH_SERVICE_DEPENDENCY_ITEM_DETAIL_BEGIN,
  FETCH_SERVICE_DEPENDENCY_ITEM_DETAIL_FAILURE,
  FETCH_SERVICE_DEPENDENCY_ITEM_DETAIL_SUCCESS,
  FETCH_SERVICE_DEPENDENCY_LINKS_BEGIN,
  FETCH_SERVICE_DEPENDENCY_LINKS_FAILURE,
  FETCH_SERVICE_DEPENDENCY_LINKS_SUCCESS,
  FETCH_SERVICE_DEPENDENCY_OPTIONS_BEGIN,
  FETCH_SERVICE_DEPENDENCY_OPTIONS_FAILURE,
  FETCH_SERVICE_DEPENDENCY_OPTIONS_SUCCESS,
  FETCH_SERVICE_DEPENDENCY_PANEL_ITEM_DETAIL_BEGIN,
  FETCH_SERVICE_DEPENDENCY_PANEL_ITEM_DETAIL_FAILURE,
  FETCH_SERVICE_DEPENDENCY_PANEL_ITEM_DETAIL_SUCCESS,
  CLEAR_SERVICE_DEPENDENCY_PANEL_ITEM_DETAIL,
  FETCH_SERVICE_DEPENDENCY_PANEL_DRI_DATA_BEGIN,
  FETCH_SERVICE_DEPENDENCY_PANEL_DRI_DATA_FAILURE,
  FETCH_SERVICE_DEPENDENCY_PANEL_DRI_DATA_SUCCESS,
  CLEAR_SERVICE_DEPENDENCY_PANEL_DRI_DATA,
  FETCH_SERVICE_DEP_COUNTS_BEGIN,
  FETCH_SERVICE_DEP_COUNTS_FAILURE,
  FETCH_SERVICE_DEP_COUNTS_SUCCESS,
} from "../actions/dependencyMappingActionTypes";
import { Link } from "../../components/charts/dependencyGraph/ServiceDependencyGraph.types";

export interface ReduxServiceDependencyMappingState {
  serviceTreeOptions: ServiceTreeObj[];
  serviceTreeOptionsLoadError: Error | AxiosError | string;
  serviceTreeOptionsLoading: boolean;

  primaryActiveServiceData: ServiceBcdrDetailedMetadata;
  primaryActiveServiceDataLoading: boolean;
  primaryActiveServiceDataError: Error | AxiosError | string;

  primaryServiceDependencyLinks: Link[];
  primaryServiceDependencyLinksError: Error | AxiosError | string;
  primaryServiceDependencyLinksLoading: boolean;

  primaryActiveServiceDriTeamData: IDriGeoResilienceData;
  primaryActiveServiceDriTeamDataLoading: boolean;
  primaryActiveServiceDriTeamDataError: Error | AxiosError | string;

  secondaryPanelServiceData: ServiceBcdrDetailedMetadata;
  secondaryPanelServiceDataLoading: boolean;
  secondaryPanelServiceDataError: Error | AxiosError | string;

  secondaryPanelServiceDriTeamData: IDriGeoResilienceData;
  secondaryPanelServiceDriTeamDataLoading: boolean;
  secondaryPanelServiceDriTeamDataError: Error | AxiosError | string;

  serviceDepAggCounts: ServiceDepAggCount;
  isServiceDepAggCountsLoading: boolean;
  serviceDepAggCountsError: Error | AxiosError | string;
}

const initialServiceDependencyMappingState: ReduxServiceDependencyMappingState =
  {
    serviceTreeOptions: [],
    serviceTreeOptionsLoadError: null,
    serviceTreeOptionsLoading: false,

    primaryActiveServiceData: null,
    primaryActiveServiceDataLoading: false,
    primaryActiveServiceDataError: null,

    primaryActiveServiceDriTeamData: null,
    primaryActiveServiceDriTeamDataError: null,
    primaryActiveServiceDriTeamDataLoading: false,

    primaryServiceDependencyLinks: [],
    primaryServiceDependencyLinksError: null,
    primaryServiceDependencyLinksLoading: false,

    secondaryPanelServiceData: null,
    secondaryPanelServiceDataLoading: false,
    secondaryPanelServiceDataError: null,

    secondaryPanelServiceDriTeamData: null,
    secondaryPanelServiceDriTeamDataLoading: false,
    secondaryPanelServiceDriTeamDataError: null,

    serviceDepAggCounts: null,
    isServiceDepAggCountsLoading: false,
    serviceDepAggCountsError: null,
  };

const mapMetadataRetrivalErrorsToStrings = (
  serviceDependencyResponse: ServiceBcdrMetadataQueryResponse
) => {
  if (
    serviceDependencyResponse.guidNotFound.length > 0 ||
    serviceDependencyResponse.serviceNameNotFound.length > 0
  ) {
    let errorString = "";
    if (serviceDependencyResponse.guidNotFound.length > 0) {
      errorString += `Data not found for ${serviceDependencyResponse.guidNotFound.join(
        ","
      )}\n`;
    }
    if (serviceDependencyResponse.serviceNameNotFound.length > 0) {
      errorString += `Data not found for ${serviceDependencyResponse.serviceNameNotFound.join(
        ","
      )}\n`;
    }
    return errorString;
  } else return null;
};

export default function serviceDependencyMapping(
  state: ReduxServiceDependencyMappingState = initialServiceDependencyMappingState,
  action: any
) {
  switch (action.type) {
    case FETCH_SERVICE_DEPENDENCY_OPTIONS_BEGIN: {
      return {
        ...state,
        serviceTreeOptionsLoadError: null,
        serviceTreeOptionsLoading: true,
      };
    }
    case FETCH_SERVICE_DEPENDENCY_OPTIONS_FAILURE: {
      return {
        ...state,
        serviceTreeOptionsLoadError:
          (action.payload as Error).message ??
          "Error loading Service Dependency Options",
        serviceTreeOptionsLoading: false,
      };
    }
    case FETCH_SERVICE_DEPENDENCY_OPTIONS_SUCCESS: {
      return {
        ...state,
        serviceTreeOptions: action.payload,
        serviceTreeOptionsLoading: false,
        serviceTreeOptionsLoadError: null,
      };
    }
    case FETCH_SERVICE_DEPENDENCY_ITEM_DETAIL_BEGIN:
      return {
        ...state,
        primaryActiveServiceDataLoading: true,
        primaryActiveServiceDataError: null,
      };
    case FETCH_SERVICE_DEPENDENCY_ITEM_DETAIL_FAILURE:
      return {
        ...state,
        primaryActiveServiceDataLoading: false,
        primaryActiveServiceDataError:
          (action.payload as Error).message ?? "Error loading service details",
      };
    case FETCH_SERVICE_DEPENDENCY_ITEM_DETAIL_SUCCESS:
      return {
        ...state,
        primaryActiveServiceData:
          action.payload.enhancedServiceDataDTO[0] ?? undefined,
        primaryActiveServiceDataLoading: false,
        primaryActiveServiceDataError: mapMetadataRetrivalErrorsToStrings(
          action.payload
        ),
      };
    case FETCH_SERVICE_DEPENDENCY_DRI_DATA_BEGIN:
      return {
        ...state,
        primaryActiveServiceDriTeamDataLoading: true,
        primaryActiveServiceDriTeamData: [],
      };
    case FETCH_SERVICE_DEPENDENCY_DRI_DATA_SUCCESS:
      return {
        ...state,
        primaryActiveServiceDriTeamDataLoading: false,
        primaryActiveServiceDriTeamData: action.payload,
      };
    case FETCH_SERVICE_DEPENDENCY_DRI_DATA_FAILURE:
      return {
        ...state,
        primaryActiveServiceDriTeamDataLoading: false,
        primaryActiveServiceDriTeamDataError:
          (action.payload as Error).message ?? "Error loading dri team details",
        primaryActiveServiceDriTeamData: [],
      };
    case FETCH_SERVICE_DEPENDENCY_PANEL_ITEM_DETAIL_BEGIN:
      return {
        ...state,
        secondaryPanelServiceDataLoading: true,
        secondaryPanelServiceDataError: null,
      };
    case FETCH_SERVICE_DEPENDENCY_PANEL_ITEM_DETAIL_FAILURE:
      return {
        ...state,
        secondaryPanelServiceDataLoading: false,
        secondaryPanelServiceDataError:
          (action.payload as Error).message ?? "Error loading service details",
      };
    case FETCH_SERVICE_DEPENDENCY_PANEL_ITEM_DETAIL_SUCCESS:
      return {
        ...state,
        secondaryPanelServiceData:
          action.payload.enhancedServiceDataDTO[0] ?? undefined,
        secondaryPanelServiceDataLoading: false,
        secondaryPanelServiceDataError: mapMetadataRetrivalErrorsToStrings(
          action.payload
        ),
      };
    case CLEAR_SERVICE_DEPENDENCY_PANEL_ITEM_DETAIL:
      return {
        ...state,
        secondaryPanelServiceData: null,
        secondaryPanelServiceDataLoading: false,
        secondaryPanelServiceDataError: null,
      };
    case FETCH_SERVICE_DEPENDENCY_PANEL_DRI_DATA_BEGIN:
      return {
        ...state,
        secondaryPanelServiceDriTeamDataLoading: true,
        secondaryPanelServiceDriTeamData: [],
      };
    case FETCH_SERVICE_DEPENDENCY_PANEL_DRI_DATA_SUCCESS:
      return {
        ...state,
        secondaryPanelServiceDriTeamDataLoading: false,
        secondaryPanelServiceDriTeamData: action.payload,
      };
    case FETCH_SERVICE_DEPENDENCY_PANEL_DRI_DATA_FAILURE:
      return {
        ...state,
        secondaryPanelServiceDriTeamDataLoading: false,
        secondaryPanelServiceDriTeamDataError:
          (action.payload as Error).message ?? "Error loading dri team details",
        secondaryPanelServiceDriTeamData: [],
      };
    case CLEAR_SERVICE_DEPENDENCY_PANEL_DRI_DATA:
      return {
        ...state,
        secondaryPanelServiceDriTeamData: [],
        secondaryPanelServiceDriTeamDataLoading: false,
        secondaryPanelServiceDriTeamDataError: null,
      };
    case FETCH_SERVICE_DEPENDENCY_LINKS_BEGIN:
      return {
        ...state,
        primaryServiceDependencyLinksLoading: true,
        primaryServiceDependencyLinksError: null,
      };
    case FETCH_SERVICE_DEPENDENCY_LINKS_FAILURE:
      return {
        ...state,
        primaryServiceDependencyLinksLoading: false,
        primaryServiceDependencyLinksError: action.payload,
      };
    case FETCH_SERVICE_DEPENDENCY_LINKS_SUCCESS:
      return {
        ...state,
        primaryServiceDependencyLinks: action.payload,
        primaryServiceDependencyLinksLoading: false,
        primaryServiceDependencyLinksError: null,
      };
    case FETCH_SERVICE_DEP_COUNTS_BEGIN:
      return {
        ...state,
        isServiceDepCountsLoading: true,
        error: null,
      };
    case FETCH_SERVICE_DEP_COUNTS_SUCCESS:
      return {
        ...state,
        isServiceDepCountsLoading: false,
        serviceDepAggCounts: action.payload,
        error: null,
      };
    case FETCH_SERVICE_DEP_COUNTS_FAILURE:
      return {
        ...state,
        isServiceDepCountsLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
