/* eslint-disable react/no-unescaped-entities */
import { Link, mergeStyleSets } from "@fluentui/react";
import {
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  SelectionMode,
} from "@fluentui/react/lib/DetailsList";
import facilitiesDataSource from "../../assets/facilitiesDataSource.png";
import facilityDataFlow from "../../assets/facilityDataFlow.png";
import peopleDataFlow from "../../assets/peopleDataFlow.png";
import peopleDataSource from "../../assets/peopleDataSource.png";
import processDataFlow from "../../assets/processDataFlow.png";
import processesDataSource from "../../assets/processesDataSource.png";
import serviceDataFlow from "../../assets/serviceDataFlow.png";
import servicesDataSource from "../../assets/servicesDataSource.png";

const helpStyles = mergeStyleSets({
  List: {
    listStyle: "none",
    paddingLeft: "0",
  },
  ListItem: {
    marginBottom: "1rem",
  },
  Link: {
    display: "inline",
  },
  DetailsListTable: {
    maxWidth: "80vw",
    margin: "auto",
  },
});

const peopleItems = [
  {
    term: "Building Assigned",
    definition: "Active employees (internal and external) that are active assigned to a building",
  },
  {
    term: "Mobile, Home Office, No workspace",
    definition:
      "Active employees (internal and external) that are active and not guaranteed to be assigned to a location",
  },
  {
    term: "Internal Active",
    definition:
      "Active employees that are not on leave of absence or pending start and are classified as regular staff",
  },
  {
    term: "External Active",
    definition:
      "Active employees that are not on leave of absence or pending start and are classified as contingent staff",
  },
  {
    term: "Other Active",
    definition: "Active employees that are on leave of absence or pending start",
  },
  {
    term: "Unknown Active",
    definition:
      "Active employees, either internal or external, that have no hierarchy mapping available.",
  },
  {
    term: "Contingent staff",
    definition:
      "A sub category of HR classifications that includes vendors, interns, temps, and other such staff types. See the HR Contingent staff link for complete list.",
  },
  {
    term: "Badge Ins (180 day avg)",
    definition: "The rolling average number of badge ins within the last 180 days",
  },
];

const facilitiesItems = [
  {
    term: "Allocated",
    definition: "Buildings that are known to have active employees assigned to that location",
  },
  {
    term: "Unallocated",
    definition: "Buildings that are known to not have active employees assigned to that location",
  },
  {
    term: "Essential Employees",
    definition:
      "Employees deemed critical to business operations that would require access or be required to be onsite during a crisis",
  },
  {
    term: "Active Employees",
    definition: "Employees not on leave or pending start",
  },
  {
    term: "Mobile",
    definition:
      "A generic count (without org hierarchy) of the number of employees that are assigned as mobile and have a building noted as their secondary location type",
  },
  {
    term: "FTE",
    definition: "Employees classified as regular type employees per HR",
  },
  {
    term: "Contingent",
    definition: "Employees classified as other type employees per HR",
  },
  {
    term: "Facility Type",
    definition:
      "The facility building classification as assigned by the team managing FAC03/MS Space",
  },
];

const coiDcClassItems = [
  {
    class: "Class A",
    facilityType: "Datacenter",
    description: "Microsoft designs, builds, owns, and fully controls the facility.",
    assetClassSupported: "HVA, MVA, LVA",
    dataClassSupported: "Highly Confidential (HBI), Confidential (MBI), General (LBI)",
    staffing: "Microsoft staff on-site",
    propertyOwner: "Microsoft",
  },
  {
    class: "Class B+",
    facilityType: "Datacenter",
    description:
      "Microsoft leases the site and is sole tenant; manages security and manages critical environments.",
    assetClassSupported: "HVA, MVA, LVA",
    dataClassSupported: "Highly Confidential (HBI), Confidential (MBI), General (LBI)",
    staffing: "Microsoft staff on-site",
    propertyOwner: "Landlord",
  },
  {
    class: "Class B",
    facilityType: "Datacenter",
    description:
      "Microsoft leases the site and is sole tenant; manages security and does not manage critical environments.",
    assetClassSupported: "HVA, MVA, LVA",
    dataClassSupported: "Highly Confidential (HBI), Confidential (MBI), General (LBI)",
    staffing: "Microsoft staff on-site",
    propertyOwner: "Landlord",
  },
  {
    class: "Class C",
    facilityType: "Datacenter",
    description:
      "Microsoft leases colocation space for hosting server assets or, in some unique cases, network assets only based on specific Engineer Group (EG) compliance requirements; is not the sole tenant at the site but has on-site staff for management and operations. Landlord or Microsoft may manage security and critical environments.",
    assetClassSupported: "HVA, MVA, LVA",
    dataClassSupported: "Highly Confidential (HBI), Confidential (MBI), General (LBI)",
    staffing: "Microsoft and/or non-Microsoft staff",
    propertyOwner: "Landlord",
  },
  {
    class: "Class D",
    facilityType: "Datacenter",
    description:
      "Microsoft leases colocation space; is not the sole tenant at the site. Landlord manages security and critical environments as well as some or all operations. Microsoft reviews performance of security and operations.",
    assetClassSupported: "HVA, MVA, LVA",
    dataClassSupported: "Highly Confidential (HBI), Confidential (MBI), General (LBI)",
    staffing: "Non-Microsoft staff only",
    propertyOwner: "Landlord",
  },
  {
    class: "Class E",
    facilityType: "Network",
    description:
      "Microsoft leases colocation space; is not the sole tenant at the site. Landlord manages security and critical environments as well as operations. Microsoft may review effectiveness of landlord’s security controls but primarily relies on contractual obligations.",
    assetClassSupported:
      "No HVA allowed, approved services only (determined by Logical Security Review)",
    dataClassSupported: "Confidential (MBI), General (LBI); no data may be decrypted on site",
    staffing: "Non-Microsoft staff only",
    propertyOwner: "Landlord",
  },
  {
    class: "Class F",
    facilityType: "Network, Off-Network",
    description:
      "Microsoft leases rack space in third-party environments. Landlord may have security provisions in place but does not have obligations to Microsoft.",
    assetClassSupported:
      "No HVA or MVA allowed, approved services only (determined by Logical Security Review)",
    dataClassSupported: "General (LBI); no data may be decrypted on site",
    staffing: "Non-Microsoft staff, or unstaffed",
    propertyOwner: "Landlord",
  },
  {
    class: "Class G",
    facilityType: "ILA (In-line Amplifier)",
    description:
      "In-Line Amplification. Connects an RNG or GW from one region to RNG or GW in another region. Amplification is required every 80 to 100 km to boost signal power and extend the transmission distance.",
    assetClassSupported:
      "No HVA or MVA allowed, approved services only (determined by Logical Security Review), specialized equipment only",
    dataClassSupported: "General (LBI); no data may be decrypted on site",
    staffing: "Non-Microsoft staff, or unstaffed",
    propertyOwner: "Landlord",
  },
  {
    class: "Class H",
    facilityType: "CLS (Cable-Landing Station)",
    description: "This is the termination point for Subsea cables.",
    assetClassSupported:
      "No HVA or MVA allowed, approved services only (determined by Logical Security Review), specialized equipment only",
    dataClassSupported: "General (LBI); no data may be decrypted on site",
    staffing: "Non-Microsoft staff, or unstaffed",
    propertyOwner: "Landlord",
  },
];

const serviceItems = [
  {
    term: "Availability Zone Enabled",
    definition: "User attested data on whether their service has availability zone redundancy",
  },
  {
    term: "External Facing",
    definition: "User attested data on whether their service has external customers",
  },
  {
    term: "Contains personal data",
    definition: "User attested data on if the service contains personal data",
  },
  {
    term: "Associated regional data centers",
    definition:
      "The data centers that are associated with a given azure region. This is often a 1:many relationship of azure region to data centers. It is sourced using Azure graph data on a given service tree ID against known ARM resources.",
  },
  {
    term: "Isolated",
    definition: "Services that only have resources in one azure region",
  },
  {
    term: "Service Classification",
    definition: "The BCDR service classification as defined in BCDR scorecard",
  },
  {
    term: "RTO Criticality",
    definition:
      "The recovery time objective classification as calculated by EGRC that reflects the time allowed to recover a process in the event of a crisis",
  },
  {
    term: "Mission Critical",
    definition:
      "Services that must recover in 0-4 hours, considered the most important to keep running",
  },
  {
    term: "Critical",
    definition: "Services that must recover in 5-72 hours",
  },
  {
    term: "Important",
    definition: "Services that must recover in 73-168 hours",
  },
  {
    term: "Deferrable",
    definition:
      "Services that can be recovered in more than 168 hours, considered the least important to keep running",
  },
  {
    term: "Unknown",
    definition:
      "Services that don’t have an identified RTO Criticality rating but are believed to be active",
  },
];

const DRIItems = [
  {
    term: "Team",
    definition: "The ICM team name.",
  },
  {
    term: "Efficiency",
    definition:
      "The analysis score based on pre-defined risk factors including team capacity and status. A low score implies low team efficiency and a higher chance of being negatively impacted.",
  },
  {
    term: "Projection",
    definition:
      "The projected impact risk. High impact means a high chance of being negatively impacted by a crisis.",
  },
  {
    term: "No Impact",
    definition: "100% of planned DRI capacity available. No interruption to service expected.",
  },
  {
    term: "Low Impact",
    definition: "70-99% of planned DRI capacity available. Risk of service interruption is low.",
  },
  {
    term: "Medium Impact",
    definition: "50-69% of planned DRI capacity available. Risk of service interruption is Medium.",
  },
  {
    term: "High Impact",
    definition:
      "Less than 50% of planned DRI capacity available. Risk of service interruption is High.",
  },
];

const processItems = [
  {
    term: "RTO Criticality",
    definition:
      "The recovery time classification as calculated by EGRC that reflects the time allowed to recover a process in the event of a crisis",
  },
  {
    term: "Mission Critical",
    definition:
      "Processes that must recover in 0-4 hours, considered the most important to keep running",
  },
  {
    term: "Critical",
    definition: "Processes that must recover in 5-72 hours",
  },
  {
    term: "Important",
    definition: "Processes that must recover in 73-168 hours",
  },
  {
    term: "Deferrable",
    definition:
      "Processes that can be recovered in more than 168 hours, considered the least important to keep running",
  },
];

export const bcdrRecoveryTerms = [
  {
    term: "Approved RTO",
    definition:
      "The Approved Recovery Time Objective, or the time after which loss of this service would become a critical impact (internal or external) to users who depend on this service",
  },
  {
    term: "Approved RTC",
    definition:
      "The Approved Recovery Time Capability, or the time it is expected to take to transfer the service from one location to another and to get the service operating again.",
  },
  {
    term: "Approved RPO",
    definition:
      "The Approved Recovery Point Objective, or the maximum acceptable (and achievable) data loss recovery point for this service.",
  },
  {
    term: "Approved RPC",
    definition:
      "The Approved Recovery Point Capability, or the amount of data lost (if any) during the recovery.",
  },
  {
    term: "In Canary Central/East",
    definition: "Is the service deployed to either of the noted canary regions?",
  },
];

export const serviceDependencyTerms = [
  {
    term: "Dependency",
    definition: "A service that depends on another service.",
  },
  {
    term: "Dependency Level",
    definition:
      "The level of the dependency relationship between services (i.e. first level dependencies are the immediate services up and down from the current service).",
  },
  {
    term: "Circular Dependency",
    definition:
      "Two services that depend on each other to function. Currently Watchtower only identifies basic circular dependencies. Later versions will identify transitive circular dependencies.",
  },
  {
    term: "Downstream Dependency",
    definition: "A service that relies on the current service to function.",
  },
  {
    term: "Downstream Recovery Gap",
    definition:
      "The current service's Recovery Time Capability (RTC) exceeds a downstream service's Desired Recovery Time.",
  },
  {
    term: "Upstream Dependency",
    definition: "A service that the current service depends on to function.",
  },
  {
    term: "Upstream Recovery Gap",
    definition:
      "The current service has an upstream dependency where the upstream service's RTC exceeds the expected RTO of the current service.",
  },
];

const columns: IColumn[] = [
  {
    key: "term",
    name: "Term",
    fieldName: "term",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: "definition",
    name: "Definition",
    fieldName: "definition",
    minWidth: 100,
    maxWidth: 300,
    isResizable: true,
    isMultiline: true,
  },
];

const coiDcColumns: IColumn[] = [
  {
    key: "class",
    name: "Class",
    fieldName: "class",
    minWidth: 50,
    maxWidth: 100,
    isResizable: true,
  },
  {
    key: "facilityType",
    name: "Facility Type",
    fieldName: "facilityType",
    minWidth: 50,
    maxWidth: 150,
    isResizable: true,
    isMultiline: true,
  },
  {
    key: "description",
    name: "Description",
    fieldName: "description",
    minWidth: 100,
    maxWidth: 400,
    isResizable: true,
    isMultiline: true,
  },
  {
    key: "assetClassSupported",
    name: "Asset Class Supported",
    fieldName: "assetClassSupported",
    minWidth: 50,
    maxWidth: 200,
    isResizable: true,
    isMultiline: true,
  },
  {
    key: "dataClassSupported",
    name: "Data Class Supported",
    fieldName: "dataClassSupported",
    minWidth: 50,
    maxWidth: 200,
    isResizable: true,
    isMultiline: true,
  },
  {
    key: "staffing",
    name: "Staffing",
    fieldName: "facilityType",
    minWidth: 50,
    maxWidth: 150,
    isResizable: true,
    isMultiline: true,
  },
  {
    key: "propertyOwner",
    name: "Property Owner",
    fieldName: "propertyOwner",
    minWidth: 100,
    maxWidth: 150,
    isResizable: true,
    isMultiline: true,
  },
];

export const makePivotContents = (
  handleNavItemClick: (source: string) => void,
  handleNavGroupClick: (source: string) => void
) => {
  const pivotContents = {
    people: [
      {
        itemKey: "people",
        groupTitle: "People",
        groupedItems: [
          {
            itemKey: "overview",
            navItemText: "Overview",
            content: (
              <>
                <div>
                  <h2>Overview</h2>
                  <p>
                    The people dashboard serves as a crisis management view of the people working in
                    a selected region (and country). It is built using SIP Shield facility & HR
                    data. The SIP Shield team filters employees based on their active and job
                    classification types.{" "}
                  </p>
                  <p>
                    The data is broken down from the total number of active employees into two
                    categories: those assigned to a building and those designated as mobile, home
                    office, or no workspace in the HR data lake. Building assigned can be further
                    broken down into internal active and external active. Refer to the definition
                    table and HR's staff definitions for more details.{" "}
                  </p>
                  <p>
                    {" "}
                    Currently, building assigned employees can be broken down by org hierarchy to
                    provide insight into the leadership (L1-L4) that have employees known to be in a
                    region. Future state will include an accounting for the people known to be
                    mobile, home office, or no workspace by a given region.
                  </p>
                </div>
              </>
            ),
            onItemClick: () => handleNavItemClick("people-overview"),
          },
          {
            itemKey: "definitions",
            navItemText: "Definitions",
            content: (
              <>
                <h2>Definitions</h2>
                <DetailsList
                  items={peopleItems}
                  columns={columns}
                  setKey="set"
                  layoutMode={DetailsListLayoutMode.justified}
                  selectionMode={SelectionMode.none}
                  className={helpStyles.DetailsListTable}
                />
              </>
            ),
            onItemClick: () => handleNavItemClick("people-definitions"),
          },
          {
            itemKey: "dataFlowDiagram",
            navItemText: "Data Flow Diagram",
            content: (
              <>
                <h2>Data Flow Diagram</h2>
                <img height="50%" width="90%" src={peopleDataFlow} alt="people data flow" />
                <h2>Data Sources</h2>
                <img
                  height="10%"
                  width="100%"
                  src={peopleDataSource}
                  alt="people data source, refreshes once a day with a resiliency of four hours."
                />
              </>
            ),
            onItemClick: () => handleNavItemClick("people-dataFlowDiagram"),
          },
          {
            itemKey: "referenceLinks",
            navItemText: "Reference Links",
            content: (
              <>
                <h2>Reference Links</h2>
                <ul className={helpStyles.List}>
                  <li className={helpStyles.ListItem}>
                    <h3 className={helpStyles.Link}>HR Contingent Staff Definitions: </h3>
                    <Link
                      className={helpStyles.Link}
                      href="https://microsoft.sharepoint.com/teams/HRInsightsCatalog/HRIC%20Data%20Dictionary/Contingent%20Staff.aspx?xsdata=MDV8MDF8fDFmOWE3YjY1YTVmOTRlNjUxNjljMDhkYTE4Yjg4MjZmfDcyZjk4OGJmODZmMTQxYWY5MWFiMmQ3Y2QwMTFkYjQ3fDB8MHw2Mzc4NDk0Nzc5Njc3MTM5NDh8R29vZHxWR1ZoYlhOVFpXTjFjbWwwZVZObGNuWnBZMlY4ZXlKV0lqb2lNQzR3TGpBd01EQWlMQ0pRSWpvaVYybHVNeklpTENKQlRpSTZJazkwYUdWeUlpd2lWMVFpT2pFeGZRPT18MXxNVGs2TXpabU1XTTROREkyWkdNME5EaGtPV0V4TldVeE5HTmhObUUzWm1aak1HRkFkR2h5WldGa0xuWXl8fA%3D%3D&sdata=eFhaV2VCdGZYZ1kvQlUvMWhaNXN6akQxSmhwbjVtRXVGL0QrZEw1UGpRQT0%3D&ovuser=72f988bf-86f1-41af-91ab-2d7cd011db47%2Ckawalton%40microsoft.com"
                      title="HR Insights Catalog - Contingent Staff"
                      target="_blank"
                    >
                      HR Insights Catalog - Contingent Staff
                    </Link>
                  </li>
                  <li>
                    <h3 className={helpStyles.Link}>HR Staffing Type Categories: </h3>
                    <Link
                      className={helpStyles.Link}
                      href="https://microsoft.sharepoint.com/teams/HRInsightsCatalog/HRIC%20Data%20Dictionary/Staffing%20Type%20Categories.aspx"
                      title="HR Insights Catalog - Staffing Type Categories"
                      target="_blank"
                    >
                      HR Insights Catalog - Staffing Type Categories
                    </Link>
                  </li>
                </ul>
              </>
            ),
            onItemClick: () => handleNavItemClick("people-referenceLinks"),
          },
        ],
        onGroupClick: () => handleNavGroupClick("people-tab"),
      },
    ],
    facilities: [
      {
        itemKey: "facilities",
        groupTitle: "Facilities",
        groupedItems: [
          {
            itemKey: "overview",
            navItemText: "Overview",
            content: (
              <>
                <div>
                  <h2>Overview</h2>
                  <p>
                    The facility dashboard serves as a crisis management view of facilities in a
                    selected region (and country). It is built using SIP Shield facility data. The
                    true source is FAC03 and SIP enriches this data with Global Workforce Security
                    data as well as their own HR data set to enable average badge in counts,
                    building classification, buildings with known essential employees, and workforce
                    allocation by building.{" "}
                  </p>
                  <p>
                    The dataset has been filtered to exclude cafeterias, parking lots, and retail
                    per the original requirements. This is subject to change and this page will be
                    updated accordingly if/when it does.{" "}
                  </p>
                  <p>
                    This data is also filtered based on user access level. Data centers will be
                    removed from the map and their city information will be marked as 'Restricted'
                    if users do not fall into the correct access category or if the data is deemed
                    too sensitive to share by leadership. Additionally, headcount data for these
                    centers may also be removed due to security requirements.
                  </p>
                  <p>
                    For questions on access, please contact the{" "}
                    <Link
                      href="mailto:watchtowersupport@microsoft.com"
                      title="Crisis Management Tooling"
                    >
                      Watchtower Support team
                    </Link>
                    .
                  </p>
                </div>
              </>
            ),
            onItemClick: () => handleNavItemClick("facilities-overview"),
          },
          {
            itemKey: "definitions",
            navItemText: "Definitions",
            content: (
              <>
                <h2>Definitions</h2>
                <DetailsList
                  items={facilitiesItems}
                  columns={columns}
                  setKey="set"
                  layoutMode={DetailsListLayoutMode.justified}
                  selectionMode={SelectionMode.none}
                  className={helpStyles.DetailsListTable}
                />
                <h2 id="coiDcClass">CO+I DC Classifications</h2>
                <DetailsList
                  items={coiDcClassItems}
                  columns={coiDcColumns}
                  setKey="set"
                  layoutMode={DetailsListLayoutMode.justified}
                  selectionMode={SelectionMode.none}
                  className={helpStyles.DetailsListTable}
                />
              </>
            ),
            onItemClick: () => handleNavItemClick("facilities-definitions"),
          },
          {
            itemKey: "dataFlowDiagram",
            navItemText: "Data Flow Diagram",
            content: (
              <>
                <h2>Data Flow Diagram</h2>
                <img height="50%" width="90%" src={facilityDataFlow} alt="facility data flow" />
                <h2>Data Sources</h2>
                <img
                  height="10%"
                  width="100%"
                  src={facilitiesDataSource}
                  alt="facilities data source, refreshes once a day with a resiliency of four hours."
                />
              </>
            ),
            onItemClick: () => handleNavItemClick("facilities-dataFlowDiagram"),
          },
          {
            itemKey: "referenceLinks",
            navItemText: "Reference Links",
            content: (
              <>
                <h2>Reference Links</h2>
                <ul className={helpStyles.List}>
                  <li className={helpStyles.ListItem}>
                    <h3 className={helpStyles.Link}>MS Space Facility Type Definitions: </h3>
                    <Link
                      className={helpStyles.Link}
                      href="https://microsoft.sharepoint.com/teams/REFMSSpacePortal/SitePages/MS%20Space%20Definitions.aspx"
                      title="MS Space Definitions"
                      target="_blank"
                    >
                      MS Space Definitions
                    </Link>
                  </li>
                </ul>
              </>
            ),
            onItemClick: () => handleNavItemClick("facilities-referenceLinks"),
          },
        ],
        onGroupClick: () => handleNavGroupClick("facilities-tab"),
      },
    ],
    services: [
      {
        itemKey: "services",
        groupTitle: "Services",
        groupedItems: [
          {
            itemKey: "overview",
            navItemText: "Overview",
            content: (
              <>
                <div>
                  <h2>Overview</h2>
                  <p>
                    The service dashboard serves as a crisis management view of the Microsoft
                    services running in a given region/country. It is built using a combination of
                    Service Tree, Azure Graph, and Ecomanager (BCDR) data.{" "}
                  </p>
                  <p>
                    {" "}
                    Services can be broken down into 5 categories of recovery time criticality:
                    mission critical, critical, important, deferrable, and unknown. These aim to
                    help identify the order in which service owners should be contacted to ensure
                    that their services are prepared to meet SLAs and commitments in a time of
                    crisis.
                  </p>
                  <p>
                    {" "}
                    Additional table details include the BCDR user inputs about the service
                    lifecycle stage (we filter anything but GA, closing down, and unknown), service
                    classification, their resiliency plans, and points of contact as identified in
                    ServiceTree. This is combined with Azure ARM and Graph data to display enhanced
                    information about all of a service's associated resources running in azure.
                  </p>
                </div>
              </>
            ),
            onItemClick: () => handleNavItemClick("services-overview"),
          },
          {
            itemKey: "definitions",
            navItemText: "Definitions",
            content: (
              <>
                <h2>Definitions</h2>
                <DetailsList
                  items={serviceItems}
                  columns={columns}
                  setKey="set"
                  layoutMode={DetailsListLayoutMode.justified}
                  selectionMode={SelectionMode.none}
                  className={helpStyles.DetailsListTable}
                />
              </>
            ),
            onItemClick: () => handleNavItemClick("services-definitions"),
          },
          {
            itemKey: "dataFlowDiagram",
            navItemText: "Data Flow Diagram",
            content: (
              <>
                <h2>Data Flow Diagram</h2>
                <img height="60%" width="90%" src={serviceDataFlow} alt="service data flow" />
                <h2>Data Sources</h2>
                <img
                  height="30%"
                  width="100%"
                  src={servicesDataSource}
                  alt="service data source, Geneva Cluster refreshes every 4 hours or less with a resiliency of four hours. ICM Cluster, DI DRI Insights, and Eco Manager refresh once a day with a resiliency of four hours."
                />
              </>
            ),
            onItemClick: () => handleNavItemClick("services-dataFlowDiagram"),
          },
          {
            itemKey: "referenceLinks",
            navItemText: "Reference Links",
            content: (
              <>
                <h2>Reference Links</h2>
                <ul className={helpStyles.List}>
                  <li className={helpStyles.ListItem}>
                    <h3 className={helpStyles.Link}>
                      BCDR Scorecard Service Classification Categories:{" "}
                    </h3>
                    <Link
                      className={helpStyles.Link}
                      href="https://microsoft.sharepoint.com/:w:/r/teams/Azure_Compliance/_layouts/15/Doc.aspx?sourcedoc=%7B5D6A35DA-A2FF-4DAF-9E71-30BA77A721CB%7D&file=Service%20Properties%20Regional%20Recovery.docx&action=default&mobileredirect=true"
                      title="Service Properties Regional Recovery"
                      target="_blank"
                    >
                      Service Properties Regional Recovery
                    </Link>
                  </li>
                </ul>
              </>
            ),
            onItemClick: () => handleNavItemClick("services-referenceLinks"),
          },
        ],
        onGroupClick: () => handleNavGroupClick("services-tab"),
      },
      {
        itemKey: "driRiskScore",
        groupTitle: "DRI Risk Score",
        groupedItems: [
          {
            itemKey: "overview-1",
            navItemText: "Overview",
            content: (
              <>
                <div>
                  <h2>Overview</h2>
                  <p>
                    DRI impact identifies the services at various level of risk of interruption due
                    to loss of DRI staffing. The interruption may include lack of support,
                    break/fix, or incident tickets due to volume, lack of familiarity with
                    high-severity issues.{" "}
                  </p>
                  <p>
                    {" "}
                    For additional information, please contact the{" "}
                    <Link href="mailto:ISRMSE@microsoft.com" title="DSR Data Intelligence">
                      DSR Data Intelligence
                    </Link>
                    .
                  </p>
                </div>
              </>
            ),
            onItemClick: () => handleNavItemClick("driRiskScore-overview"),
          },
          {
            itemKey: "definitions-1",
            navItemText: "Definitions",
            content: (
              <>
                <h2>Definitions</h2>
                <DetailsList
                  items={DRIItems}
                  columns={columns}
                  setKey="set"
                  layoutMode={DetailsListLayoutMode.justified}
                  selectionMode={SelectionMode.none}
                  className={helpStyles.DetailsListTable}
                />
              </>
            ),
            onItemClick: () => handleNavItemClick("driRiskScore-definitions"),
          },
          {
            itemKey: "dataFlowDiagram-1",
            navItemText: "Data Flow Diagram",
            content: (
              <>
                <h2>Data Flow Diagram</h2>
                <p>Please see the Data Flow Diagram under Services.</p>
                <h2>Data Sources</h2>
                <p>Please see the Data Flow Diagram under Services.</p>
              </>
            ),
            onItemClick: () => handleNavItemClick("driRiskScore-dataFlowDiagram"),
          },
        ],
        onGroupClick: () => handleNavGroupClick("driRiskScore-tab"),
      },
    ],
    processes: [
      {
        itemKey: "processes",
        groupTitle: "Processes",
        groupedItems: [
          {
            itemKey: "overview",
            navItemText: "Overview",
            content: (
              <>
                <div>
                  <h2>Overview</h2>
                  <p>
                    The process dashboard serves as a crisis management view of the business
                    processes that are taking place in a given region/country. The data is owned by
                    the Enterprise Business Continuity Management team and sourced from EGRC. It is
                    based solely on user-attested inputs at this time.{" "}
                  </p>
                  <p>
                    The intent is to provide insight into the processes that are deemed mission
                    critical to ensure continuity during a time of crisis.
                  </p>
                </div>
              </>
            ),
            onItemClick: () => handleNavItemClick("processes-overview"),
          },
          {
            itemKey: "definitions",
            navItemText: "Definitions",
            content: (
              <>
                <h2>Definitions</h2>
                <DetailsList
                  items={processItems}
                  columns={columns}
                  setKey="set"
                  layoutMode={DetailsListLayoutMode.justified}
                  selectionMode={SelectionMode.none}
                  className={helpStyles.DetailsListTable}
                />
              </>
            ),
            onItemClick: () => handleNavItemClick("processes-definitions"),
          },
          {
            itemKey: "dataFlowDiagram",
            navItemText: "Data Flow Diagram",
            content: (
              <>
                <h2>Data Flow Diagram</h2>
                <img height="50%" width="90%" src={processDataFlow} alt="facility data flow" />
                <h2>Data Sources</h2>
                <img
                  height="10%"
                  width="100%"
                  src={processesDataSource}
                  alt="process data source, refreshes every 2 hours with a resiliency of four hours."
                />
              </>
            ),
            onItemClick: () => handleNavItemClick("processes-dataFlowDiagram"),
          },
        ],
        onGroupClick: () => handleNavGroupClick("processes-tab"),
      },
    ],
    serviceDependencies: [
      {
        itemKey: "serviceDeps",
        groupTitle: "Service Dependency",
        groupedItems: [
          {
            itemKey: "serviceDepoverview",
            navItemText: "Overview",
            content: (
              <>
                <div>
                  <h2>Overview</h2>
                  <p>
                    Service Dependency Mapping aims to identify the closest dependencies for any
                    given service. This view includes the first two levels of upstream dependencies
                    (i.e. the other services that the current one may depend on) and the first two
                    levels of downstream services (i.e. the other services that depend on the
                    current one). It is intended to replace the existing Dossier tooling and provide
                    additional enhancements in future semesters.
                  </p>
                  <p>
                    The overview page provides a quick glance into the dependency status across
                    internal services. Highlighted information includes an aggregate look at BCDR
                    assessment statuses, the number of services that fall into several potential
                    risk categories, such as having circular dependencies, unknown dependencies, and
                    dependency gaps. The 10 services with the most upstream or downstream
                    dependencies are also available.
                  </p>
                  <p>
                    The service dependency page leverages current BCDR assessment data as well as
                    service tree data to provide a holistic view of the dependencies for a given
                    service. In addition, there are BCDR metadata points, quick reference data
                    points, a visual tool to help identify the most critical dependencies, and a
                    list of the most critical dependencies for a given service. The goal is not only
                    to reduce the time to gather this information when needed, but also to allow for
                    preemptive planning and mitigation.
                  </p>
                </div>
              </>
            ),
            onItemClick: () => handleNavItemClick("serviceDeps-overview"),
          },
          {
            itemKey: "bcdrRecoveryDefinitions",
            navItemText: "BCDR Recovery Definitions",
            content: (
              <>
                <h2>Definitions</h2>
                <DetailsList
                  items={bcdrRecoveryTerms}
                  columns={columns}
                  setKey="set"
                  layoutMode={DetailsListLayoutMode.justified}
                  selectionMode={SelectionMode.none}
                  className={helpStyles.DetailsListTable}
                />
              </>
            ),
            onItemClick: () => handleNavItemClick("serviceDeps-bcdrDefinitions"),
          },
          {
            itemKey: "serviceDependencyDefinitions",
            navItemText: "Service Dependency Definitions",
            content: (
              <>
                <h2>Definitions</h2>
                <DetailsList
                  items={serviceDependencyTerms}
                  columns={columns}
                  setKey="serviceDepSet"
                  layoutMode={DetailsListLayoutMode.justified}
                  selectionMode={SelectionMode.none}
                  className={helpStyles.DetailsListTable}
                />
              </>
            ),
            onItemClick: () => handleNavItemClick("serviceDeps-depDefinitions"),
          },
        ],
        onGroupClick: () => handleNavGroupClick("serviceDeps-tab"),
      },
    ],
  };
  return pivotContents;
};

export const pivotItems = [
  {
    itemKey: "people",
    headerText: "People",
  },
  {
    itemKey: "facilities",
    headerText: "Facilities",
  },
  {
    itemKey: "services",
    headerText: "Services",
  },
  {
    itemKey: "processes",
    headerText: "Processes",
  },
  {
    itemKey: "serviceDependencies",
    headerText: "Service Dependency Mapping",
  },
];
