import * as msal from "@azure/msal-browser";
import { LogLevel } from "@azure/msal-browser";
import { appInsights } from "./services/AppInsights";

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: process.env.REACT_APP_AUTHORITY,
    redirectUri: process.env.REACT_APP_REDIRECT_URL,
    postLogoutRedirectUri: "",
    forceRefresh: true,
  },
  cache: {
    cacheLocation: msal.BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: true,
  },
  system: {
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean
      ): void => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            appInsights.trackEvent(
              { name: "Error" },
              { severityLevel: level, message: message }
            );
            return;
          case LogLevel.Warning:
            appInsights.trackEvent(
              { name: "Warning" },
              { severityLevel: level, message: message }
            );
            return;
        }
      },
      piiLoggingEnabled: false,
    },
    windowHashTimeout: 6000,
    iframeHashTimeout: 6000,
    loadFrameTimeout: 6000,
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ["User.read", "openid", "profile"],
};

export const powerBiLogin = {
  scopes: [
    "https://analysis.windows.net/powerbi/api/Report.Read.All",
    "user.read",
  ],
};

export const apiScopeRequest = [process.env.REACT_APP_API_SCOPES];

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
