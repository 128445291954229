import { Dispatch } from "redux";
import { AxiosError } from "axios";
import { stringify } from "querystring";
import apiBase from "../../services/apiBase";
import { IDriGeoResilienceData, IDriGeoTeamCount } from "../../components/Types";
import { appInsights } from "../../services/AppInsights";
import {
  FETCH_SERVICE_DRI_GEO_RESIL_BEGIN,
  FETCH_SERVICE_DRI_GEO_RESIL_COUNT_BEGIN,
  FETCH_SERVICE_DRI_GEO_RESIL_COUNT_FAILURE,
  FETCH_SERVICE_DRI_GEO_RESIL_COUNT_SUCCESS,
  FETCH_SERVICE_DRI_GEO_RESIL_FAILURE,
  FETCH_SERVICE_DRI_GEO_RESIL_SUCCESS,
  RESET_DRI_GEO_ASSETS,
} from "./actionTypes";

const driGeoResilienceApiEndpointBase: string = "/api/ICM";

export const fetchDriGeoResilienceByRegionBegin = () => ({
  type: FETCH_SERVICE_DRI_GEO_RESIL_BEGIN,
});

export const fetchDriGeoResilienceByRegionSuccess = (payload: IDriGeoResilienceData[]) => ({
  type: FETCH_SERVICE_DRI_GEO_RESIL_SUCCESS,
  payload,
});

export const fetchDriGeoResilienceByRegionFailure = (error: AxiosError | string) => ({
  type: FETCH_SERVICE_DRI_GEO_RESIL_FAILURE,
  payload: error,
});

export const fetchRegionDriGeoResilience = (
  locationsToFetch: string[],
  isRegionSelection: boolean
) => {
  return async (dispatch: Dispatch) => {
    dispatch(fetchDriGeoResilienceByRegionBegin());
    try {
      const endpoint = `${driGeoResilienceApiEndpointBase}/GetDriGeoData`;
      let res;
      if (isRegionSelection) {
        res = await apiBase.get(endpoint, {
          params: {
            regionQueryList: locationsToFetch,
          },
          paramsSerializer: (params) => {
            return stringify(params);
          },
        });
      } else {
        res = await apiBase.get(endpoint, {
          params: {
            countryQueryList: locationsToFetch,
          },
          paramsSerializer: (params) => {
            return stringify(params);
          },
        });
      }

      if (res.data) {
        dispatch(fetchDriGeoResilienceByRegionSuccess(res.data));
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        dispatch(
          fetchDriGeoResilienceByRegionFailure(
            "Error calling DRI Geo Resilience API due to your permissions. Please reach out to watchtower support to confirm your access."
          )
        );
      } else {
        dispatch(fetchDriGeoResilienceByRegionFailure(err));
      }

      appInsights.trackException(err);
    }
  };
};

export const fetchDriGeoResilienceCountBegin = () => ({
  type: FETCH_SERVICE_DRI_GEO_RESIL_COUNT_BEGIN,
});

export const fetchDriGeoResilienceCountSuccess = (payload: IDriGeoTeamCount) => ({
  type: FETCH_SERVICE_DRI_GEO_RESIL_COUNT_SUCCESS,
  payload,
});

export const fetchDriGeoResilienceCountFailure = (error: AxiosError | string) => ({
  type: FETCH_SERVICE_DRI_GEO_RESIL_COUNT_FAILURE,
  payload: error,
});

export const fetchDriGeoResilienceCounts = () => {
  return async (dispatch) => {
    dispatch(fetchDriGeoResilienceCountBegin());
    try {
      const endpoint = `${driGeoResilienceApiEndpointBase}/GetAggregateDriGeoCount`;
      let res = await apiBase.get(endpoint);

      if (res.data) {
        dispatch(fetchDriGeoResilienceCountSuccess(res.data));
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        dispatch(
          fetchDriGeoResilienceCountFailure(
            "Error calling DRI Geo Resilience API due to your permissions. Please reach out to watchtower support to confirm your access."
          )
        );
      } else {
        dispatch(fetchDriGeoResilienceByRegionFailure(err));
      }

      appInsights.trackException(err);
    }
  };
};

export const clearDriGeoDetails = () => ({
  type: RESET_DRI_GEO_ASSETS,
});
