import { MessageBar, MessageBarButton, MessageBarType, ScrollablePane } from "@fluentui/react";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { FilterBase } from "components/filter/FilterBase";
import { PeoplePivot } from "components/pivots/PeoplePivot";
import { _Styles } from "pages/Page.styles";
import React from "react";
import { fetchPeopleLocationData } from "store/actions/peopleActions";
import { getSelectedCountries } from "store/selectors/facilitiesSelector";
import { getPeopleDirectsError } from "store/selectors/peopleSelector";
import { getSelectedPhysicalRegions } from "store/selectors/regionsSelector";
import { useAppDispatch, useAppSelector } from "store/store";

export interface PeopleProps {
  isNavCollapsed: boolean;
}

export const People = ({ isNavCollapsed }: PeopleProps) => {
  const appInsights = useAppInsightsContext();
  const dispatch = useAppDispatch();
  const currentRegions = useAppSelector(getSelectedPhysicalRegions);
  const currentSelectedCountries = useAppSelector(getSelectedCountries);

  // error setup
  const [showErrorMessage, setShowErrorMessage] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const peopleErrors = useAppSelector(getPeopleDirectsError);

  const retryApiCalls = () => {
    if (peopleErrors) {
      appInsights.trackEvent({ name: "People fetch retried" });
      dispatch(
        fetchPeopleLocationData(
          currentRegions?.map((r) => r.regionDescription) ?? [],
          currentSelectedCountries
        )
      );
    }
  };

  React.useEffect(() => {
    const errors = peopleErrors ?? "";
    const errorString =
      errors.length > 0 ? errors.concat("\u000AClick retry to attempt again") : null;
    if (errorString !== null) {
      setErrorMessage(errorString);
      setShowErrorMessage(true);
    }
  }, [peopleErrors]);

  return (
    <ScrollablePane
      className={isNavCollapsed ? _Styles.scrollablePaneCollapsed : _Styles.scrollablePaneExpand}
    >
      {showErrorMessage && (
        <MessageBar
          style={{ whiteSpace: "pre-wrap" }}
          messageBarType={MessageBarType.error}
          isMultiline={true}
          onDismiss={() => setShowErrorMessage(false)}
          dismissButtonAriaLabel="Close"
          actions={
            <div>
              <MessageBarButton onClick={() => retryApiCalls()}>Retry</MessageBarButton>
            </div>
          }
        >
          {errorMessage}
        </MessageBar>
      )}
      <h1 className={_Styles.header}>People Dashboard</h1>
      <FilterBase needsDcFilter={false} needsPeopleFilter={true} />
      <PeoplePivot />
    </ScrollablePane>
  );
};
