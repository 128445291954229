import { MessageBar, MessageBarButton, MessageBarType, ScrollablePane } from "@fluentui/react";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { FilterBase } from "components/filter/FilterBase";
import { ServicePivot } from "components/pivots/ServicePivot";
import { _Styles } from "pages/Page.styles";
import { useEffect, useState } from "react";
import { fetchServiceCounts, fetchServiceDetails } from "store/actions/servicesActions";
import {
  fetchDriGeoResilienceCounts,
  fetchRegionDriGeoResilience,
} from "store/actions/servicesDriGeoResilienceActions";
import { getSelectedCountries } from "store/selectors/facilitiesSelector";
import { getSelectedPhysicalRegions } from "store/selectors/regionsSelector";
import { getServiceAssetDetailsError } from "store/selectors/servicesSelector";
import { useAppDispatch, useAppSelector } from "store/store";

export interface ServicesProps {
  isNavCollapsed: boolean;
}

export const Services = ({ isNavCollapsed }: ServicesProps) => {
  const dispatch = useAppDispatch();
  const appInsights = useAppInsightsContext();

  const currentRegions = useAppSelector(getSelectedPhysicalRegions);
  const currentCountries = useAppSelector(getSelectedCountries);
  // error setup
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const serviceApiErrors = useAppSelector(getServiceAssetDetailsError);

  const retryApiCalls = () => {
    if (serviceApiErrors) {
      appInsights.trackEvent({ name: "Service fetch retried" });
      dispatch(fetchServiceCounts());
      dispatch(fetchDriGeoResilienceCounts());
      if (currentRegions.length > 0) {
        fetchRegionDriGeoResilience(
          currentRegions.map((r) => r.toString()),
          true
        );
      } else if (currentCountries.length > 0) {
        fetchRegionDriGeoResilience(currentCountries, false);
        fetchServiceDetails(currentCountries);
      }
    }
  };

  useEffect(() => {
    const errors = serviceApiErrors ?? "";
    const errorString =
      errors.length > 0 ? errors.concat("\u000AClick retry to attempt again") : null;
    if (errorString !== null) {
      setErrorMessage(errorString);
      setShowErrorMessage(true);
    }
  }, [serviceApiErrors]);

  return (
    <ScrollablePane
      className={isNavCollapsed ? _Styles.scrollablePaneCollapsed : _Styles.scrollablePaneExpand}
    >
      {showErrorMessage && (
        <MessageBar
          style={{ whiteSpace: "pre-wrap" }}
          messageBarType={MessageBarType.error}
          isMultiline={true}
          onDismiss={() => setShowErrorMessage(false)}
          dismissButtonAriaLabel="Close"
          actions={
            <div>
              <MessageBarButton onClick={() => retryApiCalls()}>Retry</MessageBarButton>
            </div>
          }
        >
          {errorMessage}
        </MessageBar>
      )}
      <h1 className={_Styles.header}>Service Dashboard</h1>
      <FilterBase needsDcFilter={true} needsPeopleFilter={false} />
      <ServicePivot />
    </ScrollablePane>
  );
};
