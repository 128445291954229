import {
  ComboBox,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IComboBoxOption,
  PrimaryButton,
  SelectableOptionMenuItemType,
  Stack,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { batch } from "react-redux";
import { PhysicalRegion } from "components/Types";
import { buildFilterEventCustomTelemetry } from "components/datagrids/Helpers";
import { _Styles } from "pages/Page.styles";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  clearFacilityDetails,
  fetchFacilities,
  fetchFacilityCounts,
  updateFacilityCityFilter,
  updateFacilityCountryFilter,
  updateFacilityStateFilter,
} from "store/actions/facilitiesActions";
import {
  clearPeopleDetails,
  fetchPeopleCounts,
  fetchPeopleLocationData,
  updatePeopleCityFilter,
  updatePeopleStateFilter,
} from "store/actions/peopleActions";
import {
  clearProcessDetails,
  fetchProcessCounts,
  fetchProcessDetails,
} from "store/actions/processesActions";
import {
  fetchAzureRegions,
  fetchPhysicalRegions,
  updateSelectedCountries,
  updateSelectedPhysicalRegions,
} from "store/actions/regionsActions";
import {
  clearAllServiceFilters,
  clearServiceDetails,
  fetchServiceCounts,
  fetchServiceDetails,
  updateServiceBclFilters,
  updateServiceCloudFilters,
  updateServiceDataCenterFilters,
  updateServiceDivisionFilters,
  updateServiceOrganizationFilters,
  updateServiceServiceGroupFilters,
} from "store/actions/servicesActions";
import {
  clearDriGeoDetails,
  fetchDriGeoResilienceCounts,
  fetchRegionDriGeoResilience,
} from "store/actions/servicesDriGeoResilienceActions";
import {
  getDcFacilityCountries,
  getDcFacilityStates,
  getFacilityCities,
  getFacilityStates,
  getSelectedCities,
  getSelectedCountries,
  getSelectedStates,
} from "store/selectors/facilitiesSelector";
import { getPeopleDirectsLoadingStatus } from "store/selectors/peopleSelector";
import {
  getMSCountryOptions,
  getMSRegionOptions,
  getMSRegions,
  getMSRegionsLoadingStatus,
  getSelectedPhysicalCountries,
  getSelectedPhysicalRegions,
} from "store/selectors/regionsSelector";
import {
  getDriInsightsByRegionLoadingStatus,
  getServiceBclFilters,
  getServiceCloudFilters,
  getServiceDataCenterFilters,
  getServiceDataCenterOptions,
  getServiceDivisionFilters,
  getServiceDriCityOptions,
  getServiceOrganizationFilters,
  getServiceSgFilters,
} from "store/selectors/servicesSelector";
import { useAppDispatch, useAppSelector } from "store/store";
import { ServiceFilterBase } from "./ServiceFilterBase";

export interface FilterBaseProps {
  needsDcFilter: boolean;
  needsPeopleFilter?: boolean;
}

export const FilterBase = (props: FilterBaseProps) => {
  const dispatch = useAppDispatch();
  const appInsights = useAppInsightsContext();
  const dcFilterActive = props.needsDcFilter;
  const peopleFilterActive = props.needsPeopleFilter;
  // is data loading
  const serviceDataLoading = useAppSelector(getDriInsightsByRegionLoadingStatus);
  const peopleDataLoading = useAppSelector(getPeopleDirectsLoadingStatus);
  const physicalRegionsLoading = useAppSelector(getMSRegionsLoadingStatus);
  const isLoading = physicalRegionsLoading || serviceDataLoading || peopleDataLoading;
  //ms region setup
  const physicalRegions: PhysicalRegion[] = useAppSelector(getMSRegions);
  const physicalRegionOptionsState: IComboBoxOption[] = useAppSelector(getMSRegionOptions);
  let currentSelectedRegions: PhysicalRegion[] = useAppSelector(getSelectedPhysicalRegions);
  const [regionKeys, setRegionKeys] = useState<string[]>(
    currentSelectedRegions?.map((r) => r.regionDescription) ?? []
  );
  useMemo(() => {
    setRegionKeys(currentSelectedRegions.map((r) => r.regionDescription));
  }, [currentSelectedRegions]);
  const [regionOptions, setRegionOptions] = useState<IComboBoxOption[]>(
    physicalRegionOptionsState ?? []
  );
  const dcCountriesByRegion: IComboBoxOption[] = useAppSelector(getDcFacilityCountries);

  const countryOptionsState = useAppSelector(getMSCountryOptions);
  const currentSelectedCountries = useAppSelector(getSelectedPhysicalCountries);
  const currentSelectedFacilityCountries = useAppSelector(getSelectedCountries);
  // countries setup
  const [countryOptions, setCountryOptions] = useState<IComboBoxOption[]>([]);
  const [countryKeys, setCountryKeys] = useState<string[]>(currentSelectedCountries);

  useEffect(() => {
    setCountryKeys(
      Array.from(new Set([...currentSelectedFacilityCountries, ...currentSelectedCountries]))
    );
  }, [currentSelectedCountries, currentSelectedFacilityCountries]);

  useEffect(() => {
    setRegionOptions(physicalRegionOptionsState);
  }, [physicalRegionOptionsState]);

  const regionSelectableOptions = regionOptions.filter(
    (option) =>
      (option.itemType === SelectableOptionMenuItemType.Normal || option.itemType === undefined) &&
      !option.disabled
  );

  // states setup
  const statesByRegion: string[] = useAppSelector(getFacilityStates);
  const dcStatesByRegion: string[] = useAppSelector(getDcFacilityStates);

  const currentSelectedStates = useAppSelector(getSelectedStates);
  const [stateOptions, setStateOptions] = useState<IComboBoxOption[]>([]);
  const [stateKeys, setStateKeys] = useState<string[]>(currentSelectedStates);

  // cities setup
  const citiesByRegion: string[] = useAppSelector(getFacilityCities);
  const driCitiesByRegion: IComboBoxOption[] = useAppSelector(getServiceDriCityOptions);
  const currentSelectedCities = useAppSelector(getSelectedCities);
  const [cityOptions, setCityOptions] = useState<IComboBoxOption[]>([]);
  const [cityKeys, setCityKeys] = useState<string[]>(currentSelectedCities);

  // dc setup
  const dcOptions = useAppSelector(getServiceDataCenterOptions);
  const currentSelectedDcs = useAppSelector(getServiceDataCenterFilters);
  const [selectedDataCenters, setSelectedDataCenters] = useState<number[]>(currentSelectedDcs);

  useMemo(() => {
    if (dcFilterActive && dcCountriesByRegion?.length > 0) {
      setCountryOptions(dcCountriesByRegion);
    } else {
      setCountryOptions(countryOptionsState);
    }
  }, [countryOptionsState, dcCountriesByRegion, dcFilterActive]);

  useMemo(() => {
    const options: IComboBoxOption[] =
      (dcFilterActive ? textToKeyTextList(dcStatesByRegion) : textToKeyTextList(statesByRegion)) ??
      [];

    if (options.length === 1 && countryOptions.length === 1) {
      options[0].selected = true;
    }
    setStateOptions(options);
  }, [dcFilterActive, dcStatesByRegion, statesByRegion, countryOptions.length]);

  useMemo(() => {
    let options = [];
    if (dcFilterActive) {
      options = driCitiesByRegion;
    } else {
      options = textToKeyTextList(citiesByRegion);
    }
    setCityOptions(options);
  }, [citiesByRegion, dcFilterActive, driCitiesByRegion]);

  useMemo(() => {
    if (cityOptions.length === 1 && countryOptions.length === 1) {
      cityOptions[0].selected = true;
    }
  }, [cityOptions, countryOptions]);

  const fetchInitialData = useCallback(() => {
    dispatch(fetchFacilityCounts([], false));
    dispatch(fetchServiceCounts());
    dispatch(fetchDriGeoResilienceCounts());
    dispatch(fetchProcessCounts());
    dispatch(fetchPeopleCounts());
  }, [dispatch]);

  const fetchProcessData = useCallback(
    (selectedLocations: string[], isRegionSelection: boolean) => {
      dispatch(fetchProcessDetails(selectedLocations, isRegionSelection));
    },
    [dispatch]
  );

  const fetchFacilityData = useCallback(
    (selectedRegions: string[], isRegionSelection: boolean) => {
      dispatch(fetchFacilities(selectedRegions, isRegionSelection));
      dispatch(fetchFacilityCounts(selectedRegions, isRegionSelection));
    },
    [dispatch]
  );

  const fetchPeopleData = useCallback(
    (
      currentRegionSelections: string[],
      currentCountrySelections: string[],
      currentStateSelections: string[],
      currentCitySelections: string[]
    ) => {
      dispatch(
        fetchPeopleLocationData(
          currentRegionSelections,
          currentCountrySelections,
          currentStateSelections,
          currentCitySelections
        )
      );
    },
    [dispatch]
  );

  const fetchServiceData = useCallback(
    (selectedCountryLocations: string[], currentRegions: string[]) => {
      dispatch(fetchServiceDetails(selectedCountryLocations));
      if (currentRegions.length) {
        dispatch(fetchRegionDriGeoResilience(currentRegions, true));
      } else {
        dispatch(fetchRegionDriGeoResilience(selectedCountryLocations, false));
      }
    },
    [dispatch]
  );

  const hasLoaded = useRef(false);
  const loadInitialData = useCallback(() => {
    dispatch(fetchPhysicalRegions());
    dispatch(fetchAzureRegions());
    fetchInitialData();
  }, [dispatch, fetchInitialData]);

  useMemo(() => {
    if (physicalRegionOptionsState?.length < 1 && !physicalRegionsLoading && !hasLoaded.current) {
      hasLoaded.current = true;
      loadInitialData();
    }
  }, [physicalRegionOptionsState?.length, physicalRegionsLoading, hasLoaded]);

  // Abstract common logic for updating keys and resetting dependent filters
  const updateFilterKeys = (option, currentKeys, setKeys, resetKeys = []) => {
    const updatedKeys = option.selected
      ? [...currentKeys, option.key as string]
      : currentKeys.filter((k) => k !== option.key);
    setKeys(updatedKeys);
    resetKeys.forEach((resetKey) => resetKey([]));
  };

  // Abstract common logic for tracking filter usage
  const trackFilterUsage = (filterName, optionText) => {
    appInsights.trackEvent(
      { name: "Filter Used" },
      buildFilterEventCustomTelemetry(filterName, optionText)
    );
  };

  // Refactored onCountryChange
  const onCountryChange = (_, option?: IComboBoxOption): void => {
    if (option) {
      updateFilterKeys(option, countryKeys, setCountryKeys, [setStateKeys, setCityKeys]);
      trackFilterUsage("Country", option.text);
    }
  };

  // Refactored onStateChange
  const onStateChange = (_, option?: IComboBoxOption): void => {
    if (option) {
      updateFilterKeys(option, stateKeys, setStateKeys, [setCityKeys]);
      trackFilterUsage("State", option.text);
    }
  };

  // Refactored onCityChange
  const onCityChange = (_, option?: IComboBoxOption): void => {
    if (option) {
      updateFilterKeys(option, cityKeys, setCityKeys);
      trackFilterUsage("City", option.text);
    }
  };
  // Refactored onDcChange
  const onDcChange = (_, option?: IComboBoxOption): void => {
    if (option) {
      updateFilterKeys(option, selectedDataCenters, setSelectedDataCenters);
      trackFilterUsage("Data Center", option.text);
    }
  };

  // Refactored onRegionChange
  const onRegionChange = (_, option?: IComboBoxOption): void => {
    if (option) {
      let updatedKeys;
      if (option.itemType === SelectableOptionMenuItemType.SelectAll) {
        const selectAllState = !regionKeys.includes("selectAll");
        updatedKeys = selectAllState
          ? []
          : [...regionSelectableOptions.map((o) => o.key as string)];
        setRegionKeys(updatedKeys);
      } else {
        updatedKeys = option.selected
          ? [...regionKeys, option.key as string]
          : regionKeys.filter((k) => k !== option.key);
        if (updatedKeys.length === regionSelectableOptions.length) {
          updatedKeys.push("selectAll");
        }
        setRegionKeys(updatedKeys);
        setCountryKeys([]);
        setStateOptions([]);
        setStateKeys([]);
        setCityOptions([]);
        setCityKeys([]);
      }
      const selectedRegion = physicalRegions.filter((pr) =>
        updatedKeys.some((key) => key === pr.regionDescription)
      );
      dispatch(updateSelectedPhysicalRegions(selectedRegion));
      trackFilterUsage("Region", option.text);
    }
  };

  const activeServiceBclFilters = useAppSelector(getServiceBclFilters);
  const [selectedBusinessContinuityLeads, setSelectedBusinessContinuityLeads] =
    useState<string[]>(activeServiceBclFilters);
  const activeServiceCloudFilters = useAppSelector(getServiceCloudFilters);
  const [selectedClouds, setSelectedClouds] = useState<string[]>(activeServiceCloudFilters);

  const activeServiceDivisions = useAppSelector(getServiceDivisionFilters);
  const [selectedDivisions, setSelectedDivisions] = useState<string[]>(activeServiceDivisions);

  const activeServiceOrgs = useAppSelector(getServiceOrganizationFilters);
  const [selectedOrgs, setSelectedOrgs] = useState<string[]>(activeServiceOrgs);

  const activeServiceGroups = useAppSelector(getServiceSgFilters);
  const [selectedServiceGroups, setSelectedServiceGroups] = useState<string[]>(activeServiceGroups);
  useMemo(() => {
    setSelectedBusinessContinuityLeads(activeServiceBclFilters);
    setSelectedClouds(activeServiceCloudFilters);
    setSelectedDivisions(activeServiceDivisions);
    setSelectedOrgs(activeServiceOrgs);
    setSelectedServiceGroups(activeServiceGroups);
  }, [
    activeServiceBclFilters,
    activeServiceCloudFilters,
    activeServiceDivisions,
    activeServiceGroups,
    activeServiceOrgs,
  ]);

  const updateFilters = useCallback((filterName: string, filterListResult: []) => {
    switch (filterName) {
      case "Service Business Continuity Lead":
        setSelectedBusinessContinuityLeads(filterListResult);
        break;
      case "Service Cloud":
        setSelectedClouds(filterListResult);
        break;
      case "Service Division":
        setSelectedDivisions(filterListResult);
        break;
      case "Service Organization":
        setSelectedOrgs(filterListResult);
        break;
      case "Service Group":
        setSelectedServiceGroups(filterListResult);
        break;
      case "DC":
        setSelectedDataCenters(filterListResult);
        break;
      default:
        break;
    }
  }, []);

  const onApplyServiceFilters = useCallback(
    (
      selectedBcls: string[],
      selectedCloud: string[],
      selectedDivs: string[],
      selectedOrgs: string[],
      selectedSgs: string[],
      selectedDcs: number[]
    ) => {
      selectedBcls.length && dispatch(updateServiceBclFilters(selectedBcls));
      selectedCloud.length && dispatch(updateServiceCloudFilters(selectedCloud));
      selectedDivs.length && dispatch(updateServiceDivisionFilters(selectedDivs));
      selectedOrgs.length && dispatch(updateServiceOrganizationFilters(selectedOrgs));
      selectedSgs.length && dispatch(updateServiceServiceGroupFilters(selectedSgs));
      selectedDcs.length && dispatch(updateServiceDataCenterFilters(selectedDcs));
    },
    [dispatch]
  );
  const onApplyButtonClicked = useCallback(
    (countryKeys, regionKeys, stateOptions, stateKeys, cityKeys) => {
      const countryCount = countryKeys.length;
      const regionCount = regionKeys.length;
      const stateOptionsCount = stateOptions.length;
      if (
        regionCount > 0 &&
        countryCount === 0 &&
        (stateOptionsCount === 0 || stateKeys.length === 0)
      ) {
        // just region selected
        fetchFacilityData(regionKeys, true);
        fetchProcessData(regionKeys, true);
        fetchServiceData(
          currentSelectedRegions.flatMap((r) => r.countries),
          regionKeys
        );
        fetchPeopleData(regionKeys, countryKeys, stateKeys, cityKeys);
        appInsights.trackMetric(
          { name: "Filter Used", average: regionCount },
          { Source: "Filter - Region" }
        );
      } else if (regionCount === 0 && countryCount > 0) {
        // just country selected or still no region but selected countries changed
        dispatch(updateSelectedCountries(countryKeys));
        fetchFacilityData(countryKeys, false);
        fetchProcessData(countryKeys, false);
        fetchServiceData(countryKeys, []);
        fetchPeopleData(regionKeys, countryKeys, stateKeys, cityKeys);
        dispatch(updateFacilityStateFilter(stateKeys));
        dispatch(updatePeopleStateFilter(stateKeys));
        dispatch(updateFacilityCityFilter(cityKeys));
        dispatch(updatePeopleCityFilter(cityKeys));
        appInsights.trackMetric(
          { name: "Filter Used", average: countryCount },
          { Source: "Filter - Country" }
        );
      } else if (regionCount > 0 && countryCount > 0 && stateOptionsCount === 0) {
        // region selected and countries selected but no apply yet
        fetchFacilityData(regionKeys, true);
        fetchProcessData(regionKeys, true);
        fetchServiceData(countryKeys, []);
        fetchPeopleData(regionKeys, countryKeys, stateKeys, cityKeys);
        dispatch(updateFacilityCountryFilter(countryKeys));
        dispatch(updateSelectedCountries(countryKeys));
        appInsights.trackMetric(
          { name: "Filter Used", average: regionCount },
          { Source: "Filter - Region" }
        );
      } else {
        // region loaded, just country changed
        dispatch(updateFacilityCountryFilter(countryKeys));
        appInsights.trackEvent(
          { name: "Filter Used" },
          buildFilterEventCustomTelemetry("Country", countryKeys?.join(","))
        );
        dispatch(updateFacilityStateFilter(stateKeys));
        dispatch(updatePeopleStateFilter(stateKeys));
        dispatch(updateFacilityCityFilter(cityKeys));
        dispatch(updatePeopleCityFilter(cityKeys));
      }
    },
    [
      appInsights,
      currentSelectedRegions,
      dispatch,
      fetchFacilityData,
      fetchPeopleData,
      fetchProcessData,
      fetchServiceData,
    ]
  );

  const onClearButtonClicked = () => {
    batch(() => {
      // temp until filter components are synced
      currentSelectedRegions.length && dispatch(updateSelectedPhysicalRegions([]));
      currentSelectedCountries.length && dispatch(updateSelectedCountries([]));
      dispatch(clearAllServiceFilters());
      dispatch(clearPeopleDetails());
      dispatch(clearFacilityDetails());
      dispatch(clearProcessDetails());
      dispatch(clearServiceDetails());
      dispatch(clearDriGeoDetails());
      fetchInitialData();
    });
  };
  // handle user warning for many regions and countries
  const createWarningString = useCallback(
    (regionKeys: string[], regionSelectableOptions: IComboBoxOption[]): string => {
      if (regionKeys.length === regionSelectableOptions.length) {
        return "Selecting all regions may result in poor performance. Please confirm to continue.";
      } else {
        return "Selecting more than 25 countries may result in poor performance. Please confirm to continue.";
      }
    },
    []
  );

  const dialogContentProps = {
    type: DialogType.normal,
    title: "Performance Alert",
    subText: createWarningString(regionKeys, regionSelectableOptions),
  };

  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

  return (
    <>
      <Dialog
        hidden={hideDialog}
        onDismiss={toggleHideDialog}
        dialogContentProps={dialogContentProps}
        modalProps={{
          isBlocking: true,
          isAlert: true,
        }}
      >
        <DialogFooter>
          <PrimaryButton
            onClick={() => {
              onApplyButtonClicked(countryKeys, regionKeys, stateOptions, stateKeys, cityKeys);
              if (dcFilterActive) {
                onApplyServiceFilters(
                  selectedBusinessContinuityLeads,
                  selectedClouds,
                  selectedDivisions,
                  selectedOrgs,
                  selectedServiceGroups,
                  selectedDataCenters
                );
              }
              toggleHideDialog();
            }}
            text="Confirm"
          />
          <DefaultButton
            onClick={() => {
              toggleHideDialog();
            }}
            text="Cancel"
          />
        </DialogFooter>
      </Dialog>
      <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
        <ComboBox
          className={_Styles.smallComboBoxStyle}
          disabled={regionOptions.length === 0}
          label="Select an MS region"
          allowFreeform={true}
          options={regionOptions}
          multiSelect={true}
          onChange={onRegionChange}
          selectedKey={regionKeys}
          calloutProps={{ doNotLayer: true }}
        />
        {countryOptions?.length > 0 && (
          <ComboBox
            className={_Styles.smallComboBoxStyle}
            label="Select a country"
            options={countryOptions}
            multiSelect={true}
            disabled={countryOptions?.length === 0 || physicalRegionsLoading}
            onChange={onCountryChange}
            selectedKey={countryKeys}
            calloutProps={{ doNotLayer: true }}
          />
        )}
        {!peopleFilterActive && stateOptions.length > 0 && (
          <ComboBox
            className={_Styles.smallComboBoxStyle}
            label="Select a state/province"
            options={stateOptions}
            multiSelect={true}
            disabled={!stateOptions.length || isLoading}
            onChange={onStateChange}
            selectedKey={stateKeys}
            calloutProps={{ doNotLayer: true }}
          />
        )}
        {!peopleFilterActive && cityOptions.length > 0 && (
          <ComboBox
            className={_Styles.smallComboBoxStyle}
            label="Select a city"
            options={cityOptions}
            multiSelect={true}
            disabled={!cityOptions.length || isLoading}
            onChange={onCityChange}
            selectedKey={cityKeys}
            calloutProps={{ doNotLayer: true }}
          />
        )}
        {dcFilterActive && (
          <ComboBox
            className={_Styles.smallComboBoxStyle}
            label="Select a data center"
            options={dcOptions}
            multiSelect={true}
            disabled={!dcOptions.length || isLoading}
            onChange={onDcChange}
            selectedKey={selectedDataCenters}
            calloutProps={{ doNotLayer: true }}
          />
        )}
        <PrimaryButton
          text="Apply"
          onClick={() => {
            if (countryKeys.length > 25 || regionKeys.length === regionSelectableOptions.length) {
              toggleHideDialog();
            } else {
              onApplyButtonClicked(countryKeys, regionKeys, stateOptions, stateKeys, cityKeys);
              if (dcFilterActive) {
                onApplyServiceFilters(
                  selectedBusinessContinuityLeads,
                  selectedClouds,
                  selectedDivisions,
                  selectedOrgs,
                  selectedServiceGroups,
                  selectedDataCenters
                );
              }
            }
          }}
          disabled={regionOptions.length === 0}
          style={{ marginTop: "28px" }}
        />
        <DefaultButton
          text="Clear All"
          onClick={onClearButtonClicked}
          disabled={regionOptions.length === 0}
          style={{ marginTop: "28px" }}
        />
      </Stack>
      {dcFilterActive && (
        <ServiceFilterBase
          isLoading={isLoading}
          updateFilters={updateFilters}
          serviceFilterState={{
            selectedBusinessContinuityLeads: selectedBusinessContinuityLeads,
            selectedClouds: selectedClouds,
            selectedDivisions: selectedDivisions,
            selectedOrgs: selectedOrgs,
            selectedServiceGroups: selectedServiceGroups,
            selectedDataCenters: selectedDataCenters,
          }}
        />
      )}
    </>
  );
};

const textToKeyTextList = (list: string[]): IComboBoxOption[] =>
  list?.map((state: string) => ({ key: state, text: state }));
