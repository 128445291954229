//handle fetch of service dependency mapping item detail
export const FETCH_SERVICE_DEPENDENCY_OPTIONS_BEGIN =
  "FETCH_SERVICE_DEPENDENCY_OPTIONS_BEGIN";
export const FETCH_SERVICE_DEPENDENCY_OPTIONS_FAILURE =
  "FETCH_SERVICE_DEPENDENCY_ITEM_DETAIL_FAILURE";
export const FETCH_SERVICE_DEPENDENCY_OPTIONS_SUCCESS =
  "FETCH_SERVICE_DEPENDENCY_OPTIONS_SUCCESS";
export const SET_SERVICE_DEPENDENCY_OPTIONS = "SET_SERVICE_DEPENDENCY_OPTIONS";

//handle fetch of service dependency mapping item detail
export const FETCH_SERVICE_DEPENDENCY_ITEM_DETAIL_BEGIN =
  "FETCH_SERVICE_DEPENDENCY_ITEM_DETAIL_BEGIN";
export const FETCH_SERVICE_DEPENDENCY_ITEM_DETAIL_FAILURE =
  "FETCH_SERVICE_DEPENDENCY_ITEM_DETAIL_FAILURE";
export const FETCH_SERVICE_DEPENDENCY_ITEM_DETAIL_SUCCESS =
  "FETCH_SERVICE_DEPENDENCY_ITEM_DETAIL_SUCCESS";
export const SET_SERVICE_DEPENDENCY_ITEM_DETAIL =
  "SET_SERVICE_DEPENDENCY_ITEM_DETAIL";

//handle fetch of service depedency graph links
export const FETCH_SERVICE_DEPENDENCY_LINKS_BEGIN =
  "FETCH_SERVICE_DEPENDENCY_LINKS_BEGIN";
export const FETCH_SERVICE_DEPENDENCY_LINKS_FAILURE =
  "FETCH_SERVICE_DEPENDENCY_LINKS_FAILURE";
export const FETCH_SERVICE_DEPENDENCY_LINKS_SUCCESS =
  "FETCH_SERVICE_DEPENDENCY_LINKS_SUCCESS";

//handle fetch of popout panel service item detail
export const FETCH_SERVICE_DEPENDENCY_PANEL_ITEM_DETAIL_BEGIN =
  "FETCH_SERVICE_DEPENDENCY_PANEL_ITEM_DETAIL_BEGIN";
export const FETCH_SERVICE_DEPENDENCY_PANEL_ITEM_DETAIL_FAILURE =
  "FETCH_SERVICE_DEPENDENCY_PANEL_ITEM_DETAIL_FAILURE";
export const FETCH_SERVICE_DEPENDENCY_PANEL_ITEM_DETAIL_SUCCESS =
  "FETCH_SERVICE_DEPENDENCY_PANEL_ITEM_DETAIL_SUCCESS";
export const CLEAR_SERVICE_DEPENDENCY_PANEL_ITEM_DETAIL =
  "CLEAR_SERVICE_DEPENDENCY_PANEL_ITEM_DETAIL";

//handle fetch of service dependency dri data
export const FETCH_SERVICE_DEPENDENCY_DRI_DATA_BEGIN =
  "FETCH_SERVICE_DEPENDENCY_DRI_DATA_BEGIN";
export const FETCH_SERVICE_DEPENDENCY_DRI_DATA_FAILURE =
  "FETCH_SERVICE_DEPENDENCY_DRI_DATA_FAILURE";
export const FETCH_SERVICE_DEPENDENCY_DRI_DATA_SUCCESS =
  "FETCH_SERVICE_DEPENDENCY_DRI_DATA_SUCCESS";
export const SET_SERVICE_DEPENDENCY_DRI_DATA =
  "SET_SERVICE_DEPENDENCY_DRI_DATA";

//handle fetch of secondary service dependency panel dri data
export const FETCH_SERVICE_DEPENDENCY_PANEL_DRI_DATA_BEGIN =
  "FETCH_SERVICE_DEPENDENCY_PANEL_DRI_DATA_BEGIN";
export const FETCH_SERVICE_DEPENDENCY_PANEL_DRI_DATA_FAILURE =
  "FETCH_SERVICE_DEPENDENCY_PANEL_DRI_DATA_FAILURE";
export const FETCH_SERVICE_DEPENDENCY_PANEL_DRI_DATA_SUCCESS =
  "FETCH_SERVICE_DEPENDENCY_PANEL_DRI_DATA_SUCCESS";
export const CLEAR_SERVICE_DEPENDENCY_PANEL_DRI_DATA =
  "CLEAR_SERVICE_DEPENDENCY_PANEL_DRI_DATA";

//handle fetch of service dependency aggregated count data
export const FETCH_SERVICE_DEP_COUNTS_BEGIN = "FETCH_SERVICE_DEP_COUNTS_BEGIN";
export const FETCH_SERVICE_DEP_COUNTS_FAILURE =
  "FETCH_SERVICE_DEP_COUNTS_FAILURE";
export const FETCH_SERVICE_DEP_COUNTS_SUCCESS =
  "FETCH_SERVICE_DEP_COUNTS_SUCCESS";
