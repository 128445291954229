import * as React from "react";
import { DonutChart, IChartProps, IChartDataPoint, ILegend } from "@fluentui/react-charting";
import { getTheme } from "@fluentui/react/lib/Styling";

export interface InfoDonutChartProps {
  chartTitle: string;
  chartData: IChartDataPoint[];
  parentRef: HTMLElement;
  showLabels?: boolean;
}
const theme = getTheme();
export const pieChartColorOptions: string[] = [
  theme.palette.blue,
  theme.palette.blueDark,
  theme.palette.teal,
  theme.palette.purple,
  theme.palette.purpleLight,
  theme.palette.tealDark,
  theme.palette.magentaDark,
  theme.palette.green,
  theme.palette.magenta,
  theme.palette.orange,
  theme.palette.yellowDark,
  theme.palette.tealDark,
  theme.palette.purpleDark,
  theme.palette.greenDark,
  theme.palette.greenLight,
  theme.palette.magentaLight,
  theme.palette.blueLight,
  theme.palette.orangeLight,
];

export const criticalityColorOptions = [
  { label: "Mission Critical", color: pieChartColorOptions[0] },
  { label: "Critical", color: pieChartColorOptions[1] },
  { label: "Important", color: pieChartColorOptions[2] },
  { label: "Deferrable", color: pieChartColorOptions[5] },
  { label: "Unknown", color: pieChartColorOptions[3] },
  { label: "Not Yet Rated", color: pieChartColorOptions[4] },
  { label: "Minor", color: pieChartColorOptions[6] },
  { label: "Missing Parent", color: pieChartColorOptions[7] },
];

export const driProjectionColorOptions = [
  { label: "High", color: pieChartColorOptions[0] },
  { label: "Moderate", color: pieChartColorOptions[1] },
  { label: "Low", color: pieChartColorOptions[2] },
  { label: "No Impact", color: pieChartColorOptions[5] },
  { label: "No Team History", color: pieChartColorOptions[3] },
];

export const facTypeColorOptions = [
  { label: "RE&F DCs", color: pieChartColorOptions[0] },
  { label: "Network", color: pieChartColorOptions[1] },
  { label: "ILA", color: pieChartColorOptions[2] },
  { label: "Warehouse", color: pieChartColorOptions[3] },
  { label: "Lab", color: pieChartColorOptions[4] },
  { label: "CLS", color: pieChartColorOptions[5] },
  { label: "Off-Network Site", color: pieChartColorOptions[6] },
  { label: "Circular Center", color: pieChartColorOptions[7] },
  { label: "IT Termination", color: pieChartColorOptions[8] },
  { label: "Office", color: pieChartColorOptions[9] },
  { label: "Serviced Office", color: pieChartColorOptions[10] },
  { label: "Business Use", color: pieChartColorOptions[11] },
  { label: "Employee Services", color: pieChartColorOptions[12] },
  { label: "Production", color: pieChartColorOptions[13] },
  { label: "R&D", color: pieChartColorOptions[14] },
  { label: "Land", color: pieChartColorOptions[15] },
  { label: "E-Suite", color: pieChartColorOptions[16] },
  { label: "Retail", color: pieChartColorOptions[17] },
];

export const coiDcClassColorOptions = [
  { label: "Class A", color: pieChartColorOptions[0] },
  { label: "Class B", color: pieChartColorOptions[1] },
  { label: "Class C", color: pieChartColorOptions[2] },
  { label: "Class D", color: pieChartColorOptions[3] },
  { label: "Class E", color: pieChartColorOptions[4] },
  { label: "Class F", color: pieChartColorOptions[5] },
  { label: "Class G", color: pieChartColorOptions[6] },
  { label: "Not Applicable", color: pieChartColorOptions[8] },
];

export default function InfoDonutChart(props: InfoDonutChartProps) {
  const data: IChartProps = {
    chartTitle: props.chartTitle,
    chartData: props.chartData,
    chartTitleAccessibilityData: { ariaLabel: `${props.chartTitle}` },
  };

  const makeLegends = (): ILegend[] => {
    return props.chartData.map((cd) => {
      let newLegend: ILegend = {
        title: cd.legend,
        action: cd.onClick,
        color: cd.color,
      };
      return newLegend;
    });
  };

  return (
    <DonutChart
      data={data}
      innerRadius={60}
      legendProps={{
        legends: makeLegends(),
        enabledWrapLines: true,
        allowFocusOnLegends: true,
      }}
      hideLabels={!props.showLabels}
      width={props.parentRef?.offsetWidth ? props.parentRef.offsetWidth : 300}
      valueInsideDonut={props?.chartData.reduce((acc, curr) => (acc += curr.data), 0) ?? null}
    />
  );
}
