import { MessageBar, MessageBarButton, MessageBarType, ScrollablePane } from "@fluentui/react";
import {
  AppInsightsErrorBoundary,
  useAppInsightsContext,
} from "@microsoft/applicationinsights-react-js";
import { FilterBase } from "components/filter/FilterBase";
import { Overview } from "components/pivots/Overview";
import { Error } from "pages/Error";
import { _Styles } from "pages/Page.styles";
import { useEffect, useState } from "react";
import { reactPlugin } from "services/AppInsights";
import { fetchFacilities, fetchFacilityCounts } from "store/actions/facilitiesActions";
import { fetchPeopleCounts, fetchPeopleLocationData } from "store/actions/peopleActions";
import { fetchProcessDetails } from "store/actions/processesActions";
import { fetchAzureRegions, fetchPhysicalRegions } from "store/actions/regionsActions";
import { fetchServiceCounts, fetchServiceDetails } from "store/actions/servicesActions";
import {
  fetchDriGeoResilienceCounts,
  fetchRegionDriGeoResilience,
} from "store/actions/servicesDriGeoResilienceActions";
import { getFacilitiesError, getSelectedCountries } from "store/selectors/facilitiesSelector";
import { getPeopleDirectsError } from "store/selectors/peopleSelector";
import { getProcessAssetDetailsError } from "store/selectors/processesSelector";
import { getMSRegionsError, getSelectedPhysicalRegions } from "store/selectors/regionsSelector";
import { getServiceAssetDetailsError } from "store/selectors/servicesSelector";
import { useAppDispatch, useAppSelector } from "store/store";

export interface HomeProps {
  isNavCollapsed: boolean;
}

export function Home({ isNavCollapsed }: HomeProps) {
  const dispatch = useAppDispatch();
  const appInsights = useAppInsightsContext();

  const currentRegions = useAppSelector(getSelectedPhysicalRegions);
  const currentSelectedCountries = useAppSelector(getSelectedCountries);
  //error setup
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const regionApiErrors = useAppSelector(getMSRegionsError);
  const facilityApiErrors = useAppSelector(getFacilitiesError);
  const serviceApiErrors = useAppSelector(getServiceAssetDetailsError);
  const processApiErrors = useAppSelector(getProcessAssetDetailsError);
  const peopleApiError = useAppSelector(getPeopleDirectsError);

  const trackAndDispatch = (errorCondition, trackEventName, actions) => {
    if (!errorCondition) return;

    appInsights.trackEvent({ name: trackEventName });
    actions.forEach((action) => action());
  };

  const dispatchWithCondition = (condition, action) => {
    if (condition) dispatch(action);
  };

  const retryApiCalls = () => {
    const currentRegionList = currentRegions.map((r) => r.regionDescription);

    trackAndDispatch(regionApiErrors, "Region fetch retried", [
      () => dispatch(fetchPhysicalRegions()),
      () => dispatch(fetchAzureRegions()),
    ]);

    trackAndDispatch(facilityApiErrors, "Facility fetch retried", [
      () => dispatch(fetchFacilityCounts([], false)),
      () =>
        dispatchWithCondition(currentRegionList.length, fetchFacilities(currentRegionList, true)),
      () =>
        dispatchWithCondition(
          currentSelectedCountries.length,
          fetchFacilities(currentSelectedCountries, false)
        ),
    ]);

    if (serviceApiErrors) {
      dispatch(fetchServiceCounts());
      dispatch(fetchDriGeoResilienceCounts());
      dispatchWithCondition(
        currentRegionList.length,
        fetchServiceDetails(currentSelectedCountries)
      );
      dispatchWithCondition(
        currentSelectedCountries.length,
        fetchRegionDriGeoResilience(currentSelectedCountries, false)
      );
      appInsights.trackEvent({ name: "Service fetch retried" });

      trackAndDispatch(processApiErrors, "Process fetch retried", [
        () =>
          dispatchWithCondition(
            currentRegionList.length,
            fetchProcessDetails(currentRegionList, true)
          ),
        () =>
          dispatchWithCondition(
            currentSelectedCountries.length,
            fetchProcessDetails(currentSelectedCountries, false)
          ),
      ]);

      trackAndDispatch(peopleApiError, "People fetch retried", [
        () => dispatch(fetchPeopleCounts()),
        () => dispatch(fetchPeopleLocationData(currentRegionList, currentSelectedCountries)),
      ]);
    }
  };

  useEffect(() => {
    const errors = [facilityApiErrors, serviceApiErrors, processApiErrors, regionApiErrors].filter(
      (e) => e !== null
    );
    const errorString =
      errors.length > 0 ? errors.join("\u000A").concat("\u000AClick retry to attempt again") : null;
    if (errorString !== null) {
      setErrorMessage(errorString);
      setShowErrorMessage(true);
    }
  }, [regionApiErrors, facilityApiErrors, serviceApiErrors, processApiErrors]);

  return (
    <AppInsightsErrorBoundary
      onError={(err: Error) => <Error isNavCollapsed={isNavCollapsed} errorMessage={err.message} />}
      appInsights={reactPlugin}
    >
      <ScrollablePane
        className={isNavCollapsed ? _Styles.scrollablePaneCollapsed : _Styles.scrollablePaneExpand}
      >
        {showErrorMessage && (
          <MessageBar
            style={{ whiteSpace: "pre-wrap" }}
            messageBarType={MessageBarType.error}
            isMultiline={true}
            onDismiss={() => setShowErrorMessage(false)}
            dismissButtonAriaLabel="Close"
            actions={
              <div>
                <MessageBarButton onClick={() => retryApiCalls()}>Retry</MessageBarButton>
              </div>
            }
          >
            {errorMessage}
          </MessageBar>
        )}
        <h1 className={_Styles.header}>Crisis Response Situational Reporting</h1>
        <FilterBase needsDcFilter={false} />
        <Overview />
      </ScrollablePane>
    </AppInsightsErrorBoundary>
  );
}
