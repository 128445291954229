import { AccountInfo } from "@azure/msal-browser";
import { CoherenceHeader, initializeOBFeedback } from "@coherence-design-system/controls";
import { ActionButton, Label, Separator, Stack, Text } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { fetchAzureMapKeyData, fetchSystemCacheData } from "store/actions/systemActions";
import { setActiveUser } from "store/actions/userActions";
import {
  getCacheRefreshDataLoadingStatus,
  getSystemAzureMapKeyLoadingStatus,
  getSystemCacheRefreshData,
} from "store/selectors/systemSelector";
import { useAppDispatch, useAppSelector } from "store/store";
import { _Styles } from "../../pages/Page.styles";

interface Header {
  account: AccountInfo[];
  onLogOut: () => unknown;
}

export default function CustomHeader({ account: [accountInfo] = [], onLogOut }: Header) {
  const dispatch = useAppDispatch();
  const cacheRefreshData = useAppSelector(getSystemCacheRefreshData);
  const cachRefreshDataLoading = useAppSelector(getCacheRefreshDataLoadingStatus);
  const azueMapKeyLoading = useAppSelector(getSystemAzureMapKeyLoadingStatus);

  useEffect(() => {
    if (accountInfo) {
      dispatch(setActiveUser(accountInfo));
    }
    if (cacheRefreshData?.length < 1 && !cachRefreshDataLoading && !azueMapKeyLoading) {
      dispatch(fetchSystemCacheData());
      dispatch(fetchAzureMapKeyData());
    }
  }, [accountInfo, dispatch, cachRefreshDataLoading, cacheRefreshData?.length, azueMapKeyLoading]);

  const makeRolesList = (roleValue: string) => {
    switch (roleValue) {
      case "Bcl.Read": {
        return "BCLs";
      }
      case "Enr.Read": {
        return "EnR";
      }
      case "Ecmp.Read": {
        return "ECMP";
      }
      case "DsrLT.Read": {
        return "DSR LT";
      }
      case "Admins.Read": {
        return "Admin";
      }
      case "Guests.Read": {
        return "Guests";
      }
      case "Site.UAT": {
        return "UATAccess";
      }
      case "Site.View": {
        return "Users";
      }
      default:
        return roleValue ?? "Unknown";
    }
  };

  const makeSourceDataList = (sourceValue: string) => {
    switch (sourceValue) {
      case "driGeoCacheRefresh": {
        return "DRI Geographic Location";
      }
      case "azureDCMTKustoAzArmDcMappingCacheRefresh":
      case "azArmCacheRefresh":
      case "azureDCMTKustoAzArmRegionCacheRefresh": {
        return "Azure DCMT Kusto";
      }
      case "driInsightsCacheRefresh": {
        return "DRI Insights";
      }
      case "egrcDbCacheRefresh": {
        return "EGRC";
      }
      case "serviceDepGuidNamePairCacheRefresh":
      case "serviceDepMetaDataCacheRefresh": {
        return "Service Dependency Kusto";
      }
      case "servicesCacheRefresh":
      case "serviceCountCacheRefresh": {
        return "Service Kusto";
      }
      case "sipDbFacilityCacheRefresh":
      case "sipDbPeopleCacheRefresh": {
        return "SIP DB";
      }
      case "icmKustoCacheRefresh": {
        return "ICM Kusto";
      }
      default:
        return sourceValue;
    }
  };

  const userRoles = accountInfo?.idTokenClaims?.roles;

  const getUserRoles = function (userRoles: any) {
    return (
      <Stack>
        <Label>Assigned Roles</Label>
        {(userRoles ?? []).map((role: any) => {
          const currentRoles = makeRolesList(role);
          return <Text key={role}>{currentRoles}</Text>;
        })}
      </Stack>
    );
  };

  const [releaseDateTime, setReleaseDateTime] = useState<string | undefined>(null);

  useEffect(() => {
    if (process.env.REACT_APP_DEPLOYMENT_STARTTIME) {
      const releaseDateTime = new Date(process.env.REACT_APP_DEPLOYMENT_STARTTIME);
      const releaseDateTimeString = new Intl.DateTimeFormat("en-US", {
        dateStyle: "short",
        timeStyle: "long",
        timeZone: "UTC",
      }).format(releaseDateTime);
      setReleaseDateTime(releaseDateTimeString);
    }
  }, []);

  const currentUserRoleList = getUserRoles(userRoles);
  const [ocvButtonIsFocused, setOcvButtonIsFocused] = useState<boolean>();
  useEffect(() => {
    initializeOcv();
  }, []);

  const initializeOcv = () => {
    const ocvAppId = Number(process.env.REACT_APP_OCV_APP_ID);
    const ocvEnvironment = process.env.REACT_APP_OCV_APP_ENV;

    initializeOBFeedback(
      ocvAppId,
      ocvEnvironment,
      "/ocv/officebrowserfeedback.min.js",
      "/ocv/officebrowserfeedback.min.css",
      "/ocv/intl/",
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      (error: string) => {
        const ocvButton = document.getElementById("coher-header-ocv-button-id");
        if (ocvButton) {
          ocvButton.focus();
        }
        setOcvButtonIsFocused(false);
      },
      undefined,
      undefined,
      undefined,
      undefined,
      (submitted: boolean) => {
        const ocvButton = document.getElementById("coher-header-ocv-button-id");
        if (ocvButton) {
          ocvButton.focus();
        }
        setOcvButtonIsFocused(false);
      },
      true,
      undefined,
      undefined,
      undefined,
      undefined,
      true
    );
  };

  const mapRefreshData = (originalRefreshData) => {
    const temp = Object.entries(originalRefreshData).reduce((newList, [key, value]) => {
      const newKey = makeSourceDataList(key);
      if (!newList[newKey] || value > newList[newKey]) {
        newList[newKey] = value;
      }
      return newList;
    }, {});
    return Object.entries(temp).map(([newKey, value]) => (
      <Text key={newKey}>
        <b>{newKey}</b>:{" "}
        {new Date(value.toString()).toLocaleString("en-US", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        })}
      </Text>
    ));
  };

  const settingsBody: JSX.Element = (
    <>
      <Stack>
        <Label>Last Cache Refresh Date and Time (UTC)</Label>
        {cacheRefreshData && mapRefreshData(cacheRefreshData)}
      </Stack>
      <Separator></Separator>
      <Stack>
        <Label className={_Styles.boldFont}>Build Number</Label>
        {process.env.REACT_APP_BUILD_NUMBER ?? "Unknown"}
      </Stack>
      <Stack>
        <Label className={_Styles.boldFont}>Release Name</Label>
        {process.env.REACT_APP_RELEASE_NAME ?? "Unknown"}
      </Stack>
      <Stack>
        <Label className={_Styles.boldFont}>Release Deployment Date and Time</Label>
        {releaseDateTime ?? "Unknown"};
      </Stack>
    </>
  );

  const profileBody: JSX.Element = (
    <>
      {currentUserRoleList}
      <Separator></Separator>
      <ActionButton
        iconProps={{ iconName: "Lock" }}
        onClick={() =>
          window.open("https://privacy.microsoft.com/en-US/data-privacy-notice", "_blank")
        }
      >
        Data Privacy Notice
      </ActionButton>
    </>
  );

  return (
    <CoherenceHeader
      headerLabel={"header"}
      appNameSettings={{
        label: process.env.REACT_APP_NAME,
      }}
      farRightSettings={{
        feedbackSettings: {
          panelSettings: {
            ocvButtonIsFocused: ocvButtonIsFocused,
            onClick: () => {
              setOcvButtonIsFocused(true);
              return true;
            },
            launchOptions: {
              categories: {
                show: true,
                customCategories: ["Overview", "Facilities", "Services", "Processes", "Other"],
              },
            },
          },
        },
        settingsSettings: {
          buttonSettings: {
            title: "Settings",
            ariaLabel: "Settings",
          },
          panelSettings: {
            titleText: "Settings",
            body: settingsBody,
          },
        },
        profileSettings: {
          buttonSettings: {
            title: "Profile",
            ariaLabel: "Profile",
          },
          panelSettings: {
            titleText: "Profile",
            fullName: accountInfo ? accountInfo.name : "Error",
            emailAddress: accountInfo ? accountInfo.username : "Error",
            imageUrl: "",
            logOutLink: "",
            body: profileBody,
            onLogOut: onLogOut,
            viewAccountLink: "https://myaccount.microsoft.com",
            onViewAccount: () => window.open("https://myaccount.microsoft.com", "_blank"),
          },
        },
      }}
    />
  );
}
