import React from "react";
import "d3-transition";
import "d3-selection";
import { FocusZone, IProcessedStyleSet, getTheme } from "@fluentui/react";
import { DependencyGraphBase } from "./DependencyGraphBase";
import {
  IDependencyGraphStyles,
  ServiceDependencyGraphProps,
} from "./ServiceDependencyGraph.types";

import { getClassNames, getStyles } from "./ServiceDependencyGraph.styles";

/**
 * V1 of accessible service dependency graph. The graph base can be easily
 * copied and modified to create a new graph specific to other uses.
 */
export const ServiceDependencyGraph = (props: ServiceDependencyGraphProps) => {
  // chart settings
  const height = props.height ?? 500;
  const width = props.width ?? 500;
  const margin = { top: 100, right: 40, bottom: 10, left: 40 };
  const rectangle = {
    width: 250,
    height: 150,
    textMargin: 8,
  };
  // import theme to support accessible palette
  const theme = getTheme();
  const styleClassNames: IProcessedStyleSet<IDependencyGraphStyles> =
    getClassNames(getStyles(theme));

  return (
    <FocusZone>
      <div className={styleClassNames.root}>
        <DependencyGraphBase
          nodes={props.nodes}
          links={props.links}
          linkTypes={Array.from(
            new Set(props.links.map((link) => link.direction))
          )}
          rectangle={rectangle}
          styleClassNames={styleClassNames}
          theme={theme}
          chartHeight={height}
          chartWidth={width}
          margin={margin}
          legend={props.legend}
          handleOnClick={props.handleOnClick}
        />
      </div>
    </FocusZone>
  );
};
