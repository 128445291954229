import { AccountInfo } from "@azure/msal-browser";
import { SET_USER } from "../actions/actionTypes";

export interface ReduxUserState {
  userName: string;
  userEmail: string;
  userCanDownload: boolean;
  userIsRestricted: boolean;
}

const userInitialState: ReduxUserState = {
  userName: null,
  userEmail: null,
  userCanDownload: false,
  userIsRestricted: true,
};

const setDownloadPermissions = (accountInfo: AccountInfo) => {
  const idTokenClaims = accountInfo.idTokenClaims;
  if (idTokenClaims === null) {
    return false;
  }
  const siteRoles: string[] = idTokenClaims["roles"];
  //anyone but guest users can download
  return !siteRoles.find((roleName) => roleName === "Guests.Read");
};

const setRestrictionPermissions = (accountInfo: AccountInfo) => {
  const idTokenClaims = accountInfo.idTokenClaims;
  if (idTokenClaims === null) {
    return false;
  }
  const siteRoles: string[] = idTokenClaims["roles"];
  //is the user a guest or bcl with restricted view permissions?
  return siteRoles.includes("Guests.Read") || siteRoles.includes("Bcl.Read");
};

export default function users(
  state: ReduxUserState = userInitialState,
  action: any
) {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        userName: action.payload.name,
        userEmail: action.payload.userName,
        userCanDownload: setDownloadPermissions(action.payload),
        userIsRestricted: setRestrictionPermissions(action.payload),
      };
    default:
      return state;
  }
}
