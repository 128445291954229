import { ComboBox, IComboBox, IComboBoxOption, ISelectableOption, Separator, Stack, } from "@fluentui/react";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { buildFilterEventCustomTelemetry } from "components/datagrids/Helpers";
import { defaultStackTokens } from "pages/Page.styles";
import React, { FormEvent, useEffect, useMemo, useState } from "react";
import { updateProcessCriticalityFilters, updateProcessDivisionFilters, updateProcessFacilityFilters } from "store/actions/processesActions";
import { getProcessDivisionFilters, getProcessDivisionOptions, getProcessFacilityFilters, getProcessFacilityOptions, getProcessRTOCriticalityFilters, getProcessRTOCriticalityOptions } from "store/selectors/processesSelector";
import { useAppDispatch, useAppSelector } from "store/store";

const panelComboBoxStyle = { optionsContainerWrapper: { maxHeight: 400 } };

export const ProcessesFilter = () => {
  const appInsights = useAppInsightsContext();
  const dispatch = useAppDispatch()

  //criticality filter setup
  const processCriticalityFiltersState = useAppSelector(getProcessRTOCriticalityFilters);
  const processCriticalityOptionsState = useAppSelector(getProcessRTOCriticalityOptions);
  const [processCriticalOptions, setProcessCriticalOptions] = useState<ISelectableOption[]>([]);
  useMemo(() => { setProcessCriticalOptions(processCriticalityOptionsState as ISelectableOption[]); }, [processCriticalityOptionsState]);

  const onProcessCriticalityChange = (
    _event: FormEvent<IComboBox>,
    option: IComboBoxOption
  ): void => {
    if (option) {
      const selections = option.selected
        ? [...processCriticalityFiltersState, option.key as string]
        : processCriticalityFiltersState.filter((key) => key !== option.key);
      dispatch(updateProcessCriticalityFilters(selections));
      appInsights.trackEvent(
        { name: "Filter Used" },
        buildFilterEventCustomTelemetry(
          "RTO Criticality - Process",
          option.text
        )
      );
    }
  };

  //division filter setup
  const processDivisionFiltersState = useAppSelector(getProcessDivisionFilters);
  const processDivisionOptionsState = useAppSelector(getProcessDivisionOptions);
  const [processDivisionOptions, setDivisionOptions] = useState<
    IComboBoxOption[]
  >([]);
  useEffect(() => {
    setDivisionOptions(processDivisionOptionsState);
  }, [processDivisionOptionsState]);

  const onProcessDivisionChange = (
    _event: FormEvent<IComboBox>,
    option: IComboBoxOption
  ): void => {
    if (option) {
      const selections = option.selected
        ? [...processDivisionFiltersState, option.key as string]
        : processDivisionFiltersState.filter((key) => key !== option.key);
      dispatch(updateProcessDivisionFilters(selections));
      appInsights.trackEvent(
        { name: "Filter Used" },
        buildFilterEventCustomTelemetry("Division - Process", option.text)
      );
    }
  };

  //facility filter setup
  const processFacilityOptionsState = useAppSelector(getProcessFacilityOptions);
  const processFacilityFiltersState = useAppSelector(getProcessFacilityFilters);
  const [processFacilityOptions, setFacilityOptions] = useState<ISelectableOption[]>([]);
  useEffect(() => { setFacilityOptions(processFacilityOptionsState); }, [processFacilityOptionsState]);

  const onProcessFacilityChange = (_event: never, option: IComboBoxOption): void => {
    if (option) {
      const selections = option.selected
        ? [...processFacilityFiltersState, option.key as string]
        : processFacilityFiltersState.filter((key) => key !== option.key);
      dispatch(updateProcessFacilityFilters(selections));
      appInsights.trackEvent(
        { name: "Filter Used" },
        buildFilterEventCustomTelemetry("Facility - Process", option.text)
      );
    }
  };

  return (
    <Stack tokens={defaultStackTokens}>
      <ComboBox
        multiSelect
        label="Filter by criticality rating"
        styles={panelComboBoxStyle}
        options={processCriticalOptions}
        onChange={onProcessCriticalityChange}
        selectedKey={processCriticalityFiltersState}
      />
      <Separator />
      <ComboBox
        styles={panelComboBoxStyle}
        multiSelect={true}
        label="Filter by divsion"
        options={processDivisionOptions}
        onChange={onProcessDivisionChange}
        selectedKey={processDivisionFiltersState}
      />
      <Separator />
      <ComboBox
        styles={panelComboBoxStyle}
        multiSelect={true}
        label="Filter by facility"
        options={processFacilityOptions}
        onChange={onProcessFacilityChange}
        selectedKey={processFacilityFiltersState}
      />
    </Stack>
  );
};
