import { FontWeights, IContextualMenuItem, IContextualMenuProps, Text, ThemeProvider, memoizeFunction, mergeStyles, } from "@fluentui/react";
import { DashboardCard } from "@m365-admin/card";
import { M365LightTheme, useM365Theme } from "@m365-admin/customizations";
import { Dashboard } from "@m365-admin/dashboard";
import { IRearrangeableGridDefinition, IRearrangeableGridGap } from "@m365-admin/rearrangeable-grid";
import { CardSizeValues, CardSizes } from "components/Cards.constants";
import { FacilityAggregateCountBar } from "components/charts/FacilityAggregateCountBar";
import { ProcessAggregateCountBar } from "components/charts/ProcessAggregateCountBar";
import { ServiceAggregateCountBar } from "components/charts/ServiceAggregateCountBar";
import { ServiceDriAggregateCountBar } from "components/charts/ServiceDriAggregateCountBar";
import { PeopleAggregateCountBar } from "components/charts/people/PeopleAggregateCountBar";
import Map from "components/mapping/Map";
import { FacilityOverviewCallout, PeopleOverviewCallout, ProcessCriticality, ServiceBreakdown } from "components/modals/InsightCallout";
import React from "react";
import { getFacilityCountLoading } from "store/selectors/facilitiesSelector";
import { getPeopleCountsLoadingStatus } from "store/selectors/peopleSelector";
import { getProcessCountsLoadingStatus } from "store/selectors/processesSelector";
import { getServiceCountsLoadingStatus } from "store/selectors/servicesSelector";
import { useAppSelector } from "store/store";

export function Overview() {
  const facilityCountLoading = useAppSelector(getFacilityCountLoading);
  const serviceCountLoading = useAppSelector(getServiceCountsLoadingStatus);
  const processCountLoading = useAppSelector(getProcessCountsLoadingStatus);
  const peopleCountLoading = useAppSelector(getPeopleCountsLoadingStatus);

  const headerStyles = memoizeFunction(mergeStyles);
  const getCardTitle = (title: string) => {
    const titleClassName = headerStyles([
      theme.fonts.medium,
      { padding: 0, margin: 0, fontWeight: FontWeights.semibold },
    ]);

    return <h3 className={titleClassName}>{title}</h3>;
  };

  const theme = useM365Theme();

  const backgroundStyles = mergeStyles({
    background: theme.semanticColors.dashboardBackdrop,
    paddingTop: 24,
    paddingBottom: 24,
    paddingRight: 16,
    paddingLeft: 16,
  });

    // store the state of the Dashboard and handle changes within it
    const [map, setMap] = React.useState<IRearrangeableGridDefinition>({
      peopleOverviewCard: { cellHeight: 1, cellWidth: 1, row: 0, col: 0 },
      facilitiesOverviewCard: { cellHeight: 1, cellWidth: 1, row: 0, col: 1},
      regionalMapCard: { cellHeight: 2, cellWidth: 3, row: 0, col: 3, },
      servicesOverviewCard: { cellHeight: 1, cellWidth: 1, row: 0, col: 2 },
      driGeoCountOverviewCard: { cellHeight: 1, cellWidth: 1, row: 1, col: 0 },
      processesOverviewCard: { cellHeight: 1, cellWidth: 1, row: 1, col: 1},

    });

  //handle resizing in the dashboard grid
 const resizeCallback =
  React.useRef<
    (
      width: number,
      height: number,
      itemKey: string,
      position?: { col: number; row: number },
    ) => void
  >();

  const generateResizeCallback = (key: string) => {
    return (
      ev: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>,
      item: IContextualMenuItem,
    ) => {
      const dimension = CardSizeValues[item.key as CardSizes];

      resizeCallback.current?.(dimension.cellWidth, dimension.cellHeight, key);
    };
  };

  const columnResizeRef =
    React.useRef<(width: number, height: number, gap: IRearrangeableGridGap) => void>();
  const columnResize = (width: number, height: number, gap: IRearrangeableGridGap) => {
    columnResizeRef.current?.(width, height, gap);
  };

  const onChange = (
    newMap: IRearrangeableGridDefinition,
  ) => {
    setMap(newMap);
  };
  const resizeText = 'Resize';
  const moreDetailsText = 'Info';

  const generateCallout = (calloutName: string) => {
    switch (calloutName){
      case "people":return <div data-is-focusable><PeopleOverviewCallout /><Text>Info</Text></div>;
      case "facilities":return <div data-is-focusable><FacilityOverviewCallout /><Text>Info</Text></div>;
      case "services":return <div data-is-focusable><ServiceBreakdown /><Text>Info</Text></div>;
      case "processes":return <div data-is-focusable><ProcessCriticality /><Text>Info</Text></div>;
    }
  }

  const generateItemMenu = (key: string, calloutName: string): IContextualMenuProps => {
    let items:IContextualMenuItem[] = [
      {
        iconProps: { iconName: 'MiniExpand' },
        key: resizeText,
        text: resizeText,
        subMenuProps: {
          items: [
            { key: CardSizes.small, text: CardSizes.small },
            { key: CardSizes.medium, text: CardSizes.medium },
            { key: CardSizes.mediumTall, text: CardSizes.mediumTall },
            { key: CardSizes.mediumWide, text: CardSizes.mediumWide },
            { key: CardSizes.large, text: CardSizes.large },
          ],
          onItemClick: generateResizeCallback(key),
        },
      }]
    if (calloutName){
      items = [...items, {
        iconProps: { iconName: 'Info' },
        key: moreDetailsText,
        text: moreDetailsText,
        data: generateCallout(calloutName),
        onRender: (item) => item.data
      }]
    }
    return { items };
  };

  return (
    <ThemeProvider theme={M365LightTheme.settings.theme}>
      <div className={backgroundStyles}>
      <Dashboard
        map={map}
        onChange={onChange}
        ariaLabel="M365 Dashboard"
        ariaDescription="Use the arrow keys to navigate to a card. Press alt-shift-arrow to move a card. Press enter key to enter each item. Press escape to return to the grid when within an item."
        rearrangeableGridProps={{
          resizeHandler: resizeCallback,
          onColumnResize: columnResize,
          minCellWidth: 250,
          minCellHeight: 200
        }}
      >
        <DashboardCard
          key={"peopleOverviewCard"}
          titleText={getCardTitle("People Overview")}
          isLoading={peopleCountLoading}
          moreIconButtonProps={{
            ariaLabel: "More Actions",
            menuProps: generateItemMenu("peopleOverviewCard", "people")
          }}
        >
          <PeopleAggregateCountBar />
        </DashboardCard>

        <DashboardCard
          key={"facilitiesOverviewCard"}
          titleText={getCardTitle("Facilities Overview")}
          isLoading={facilityCountLoading}
          moreIconButtonProps={{
            ariaLabel: "More Actions",
            menuProps: generateItemMenu("facilitiesOverviewCard", "facilities")
          }}
        >
          <FacilityAggregateCountBar />
        </DashboardCard>

        <DashboardCard
          key={"servicesOverviewCard"}
          titleText={getCardTitle("Services Overview")}
          isLoading={serviceCountLoading}
          moreIconButtonProps={{
            ariaLabel: "More Actions",
            menuProps: generateItemMenu("servicesOverviewCard", "services")
          }}
        >
          <ServiceAggregateCountBar />
        </DashboardCard>
        <DashboardCard
          key={"driGeoCountOverviewCard"}
          titleText={getCardTitle("DRI Overview")}
          isLoading={serviceCountLoading}
          moreIconButtonProps={{
            ariaLabel: "More Actions",
            menuProps: generateItemMenu("driGeoCountOverviewCard", null)
          }}
        >
          <ServiceDriAggregateCountBar />
        </DashboardCard>
        <DashboardCard
          key={"processesOverviewCard"}
          titleText={getCardTitle("Processes Overview")}
          isLoading={processCountLoading}
          moreIconButtonProps={{
            ariaLabel: "More Actions",
            menuProps: generateItemMenu("processesOverviewCard", "processes")
          }}
        >
          <ProcessAggregateCountBar />
        </DashboardCard>
        <DashboardCard
          key={"regionalMapCard"}
          titleText={getCardTitle("Regional Facility Map")}
          moreIconButtonProps={{
            ariaLabel: "More Actions",
            menuProps: generateItemMenu("regionalMapCard", null)
          }}
        >
          <Map />
        </DashboardCard>
        </Dashboard>
      </div>
    </ThemeProvider>
  );
}
