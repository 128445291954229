import { CountAnnotationBar, CountAnnotationBarDirection, ICountAnnotationProps } from "@m365-admin/count-annotation";
import React, { useMemo } from "react";
import { getDependencyGapCounts } from "store/selectors/serviceDependencyMappingSelector";
import { useAppSelector } from "store/store";

export const ServiceDepGapAnnotationCard = () => {
  const [dependencyGapCountProps, setDependencyGapCountProps] = React.useState<ICountAnnotationProps[]>([]);
  const dependencyGapAggCounts: ICountAnnotationProps[] = useAppSelector(getDependencyGapCounts);

  useMemo(() => {
    const dependencyGapCounts = dependencyGapAggCounts;
    if (
      dependencyGapAggCounts &&
      dependencyGapAggCounts[0]?.count !== dependencyGapCountProps[0]?.count
    )
      setDependencyGapCountProps(dependencyGapCounts);
  }, [dependencyGapAggCounts]);

  return (
    <CountAnnotationBar
      countAnnotationProps={dependencyGapCountProps ?? []}
      direction={CountAnnotationBarDirection.Horizontal}
    />
  );
};
