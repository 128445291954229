import { navCollapsedWidth } from "@m365-admin/nav";
import { getScrollBarWidth } from "@coherence-design-system/controls";
import {
  IStyle,
  mergeStyleSets,
  getTheme,
  IScrollablePaneStyles,
  ISearchBoxStyles,
} from "@fluentui/react";

const theme = getTheme();
const scrollablePaneStyles = {
  position: "fixed",
  top: 48,
  bottom: 0,
  right: 0,
};

let scrollBarWidth = 0;
let currentZoomLevel = 0;

const calculcateScrollBarWidth = () => {
  [scrollBarWidth, currentZoomLevel] = getScrollBarWidth(
    scrollBarWidth,
    currentZoomLevel,
    window.devicePixelRatio
  );
  return scrollBarWidth;
};

export const _Styles = mergeStyleSets({
  scrollablePaneCollapsed: {
    ...scrollablePaneStyles,
    left: navCollapsedWidth + calculcateScrollBarWidth() + 10,
  } as IStyle,
  scrollablePaneExpand: {
    ...scrollablePaneStyles,
    left: 228 + calculcateScrollBarWidth() + 10,
  } as IStyle,
  rootDiv: {
    paddingRight: "30px",
    paddingLeft: "10px",
  } as IStyle,
  dropdownDefault: {
    minWidth: 300,
    dropdownItemSelected: {
      selectors: {
        "&:before": {
          content: '""',
          position: "absolute",
          left: 0,
          top: 0,
          bottom: 0,
          width: "4px",
          background: "rgb(0, 120, 212)",
        },
      },
    },
  } as IStyle,
  dropdownSmall: {
    minWidth: 200,
    maxWidth: 500,
    dropdownItemSelected: {
      selectors: {
        "&:before": {
          content: '""',
          position: "absolute",
          left: 0,
          top: 0,
          bottom: 0,
          width: "4px",
          background: "rgb(0, 120, 212)",
        },
      },
    },
  } as IStyle,
  smallComboBoxStyle: {
    optionsContainer: { minWidth: 150, maxHeight: 400 },
  } as IStyle,
  dividerLine: {
    width: "100%",
    height: "1px",
    backgroundColor: "black",
    marginBottom: "20px",
  } as IStyle,
  rowGap: {
    height: "30px",
  } as IStyle,
  centeredDiv: {
    justifyContent: "center",
    display: "flex",
    textAlign: "center",
    alignSelf: "center",
    alignItems: "center",
    marginRight: "auto",
    marginLeft: "auto",
    padding: "100px",
    whiteSpace: "pre-wrap",
  } as IStyle,
  spacer: {
    height: "480px",
  } as IStyle,
  header: {
    marginTop: "48px",
    marginBottom: "16px",
  } as IStyle,
  fullDiv: {
    height: "100%",
    width: "100%",
    justifyContent: "center",
  } as IStyle,
  tinyCard: {
    maxWidth: 400,
    maxHeight: 210,
  } as IStyle,
  pivotBase: {
    marginBottom: "14px",
  } as IStyle,
  smallCardChart: {
    height: 250,
    width: "100%",
    justifyContent: "center",
  } as IStyle,
  smallDonutCard: {
    minHeight: 125,
    minWidth: 300,
    justifyContent: "center",
  } as IStyle,
  peopleTreeChartBase: {
    width: "inherit",
    height: "inherit",
    justifyContent: "center",
  } as IStyle,
  map: {
    position: "relative",
    justifyContent: "center",
    height: "100vh",
    width: "100vw",
    margin: "12px",
  } as IStyle,
  popupStyles: {
    padding: "20px",
    color: theme.palette.themeDark,
  } as IStyle,
  wrapper: {
    height: "80vh",
    position: "relative",
    justifyContent: "center",
    maxHeight: "inherit",
  },
  smallPane: {
    position: "relative",
    justifyContent: "center",
    minWidth: 300,
    minHeight: 200,
    maxWidth: "inherit",
    maxHeight: "inherit",
    height: "40vh",
    margin: "10px",
  },
  mediumPane: {
    position: "relative",
    minHeight: 800,
    minWidth: 600,
    justifyContent: "center",
  },
  largePane: {
    position: "absolute",
    minHeight: 800,
    minWidth: 600,
    justifyContent: "center",
  },
  managerMap: {
    position: "relative",
    justifyContent: "center",
    maxWidth: 900,
    minHeight: 400,
    height: "50vh",
    width: "50vw",
    margin: "12px",
  },
  weatherMap: {
    position: "relative",
    justifyContent: "center",
    maxWidth: 900,
    minHeight: 400,
    maxHeight: 800,
    height: "75vh",
    width: "75vw",
    margin: "12px",
  },
  smallFont: {
    fontSize: "small",
  } as IStyle,
  boldFont: {
    fontWeight: "bold",
  },
  statusIcon: {
    justifyContent: "left",
  },
  defaultDashboard: {
    marginLeft: 8,
  },
  sliderSmall: {
    minWidth: 200,
    maxWidth: 500,
  },
  stackStyleDefault: {
    root: {
      muinWidth: 100,
      maxWidth: 500,
    },
  },
});

export const tinyScrollablePaneStyles: Partial<IScrollablePaneStyles> = {
  root: _Styles.smallPane,
};

export const largeScrollablePaneStyles: Partial<IScrollablePaneStyles> = {
  root: _Styles.largePane,
};

export const cardScrollablePaneStyles: Partial<IScrollablePaneStyles> = {
  root: {
    position: "relative",
    justifyContent: "center",
    margin: "16px",
    padding: "8px",
    minHeight: 400,
    height: 500,
    maxHeight: 700,
    minWidth: 200,
    maxWidth: 800,
  },
};

export const panelScrollablePaneStyles: Partial<IScrollablePaneStyles> = {
  root: {
    position: "relative",
    minHeight: 400,
    maxHeight: 900,
    minWidth: 400,
    maxWidth: 800,
    justifyContent: "center",
    margin: "16px",
    padding: "8px",
  },
};

export const defaultStackTokens = { childrenGap: 10, padding: 8 };

export const searchBoxStyles: Partial<ISearchBoxStyles> = {
  root: { width: 300, marginBottom: 20 },
};

export const largeSearchBoxStyles: Partial<ISearchBoxStyles> = {
  root: { width: 600, marginBottom: 20, marginLeft: 8 },
};
