import { Stack, TagItem } from "@fluentui/react";
import React from "react";
import { updateFacilityClassificationFilter, updateFacilityDivisionFilter, updateFacilityGwsCriticalityFilter, updateFacilityIdFilter, updateFacilityTypeFilter } from "store/actions/facilitiesActions";
import { getSelectedFacilityClassifications, getSelectedFacilityDivisions, getSelectedFacilityGwsCriticalities, getSelectedFacilityTypes } from "store/selectors/facilitiesSelector";
import { useAppDispatch, useAppSelector } from "store/store";

export const FacilityFilterTags = () => {
  const dispatch = useAppDispatch()
  const criticalityFilters = useAppSelector(getSelectedFacilityGwsCriticalities);
  const facTypeFilters = useAppSelector(getSelectedFacilityTypes);
  const facClassFilters = useAppSelector(getSelectedFacilityClassifications);
  const facDivFilters = useAppSelector(getSelectedFacilityDivisions);

  return (
    <Stack
      horizontal
      aria-label="Active Filters"
      horizontalAlign="start"
      verticalAlign="start"
      tokens={{ childrenGap: 10, padding: 4 }}
      role="list"
    >
      {criticalityFilters.length > 0 && (
        <TagItem
          maxLength={250}
          removeButtonAriaLabel="Remove"
          item={{
            key: "gwsCriticalityFilters",
            name: `Active GWS Criticality Filters: ${criticalityFilters.join(
              ", "
            )}`,
          }}
          index={0}
          onRemoveItem={() => dispatch(updateFacilityGwsCriticalityFilter([]))}
        >
          GWS Criticality: {criticalityFilters.join(", ")}
        </TagItem>
      )}
      {facTypeFilters.length > 0 && (
        <TagItem
          removeButtonAriaLabel="Remove"
          item={{
          key: "facTypeFilters",
          name: `Active Facility Type Filters: ${facTypeFilters.map(filter => filter === "Datacenter" ? "CO+I Data Center" : filter).join(", ")}`,
          }}
          index={1}
          onRemoveItem={() => dispatch(updateFacilityTypeFilter([]))}
        >
          Facility Type: {facTypeFilters.map(filter => filter === "Datacenter" ? "CO+I Data Center" : filter).join(", ")}
        </TagItem>
      )}
      {facClassFilters.length > 0 && (
        <TagItem
         removeButtonAriaLabel="Remove"
         item={{
           key: "facClassFilters",
           name: `Active CO+I DC Class Filters: ${facClassFilters.join(", ")}`,
         }}
         index={3}
          onRemoveItem={() => {dispatch(updateFacilityClassificationFilter([]));
         }}
        >
    CO+I DC Facility Class: {facClassFilters
      .join(", ")}
  </TagItem>
)}
      {facDivFilters.length > 0 && (
        <TagItem
          removeButtonAriaLabel="Remove"
          item={{
            key: "facDivFilters",
            name: `Active Facility Division Filters: ${facDivFilters.join(", ")}`,
          }}
          index={2}
          onRemoveItem={() => {
            dispatch(updateFacilityDivisionFilter([]));
            dispatch(updateFacilityIdFilter([]));
          }}
        >
          Division Manager:{" "}
          {facDivFilters
            .map((alias) => (alias === "" ? "Unknown" : alias))
            .join(", ")}
        </TagItem>
      )}
    </Stack>
  );
};
