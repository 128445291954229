import { mergeStyles } from "@fluentui/merge-styles";
import { ActionButton, DefaultButton, IContextualMenuItem, IContextualMenuProps, IIconProps, Label, Panel, PrimaryButton, ScrollablePane, SearchBox, Stack, Text, ThemeProvider } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { FontWeights } from "@fluentui/theme";
import { memoizeFunction } from "@fluentui/utilities";
import { DashboardCard } from "@m365-admin/card";
import { CountAnnotationBar, ICountAnnotationProps } from "@m365-admin/count-annotation";
import { M365LightTheme, useM365Theme } from "@m365-admin/customizations";
import { Dashboard } from "@m365-admin/dashboard";
import { IRearrangeableGridDefinition, IRearrangeableGridGap } from "@m365-admin/rearrangeable-grid";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { CardSizeValues, CardSizes } from "components/Cards.constants";
import { EmptyCardContent } from "components/EmptyCardContent";
import { IDownloadDetails, ProcessAssetDetail } from "components/Types";
import { criticalityColorOptions, pieChartColorOptions } from "components/charts/InfoDonutChart";
import { OrgProcessBarChart } from "components/charts/OrgProcessBarChart";
import { ExportToExcelUtil } from "components/datagrids/Helpers";
import ProcessDataGrid from "components/datagrids/ProcessDataGrid";
import { ProcessCriticality, ProcessGeneral } from "components/modals/InsightCallout";
import { ProcessFilterTags } from "components/panels/filters/ProcessFilterTags";
import { ProcessesFilter } from "components/panels/filters/ProcessesFilter";
import { mapToProcessView } from "components/pivots/helpers/downloadMappingHelpers";
import { searchBoxStyles, tinyScrollablePaneStyles } from "pages/Page.styles";
import React from "react";
import { updateProcessCriticalityFilters, updateProcessDetailsSearchTerm, updateProcessDivisionFilters, updateProcessFacilityFilters } from "store/actions/processesActions";
import { getProcessAssetDetails, getProcessAssetDetailsLoadingStatus, getProcessDetailsSearchTerm, getProcessDivisionFilters, getProcessFacilityFilters, getProcessRTOCriticalityFilters } from "store/selectors/processesSelector";
import { getUserDownloadPermissions, getUserIsRestricted } from "store/selectors/usersSelector";
import { useAppDispatch, useAppSelector } from "store/store";


export const ProcessPivot = () => {
  const appInsights = useAppInsightsContext();
  const dispatch = useAppDispatch();

  const userCanDownload = useAppSelector(getUserDownloadPermissions);
  const userHasViewRestriction = useAppSelector(getUserIsRestricted);

  const logAndDownloadXlsx = () => {
    const fileName = "WatchtowerProcessDataExport";
    appInsights.trackEvent(
      { name: "Download Used" },
      { Source: "Process", "restricted user": userHasViewRestriction }
    );

    const timeStamp = new Date(Date.now()).toLocaleDateString("en-US");
    const processDataMapped: ProcessAssetDetail[] =
      filteredProcessAssetData.map((p) => mapToProcessView(p, timeStamp));
    const downloadData: IDownloadDetails = {
      fileName: fileName,
      data: [{ dataToExport: processDataMapped, sheetName: "Process Details" }],
    };
    ExportToExcelUtil(downloadData);
  };

  const theme = useM365Theme();
  const backgroundStyles = mergeStyles({
    background: theme.semanticColors.navBackground,
    paddingTop: 24,
    paddingBottom: 24,
    paddingRight: 16,
    paddingLeft: 16,
    margin: 8,
  });

  const headerStyles = memoizeFunction(mergeStyles);
  const getCardTitle = (title: string) => {
    const titleClassName = headerStyles([
      theme.fonts.medium,
      { padding: 0, margin: 0, fontWeight: FontWeights.semibold },
    ]);

    return <h3 className={titleClassName}>{title}</h3>;
  };

  // store the state of the Dashboard and handle changes within it
  const [map, setMap] = React.useState<IRearrangeableGridDefinition>({
    processCriticalityCard: { cellHeight: 1, cellWidth: 5, row: 0, col: 0 },
    orgProcessCard: { cellHeight: 2, cellWidth: 1, row: 1, col: 4 },
    processDataGridCard: { cellHeight: 3, cellWidth: 4, row: 1, col: 0 },
  });

  //handle resizing in the dashboard grid
  const resizeCallback =
    React.useRef<
      (
        width: number,
        height: number,
        itemKey: string,
        position?: { col: number; row: number }
      ) => void
    >();

  const generateResizeCallback = (key: string) => {
    return (
      ev:
        | React.MouseEvent<HTMLElement, MouseEvent>
        | React.KeyboardEvent<HTMLElement>,
      item: IContextualMenuItem
    ) => {
      const dimension = CardSizeValues[item.key as CardSizes];

      resizeCallback.current?.(dimension.cellWidth, dimension.cellHeight, key);
    };
  };

  const columnResizeRef =
    React.useRef<
      (width: number, height: number, gap: IRearrangeableGridGap) => void
    >();

  const columnResize = (
    width: number,
    height: number,
    gap: IRearrangeableGridGap
  ) => {
    columnResizeRef.current?.(width, height, gap);
  };

  const onChange = (newMap: IRearrangeableGridDefinition) => {
    setMap(newMap);
  };
  const resizeText = "Resize";
  const moreDetailsText = "Info";

  const generateCallout = (calloutName: string) => {
    switch (calloutName) {
      case "processCriticality":
        return (
          <div data-is-focusable>
            <ProcessCriticality />
            <Text>Info</Text>
          </div>
        );
      default:
        return (
          <div data-is-focusable>
            <ProcessGeneral />
            <Text>Info</Text>
          </div>
        );
    }
  };

  const generateItemMenu = (
    key: string,
    calloutName: string
  ): IContextualMenuProps => {
    let items: IContextualMenuItem[] = [
      {
        iconProps: { iconName: "MiniExpand" },
        key: resizeText,
        text: resizeText,
        subMenuProps: {
          items: [
            { key: CardSizes.small, text: CardSizes.small },
            { key: CardSizes.smallWide, text: CardSizes.smallWide },
            { key: CardSizes.medium, text: CardSizes.medium },
            { key: CardSizes.mediumTall, text: CardSizes.mediumTall },
            { key: CardSizes.mediumWide, text: CardSizes.mediumWide },
            { key: CardSizes.large, text: CardSizes.large },
            { key: CardSizes.extraLarge, text: CardSizes.extraLarge },
          ],
          onItemClick: generateResizeCallback(key),
        },
      },
    ];
    if (calloutName) {
      items = [
        ...items,
        {
          iconProps: { iconName: "Info" },
          key: moreDetailsText,
          text: moreDetailsText,
          data: generateCallout(calloutName),
          onRender: (item) => item.data,
        },
      ];
      if (userCanDownload && calloutName === "processDataGridCard") {
        items = [
          ...items,
          {
            key: "downloadProcessDataExcel",
            iconProps: { iconName: "ExcelDocument" },
            text: "Download to Excel",
            onClick: () => logAndDownloadXlsx(),
          },
        ];
      }
    }

    return {
      items,
    };
  };

  const processDataLoading = useAppSelector(getProcessAssetDetailsLoadingStatus);
  const processAssetData = useAppSelector(getProcessAssetDetails);
  const [filteredProcessAssetData, setFilteredProcessAssetData] =
    React.useState<ProcessAssetDetail[]>([]);

  const [processInfoData, setProcessInfoData] = React.useState<
    ICountAnnotationProps[]
  >([]);

  //set up search filter
  const processDetailsSearchFilterState = useAppSelector(
    getProcessDetailsSearchTerm
  );
  const [searchFilters, setSearchFilters] = React.useState<string>("");
  React.useEffect(() => {
    setSearchFilters(processDetailsSearchFilterState);
  }, [processDetailsSearchFilterState]);

  //set process chart data based on active filters & region.
  const setProcessChartData = (processData: ProcessAssetDetail[]) => {
    const criticalityList = new Map<string, number>([
      ["Mission Critical", 0],
      ["Critical", 0],
      ["Important", 0],
      ["Deferrable", 0],
      ["Unknown", 0],
    ]);
    criticalityList.forEach((value, key) => {
      const sum = processData.filter(
        (s) => s.rtoCriticalityRating === key
      ).length;
      criticalityList.set(key, sum);
    });

    const countAnnotations: ICountAnnotationProps[] = [];
    countAnnotations.push({
      annotationText: "Total",
      count: processData.length,
      size: 1, //set to large
      annotationColor: pieChartColorOptions[4],
    });
    criticalityList.forEach((value, key) => {
      countAnnotations.push({
        annotationText: key,
        count: value,
        size: 1, //set to large
        annotationColor: criticalityColorOptions.find((i) => i.label === key)
          .color,
      });
    });

    setProcessInfoData(countAnnotations);
  };
  React.useMemo(() => {
    setFilteredProcessAssetData(processAssetData);
    setProcessChartData(processAssetData);
  }, [processAssetData]);

  //criticality filter setup
  const processCriticalityFiltersState = useAppSelector(
    getProcessRTOCriticalityFilters
  );
  const [criticalFilters, setCriticalFilters] = React.useState<string[]>([]);
  React.useEffect(() => {
    setCriticalFilters(processCriticalityFiltersState);
  }, [processCriticalityFiltersState]);

  //division filter setup
  const processDivisionFiltersState = useAppSelector(getProcessDivisionFilters);
  const [divFilters, setDivFilters] = React.useState<string[]>([]);
  React.useEffect(() => {
    setDivFilters(processDivisionFiltersState);
  }, [processDivisionFiltersState]);

  //facility filter setup
  const processFacilityFiltersState = useAppSelector(getProcessFacilityFilters);
  const [facilityFilters, setFacilityFilters] = React.useState<string[]>([]);
  React.useEffect(() => {
    setFacilityFilters(processFacilityFiltersState);
  }, [processFacilityFiltersState]);

  //on filter update, update the available results & options
  React.useMemo(() => {
    let newFilterResults = [...processAssetData];

    //set up filtered data
    if (searchFilters?.length > 0) {
      newFilterResults = newFilterResults.filter((proc) =>
        proc.relatedProcess.toLowerCase().includes(searchFilters)
      );
    }

    if (criticalFilters?.length > 0) {
      newFilterResults = newFilterResults.filter((proc) =>
        criticalFilters.includes(proc.rtoCriticalityRating)
      );
    }

    if (divFilters?.length > 0) {
      newFilterResults = newFilterResults.filter((proc) =>
        divFilters.includes(
          proc.fullyQualifiedOrganization.split(":")[0].trim()
        )
      );
    }

    if (facilityFilters?.length > 0) {
      newFilterResults = newFilterResults.filter((proc) =>
        facilityFilters.includes(proc.facilityName)
      );
    }
    setFilteredProcessAssetData(newFilterResults);
    setProcessChartData(newFilterResults);
  }, [
    criticalFilters,
    divFilters,
    facilityFilters,
    processAssetData,
    searchFilters,
  ]);

  const filterIcon: IIconProps = { iconName: "Filter" };
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);

  const buttonStyles = { root: { margin: 14 } };

  const clearProcessFilters = () => {
    dispatch(updateProcessCriticalityFilters([]));
    dispatch(updateProcessDivisionFilters([]));
    dispatch(updateProcessFacilityFilters([]));
  };

  const onRenderFooterContent = React.useCallback(() => {
    return (
      <div>
        <Stack horizontal>
          <PrimaryButton
            onClick={() => {
              dismissPanel();
            }}
            styles={buttonStyles}
            text="Close"
          />
          <DefaultButton
            text="Clear"
            styles={buttonStyles}
            onClick={() => {
              clearProcessFilters();
            }}
          />
        </Stack>
      </div>
    );
  }, [dismissPanel]);

  return (
    <ThemeProvider theme={M365LightTheme.settings.theme}>
      <Panel
        isLightDismiss
        isOpen={isOpen}
        onDismiss={dismissPanel}
        hasCloseButton={true}
        headerText="Additional Process Filters"
        closeButtonAriaLabel="Close"
        isFooterAtBottom={true}
        onRenderFooter={onRenderFooterContent}
      >
        <ProcessesFilter />
      </Panel>
      <Stack horizontal tokens={{ childrenGap: 10, padding: 8 }}>
        <ActionButton
          iconProps={filterIcon}
          onClick={openPanel}
          disabled={processDataLoading || processAssetData.length === 0}
        >
          More Filters
        </ActionButton>
        <ProcessFilterTags />
      </Stack>
      <div className={backgroundStyles}>
        <Dashboard
          map={map}
          onChange={onChange}
          ariaLabel="Crisis Response Process Dashboard"
          ariaDescription="Use the arrow keys to navigate to a card. Press alt-shift-arrow to move a card. Press enter key to enter each item. Press escape to return to the grid when within an item."
          rearrangeableGridProps={{
            resizeHandler: resizeCallback,
            onColumnResize: columnResize,
            minCellWidth: 250,
            minCellHeight: 150,
          }}
        >
          <DashboardCard
            key={"processDataGridCard"}
            titleText={getCardTitle("Business Process Details")}
            isLoading={processDataLoading}
            moreIconButtonProps={{
              ariaLabel: "More Actions",
              menuProps: generateItemMenu(
                "processDataGridCard",
                "processDataGridCard"
              ),
            }}
            body={
              <div>
                <Label>Search:</Label>
                <SearchBox
                  styles={searchBoxStyles}
                  placeholder="Search By Process Name"
                  onChange={(_, newValue: string) =>
                    dispatch(updateProcessDetailsSearchTerm(newValue))
                  }
                  onClear={() => dispatch(updateProcessDetailsSearchTerm(""))}
                />
                <ScrollablePane styles={tinyScrollablePaneStyles}>
                  {filteredProcessAssetData.length > 0 ? (
                    <ProcessDataGrid
                      processAssetData={filteredProcessAssetData}
                    />
                  ) : (
                    <EmptyCardContent />
                  )}
                </ScrollablePane>
              </div>
            }
          />
          <DashboardCard
            key={"processCriticalityCard"}
            titleText={getCardTitle("Process Criticality")}
            isLoading={processDataLoading}
            moreIconButtonProps={{
              ariaLabel: "More Actions",
              menuProps: generateItemMenu(
                "processCriticalityCard",
                "processCriticality"
              ),
            }}
            body={<CountAnnotationBar countAnnotationProps={processInfoData} />}
          />
          <DashboardCard
            key={"orgProcessCard"}
            titleText={getCardTitle("Processes by Division")}
            isLoading={processDataLoading}
            moreIconButtonProps={{
              ariaLabel: "More Actions",
              menuProps: generateItemMenu(
                "processCriticalityCard",
                "orgProcesses"
              ),
            }}
          >
            <OrgProcessBarChart processAssetData={filteredProcessAssetData} />
          </DashboardCard>
        </Dashboard>
      </div>
    </ThemeProvider>
  );
};
