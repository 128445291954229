import React from "react";
import { DependencyGraphLinkProps, Link } from "./ServiceDependencyGraph.types";

export function DependencyGraphLink(props: DependencyGraphLinkProps) {
  // determines the length and shape of the path between nodes
  // if self referencing, draws a circle to itself
  // if not self referencing and not circular dep, draws a straight line
  // if circular dep, draws an arc to the target node
  function linkArc(d: Link, rectangle: any) {
    if (d !== undefined) {
      const selfReferencing = d.source.x === d.target.x;
      if (selfReferencing) {
        return `
        M${d.source.x},${d.source.y}
        A 1, 1 0 0 1 ${
          selfReferencing ? d.target.x - rectangle.width : d.target.x
        },${selfReferencing ? d.target.y - rectangle.height : d.target.y}
        A 1, 1 0 0 1 ${d.target.x},${d.target.y} 
        
      `;
      }
      return `
          M${d.source.x},${d.source.y}
          A${d.direction === "circular" ? 1 : 0},${
        d.direction === "circular" ? 1 : 0
      } 0 0 1 ${d.target.x},${d.target.y}`;
    }
  }

  // sets up line colors for the links
  const getLineColor = (typeInput: string, levelInput: number) => {
    if (typeInput === "downstream") {
      if (levelInput === 1) {
        return props.legend[1]?.color ?? props.theme.palette.black;
      }
      return props.legend[0]?.color ?? props.theme.palette.tealDark;
    } else if (typeInput === "circular") {
      return props.legend[2]?.color ?? props.theme.palette.blue;
    } else if (typeInput === "upstream") {
      if (levelInput === 1) {
        return props.legend[4]?.color ?? props.theme.palette.black;
      }
      return props.legend[3]?.color ?? props.theme.palette.purpleDark;
    }
  };

  // sets up line styles for the links
  const getLineStyle = (input: string) => {
    if (input === "downstream") {
      return "5 10 15";
    } else return "none";
  };

  return (
    <g
      className="rectLink"
      key={`${props.link.source?.id}${props.link.target.id}-${props.link.relationshipLevel}`}
    >
      <path
        className={props.styleClassNames.link}
        d={linkArc(props.link, props.rectangle)}
        key={`${props.link.nodeId ?? props.link.index}${
          props.styleClassNames.link
        }`}
        stroke={getLineColor(
          props.link.direction,
          props.link.relationshipLevel
        )}
        strokeDasharray={getLineStyle(props.link.direction)}
        markerEnd={`url(#${props.link.direction})`}
      />
    </g>
  );
}
