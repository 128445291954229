import * as React from "react";
import { Image, Stack, Text } from "@fluentui/react";

import { _Styles } from "../pages/Page.styles";
import goFishCat from "./../assets/GoFishCat.png";
export interface EmptyDashboardContentProps {
  headingText?: string;
  descriptionText?: string;
}

export const EmptyDashboardContent = (props: EmptyDashboardContentProps) => {
  const header =
    props.headingText?.length > 0 ? props.headingText : "No data loaded yet";
  const message =
    props.descriptionText?.length > 0
      ? props.descriptionText
      : "Get started by searching for a service id or name";

  return (
    <div className={_Styles.centeredDiv}>
      <Stack horizontalAlign="center">
        <Image
          src={goFishCat}
          alt="cat holding cards"
          width={200}
          height={200}
        />
        <h1>{header}</h1>
        <Text block variant="medium">
          {message}
        </Text>
      </Stack>
    </div>
  );
};
