import { Stack, TagItem } from "@fluentui/react";
import React from "react";
import { updateProcessCriticalityFilters, updateProcessDivisionFilters, updateProcessFacilityFilters } from "store/actions/processesActions";
import { getProcessDivisionFilters, getProcessFacilityFilters, getProcessRTOCriticalityFilters } from "store/selectors/processesSelector";
import { useAppDispatch, useAppSelector } from "store/store";

export const ProcessFilterTags = () => {
  const dispatch = useAppDispatch()
  const criticalityFilters = useAppSelector(getProcessRTOCriticalityFilters);
  const divFilters = useAppSelector(getProcessDivisionFilters);
  const facilityFilters = useAppSelector(getProcessFacilityFilters);

  return (
    <Stack
      horizontal
      aria-label="Active Filters"
      horizontalAlign="start"
      verticalAlign="start"
      tokens={{ childrenGap: 10, padding: 4 }}
      role="list"
    >
      {criticalityFilters.length > 0 && (
        <TagItem
          maxLength={250}
          removeButtonAriaLabel="Remove"
          item={{
            key: "criticalityFilters",
            name: `Active Criticality Filters: ${criticalityFilters.join(
              ", "
            )}`,
          }}
          index={0}
          onRemoveItem={() => dispatch(updateProcessCriticalityFilters([]))}
        >
          Criticality: {criticalityFilters.join(", ")}
        </TagItem>
      )}
      {divFilters.length > 0 && (
        <TagItem
          removeButtonAriaLabel="Remove"
          item={{
            key: "divGroup",
            name: `Active Division Filters: ${divFilters
              .map((alias) => (alias === "" ? "Unknown" : alias))
              .join(", ")} `,
          }}
          index={1}
          onRemoveItem={() => dispatch(updateProcessDivisionFilters([]))}
        >
          Division:{" "}
          {divFilters
            .map((alias) => (alias === "" ? "Unknown" : alias))
            .join(", ")}
        </TagItem>
      )}
      {facilityFilters.length > 0 && (
        <TagItem
          removeButtonAriaLabel="Remove"
          item={{
            key: "recoveryClassification",
            name: `Active Facility Filters: ${facilityFilters.join(", ")}`,
          }}
          index={2}
          onRemoveItem={() => dispatch(updateProcessFacilityFilters([]))}
        >
          Recovery Classification: {facilityFilters.join(", ")}
        </TagItem>
      )}
    </Stack>
  );
};
