import React from "react";
import { DetailsList, IColumn, SelectionMode } from "@fluentui/react";

import { NormalizedEmployeeProfile } from "../Types";
import { copyAndSort } from "./Helpers";

export interface FacilityPeopleDataGridProps {
  currentPeopleData: NormalizedEmployeeProfile[];
}

export default function FacilityPeopleDataGrid(
  props: FacilityPeopleDataGridProps
) {
  const [currentPeopleByFacilityData, setCurrentPeopleByFacilityData] =
    React.useState<NormalizedEmployeeProfile[]>(props.currentPeopleData);

  React.useEffect(() => {
    if (props.currentPeopleData) {
      setCurrentPeopleByFacilityData(props.currentPeopleData);
    }
  }, [props.currentPeopleData]);

  const onFacilityPeopleColumnClick = React.useCallback(
    (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
      const columns = currentColumns;
      const newColumns: IColumn[] = columns.slice();
      const currColumn: IColumn = newColumns.filter(
        (currCol) => column.key === currCol.key
      )[0];
      newColumns.forEach((newCol: IColumn) => {
        if (newCol === currColumn) {
          currColumn.isSortedDescending = !currColumn.isSortedDescending;
          currColumn.isSorted = true;
        } else {
          newCol.isSorted = false;
          newCol.isSortedDescending = true;
        }
      });

      const newItems = copyAndSort(
        currentPeopleByFacilityData,
        currColumn.key!,
        currColumn.isSortedDescending
      );
      setCurrentColumns(newColumns);
      setCurrentPeopleByFacilityData([...newItems]);
    },
    [currentPeopleByFacilityData]
  );

  const peopleColumns: IColumn[] = [
    {
      key: "fullName",
      name: "Name",
      fieldName: "fullName",
      ariaLabel: "Name",
      minWidth: 100,
      maxWidth: 125,
      data: "string",
      isResizable: true,
      isRowHeader: true,
      onColumnClick: onFacilityPeopleColumnClick,
    },
    {
      key: "alias",
      fieldName: "alias",
      name: "Alias",
      ariaLabel: "Alias",
      data: "string",
      minWidth: 100,
      isResizable: true,
      onColumnClick: onFacilityPeopleColumnClick,
    },
    {
      key: "managerAlias",
      name: "Manager",
      fieldName: "managerAlias",
      ariaLabel: "Manager",
      minWidth: 100,
      maxWidth: 125,
      data: "string",
      isResizable: true,
      onColumnClick: onFacilityPeopleColumnClick,
    },
    {
      key: "l1Manager",
      fieldName: "l1Manager",
      name: "Division Manager",
      ariaLabel: "Division Manager",
      data: "string",
      minWidth: 150,
      isResizable: true,
      onColumnClick: onFacilityPeopleColumnClick,
    },
    {
      key: "l2Manager",
      fieldName: "l2Manager",
      name: "Org Manager",
      ariaLabel: "Org Manager",
      data: "string",
      minWidth: 100,
      isResizable: true,
      onColumnClick: onFacilityPeopleColumnClick,
    },
  ];

  const [currentColumns, setCurrentColumns] =
    React.useState<IColumn[]>(peopleColumns);

  return (
    <DetailsList
      items={currentPeopleByFacilityData}
      selectionMode={SelectionMode.none}
      columns={currentColumns}
      compact={true}
    />
  );
}
