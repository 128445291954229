import React from "react";
import {
  EmptyStateComponent,
  EmptyStateIconType,
  IEmptyStateComponentProps,
} from "@coherence-design-system/controls";

export const EmptyCardContent = () => {
  const emptyStateProps: IEmptyStateComponentProps = {
    headingText: "No data to show yet",
    descriptionText: "Get started by selecting a region.",
    type: EmptyStateIconType.EMPTY,
    isDescriptionLinkUrlOpenInNewTab: true,
  };
  return <EmptyStateComponent {...emptyStateProps} />;
};
