import { Dispatch } from "redux";
import { AxiosError } from "axios";
import { stringify } from "querystring";
import apiBase from "../../services/apiBase";
import { appInsights } from "../../services/AppInsights";
import {
  ComplianceStatusCount,
  ServiceBcdrMetadataQueryResponse,
  ServiceTreeObj,
} from "../../types/DependencyMappingTypes";
import {
  FETCH_SERVICE_DEPENDENCY_ITEM_DETAIL_BEGIN,
  FETCH_SERVICE_DEPENDENCY_ITEM_DETAIL_SUCCESS,
  FETCH_SERVICE_DEPENDENCY_ITEM_DETAIL_FAILURE,
  FETCH_SERVICE_DEPENDENCY_DRI_DATA_BEGIN,
  FETCH_SERVICE_DEPENDENCY_DRI_DATA_FAILURE,
  FETCH_SERVICE_DEPENDENCY_DRI_DATA_SUCCESS,
  FETCH_SERVICE_DEPENDENCY_OPTIONS_BEGIN,
  FETCH_SERVICE_DEPENDENCY_OPTIONS_FAILURE,
  FETCH_SERVICE_DEPENDENCY_OPTIONS_SUCCESS,
  FETCH_SERVICE_DEPENDENCY_LINKS_BEGIN,
  FETCH_SERVICE_DEPENDENCY_LINKS_FAILURE,
  FETCH_SERVICE_DEPENDENCY_LINKS_SUCCESS,
  FETCH_SERVICE_DEPENDENCY_PANEL_ITEM_DETAIL_BEGIN,
  FETCH_SERVICE_DEPENDENCY_PANEL_ITEM_DETAIL_FAILURE,
  FETCH_SERVICE_DEPENDENCY_PANEL_ITEM_DETAIL_SUCCESS,
  CLEAR_SERVICE_DEPENDENCY_PANEL_ITEM_DETAIL,
  FETCH_SERVICE_DEPENDENCY_PANEL_DRI_DATA_BEGIN,
  FETCH_SERVICE_DEPENDENCY_PANEL_DRI_DATA_FAILURE,
  FETCH_SERVICE_DEPENDENCY_PANEL_DRI_DATA_SUCCESS,
  CLEAR_SERVICE_DEPENDENCY_PANEL_DRI_DATA,
  FETCH_SERVICE_DEP_COUNTS_BEGIN,
  FETCH_SERVICE_DEP_COUNTS_SUCCESS,
  FETCH_SERVICE_DEP_COUNTS_FAILURE,
} from "./dependencyMappingActionTypes";
import { IDriGeoResilienceData } from "../../components/Types";
import { Link } from "../../components/charts/dependencyGraph/ServiceDependencyGraph.types";

const serviceDependencyEnhancedDataEndpoint: string =
  "api/ServiceDependencies/GetEnhancedServiceData";
const serviceDependencyOptionsEndpoint: string = "api/ServiceDependencies/GetServiceOptions";
const serviceDependencyLinksEndpoint: string = "api/ServiceDependencies/GetServiceDependencyLinks";
const serviceDependencyCountsEndpoint: string =
  "api/ServiceDependencies/GetServiceDependencyCounts";
const serviceDependencyDriApiEndpoint: string = "api/ICM/GetDriGeoData";

export const fetchServiceDependencyMetadataBegin = () => ({
  type: FETCH_SERVICE_DEPENDENCY_ITEM_DETAIL_BEGIN,
});

export const fetchServiceDependencyMetadataSuccess = (
  payload: ServiceBcdrMetadataQueryResponse
) => ({
  type: FETCH_SERVICE_DEPENDENCY_ITEM_DETAIL_SUCCESS,
  payload,
});

export const fetchServiceDependencyMetadataFailure = (error: string | AxiosError) => ({
  type: FETCH_SERVICE_DEPENDENCY_ITEM_DETAIL_FAILURE,
  payload: error,
});

export const fetchServiceDependencyMetadata = (serviceToFetch: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(fetchServiceDependencyMetadataBegin());
    try {
      const res = await apiBase.get(serviceDependencyEnhancedDataEndpoint, {
        params: {
          serviceIdList: [serviceToFetch],
        },
        paramsSerializer: (params) => {
          return stringify(params);
        },
      });
      if (res.data) {
        dispatch(fetchServiceDependencyMetadataSuccess(res.data));
      }
    } catch (err: any) {
      if (err?.response?.status === 403) {
        dispatch(
          fetchServiceDependencyMetadataFailure(
            "Error calling Service Dependency due to your permissions. Please reach out to watchtower support to confirm your access."
          )
        );
      } else {
        dispatch(fetchServiceDependencyMetadataFailure(err));
      }
      appInsights.trackException(err);
    }
  };
};

export const fetchServiceDependencyPanelMetadataBegin = () => ({
  type: FETCH_SERVICE_DEPENDENCY_PANEL_ITEM_DETAIL_BEGIN,
});

export const fetchServiceDependencyPanelMetadataSuccess = (
  payload: ServiceBcdrMetadataQueryResponse
) => ({
  type: FETCH_SERVICE_DEPENDENCY_PANEL_ITEM_DETAIL_SUCCESS,
  payload,
});

export const fetchServiceDependencyPanelMetadataFailure = (error: string | AxiosError) => ({
  type: FETCH_SERVICE_DEPENDENCY_PANEL_ITEM_DETAIL_FAILURE,
  payload: error,
});

export const fetchServiceDependencyPanelMetadata = (serviceToFetch: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(fetchServiceDependencyPanelMetadataBegin());
    try {
      const res = await apiBase.get(serviceDependencyEnhancedDataEndpoint, {
        params: {
          serviceIdList: [serviceToFetch],
        },
        paramsSerializer: (params) => {
          return stringify(params);
        },
      });
      if (res.data) {
        dispatch(fetchServiceDependencyPanelMetadataSuccess(res.data));
      }
    } catch (err: any) {
      if (err?.response?.status === 403) {
        dispatch(
          fetchServiceDependencyPanelMetadataFailure(
            "Error calling Service Dependency due to your permissions. Please reach out to watchtower support to confirm your access."
          )
        );
      } else {
        dispatch(fetchServiceDependencyPanelMetadataFailure(err));
      }
      appInsights.trackException(err);
    }
  };
};

export const clearServiceDependencyPanelMetadata = () => ({
  type: CLEAR_SERVICE_DEPENDENCY_PANEL_ITEM_DETAIL,
});

export const fetchServiceDependencyDriDataBegin = () => ({
  type: FETCH_SERVICE_DEPENDENCY_DRI_DATA_BEGIN,
});

export const fetchServiceDependencyDriDataSuccess = (payload: IDriGeoResilienceData) => ({
  type: FETCH_SERVICE_DEPENDENCY_DRI_DATA_SUCCESS,
  payload,
});

export const fetchServiceDependencyDriDataFailure = (error: string | AxiosError) => ({
  type: FETCH_SERVICE_DEPENDENCY_DRI_DATA_FAILURE,
  payload: error,
});

export const fetchServiceDependencyDriData = (serviceToFetch: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(fetchServiceDependencyDriDataBegin());
    try {
      const res = await apiBase.get(serviceDependencyDriApiEndpoint, {
        params: {
          serviceIdQueryList: [serviceToFetch],
        },
        paramsSerializer: (params) => {
          return stringify(params);
        },
      });
      if (res.data) {
        dispatch(fetchServiceDependencyDriDataSuccess(res.data));
      }
    } catch (err: any) {
      if (err?.response?.status === 403) {
        dispatch(
          fetchServiceDependencyDriDataFailure(
            "Error calling Service Dependency due to your permissions. Please reach out to watchtower support to confirm your access."
          )
        );
      } else {
        dispatch(fetchServiceDependencyDriDataFailure(err));
      }
      appInsights.trackException(err);
    }
  };
};

export const fetchSecondaryServiceDependencyDriDataBegin = () => ({
  type: FETCH_SERVICE_DEPENDENCY_PANEL_DRI_DATA_BEGIN,
});

export const fetchSecondaryServiceDependencyDriDataSuccess = (payload: IDriGeoResilienceData) => ({
  type: FETCH_SERVICE_DEPENDENCY_PANEL_DRI_DATA_SUCCESS,
  payload,
});

export const fetchSecondaryServiceDependencyDriDataFailure = (error: string | AxiosError) => ({
  type: FETCH_SERVICE_DEPENDENCY_PANEL_DRI_DATA_FAILURE,
  payload: error,
});

export const fetchSecondaryServiceDependencyDriData = (serviceToFetch: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(fetchSecondaryServiceDependencyDriDataBegin());
    try {
      const res = await apiBase.get(serviceDependencyDriApiEndpoint, {
        params: {
          serviceIdQueryList: [serviceToFetch],
        },
        paramsSerializer: (params) => {
          return stringify(params);
        },
      });
      if (res.data) {
        dispatch(fetchSecondaryServiceDependencyDriDataSuccess(res.data));
      }
    } catch (err: any) {
      if (err?.response?.status === 403) {
        dispatch(
          fetchSecondaryServiceDependencyDriDataFailure(
            "Error calling Service Dependency DRI Data due to your permissions. Please reach out to watchtower support to confirm your access."
          )
        );
      } else {
        dispatch(fetchSecondaryServiceDependencyDriDataFailure(err));
      }
      appInsights.trackException(err);
    }
  };
};

export const clearSecondaryServiceDependencyDriData = () => ({
  type: CLEAR_SERVICE_DEPENDENCY_PANEL_DRI_DATA,
});

export const fetchServiceTreeOptionsBegin = () => ({
  type: FETCH_SERVICE_DEPENDENCY_OPTIONS_BEGIN,
});

export const fetchServiceTreeOptionsSuccess = (payload: ServiceTreeObj[]) => ({
  type: FETCH_SERVICE_DEPENDENCY_OPTIONS_SUCCESS,
  payload,
});

export const fetchServiceTreeOptionsFailure = (error: string | AxiosError) => ({
  type: FETCH_SERVICE_DEPENDENCY_OPTIONS_FAILURE,
  payload: error,
});

export const fetchServiceTreeOptions = () => {
  return async (dispatch: Dispatch) => {
    dispatch(fetchServiceTreeOptionsBegin());
    try {
      const res = await apiBase.get(serviceDependencyOptionsEndpoint);
      if (res.data) {
        dispatch(fetchServiceTreeOptionsSuccess(res.data));
      }
    } catch (err: any) {
      if (err?.response?.status === 403) {
        dispatch(
          fetchServiceTreeOptionsFailure(
            "Error calling Service Dependency due to your permissions. Please reach out to watchtower support to confirm your access."
          )
        );
      } else {
        dispatch(fetchServiceDependencyMetadataFailure(err));
      }
      appInsights.trackException(err);
    }
  };
};

export const fetchServiceDependencyGraphLinksBegin = () => ({
  type: FETCH_SERVICE_DEPENDENCY_LINKS_BEGIN,
});

export const fetchServiceDependencyGraphLinksSuccess = (linkData: Link[]) => ({
  type: FETCH_SERVICE_DEPENDENCY_LINKS_SUCCESS,
  payload: linkData,
});

export const fetchServiceDependencyGraphLinksFailure = (
  errorResult: Error | string | AxiosError
) => ({
  type: FETCH_SERVICE_DEPENDENCY_LINKS_FAILURE,
  payload: errorResult,
});

export const fetchServiceDependencyGraphLinksData = (serviceToFetch: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(fetchServiceDependencyGraphLinksBegin());
    try {
      const res = await apiBase.get(serviceDependencyLinksEndpoint, {
        params: {
          serviceId: serviceToFetch,
        },
        paramsSerializer: (params) => {
          return stringify(params);
        },
      });
      if (res.data) {
        dispatch(fetchServiceDependencyGraphLinksSuccess(res.data));
      }
    } catch (err: any) {
      if (err?.response?.status === 403) {
        dispatch(
          fetchServiceTreeOptionsFailure(
            "Error calling Service Dependency due to your permissions. Please reach out to watchtower support to confirm your access."
          )
        );
      } else {
        dispatch(fetchServiceDependencyGraphLinksFailure(err));
      }
      appInsights.trackException(err);
    }
  };
};

export const fetchServiceDepCountsBegin = () => ({
  type: FETCH_SERVICE_DEP_COUNTS_BEGIN,
});

export const fetchServiceDepCountsSuccess = (payload: ComplianceStatusCount) => ({
  type: FETCH_SERVICE_DEP_COUNTS_SUCCESS,
  payload,
});

export const fetchServiceDepCountsFailure = (error: AxiosError | string) => ({
  type: FETCH_SERVICE_DEP_COUNTS_FAILURE,
  payload: error,
});

export const fetchServiceDepCounts = () => {
  return async (dispatch: Dispatch) => {
    dispatch(fetchServiceDepCountsBegin());
    try {
      const res = await apiBase.get(serviceDependencyCountsEndpoint);
      if (res.data) {
        dispatch(fetchServiceDepCountsSuccess(res.data));
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        dispatch(
          fetchServiceDepCountsFailure(
            "Error calling Service Dependency due to your permissions. Please reach out to watchtower support to confirm your access."
          )
        );
      } else {
        dispatch(fetchServiceDepCountsFailure(err));
      }

      appInsights.trackException(err);
    }
  };
};
