import React from "react";
import {
  MultiStackedBarChart,
  IChartProps,
  IChartDataPoint,
} from "@fluentui/react-charting";
import { ScrollablePane } from "@fluentui/react";
import { tinyScrollablePaneStyles } from "../../../pages/Page.styles";
import { pieChartColorOptions } from "../InfoDonutChart";
import {
  ComplianceStatus,
  ComplianceStatusCount,
} from "../../../types/DependencyMappingTypes";
import { ServiceAssetDetail } from "../../Types";

const buildAggregateComplianceStatusCounts = (
  serviceAssets: ComplianceStatusCount,
  divisionName: string
): IChartDataPoint[] => {
  if (serviceAssets) {
    return [
      {
        legend: "Total Compliant Services",
        data: serviceAssets?.totalBcdrCompliantServices ?? -1,
        color: pieChartColorOptions[4],
        callOutAccessibilityData: {
          ariaLabel: `Total Compliant Services for ${divisionName}: ${serviceAssets?.totalBcdrCompliantServices}`,
        },
      },
      {
        legend: "Total Compliant with Gap Services",
        data: serviceAssets?.totalBcdrCompliantWithGapServices ?? -1,
        color: pieChartColorOptions[0],
        callOutAccessibilityData: {
          ariaLabel: `Total Compliant with Gap Services for ${divisionName}: ${serviceAssets?.totalBcdrCompliantWithGapServices}`,
        },
      },
      {
        legend: "Total NonCompliant Services",
        data: serviceAssets?.totalBcdrNonCompliantServices ?? -1,
        color: pieChartColorOptions[2],
        callOutAccessibilityData: {
          ariaLabel: `Total NonCompliant Services for ${divisionName}: ${serviceAssets?.totalBcdrNonCompliantServices}`,
        },
      },
      {
        legend: "Total RA Services",
        data: serviceAssets?.totalBcdrRaServices ?? -1,
        color: pieChartColorOptions[3],
        callOutAccessibilityData: {
          ariaLabel: `Total RA Services for ${divisionName}: ${serviceAssets?.totalBcdrRaServices}`,
        },
      },
      {
        legend: "Total RA Expired Services",
        data: serviceAssets?.totalBcdrRaExpiredServices ?? -1,
        color: pieChartColorOptions[1],
        callOutAccessibilityData: {
          ariaLabel: `Total RA Expired Services for ${divisionName}: ${serviceAssets?.totalBcdrRaExpiredServices}`,
        },
      },
      {
        legend: "Total Post Approval Rejected Services",
        data: serviceAssets?.totalBcdrPostApprovalRejectedServices ?? -1,
        color: pieChartColorOptions[5],
        callOutAccessibilityData: {
          ariaLabel: `Total Post Approval Rejected Services for ${divisionName}: ${serviceAssets?.totalBcdrPostApprovalRejectedServices}`,
        },
      },
      {
        legend: "Total Unknown Services",
        data: serviceAssets?.totalBcdrUnknownServices ?? -1,
        color: pieChartColorOptions[6],
        callOutAccessibilityData: {
          ariaLabel: `Total Unknown Services for ${divisionName}: ${serviceAssets?.totalBcdrUnknownServices}`,
        },
      },
    ];
  }
  return [];
};

const buildServiceComplianceStatusCounts = (
  serviceAssets: ServiceAssetDetail[]
): ComplianceStatusCount => {
  const initialCounts: ComplianceStatusCount = {
    totalServices: serviceAssets.length,
    totalBcdrCompliantServices: 0,
    totalBcdrCompliantWithGapServices: 0,
    totalBcdrNonCompliantServices: 0,
    totalBcdrPostApprovalRejectedServices: 0,
    totalBcdrRaExpiredServices: 0,
    totalBcdrRaServices: 0,
    totalBcdrUnknownServices: 0,
  };

  serviceAssets.forEach((s) => {
    switch (s.complianceStatus) {
      case ComplianceStatus.Compliant:
        initialCounts.totalBcdrCompliantServices++;
        break;
      case ComplianceStatus.CompliantWithGap:
        initialCounts.totalBcdrCompliantWithGapServices++;
        break;
      case ComplianceStatus.NonCompliant:
        initialCounts.totalBcdrNonCompliantServices++;
        break;
      case ComplianceStatus.PostApprovalRejected:
        initialCounts.totalBcdrPostApprovalRejectedServices++;
        break;
      case ComplianceStatus.RAExpired:
        initialCounts.totalBcdrRaExpiredServices++;
        break;
      case ComplianceStatus.RA:
        initialCounts.totalBcdrRaServices++;
        break;
      default:
        initialCounts.totalBcdrUnknownServices++;
        break;
    }
  });

  return initialCounts;
};

export interface barChartProps {
  filteredServices: ServiceAssetDetail[];
}
export const ComplianceStatusByDivStackedBarChart = (props: barChartProps) => {
  const [orgChartData, setOrgChartData] = React.useState<IChartProps[]>([]);
  React.useEffect(() => {
    if (props.filteredServices.length > 0) {
      const divisions = Array.from(
        new Set(props.filteredServices.map((s) => s.divisionName))
      );

      let chartData = [];

      divisions.forEach((division) => {
        const divisionServices = props.filteredServices.filter(
          (s) => s.divisionName === division
        );
        const divisionStatusCount =
          buildServiceComplianceStatusCounts(divisionServices);
        let mappedStatuses = {
          name: division,
          counts: divisionStatusCount,
        };
        chartData.push(mappedStatuses);
      });
      let mappedDivData: IChartProps[] = chartData.map((dpd) => ({
        chartTitle: `${dpd?.name.length > 0 ? dpd.name : "Division Unknown"} (${
          dpd.counts.totalServices
        })`,
        chartData: buildAggregateComplianceStatusCounts(
          dpd.counts as ComplianceStatusCount,
          dpd.name
        ),
      }));
      setOrgChartData(mappedDivData);
    }
  }, [props.filteredServices]);

  return (
    <div>
      <ScrollablePane styles={tinyScrollablePaneStyles}>
        <MultiStackedBarChart
          data={orgChartData}
          styles={{
            singleChartRoot: { marginBottom: "6px", marginRight: "6px" },
          }}
        />
      </ScrollablePane>
    </div>
  );
};
