import React, { useRef } from "react";
import "d3-transition";
import "d3-selection";
import * as d3 from "d3";
import { HierarchyPointNode, linkVertical } from "d3";
import { getTheme } from "@fluentui/react";

export interface TreeProps {
  height: number;
  width: number;
  treeData: any;
}

const theme = getTheme();

export const Tree = (props: TreeProps) => {
  const treeSvg = useRef(null);

  const margin = { top: 100, right: 40, bottom: 10, left: 40 };

  const rectangle = {
    width: 180,
    height: 90,
    textMargin: 5,
  };

  const diagonal = (linkData: {
    source: HierarchyPointNode<unknown> | { x: number; y: number } | any;
    target: HierarchyPointNode<unknown> | { x: number; y: number } | any;
  }) => {
    const { source, target } = linkData;
    return linkVertical()({
      source: [
        source?.x + rectangle.width / 2,
        source?.y + rectangle.height / 2,
      ],
      target: [target.x + rectangle.width / 2, target.y + rectangle.height / 2],
    });
  };

  // append the svg object to the body of the page
  // appends a 'group' element to 'svg'
  // moves the 'group' element to the top left margin
  const svg = d3
    .create(`svg`)
    .attr("width", props.width + margin.right + margin.left)
    .attr("height", props.height + margin.top + margin.bottom)
    .style("font", "12px sans-serif")
    .style("user-select", "none");

  const gLink = svg
    .append("g")
    .attr("fill", "none")
    .attr("stroke", "#555")
    .attr("stroke-opacity", 0.4)
    .attr("stroke-width", 1.5);

  const gNode = svg
    .append("g")
    .attr("cursor", "pointer")
    .attr("pointer-events", "all");

  svg.append("g").attr("cursor", "grab");

  let tree = d3
    .tree()
    .separation((a, b) => (a.parent === root && b.parent === root ? 3.25 : 3.5))
    .nodeSize([60, 30]);

  function update(source) {
    let treeNodes = tree(root);
    // Compute the new tree layout.
    let nodes = root.descendants().reverse();
    let links = root.links();

    let left = treeNodes;
    let right = treeNodes;
    treeNodes.eachBefore((node) => {
      if (node.x < left.x) left = node;
      if (node.x > right.x) right = node;
    });

    let i = 0;
    // Update the nodes…
    let node = gNode.selectAll("g").data(nodes, (d: any) => {
      return d.id || (d.id = ++i);
    });

    //define their vertical gap distance
    nodes.forEach(function (d) {
      d.y = d.depth === 0 ? 40 : d.depth * rectangle.height * 2;
      d.x += d.depth * rectangle.width;
    });

    // Enter any new nodes at the parent's previous position.
    const nodeEnter = node
      .enter()
      .append("g")
      .attr("transform", (d: any) => `translate(${source?.x0},${source?.y0})`)
      .attr("fill-opacity", 0)
      .attr("stroke-opacity", 0)
      .on("click", (event, d: any) => {
        d.children = d.children ? null : d._children;
        update(d);
      });

    nodeEnter
      .append("rect")
      .attr("width", rectangle.width)
      .attr("height", rectangle.height)
      .attr("x", 0)
      .attr("y", (rectangle.height / 2) * -1)
      .attr("rx", "5")
      .style("fill", (d: any) =>
        d.data.data === "Out of area"
          ? theme.palette.themeLight
          : theme.palette.greenLight
      );

    // Add labels for the nodes
    nodeEnter
      .append("text")
      .attr("dy", "-.3em")
      .attr("x", function (d) {
        return 13;
      })
      .attr("text-anchor", function (d) {
        return "start";
      })
      .text((d: any) => `${d.data.name}`)
      .append("tspan")
      .attr("dy", "1.75em")
      .attr("x", function (d) {
        return 13;
      })
      .text((d: any) => `${d.data.data ? `Location: ${d.data.data}` : ``}`)
      .append("tspan")
      .attr("dy", "1.75em")
      .attr("x", function (d) {
        return 13;
      })
      .text(
        (d: any) =>
          `${
            d.data.children?.length === 0
              ? ``
              : `Reports in area: ${d.data.value}`
          }`
      );

    // Transition nodes to their new position.
    const nodeUpdate = node
      .merge(nodeEnter)
      .attr("transform", (d: any) => `translate(${d.x},${d.y})`)
      .attr("fill-opacity", 1)
      .attr("stroke-opacity", 1);

    // Transition exiting nodes to the parent's new position.
    const nodeExit = node
      .exit()
      .remove()
      .attr("transform", (d: any) => `translate(${source?.x},${source?.y})`)
      .attr("fill-opacity", 0)
      .attr("stroke-opacity", 0);

    // Update the links…
    const link = gLink.selectAll("path").data(links, (d: any) => d.target.id);

    // Enter any new links at the parent's previous position.
    const linkEnter = link
      .enter()
      .append("path")
      .attr("d", function (d) {
        const o = { y: source?.y0, x: source?.x0 };
        return diagonal({ source: o, target: o });
      });

    // Transition links to their new position.
    const linkUpdate = link.merge(linkEnter);

    linkUpdate.attr("d", diagonal);

    // Transition exiting nodes to the parent's new position.
    link
      .exit()
      .remove()
      .attr("d", function (d) {
        const o = { y: source?.y0, x: source?.x0 };
        return diagonal({ source: o, target: o });
      });

    // Stash the old positions for transition.
    root.eachBefore((d: any) => {
      d.x0 = d.x;
      d.y0 = d.y;
    });
  }

  function collapse(d) {
    if (d.children) {
      d._children = d.children;
      d._children.forEach(collapse);
      d.children = null;
    }
  }

  let root = props.treeData.sort((a, b) => b.height - a.height);
  (root as any).x = props.width / 2;
  (root as any).y = 0;

  const createNewRootDescendents = () => {
    root.descendants().forEach((d: any, i) => {
      d.id = i;
      d._children = d.children;
      if (d.depth && d?.data[0]?.length < 1) d.children = null;
    });
    root.children.forEach(collapse);
  };

  //enable zoom/pan on chart
  svg.call(
    d3
      .zoom()
      .extent([
        [0, 0],
        [props.width - margin.left, props.height - margin.top],
      ])
      .on("zoom", zoomed)
  );
  const g = svg.selectAll("g");

  function zoomed({ transform }) {
    g.attr("transform", transform);
  }

  React.useLayoutEffect(() => {
    if (treeSvg.current) {
      if (treeSvg.current?.children.length > 0)
        treeSvg.current?.children[0].remove();
      createNewRootDescendents();
      update(root);
      treeSvg.current.appendChild(svg.node());
    }
  }, [props.treeData]);

  return <div ref={treeSvg} />;
};
