import { IContextualMenuItem, IContextualMenuProps, ScrollablePane, Text, mergeStyles, } from "@fluentui/react";
import { DashboardCard } from "@m365-admin/card";
import { useM365Theme } from "@m365-admin/customizations";
import { Dashboard } from "@m365-admin/dashboard";
import { IRearrangeableGridDefinition, IRearrangeableGridGap, } from "@m365-admin/rearrangeable-grid";
import { CardSizeValues, CardSizes } from "components/Cards.constants";
import { ServiceDepGapAnnotationCard } from "components/cards/ServiceDepGapAnnotationCard";
import { ServicesWithCircularDepDataGrid } from "components/datagrids/ServicesWithCircularDepDataGrid";
import { Top10ServiceDownstreamDepDataGrid } from "components/datagrids/Top10ServiceDownstreamDepDataGrid";
import { Top10ServiceUpstreamDepDataGrid } from "components/datagrids/Top10ServiceUpstreamDepDataGrid";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchServiceDepCounts, fetchServiceDependencyDriData, fetchServiceDependencyGraphLinksData, fetchServiceDependencyMetadata } from "store/actions/serviceDependencyMappingActions";
import { getComplianceStatusCounts, getDependencyGapCounts, getServiceDepAggCountsLoadingStatus, getServicesWithCircularDependencies, getTop10ServicesWithMostDownstreamDependencies, getTop10ServicesWithMostUpstreamDependencies } from "store/selectors/serviceDependencyMappingSelector";
import { useAppDispatch, useAppSelector } from "store/store";
import { _Styles } from "../../pages/Page.styles";

export interface DependencyMappingDashboardProps { }

export function DependencyMappingDashboard() {
  const navigate= useNavigate();
  const theme = useM365Theme();

  const dispatch = useAppDispatch()

  // store the state of the Dashboard and handle changes within it
  const [map, setMap] = useState<IRearrangeableGridDefinition>({
    dependencyGapCard: { cellHeight: 1, cellWidth: 4, row: 0, col: 0 },
    top10upstreamCard: { cellHeight: 1, cellWidth: 4, row: 1, col: 0 },
    top10downstreamCard: { cellHeight: 1, cellWidth: 4, row: 1, col: 4 },
    serviceswithCircularDepCard: {
      cellHeight: 1,
      cellWidth: 4,
      row: 2,
      col: 0,
    },
  });

  // default row/column sizing
  const defaultDashboardGridSize = {
    minCellWidth: 200,
    minCellHeight: 150,
  };

  //handle resizing in the dashboard grid
  const resizeCallback =
    useRef<
      (
        width: number,
        height: number,
        itemKey: string,
        position?: { col: number; row: number }
      ) => void
    >();

  const generateResizeCallback = (key: string) => {
    return (
      _,
      item: IContextualMenuItem
    ) => {

      resizeCallback.current?.(
        CardSizeValues[item.key as CardSizes].cellWidth,
        CardSizeValues[item.key as CardSizes].cellHeight, key);
    };
  };

  const columnResizeRef =
    useRef<
      (width: number, height: number, gap: IRearrangeableGridGap) => void
    >();

  const columnResize = (
    width: number,
    height: number,
    gap: IRearrangeableGridGap
  ) => {
    columnResizeRef.current?.(width, height, gap);
  };

  const onChange = (newMap: IRearrangeableGridDefinition) => {
    setMap(newMap);
  };
  const resizeText = "Resize";

  const generateItemMenu = (key: string): IContextualMenuProps => {
    const items: IContextualMenuItem[] = [
      {
        iconProps: { iconName: "MiniExpand" },
        key: resizeText,
        text: resizeText,
        subMenuProps: {
          items: [
            { key: CardSizes.small, text: CardSizes.small },
            { key: CardSizes.smallWide, text: CardSizes.smallWide },
            { key: CardSizes.medium, text: CardSizes.medium },
            { key: CardSizes.mediumTall, text: CardSizes.mediumTall },
            { key: CardSizes.mediumWide, text: CardSizes.mediumWide },
            { key: CardSizes.large, text: CardSizes.large },
            { key: CardSizes.extraLarge, text: CardSizes.extraLarge },
          ],
          onItemClick: generateResizeCallback(key),
        },
      },
    ];
    return { items };
  };

  const onSelectedService = (serviceOid: string) => {
    dispatch(fetchServiceDependencyMetadata(serviceOid));
    dispatch(fetchServiceDependencyDriData(serviceOid));
    dispatch(fetchServiceDependencyGraphLinksData(serviceOid));
    navigate("/serviceDependencies");
  };

  const complianceStatusCounts = useAppSelector(getComplianceStatusCounts);
  const dependencyGapCounts = useAppSelector(getDependencyGapCounts);
  const top10ServicesWithMostUpstreamDependencies = useAppSelector(getTop10ServicesWithMostUpstreamDependencies);
  const top10ServicesWithMostDownstreamDependencies = useAppSelector(getTop10ServicesWithMostDownstreamDependencies);
  const servicesWithCircularDependencies = useAppSelector(getServicesWithCircularDependencies);

  const serviceDepAggCountsLoadingStatus = useAppSelector(getServiceDepAggCountsLoadingStatus);

  useEffect(() => {
    if (!complianceStatusCounts || complianceStatusCounts.length === 0) {
      dispatch(fetchServiceDepCounts());
    }
  }, [complianceStatusCounts]);

  useEffect(() => {
    if (!dependencyGapCounts || dependencyGapCounts.length === 0) {
      dispatch(fetchServiceDepCounts());
    }
  }, [dependencyGapCounts]);

  return (
    <div className={_Styles.defaultDashboard}>
      <div
        className={mergeStyles({
          backgroundColor: theme.semanticColors.navBackground,
          padding: 24,
          marginRight: 16,
        })}
      >
        <Dashboard
          map={map}
          onChange={onChange}
          ariaLabel="Dependency Mapping Dashboard"
          ariaDescription="Use the arrow keys to navigate to a card. Press enter key to enter each item. Press escape to return to the grid when within an item."
          rearrangeableGridProps={{
            resizeHandler: resizeCallback,
            onColumnResize: columnResize,
            minCellWidth: defaultDashboardGridSize.minCellWidth,
            minCellHeight: defaultDashboardGridSize.minCellHeight,
            gridGap: 24,
          }}
        >
          <DashboardCard
            key={"dependencyGapCard"}
            titleText={"Service Dependency Gap Details"}
            isLoading={serviceDepAggCountsLoadingStatus}
            body={ <ServiceDepGapAnnotationCard /> }
            moreIconButtonProps={{
              ariaLabel: "More Actions",
              menuProps: generateItemMenu("dependencyGapCard"),
            }}
          />
          <DashboardCard
            key={"top10upstreamCard"}
            titleText={
              "Top 10 Services with Most Upstream Dependencies Details"
            }
            isLoading={serviceDepAggCountsLoadingStatus}
            body={
              top10ServicesWithMostUpstreamDependencies.length > 0 ? (
                <div>
                  <ScrollablePane className={_Styles.wrapper}>
                    <Top10ServiceUpstreamDepDataGrid
                      top10ServiceUpstreamDepData={
                        top10ServicesWithMostUpstreamDependencies
                      }
                      onSelectedItem={onSelectedService}
                    />
                  </ScrollablePane>
                </div>
              ) : (
                <div>
                  <Text> No service with upstream dependency data found. </Text>
                </div>
              )
            }
            moreIconButtonProps={{
              ariaLabel: "More Actions",
              menuProps: generateItemMenu("top10upstreamCard"),
            }}
          />
          <DashboardCard
            key={"top10downstreamCard"}
            titleText={
              "Top 10 Services with Most Downstream Dependencies Details"
            }
            body={
              top10ServicesWithMostDownstreamDependencies.length > 0 ? (
                <div>
                  <ScrollablePane className={_Styles.wrapper}>
                    <Top10ServiceDownstreamDepDataGrid
                      top10ServiceDownstreamDepData={
                        top10ServicesWithMostDownstreamDependencies
                      }
                      onSelectedItem={onSelectedService}
                    />
                  </ScrollablePane>
                </div>
              ) : (
                <div>
                  <Text>
                    {" "}
                    No service with downstream dependency data found.{" "}
                  </Text>
                </div>
              )
            }
            moreIconButtonProps={{
              ariaLabel: "More Actions",
              menuProps: generateItemMenu("top10downstreamCard"),
            }}
          />
          <DashboardCard
            key={"serviceswithCircularDepCard"}
            titleText={"Services with Circular Dependencies Details"}
            isLoading={serviceDepAggCountsLoadingStatus}
            body={
              servicesWithCircularDependencies.length > 0 ? (
                <div>
                  <ScrollablePane className={_Styles.wrapper}>
                    <ServicesWithCircularDepDataGrid
                      servicesWithCircularDepData={
                        servicesWithCircularDependencies
                      }
                      onSelectedItem={onSelectedService}
                    />
                  </ScrollablePane>
                </div>
              ) : (
                <div>
                  <Text> No service with circular dependency data found. </Text>
                </div>
              )
            }
            moreIconButtonProps={{
              ariaLabel: "More Actions",
              menuProps: generateItemMenu("serviceswithCircularDepCard"),
            }}
          />
        </Dashboard>
      </div>
    </div>
  );
}
