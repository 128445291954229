import React from 'react';
import { HorizontalBarChart, IChartProps } from '@fluentui/react-charting';

import { pieChartColorOptions } from './InfoDonutChart';
import { ProcessAssetDetail } from '../Types';

export interface OrgProcessBarChartProps {
  processAssetData: ProcessAssetDetail[];
}

export const OrgProcessBarChart = (props: OrgProcessBarChartProps) => {
  const [points, setPoints] = React.useState<IChartProps[]>([]);

  React.useMemo(() => {
    if (props.processAssetData.length > 0) {
      let processList = props.processAssetData;

      let temp = processList.reduce(function (procList, proc) {
        let org = proc.fullyQualifiedOrganization.split(':')[0].trim();
        if (org === '') {
          org = 'Unknown';
        }
        if (procList == null || !procList[org]) {
          procList[org] = 1;
        } else {
          procList[org]++;
        }
        return procList;
      }, {});
      let procCountList = Object.entries(temp).map(([key, value]) => {
        return {
          type: key,
          count: Number(value),
        };
      });

      let index = 0;
      let totalCount = procCountList.reduce((acc, proc) => acc + proc.count, 0);
      let data: IChartProps[] = procCountList
        .filter((pc) => pc.count > 0)
        .map((p): IChartProps => {
          return {
            chartTitle: p.type,
            chartData: [
              {
                legend: p.type,
                horizontalBarChartdata: { x: p.count, y: totalCount },
                xAxisCalloutData: p.type,
                yAxisCalloutData: p.count.toString(),
                color: pieChartColorOptions[index++],
              },
            ],
          };
        });
      data.sort(
        (a, b) => b.chartData[0].horizontalBarChartdata.x - a.chartData[0].horizontalBarChartdata.x
      );
      setPoints(data);
    }
  }, [props.processAssetData]);

  return <HorizontalBarChart data={points} hideRatio={[false, false]} />;
};
