import {
  IFacilityModel,
  IRestrictedFacilityDataDownload,
  IFacilityDataDownload,
  NormalizedEmployeeProfile,
  ProcessAssetDetail,
  IGeoResilienceTeamDataDownload,
  IGeoResilienceTeamData,
} from "../../Types";
import {
  DownstreamDependencyData,
  IDownstreamDependencyDataDownload,
  IServiceBcdrDetailedDataDownload,
  IUpstreamDependencyDataDownload,
  ServiceBcdrDetailedMetadata,
  UpstreamDependencyData,
} from "../../../types/DependencyMappingTypes";
import { IPeopleDataDownload, IRestrictedPeopleDataDownload } from "../../../types/PeopleTypes";

export const mapToRestrictedFacView = (
  f: IFacilityModel,
  timeStamp
): IRestrictedFacilityDataDownload => {
  return {
    source: f.source,
    facilityId: f.facilityId,
    facilityName: f.facilityName,
    facilityShortCode: f.facilityShortCode,
    facilityRegion: f.facilityRegion,
    facilityType: f.facilityType,
    dcClassification: f.coiDatacenter?.operationalTaxonomy ?? "",
    state: f.state,
    country: f.country,
    ownershipType: f.ownershipType,
    owner: f.owner,
    buildingClassification: f.buildingClassification,
    containsEssentials: f.containsEssentials,
    fireDepartment: f.fireDepartment,
    policeDepartment: f.policeDepartment,
    powerDepartment: f.powerDepartment,
    waterDepartment: f.waterDepartment,
    portfolioName: f.portfolioName,
    portfolioManagerName: f.portfolioManagerName,
    sysLastModifiedDateTime: timeStamp,
  };
};

export const mapToUnrestrictedFacView = (f: IFacilityModel, timeStamp): IFacilityDataDownload => {
  return {
    source: f.source,
    facilityId: f.facilityId,
    facilityName: f.facilityName,
    facilityShortCode: f.facilityShortCode,
    facilityRegion: f.facilityRegion,
    facilityType: f.facilityType,
    dcClassification: f.coiDatacenter?.operationalTaxonomy ?? "",
    address: f.address,
    city: f.city,
    state: f.state,
    country: f.country,
    ownershipType: f.ownershipType,
    owner: f.owner,
    buildingClassification: f.buildingClassification,
    containsEssentials: f.containsEssentials,
    fireDepartment: f.fireDepartment,
    policeDepartment: f.policeDepartment,
    powerDepartment: f.powerDepartment,
    waterDepartment: f.waterDepartment,
    portfolioName: f.portfolioName,
    portfolioManagerName: f.portfolioManagerName,
    sysLastModifiedDateTime: timeStamp,
  };
};

export const mapToRestrictedPeopleView = (
  p: NormalizedEmployeeProfile,
  timeStamp
): IRestrictedPeopleDataDownload => {
  return {
    alias: p.alias,
    isMobile: p.isMobile,
    isActiveFte: p.isActiveFte,
    fullName: p.fullName,
    jobTitle: p.jobTitle,
    jobTitleSummaryDesc: p.jobTitleSummaryDesc,
    csCompanyAgencyName: p.csCompanyAgencyName,
    execOrgSummaryDesc: p.execOrgSummaryDesc,
    execOrgDesc: p.execOrgDesc,
    execOrgDetailDesc: p.execOrgDetailDesc,
    execFunctionSummaryDesc: p.execFunctionSummaryDesc,
    l4ManagerEmailName: p.l4ManagerEmailName,
    l4Manager: p.l4Manager,
    l3ManagerEmailName: p.l3ManagerEmailName,
    l3Manager: p.l3Manager,
    l2ManagerEmailName: p.l2ManagerEmailName,
    l2Manager: p.l2Manager,
    l1ManagerEmailName: p.l1ManagerEmailName,
    l1Manager: p.l1Manager,
    l0ManagerEmailName: p.l0ManagerEmailName,
    l0Manager: p.l0Manager,
    l5Manager: p.l5Manager,
    l5ManagerEmailName: p.l5ManagerEmailName,
    l6Manager: p.l6Manager,
    l6ManagerEmailName: p.l6ManagerEmailName,
    l7Manager: p.l7Manager,
    l7ManagerEmailName: p.l7ManagerEmailName,
    region: p.region,
    country: p.country,
    state: p.state,
    city: p.city,
    buildingName: p.buildingName,
    datetimeStamp: timeStamp,
  };
};

export const mapToUnrestrictedPeopleView = (
  p: NormalizedEmployeeProfile,
  timeStamp
): IPeopleDataDownload => {
  return {
    alias: p.alias,
    isMobile: p.isMobile,
    isActiveFte: p.isActiveFte,
    isDataCenterEmployee: p.isDataCenterEmployee,
    fullName: p.fullName,
    jobTitle: p.jobTitle,
    jobTitleSummaryDesc: p.jobTitleSummaryDesc,
    csCompanyAgencyName: p.csCompanyAgencyName,
    execOrgSummaryDesc: p.execOrgSummaryDesc,
    execOrgDesc: p.execOrgDesc,
    execOrgDetailDesc: p.execOrgDetailDesc,
    execFunctionSummaryDesc: p.execFunctionSummaryDesc,
    l4ManagerEmailName: p.l4ManagerEmailName,
    l4Manager: p.l4Manager,
    l3ManagerEmailName: p.l3ManagerEmailName,
    l3Manager: p.l3Manager,
    l2ManagerEmailName: p.l2ManagerEmailName,
    l2Manager: p.l2Manager,
    l1ManagerEmailName: p.l1ManagerEmailName,
    l1Manager: p.l1Manager,
    l0ManagerEmailName: p.l0ManagerEmailName,
    l0Manager: p.l0Manager,
    l5Manager: p.l5Manager,
    l5ManagerEmailName: p.l5ManagerEmailName,
    l6Manager: p.l6Manager,
    l6ManagerEmailName: p.l6ManagerEmailName,
    l7Manager: p.l7Manager,
    l7ManagerEmailName: p.l7ManagerEmailName,
    region: p.region,
    country: p.country,
    state: p.state,
    city: p.city,
    buildingName: p.buildingName,
    buildingId: p.buildingId,
    datetimeStamp: timeStamp,
  };
};

export const mapToProcessView = (
  processAssetDetail: ProcessAssetDetail,
  timeStamp
): ProcessAssetDetail => {
  return { ...processAssetDetail, datetimeStamp: timeStamp };
};

export const mapToDriDataView = (
  geoData: IGeoResilienceTeamData,
  teamId: number,
  teamName: string,
  serviceOid: string,
  timestamp
): IGeoResilienceTeamDataDownload => {
  return {
    teamId: teamId,
    teamName: teamName,
    serviceId: serviceOid,
    ...geoData,
    datetimeStamp: timestamp,
  };
};
export const mapToDownstreamDependencyView = (
  d: DownstreamDependencyData,
  referenceServiceName: string,
  referenceServiceOid: string,
  timeStamp
): IDownstreamDependencyDataDownload => {
  return {
    downstreamServiceName: d.serviceName,
    downstreamServiceOid: d.serviceOid,
    dependencyClassification: d.dependencyClassification,
    desiredDependencyServiceRtc: d.desiredDependencyServiceRtc,
    isUpstreamViolatingDownstreamRtcRequirements: d.isUpstreamViolatingDownstreamRtcRequirements,
    currentServiceName: referenceServiceName,
    currentServiceOid: referenceServiceOid,
    datetimeStamp: timeStamp,
  };
};

export const mapToUpstreamDependencyView = (
  d: UpstreamDependencyData,
  referenceServiceName: string,
  referenceServiceOid: string,
  timeStamp
): IUpstreamDependencyDataDownload => {
  return {
    upstreamServiceName: d.serviceName,
    upstreamServiceOid: d.serviceOid,
    criticalDataRecoveryRpo: d.criticalDataRecoveryRpo,
    dependencyClassification: d.dependencyClassification,
    dependencyResiliencyType: d.dependencyResiliencyType,
    desiredDependencyServiceRtc: d.desiredDependencyServiceRtc,
    isViolatingDownstreamRtcRequirements: d.isViolatingDownstreamRtcRequirements,
    resiliencyWorkaroundDescription: d.resiliencyWorkaroundDescription,
    resiliencyWorkaroundRtc: d.resiliencyWorkaroundRtc,
    storesServiceData: d.storesServiceData,
    upstreamApprovedRpc: d.upstreamApprovedRpc,
    upstreamApprovedRtc: d.upstreamApprovedRtc,
    upstreamDetectionType: d.upstreamDetectionType,
    upstreamInCanaryCentral: d.upstreamInCanaryCentral,
    upstreamInCanaryEast: d.upstreamInCanaryEast,
    upstreamLastTestDate: d.upstreamLastTestDate,
    upstreamRecoveryAutomation: d.upstreamRecoveryAutomation,
    upstreamRecoveryDecision: d.upstreamRecoveryDecision,
    currentServiceName: referenceServiceName,
    currentServiceOid: referenceServiceOid,
    datetimeStamp: timeStamp,
  };
};

export const mapToServiceDependencyView = (
  currentService: ServiceBcdrDetailedMetadata,
  timeStamp
): IServiceBcdrDetailedDataDownload => {
  return {
    approvedRpc: currentService.approvedRpc,
    approvedRpo: currentService.approvedRpo,
    approvedRto: currentService.approvedRto,
    approvedRtc: currentService.coreServiceData.approvedRtc,
    assessmentDate: currentService.assessmentDate,
    assessmentLink: currentService.assessmentLink,
    assessmentStatus: currentService.assessmentStatus,
    bcdrContact: currentService.bcdrContact,
    bcdrContactEmail: currentService.bcdrContactEmail,
    bcdrRecoveryClassification: currentService.bcdrRecoveryClassification,
    complianceStatus: currentService.complianceStatus,
    containsCustomerData: currentService.containsCustomerData,
    criticalityRating: currentService.coreServiceData.criticalityRating,
    divisionName: currentService.coreServiceData.divisionName,
    organizationName: currentService.coreServiceData.organizationName,
    serviceGroupName: currentService.coreServiceData.serviceGroupName,
    serviceName: currentService.coreServiceData.serviceName,
    serviceTreeLink: currentService.coreServiceData.serviceTreeLink,
    criticalityCriteria: currentService.criticalityCriteria,
    criticalityRatingReason: currentService.criticalityRatingReason,
    detectionType: currentService.detectionType,
    devContacts: currentService.devContacts,
    devContactsEmail: currentService.devContactsEmail,
    hasCircularDependency: currentService.hasCircularDependency,
    hasDownstreamRecoveryGap: currentService.hasDownstreamRecoveryGap,
    hasUpstreamRecoveryGap: currentService.hasUpstreamRecoveryGap,
    inCanaryCentral: currentService.inCanaryCentral,
    inCanaryEast: currentService.inCanaryEast,
    lastTestDate: currentService.lastTestDate,
    maxLifecycle: currentService.maxLifecycle,
    pmContacts: currentService.pmContacts,
    pmContactsEmail: currentService.pmContactsEmail,
    recoveryAutomation: currentService.recoveryAutomation,
    recoveryDecision: currentService.recoveryDecision,
    resiliencyDescription: currentService.resiliencyDescription,
    serviceOid: currentService.serviceOid,
    serviceRing: currentService.serviceRing,
    serviceType: currentService.serviceType,
    testEnvironment: currentService.testEnvironment,
    testScenario: currentService.testScenario,
    willingnessToFailover: currentService.willingnessToFailover,
    datetimeStamp: timeStamp,
  };
};
