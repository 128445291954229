import { createSelector } from "reselect";
import { ReduxUserState } from "../reducers/userReducer";

const users = (state: any): ReduxUserState => state.users;

export const getUserDownloadPermissions = createSelector(
  users,
  (users: ReduxUserState): boolean => users.userCanDownload
);

export const getUserIsRestricted = createSelector(
  users,
  (users: ReduxUserState): boolean => users.userIsRestricted
);
