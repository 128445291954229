import React from 'react';
import {
  Facepile,
  IFacepilePersona,
  OverflowButtonType,
  PersonaSize,
} from '@fluentui/react';

export interface ContactModalContentProps {
  userList: string[];
  userType: userType;
}
export type userType = 'Project Manager' | 'Developer' | 'Manager';
export const ContactModalContent = (props: ContactModalContentProps) => {
  const [personas, setPersonas] = React.useState<IFacepilePersona[]>([]);

  const makePersona = (users: string[], userType: string) => {
    let color = 1;
    if (userType === 'Project Manager') {
      color = 3;
    } else if (userType === 'Developer') {
      color = 10;
    }
    let personas: IFacepilePersona[] = users.map((user) => {
      return {
        personaName: user,
        initialsColor: color,
      };
    });
    return personas;
  };

  React.useEffect(() => {
    let personas = makePersona(props.userList, props.userType);
    setPersonas(personas);
  }, [props.userList]);

  return (
    <Facepile
      personaSize={PersonaSize.size24}
      personas={personas}
      overflowButtonType={OverflowButtonType.descriptive}
      maxDisplayablePersonas={3}
      ariaDescription="To move through the items use left and right arrow keys."
      ariaLabel={`List of Facepile personas representing ${props.userType}s`}
    />
  );
};
