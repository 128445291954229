import { IComboBoxOption } from "@fluentui/react";
import { ICountAnnotationProps } from "@m365-admin/count-annotation";
import { createSelector } from "reselect";
import {
  AzureRegion,
  IDriGeoTeamCount,
  ServiceAssetDetail,
  ServiceCount,
} from "../../components/Types";
import { pieChartColorOptions } from "../../components/charts/InfoDonutChart";
import { buildAggregateCounts, buildServiceCounts } from "../../helpers/serviceAssetMappingHelpers";
import { ReduxFacilitiesState } from "../reducers/facilitiesReducer";
import { ReduxServicesState } from "../reducers/servicesReducer";
import { facilityFilterHelper } from "./facilitiesSelector";
import { RootState } from "store/store";
import { DcDetected } from "store/actions/servicesActions";

const facilities = (state: any): ReduxFacilitiesState => state.facilities;
const services = (state: RootState): ReduxServicesState => state.services;

export const getDriInsightsByRegionLoadingStatus = createSelector(
  services,
  (services: ReduxServicesState) =>
    services.isServiceAssetDetailsLoading || services.isDriGeoResilDataLoading
);

export const getDriGeoCounts = () =>
  createSelector(
    services,
    facilities,
    (services: ReduxServicesState, facilities: ReduxFacilitiesState): ICountAnnotationProps[] => {
      if (services) {
        const totalServices = services?.serviceCounts?.totalCount ?? 0;
        let newAggregateCounts = {
          ...services.driGeoResilTeamCountData,
          totalServicesWithNoDriCount:
            totalServices > 0
              ? totalServices - services.driGeoResilTeamCountData?.totalServicesWithDriCount ?? 0
              : 0,
        };
        let filteredServices = services?.serviceAssetDetails ?? [];
        if (filteredServices.length === 0 && services.driGeoResilTeamCountData !== null) {
          return buildDriGeoTeamCounts(newAggregateCounts);
        }
        filteredServices = countryFilterHelper(filteredServices, facilities);
        const filteredDriGeoServiceData = filteredServices.filter(
          (fs) => fs.driGeoTeamData.length > 0
        );
        const driTeamCount = filteredDriGeoServiceData.reduce(
          (acc, curr) =>
            curr.driGeoTeamData?.length > 0 ? (acc += curr.driGeoTeamData.length) : acc,
          0
        );
        newAggregateCounts = {
          totalDriCount: filteredDriGeoServiceData.reduce(
            (acc, curr) => acc + curr.driGeoTeamData[0]?.geoResilienceTeamData[0]?.driTotal ?? 0,
            0
          ),
          totalServicesWithDriCount: filteredDriGeoServiceData?.length,
          totalServicesWithNoDriCount:
            filteredServices?.length - filteredDriGeoServiceData?.length ?? 0,
          totalTeamsWithServiceCount: driTeamCount,
          totalTeamsWithoutServiceCount:
            filteredServices.length - driTeamCount > 0 ? filteredServices.length - driTeamCount : 0,
        };

        return buildDriGeoTeamCounts(newAggregateCounts);
      }
    }
  );

export const getServiceCounts = () =>
  createSelector(
    services,
    facilities,
    (services: ReduxServicesState, facilities: ReduxFacilitiesState) => {
      let newAggregateCounts: ServiceCount = {
        totalCount: 0,
        missionCriticalCount: 0,
        criticalCount: 0,
        importantCount: 0,
        deferrableCount: 0,
        minorCount: 0,
        notYetRatedCount: 0,
        missingParentCount: 0,
        unknown: 0,
        regionIsolated: 0,
        dcIsolated: 0,
        dcsInScope: 0,
        dcsInTotal: 0,
      };
      if (services) {
        let filteredServices = services.serviceAssetDetails;
        if (filteredServices.length === 0 && services.serviceCounts !== null) {
          newAggregateCounts = services.serviceCounts;
          return buildAggregateCounts(newAggregateCounts);
        }

        filteredServices = countryFilterHelper(filteredServices, facilities);
        const activeDcs = services.serviceDcFilters ?? [];
        newAggregateCounts = buildServiceCounts(filteredServices, activeDcs);
      }
      return buildAggregateCounts(newAggregateCounts);
    }
  );

export const getServiceCountsLoadingStatus = createSelector(
  services,
  (services: ReduxServicesState) =>
    services.isServiceCountsLoading || services.isServiceAssetDetailsLoading
);

export const getServiceAssetDetails = createSelector(
  services,
  facilities,
  (services: ReduxServicesState, facilities: ReduxFacilitiesState): ServiceAssetDetail[] => {
    if (services) {
      let filteredServices = services.serviceAssetDetails;
      filteredServices = countryFilterHelper(filteredServices, facilities);
      const currentSearchTerm = services.serviceAssetSearchTerm ?? "";
      if (currentSearchTerm.length > 0) {
        filteredServices = filteredServices.filter((fs) =>
          fs.serviceName.toUpperCase().includes(currentSearchTerm.toUpperCase())
        );
      }
      return filteredServices;
    }
  }
);

export const getServiceAssetAzureCounts = createSelector(
  services,
  facilities,
  (services: ReduxServicesState, facilities: ReduxFacilitiesState): number => {
    if (services) {
      let filteredServices = services.serviceAssetDetails;
      const locationsToFilter = [
        ...facilities.facilityCityFilter,
        ...facilities.facilityCountryFilter,
        ...facilities.facilityStateFilter,
      ];
      const serviceDcFilters = services.serviceDcFilters;
      // filter out any services that do not have azure region data in the applied city, state, or country filters
      const filterResults: AzureRegion[] = filteredServices.flatMap((fs) => fs.azureRegionData);

      return filterResults.filter((reg) => {
        if (
          locationsToFilter.length > 0 &&
          !locationsToFilter.includes(reg.countryRegion.toUpperCase())
        ) {
          return false;
        }
        if (serviceDcFilters.length > 0 && !serviceDcFilters.includes(reg.datacenterCampusId)) {
          return false;
        }
        return true;
      }).length;
    }
    return 0;
  }
);

export const getServiceAssetDetailsError = createSelector(
  services,
  (services: ReduxServicesState) => {
    let errorString: string = "";
    if (services.serviceCountError) {
      errorString += `Error loading service counts. ${services.serviceCountError}`;
    }
    if (services.serviceAssetError) {
      errorString += `Error loading service assets. ${services.serviceAssetError}`;
    }
    if (services.driGeoResilLoadError) {
      errorString += `Error loading dri geo resilience services. ${services.driGeoResilLoadError}`;
    }
    if (errorString.length === 0) return null;
    return errorString;
  }
);

export const getServiceAssetDetailsByIdsLoadingStatus = createSelector(
  services,
  (services: ReduxServicesState) => services.isServiceAssetDetailsLoading
);

export const getServiceAssetDetailsByIds = createSelector(
  services,
  (services: ReduxServicesState): ServiceAssetDetail[] => {
    return services.serviceAssetDetails;
  }
);

export const getServiceAssetDetailsByIdsError = createSelector(
  services,
  (services: ReduxServicesState) => {
    let errorString: string = "";
    if (services.serviceAssetError) {
      errorString += `Error loading service assets. ${services.serviceAssetError}`;
    }
    if (services.driGeoResilLoadError) {
      errorString += `Error loading dri geo resilience services. ${services.driGeoResilLoadError}`;
    }
    if (errorString.length === 0) return null;
    return errorString;
  }
);

export const getServiceAssetDetailsLoadingStatus = createSelector(
  services,
  (services: ReduxServicesState) => services.isServiceAssetDetailsLoading
);

export const getServiceAssetSearchTerm = createSelector(
  services,
  (services: ReduxServicesState) => services?.serviceAssetSearchTerm ?? ""
);

export const getServiceRtoCriticalityOptions = createSelector(
  services,
  (services: ReduxServicesState): IComboBoxOption[] =>
    services.serviceAssetDetails
      .reduce(function (critList, service) {
        if (critList == null || !critList.includes(service.rtoCriticalRating)) {
          critList.push(service.rtoCriticalRating);
        }
        return critList;
      }, [])
      .map((rto) => ({ key: rto, text: rto }))
);

export const getServiceRtoCriticalityFilters = createSelector(
  services,
  (services: ReduxServicesState) => services.serviceCriticalityFilters
);

export const getServiceSingleDcFilter = createSelector(
  services,
  (services: ReduxServicesState) => services.serviceSingleDcFilter
);

export const getServiceDivisionOptions = createSelector(
  services,
  (services: ReduxServicesState): IComboBoxOption[] => {
    if (services) {
      const activeCriticalityFilters = services.serviceCriticalityFilters;
      const activeServices =
        activeCriticalityFilters.length === 0
          ? services.serviceAssetDetails
          : services.serviceAssetDetails.filter((srv) =>
              activeCriticalityFilters.includes(srv.rtoCriticalRating)
            );
      const divSet = activeServices.reduce(function (divList, service) {
        if (divList == null || !divList.includes(service.divisionName)) {
          divList.push(service.divisionName);
        }
        return divList;
      }, []);
      return divSet.sort().map((div) => ({
        key: div,
        text: div.length > 0 ? div : "Unknown",
      }));
    }
    return [];
  }
);

export const getServiceDivisionFilters = createSelector(
  services,
  (services: ReduxServicesState) => services.serviceDivisionFilters
);

export const getServiceOrganizationOptions = createSelector(
  services,
  (services: ReduxServicesState): IComboBoxOption[] => {
    if (services) {
      const activeCriticalityFilters = services?.serviceCriticalityFilters ?? [];
      const activeDivisionFilters = services?.serviceDivisionFilters ?? [];
      let activeServices = services.serviceAssetDetails;
      if (activeCriticalityFilters.length > 0) {
        activeServices = activeServices.filter((srv) =>
          activeCriticalityFilters.includes(srv.rtoCriticalRating)
        );
      }
      if (activeDivisionFilters.length > 0) {
        activeServices = activeServices.filter((srv) =>
          activeDivisionFilters.includes(srv.divisionName)
        );
      }
      const orgSet = activeServices.reduce(function (orgList, service) {
        if (orgList == null || !orgList.includes(service.organizationName)) {
          orgList.push(service.organizationName);
        }
        return orgList;
      }, []);
      return orgSet.sort().map((org) => ({
        key: org,
        text: org.length > 0 ? org : "Unknown",
      }));
    }
    return [];
  }
);

export const getServiceOrganizationFilters = createSelector(
  services,
  (services: ReduxServicesState) => services.serviceOrganizationFilters
);

export const getServiceSgOptions = createSelector(
  services,
  (services: ReduxServicesState): IComboBoxOption[] => {
    if (services) {
      let activeServices = services?.serviceAssetDetails ?? [];
      const activeCriticalityFilters = services?.serviceCriticalityFilters ?? [];
      if (activeCriticalityFilters.length > 0) {
        activeServices = activeServices.filter((srv) =>
          activeCriticalityFilters.includes(srv.rtoCriticalRating)
        );
      }

      const activeDivisionFilters = services?.serviceDivisionFilters ?? [];
      if (activeDivisionFilters.length > 0) {
        activeServices = activeServices.filter((srv) =>
          activeDivisionFilters.includes(srv.divisionName)
        );
      }

      const activeOrgFilters = services?.serviceOrganizationFilters ?? [];
      if (activeOrgFilters.length > 0) {
        activeServices = activeServices.filter((srv) =>
          activeOrgFilters.includes(srv.organizationName)
        );
      }

      const serviceGroupSet = activeServices.reduce(function (sgList, service) {
        if (sgList == null || !sgList.includes(service.serviceGroupName)) {
          sgList.push(service.serviceGroupName);
        }
        return sgList;
      }, []);
      //set up options
      return serviceGroupSet.sort().map((sg) => ({
        key: sg,
        text: sg.length > 0 ? sg : "Unknown",
      }));
    }
    return [];
  }
);

export const getServiceSgFilters = createSelector(
  services,
  (services: ReduxServicesState): string[] => services?.serviceSgFilters ?? []
);

export const getServiceRecoveryClassificationOptions = createSelector(
  services,
  (services: ReduxServicesState): IComboBoxOption[] => {
    if (services) {
      let activeServices = services?.serviceAssetDetails ?? [];
      const activeCriticalityFilters = services?.serviceCriticalityFilters ?? [];
      if (activeCriticalityFilters.length > 0) {
        activeServices = activeServices.filter((srv) =>
          activeCriticalityFilters.includes(srv.rtoCriticalRating)
        );
      }

      const activeDivisionFilters = services?.serviceDivisionFilters ?? [];
      if (activeDivisionFilters.length > 0) {
        activeServices = activeServices.filter((srv) =>
          activeDivisionFilters.includes(srv.divisionName)
        );
      }

      const activeOrgFilters = services?.serviceOrganizationFilters ?? [];
      if (activeOrgFilters.length > 0) {
        activeServices = activeServices.filter((srv) =>
          activeOrgFilters.includes(srv.organizationName)
        );
      }

      const activeSgFilters = services?.serviceSgFilters ?? [];
      if (activeSgFilters.length > 0) {
        activeServices = activeServices.filter((srv) =>
          activeSgFilters.includes(srv.serviceGroupName)
        );
      }

      const activeBclFilters = services?.serviceBclFilters ?? [];
      if (activeBclFilters.length > 0) {
        activeServices = activeServices.filter((srv) =>
          srv.bclNames.some((bcl) => activeBclFilters.includes(bcl))
        );
      }

      const recoveryClassGroupSet = activeServices.reduce(function (rcList, service) {
        if (rcList == null || !rcList.includes(service.bcdrRecoveryClassification)) {
          rcList.push(service.bcdrRecoveryClassification);
        }
        return rcList;
      }, []);
      //set up options
      return recoveryClassGroupSet.sort().map((rc) => ({
        key: rc,
        text: rc.length > 0 ? rc : "Unknown",
      }));
    }
    return [];
  }
);

export const getServiceRecoveryClassificationFilters = createSelector(
  services,
  (services: ReduxServicesState): string[] => services?.serviceRecoveryClassificationFilters ?? []
);

export const getServiceBclFilters = createSelector(
  services,
  (services: ReduxServicesState): string[] => services?.serviceBclFilters ?? []
);

export const getServiceBclOptions = createSelector(
  services,
  (services: ReduxServicesState): IComboBoxOption[] => {
    if (services) {
      let activeServices = services?.serviceAssetDetails ?? [];
      const activeCriticalityFilters = services?.serviceCriticalityFilters ?? [];
      if (activeCriticalityFilters.length > 0) {
        activeServices = activeServices.filter((srv) =>
          activeCriticalityFilters.includes(srv.rtoCriticalRating)
        );
      }

      const activeDivisionFilters = services?.serviceDivisionFilters ?? [];
      if (activeDivisionFilters.length > 0) {
        activeServices = activeServices.filter((srv) =>
          activeDivisionFilters.includes(srv.divisionName)
        );
      }

      const activeOrgFilters = services?.serviceOrganizationFilters ?? [];
      if (activeOrgFilters.length > 0) {
        activeServices = activeServices.filter((srv) =>
          activeOrgFilters.includes(srv.organizationName)
        );
      }

      const activeSgFilters = services?.serviceSgFilters ?? [];
      if (activeSgFilters.length > 0) {
        activeServices = activeServices.filter((srv) =>
          activeSgFilters.includes(srv.serviceGroupName)
        );
      }

      const bclGroupSet = activeServices.reduce(function (bclList, service) {
        service.bclNames.forEach(function (bcl) {
          if (bclList == null || !bclList.includes(bcl)) {
            bclList.push(bcl);
          }
        });
        return bclList;
      }, []);

      //set up options
      return bclGroupSet.sort().map((bcl) => ({
        key: bcl,
        text: bcl.length > 0 ? bcl : "Unknown",
      }));
    }
    return [];
  }
);

export const getServiceCloudFilters = createSelector(
  services,
  (services: ReduxServicesState): string[] => services?.serviceCloudFilters ?? []
);

export const getServiceCloudOptions = createSelector(
  services,
  facilities,
  (services: ReduxServicesState, facilities: ReduxFacilitiesState): IComboBoxOption[] => {
    if (services) {
      const activeServices = services?.serviceAssetDetails ?? [];
      const activeFacilities = facilityFilterHelper(facilities);
      const dcFacs = Array.from(
        new Set(
          activeFacilities
            .filter((fac) => fac.facilityType === "Datacenter")
            .map((fac) => fac.facilityName)
        )
      );

      const dcSet = activeServices
        .flatMap((x) => x.azureRegionData)
        .filter((dc) => dcFacs.includes(dc.datacenterCampusName))
        .reduce(
          (prev, reg) => ({
            ...prev,
            [reg.azureCloudName]: {
              key: reg.azureCloudName,
              text: reg.azureCloudName,
            },
          }),
          {}
        );

      const sortedKeyText = Object.keys(dcSet)
        .sort()
        .reduce((pre, key) => [...pre, dcSet[key]], []);

      return sortedKeyText;
    }
    return [];
  }
);

export const getServiceDataCenterOptions = createSelector(
  services,
  facilities,
  (services: ReduxServicesState, facilities: ReduxFacilitiesState): IComboBoxOption[] => {
    if (services) {
      const activeServices = services?.serviceAssetDetails ?? [];
      const activeFacilities = facilityFilterHelper(facilities);
      const dcFacs = Array.from(
        new Set(
          activeFacilities
            .filter((fac) => fac.facilityType === "Datacenter")
            .map((fac) => fac.facilityName)
        )
      );

      const dcSet = activeServices
        .flatMap((x) => x.azureRegionData)
        .filter((dc) => dcFacs.includes(dc.datacenterCampusName))
        .reduce(
          (prev, reg) => ({
            ...prev,
            [reg.datacenterCampusId]: {
              key: reg.datacenterCampusId,
              text: reg.datacenterCampusName,
            },
          }),
          {}
        );

      const sortedKeyText = Object.keys(dcSet)
        .sort()
        .reduce((pre, key) => [...pre, dcSet[key]], []);

      return sortedKeyText;
    }
    return [];
  }
);

export const getServiceDataCenterFilters = createSelector(
  services,
  (services: ReduxServicesState): number[] => services?.serviceDcFilters ?? []
);

export const getServiceDataCenterFiltersWithNames = createSelector(
  services,
  (services: ReduxServicesState): { id: number; name: string }[] => {
    const dcFilters = services?.serviceDcFilters ?? [];
    const azureRegions = services?.serviceAssetDetails.flatMap((x) => x.azureRegionData) ?? [];

    return dcFilters.map((id) => {
      const region = azureRegions.find((region) => region.datacenterCampusId === id);
      const name = region ? region.datacenterCampusName : String(id);
      return { id, name };
    });
  }
);

export const getServiceDcDetectedFilter = createSelector(
  services,
  (services: ReduxServicesState): DcDetected => services?.serviceDcDetectedFilter ?? DcDetected.ALL
);

export const getDCDetectedCount = createSelector(services, (services: ReduxServicesState) => {
  const activeServices = services?.serviceAssetDetails ?? [];
  const dcDetectedTotal = activeServices.length;
  const notDetected = activeServices.filter((s) => s.azureRegionData.length === 0);
  const dcDetectedCount = dcDetectedTotal - notDetected.length;

  return {
    dcDetectedTotal,
    dcDetectedCounts: [
      {
        id: DcDetected.DC_DETECTED,
        data: dcDetectedCount,
        legend: `Detected (${dcDetectedCount})`,
      },
      {
        id: DcDetected.DC_NOT_DETECTED,
        data: notDetected.length,
        legend: `Not Detected (${notDetected.length})`,
      },
    ],
  };
});

export const getServiceDriFacilityOptions = createSelector(
  services,
  facilities,
  (services: ReduxServicesState, facilities: ReduxFacilitiesState): IComboBoxOption[] => {
    if (services) {
      const activeTeams = services?.driGeoResilData ?? [];
      const teamData = activeTeams.flatMap((t) =>
        t.driTeamData.flatMap((dri) => dri.geoResilienceTeamData)
      );
      const activeCountryFilters = facilities.facilityCountryFilter;
      let facilityResults = [];
      if (activeCountryFilters.length > 0) {
        const countryCodeMapping = facilities.countryCodeMapping;
        const countryFilterList = activeCountryFilters.map((c) => countryCodeMapping[c]);
        facilityResults = Array.from(
          new Set(
            teamData
              .filter((t) => countryFilterList.includes(t.driCountry.toUpperCase()))
              .map((t) => t.driFacility.toUpperCase())
          )
        );
      } else {
        new Set(teamData.map((t) => t.driFacility.toUpperCase()));
      }

      return facilityResults.sort().map((city) => ({
        key: city,
        text: city,
      }));
    }
    return [];
  }
);

export const getServiceDriCityOptions = createSelector(
  services,
  facilities,
  (services: ReduxServicesState, facilities: ReduxFacilitiesState): IComboBoxOption[] => {
    if (services) {
      const activeTeams = services?.driGeoResilData ?? [];
      const teamData = activeTeams.flatMap((t) =>
        t.driTeamData.flatMap((dri) => dri.geoResilienceTeamData)
      );
      const activeCountryFilters = facilities.facilityCountryFilter;
      const cityResults: Set<string> = facilities.facilities.reduce(
        (cityList: Set<string>, fac) => {
          if (fac.coiDatacenter?.azureRegionDetail && activeCountryFilters.includes(fac.country)) {
            cityList.add(fac.city.toUpperCase());
          }
          return cityList;
        },
        new Set<string>()
      );

      if (activeCountryFilters.length > 0) {
        const countryCodeMapping = facilities.countryCodeMapping;
        const countryFilterList = activeCountryFilters.map((c) => countryCodeMapping[c]);
        teamData.forEach((t) => {
          if (countryFilterList.includes(t.driCountry.toUpperCase()))
            cityResults.add(t.driCity.toUpperCase().trim());
        });
      } else {
        teamData.forEach((t) => cityResults.add(t.driCity.toUpperCase().trim()));
      }

      return Array.from(cityResults)
        .sort()
        .map((city) => ({
          key: city,
          text: city,
        }));
    }
    return [];
  }
);

export const getServiceDriCityFilters = createSelector(
  services,
  (services: ReduxServicesState): string[] => services?.serviceDriCityFilters ?? []
);

const countryFilterHelper = (
  servicesToFilter: ServiceAssetDetail[],
  facilities: ReduxFacilitiesState
): ServiceAssetDetail[] => {
  const countryFilter = facilities.facilityCountryFilter ?? [];
  const cityFilter =
    facilities.facilityCityFilter
      ?.flatMap((c) => [c, c.replace(" ", "")])
      .map((c) => c?.toUpperCase()) ?? [];

  if (countryFilter.length === 0 && cityFilter.length === 0) {
    return servicesToFilter;
  }

  const countryCodeMapping = facilities.countryCodeMapping;
  const countryFilterList = new Set(
    countryFilter
      .flatMap((c) => [countryCodeMapping[c], c, c.replace(" ", "")])
      .map((c) => c?.toUpperCase())
  );

  return servicesToFilter.filter((service) => {
    const hasValidRegionData =
      service.azureRegionData?.length > 0 || service.driGeoTeamData?.length > 0;
    if (!hasValidRegionData) return false;

    const matchesCountry =
      service.driGeoTeamData?.some((dri) =>
        dri.geoResilienceTeamData.some((s) => countryFilterList.has(s.driCountry.toUpperCase()))
      ) ||
      service.azureRegionData?.some((reg) =>
        countryFilterList.has(reg.countryRegion.toUpperCase())
      );

    const matchesCity =
      cityFilter.length === 0 ||
      service.driGeoTeamData?.some((dri) =>
        dri.geoResilienceTeamData.some((s) => cityFilter.includes(s.driCity.toUpperCase()))
      );

    return matchesCountry && matchesCity;
  });
};

const buildDriGeoTeamCounts = (driTeamCountData: IDriGeoTeamCount): ICountAnnotationProps[] => {
  if (driTeamCountData) {
    return [
      {
        annotationText: "Total DRI",
        count: driTeamCountData?.totalDriCount ?? -1,
        annotationColor: pieChartColorOptions[4],
        size: 1,
      },
      {
        annotationText: "Services with DRIs",
        count: driTeamCountData?.totalServicesWithDriCount ?? 0,
        annotationColor: pieChartColorOptions[0],
        size: 1,
      },
      {
        annotationText: "Services without DRIs",
        count: driTeamCountData?.totalServicesWithNoDriCount ?? 0,
        annotationColor: pieChartColorOptions[2],
        size: 1,
      },
      {
        annotationText: "Teams with a known Service",
        count: driTeamCountData?.totalTeamsWithServiceCount ?? -1,
        annotationColor: pieChartColorOptions[3],
        size: 1,
      },
      {
        annotationText: "Teams without a known Service",
        count: driTeamCountData?.totalTeamsWithoutServiceCount ?? -1,
        annotationColor: pieChartColorOptions[1],
        size: 1,
      },
    ];
  }
  return [];
};
