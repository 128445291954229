import { CoherenceLoading } from "@coherence-design-system/controls";
import { ScrollablePane, ThemeProvider } from "@fluentui/react";
import { M365LightTheme } from "@m365-admin/customizations";
import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import { DependencyMappingDashboard } from "components/dashboards/DependencyMappingDashboard";
import { _Styles } from "pages/Page.styles";
import React, { useEffect } from "react";
import { reactPlugin } from "services/AppInsights";
import { fetchServiceDepCounts } from "store/actions/serviceDependencyMappingActions";
import {
  getServiceDepAggCounts,
  getServiceDepAggCountsLoadingStatus,
} from "store/selectors/serviceDependencyMappingSelector";
import { useAppDispatch, useAppSelector } from "store/store";
import { Error } from "./Error";

export interface DependencyMappingOverviewProps {
  isNavCollapsed: boolean;
}

export function DependencyMappingOverview({ isNavCollapsed }: DependencyMappingOverviewProps) {
  const dispatch = useAppDispatch();
  const serviceDepAggCountsLoadingStatus = useAppSelector(getServiceDepAggCountsLoadingStatus);
  const serviceDepAggCounts = useAppSelector(getServiceDepAggCounts);

  useEffect(() => {
    if (!serviceDepAggCountsLoadingStatus) {
      dispatch(fetchServiceDepCounts());
    }
  }, [dispatch, serviceDepAggCountsLoadingStatus]);

  return (
    <ThemeProvider theme={M365LightTheme.settings.theme}>
      <AppInsightsErrorBoundary
        onError={(err: Error) => (
          <Error isNavCollapsed={isNavCollapsed} errorMessage={err.message} />
        )}
        appInsights={reactPlugin}
      >
        <ScrollablePane
          className={
            isNavCollapsed ? _Styles.scrollablePaneCollapsed : _Styles.scrollablePaneExpand
          }
        >
          <h1 className={_Styles.header}>Dependency Overview</h1>
          {(!serviceDepAggCounts || serviceDepAggCountsLoadingStatus) && (
            <CoherenceLoading primaryText="Loading" />
          )}
          {serviceDepAggCounts && !serviceDepAggCountsLoadingStatus && (
            <>
              <DependencyMappingDashboard />
            </>
          )}
        </ScrollablePane>
      </AppInsightsErrorBoundary>
    </ThemeProvider>
  );
}
