import * as React from "react";
import {
  mergeStyleSets,
  DelayedRender,
  Callout,
  Text,
  IconButton,
  IIconProps,
  Link,
  Icon,
  getTheme,
} from "@fluentui/react";
import { useBoolean, useId } from "@fluentui/react-hooks";

const theme = getTheme();

export const makeGenericTooltipCallout = (definitionList: any[]) => {
  return (
    <>
      <Text variant="small" block={true}>
        <dl>
          <dt>
            <b>Definitions</b>
          </dt>
          {definitionList.map((b) => (
            <dd key={b.term}>
              <b>{b.term}</b> {b.definition}
            </dd>
          ))}
        </dl>
      </Text>
    </>
  );
};

const mailToDsreIntelEmail = `mailto:dsreintelplatpm@microsoft.com`;
//People page
export const HeatMapPeopleCallout: React.FunctionComponent = () => {
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
  const buttonId = useId("callout-button");
  const infoIcon: IIconProps = { iconName: "Info" };
  return (
    <>
      <IconButton
        id={buttonId}
        iconProps={infoIcon}
        onClick={toggleIsCalloutVisible}
        text={"More Info"}
        ariaLabel={"More Info"}
      />
      {isCalloutVisible && (
        <Callout
          className={styles.callout}
          target={`#${buttonId}`}
          onDismiss={toggleIsCalloutVisible}
          role="dialog"
          aria-live="assertive"
        >
          <DelayedRender>
            <Text variant="small" block={true}>
              <p>
                The heatmap represents employees by their best known location. This data has been
                aggregated by Microsoft building or by the city, state, and/or country data that is
                available to Employee Central and Global Workforce Security.
              </p>
              <p>
                This data is not traceable to a single employee's location (except their assigned MS
                office, if applicable) and is not intended to be used for such functionality. Any
                data needs that require precise employee location must go through proper HR related
                channels.
              </p>
              <p>
                Additional people page information can be found on our{" "}
                <Link href={`./Wiki`}>Wiki </Link>
                page.
              </p>
              <p>
                For any questions about people data, please reach out to{" "}
                <Link href={mailToDsreIntelEmail}>DSR SIP PM team</Link>.
              </p>
            </Text>
          </DelayedRender>
        </Callout>
      )}
    </>
  );
};

export const TreeMapPeopleCallout: React.FunctionComponent = () => {
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
  const buttonId = useId("callout-button");
  const infoIcon: IIconProps = { iconName: "Info" };
  return (
    <>
      <IconButton
        id={buttonId}
        iconProps={infoIcon}
        onClick={toggleIsCalloutVisible}
        text={"More Info"}
        ariaLabel={"More Info"}
      />
      {isCalloutVisible && (
        <Callout
          className={styles.callout}
          target={`#${buttonId}`}
          onDismiss={toggleIsCalloutVisible}
          role="dialog"
          aria-live="assertive"
        >
          <DelayedRender>
            <Text variant="small" block={true}>
              <dl>
                <dt>
                  <b>Location Definitions</b>
                </dt>
                <dd>
                  <b> Out of area:</b> The employee is not mapped to a location within the defined
                  location context.
                </dd>
                <dd>
                  <b>Building Assigned:</b> Employees (internal and external) that are active
                  assigned to a building
                </dd>
                <dd>
                  <b>Mobile, Home Office, No workspace:</b> Employees (internal and external) that
                  are active and not guaranteed to be assigned to a location.
                </dd>
              </dl>
              <p>
                These are mapped to the best of our knowledge based on available Employee Central
                and Global Workforce Security data.
              </p>
              <p>
                Additional people page information can be found on our{" "}
                <Link href={`./Wiki`}>Wiki </Link>
                page.
              </p>
              <p>
                For any questions about people data, please reach out to{" "}
                <Link href={`mailto:dsreintelplatpm@microsoft.com`}>DSR SIP PM team</Link>.
              </p>
            </Text>
          </DelayedRender>
        </Callout>
      )}
    </>
  );
};

export const BuildingWorkforceCallout: React.FunctionComponent = () => {
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
  const buttonId = useId("callout-button");
  const infoIcon: IIconProps = { iconName: "Info" };
  return (
    <>
      <IconButton
        id={buttonId}
        iconProps={infoIcon}
        onClick={toggleIsCalloutVisible}
        text={"More Info"}
        ariaLabel={"More Info"}
      />
      {isCalloutVisible && (
        <Callout
          className={styles.callout}
          target={`#${buttonId}`}
          onDismiss={toggleIsCalloutVisible}
          role="dialog"
          aria-live="assertive"
        >
          <DelayedRender>
            <Text variant="small" block={true}>
              <dl>
                <dt>
                  <b>Definitions</b>
                </dt>
                <dd>
                  <b>Internal Active:</b> Employees that are not on leave of absence or pending
                  start and are classified as regular staff
                </dd>
                <dd>
                  <b>External Active:</b> Employees that are not on leave of absence or pending
                  start and are classified as contingent staff
                </dd>
              </dl>
              <p>
                Additional people page information can be found on our{" "}
                <Link href={`./Wiki`}>Wiki </Link>
                page.
              </p>
              <p>
                For any questions about people data, please reach out to{" "}
                <Link href={`mailto:dsreintelplatpm@microsoft.com`}>DSR SIP PM team</Link>.
              </p>
            </Text>
          </DelayedRender>
        </Callout>
      )}
    </>
  );
};

export const WorkforceByDivisionCallout: React.FunctionComponent = () => {
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
  const buttonId = useId("callout-button");
  const infoIcon: IIconProps = { iconName: "Info" };
  return (
    <>
      <IconButton
        id={buttonId}
        iconProps={infoIcon}
        onClick={toggleIsCalloutVisible}
        text={"More Info"}
        ariaLabel={"More Info"}
      />
      {isCalloutVisible && (
        <Callout
          className={styles.callout}
          target={`#${buttonId}`}
          onDismiss={toggleIsCalloutVisible}
          role="dialog"
          aria-live="assertive"
        >
          <DelayedRender>
            <Text variant="small" block={true}>
              <p>
                This is a tablular view of the data displayed in the tree diagram. It includes
                alias, name, reporting manager, location data, as well as the first 4 hierarchy
                mappings.
              </p>
              <p>
                Additional people page information can be found on our{" "}
                <Link href={`./Wiki`}>Wiki </Link>
                page.
              </p>
              <p>
                For any questions about people data, please reach out to{" "}
                <Link href={`mailto:dsreintelplatpm@microsoft.com`}>DSR SIP PM team</Link>.
              </p>
            </Text>
          </DelayedRender>
        </Callout>
      )}
    </>
  );
};

export const PeopleOverviewCallout: React.FunctionComponent = () => {
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
  const buttonId = useId("callout-button");
  const infoIcon: IIconProps = { iconName: "Info" };
  return (
    <>
      <IconButton
        id={buttonId}
        iconProps={infoIcon}
        onClick={toggleIsCalloutVisible}
        text={"More Info"}
        ariaLabel={"More Info"}
      />
      {isCalloutVisible && (
        <Callout
          className={styles.callout}
          target={`#${buttonId}`}
          onDismiss={toggleIsCalloutVisible}
          role="dialog"
          aria-live="assertive"
        >
          <DelayedRender>
            <Text variant="small" block={true}>
              <dl>
                <dt>
                  <b>Definitions</b>
                </dt>
                <dd>
                  <b>Total Employees:</b> Total count of active employees (internal and external)
                  known to be in a selected area. This number does not include employees pending
                  hire or on LOA
                </dd>
                <dd>
                  <b>Internal Employees:</b> Active internal employees known to be in a selected
                  area
                </dd>
                <dd>
                  <b>External Employees:</b> Active Contingent (v-, contract, etc) employees known
                  to be in a selected area
                </dd>
                <dd>
                  <b>Other Employees:</b> Inactive employees or employees that have yet to be
                  assigned in the system.
                </dd>
                <dd>
                  <b>Badge Ins:</b> The rolling average number of badge ins within the last 180 days
                </dd>
              </dl>
              <p>
                Additional HR definitions and people page information can be found on our{" "}
                <Link href={`./Wiki`}>Wiki </Link>
                page.
              </p>
              <p>
                For any questions about people data, please reach out to{" "}
                <Link href={`mailto:dsreintelplatpm@microsoft.com`}>DSR SIP PM team</Link>.
              </p>
            </Text>
          </DelayedRender>
        </Callout>
      )}
    </>
  );
};

//Facilities page
export const FacilityTypesCallout: React.FunctionComponent = () => {
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
  const buttonId = useId("callout-button");
  const infoIcon: IIconProps = { iconName: "Info" };
  return (
    <>
      <IconButton
        id={buttonId}
        iconProps={infoIcon}
        onClick={toggleIsCalloutVisible}
        text={"More Info"}
        ariaLabel={"More Info"}
      />
      {isCalloutVisible && (
        <Callout
          className={styles.callout}
          target={`#${buttonId}`}
          onDismiss={toggleIsCalloutVisible}
          role="dialog"
          aria-live="assertive"
        >
          <DelayedRender>
            <Text variant="small" block={true}>
              <p>
                Facility types are defined and maintained by the MS Space team. Please refer to
                their site for current definitions:{" "}
                <Link
                  href={`https://microsoft.sharepoint.com/teams/REFMSSpacePortal/SitePages/MS%20Space%20Definitions.aspx`}
                  target="_blank"
                >
                  MS Space Definitions
                </Link>
                .
              </p>{" "}
              <p>
                Additional facilities page information can be found on our{" "}
                <Link href={`./Wiki`}>Wiki </Link>
                page.
              </p>
              <p>
                For any questions about facility data, please reach out to{" "}
                <Link href={`mailto:dsreintelplatpm@microsoft.com`}>DSR SIP PM team</Link>.
              </p>
            </Text>
          </DelayedRender>
        </Callout>
      )}
    </>
  );
};

export const FacilitiesBreakdown: React.FunctionComponent = () => {
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
  const buttonId = useId("callout-button");
  const infoIcon: IIconProps = { iconName: "Info" };
  return (
    <>
      <IconButton
        id={buttonId}
        iconProps={infoIcon}
        onClick={toggleIsCalloutVisible}
        text={"More Info"}
        ariaLabel={"More Info"}
      />
      {isCalloutVisible && (
        <Callout
          className={styles.callout}
          target={`#${buttonId}`}
          onDismiss={toggleIsCalloutVisible}
          role="dialog"
          aria-live="assertive"
        >
          <DelayedRender>
            <Text variant="small" block={true}>
              <dl>
                <dt>
                  <b>Definitions</b>
                </dt>
                <dd>
                  <b>Allocated:</b> Buildings that are known to have active employees assigned to
                  that location
                </dd>
                <dd>
                  <b>Unallocated:</b> Buildings that are known to not have active employees assigned
                  to that location
                </dd>
              </dl>
              <p>
                Additional facilities page information can be found on our{" "}
                <Link href={`./Wiki`}>Wiki </Link>
                page.
              </p>
              <p>
                For any questions about facility data, please reach out to{" "}
                <Link href={`mailto:dsreintelplatpm@microsoft.com`}>DSR SIP PM team</Link>.
              </p>
            </Text>
          </DelayedRender>
        </Callout>
      )}
    </>
  );
};

export const FacilitiesGeneral: React.FunctionComponent = () => {
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
  const buttonId = useId("callout-button");
  const infoIcon: IIconProps = { iconName: "Info" };
  return (
    <>
      <IconButton
        id={buttonId}
        iconProps={infoIcon}
        onClick={toggleIsCalloutVisible}
        text={"More Info"}
        ariaLabel={"More Info"}
      />
      {isCalloutVisible && (
        <Callout
          className={styles.callout}
          target={`#${buttonId}`}
          onDismiss={toggleIsCalloutVisible}
          role="dialog"
          aria-live="assertive"
        >
          <DelayedRender>
            <Text variant="small" block={true}>
              <p>
                CO+I DC Class information, along with other additional facilities page information
                can be found on our <Link href={`./Wiki`}>Wiki </Link>
                page.
              </p>
              <p>
                For any questions about facility data, please reach out to{" "}
                <Link href={`mailto:dsreintelplatpm@microsoft.com`}>DSR SIP PM team</Link>.
              </p>
            </Text>
          </DelayedRender>
        </Callout>
      )}
    </>
  );
};

export const LocationWorkforce: React.FunctionComponent = () => {
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
  const buttonId = useId("callout-button");
  const infoIcon: IIconProps = { iconName: "Info" };
  return (
    <>
      <IconButton
        id={buttonId}
        iconProps={infoIcon}
        onClick={toggleIsCalloutVisible}
        text={"More Info"}
        ariaLabel={"More Info"}
      />
      {isCalloutVisible && (
        <Callout
          className={styles.callout}
          target={`#${buttonId}`}
          onDismiss={toggleIsCalloutVisible}
          role="dialog"
          aria-live="assertive"
        >
          <DelayedRender>
            <Text variant="small" block={true}>
              <dl>
                <dt>
                  <b>Definitions</b>
                </dt>
                <dd>
                  <b>Active Employees:</b> Employees not on leave or pending start
                </dd>
                <dd>
                  <b>Mobile:</b> A generic count (without org hierarchy) of the number of employees
                  that are assigned as mobile and have a building noted as their secondary location
                  type
                </dd>
              </dl>
              <p>
                Certain data center information may be restricted based on your role in the
                Watchtower platform due to tenting requirements.
              </p>
              <p>
                Additional facilities page information can be found on our{" "}
                <Link href={`./Wiki`}>Wiki </Link>
                page.
              </p>
              <p>
                For any questions about facility data, please reach out to{" "}
                <Link href={`mailto:dsreintelplatpm@microsoft.com`}>DSR SIP PM team</Link>.
              </p>
            </Text>
          </DelayedRender>
        </Callout>
      )}
    </>
  );
};

export const AssignedWorkforce: React.FunctionComponent = () => {
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
  const buttonId = useId("callout-button");
  const infoIcon: IIconProps = { iconName: "Info" };
  return (
    <>
      <IconButton
        id={buttonId}
        iconProps={infoIcon}
        onClick={toggleIsCalloutVisible}
        text={"More Info"}
        ariaLabel={"More Info"}
      />
      {isCalloutVisible && (
        <Callout
          className={styles.callout}
          target={`#${buttonId}`}
          onDismiss={toggleIsCalloutVisible}
          role="dialog"
          aria-live="assertive"
        >
          <DelayedRender>
            <Text variant="small" block={true}>
              <dl>
                <dt>
                  <b>Definitions</b>
                </dt>
                <dd>
                  <b>FTE:</b> Employees classified as regular type employees per HR
                </dd>
                <dd>
                  <b>Contingent:</b> Employees classified as other type employees per HR
                </dd>
              </dl>
              <p>
                Additional facilities page information can be found on our{" "}
                <Link href={`./Wiki`}>Wiki </Link>
                page.
              </p>
              <p>
                For any questions about facility data, please reach out to{" "}
                <Link href={`mailto:dsreintelplatpm@microsoft.com`}>DSR SIP PM team</Link>.
              </p>
            </Text>
          </DelayedRender>
        </Callout>
      )}
    </>
  );
};

export const EssentialEmployees: React.FunctionComponent = () => {
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
  const buttonId = useId("callout-button");
  const infoIcon: IIconProps = { iconName: "Info" };
  return (
    <>
      <IconButton
        id={buttonId}
        iconProps={infoIcon}
        onClick={toggleIsCalloutVisible}
        text={"More Info"}
        ariaLabel={"More Info"}
      />
      {isCalloutVisible && (
        <Callout
          className={styles.callout}
          target={`#${buttonId}`}
          onDismiss={toggleIsCalloutVisible}
          role="dialog"
          aria-live="assertive"
        >
          <DelayedRender>
            <Text variant="small" block={true}>
              <dl>
                <dt>
                  <b>Definitions</b>
                </dt>
                <dd>
                  <b>Essential Employees:</b> Employees deemed critical to business operations that
                  would require access or be required to be onsite during a crisis
                </dd>
              </dl>
              <p>
                Additional facilities page information can be found on our{" "}
                <Link href={`./Wiki`}>Wiki </Link>
                page.
              </p>
              <p>
                For any questions about facility data, please reach out to{" "}
                <Link href={`mailto:dsreintelplatpm@microsoft.com`}>DSR SIP PM team</Link>.
              </p>
            </Text>
          </DelayedRender>
        </Callout>
      )}
    </>
  );
};

export const FacilityOverviewCallout: React.FunctionComponent = () => {
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
  const buttonId = useId("fac-overview-callout-button");
  const infoIcon: IIconProps = { iconName: "Info" };
  return (
    <>
      <IconButton
        id={buttonId}
        iconProps={infoIcon}
        onClick={toggleIsCalloutVisible}
        text={"More Info"}
        ariaLabel={"More Info"}
      />
      {isCalloutVisible && (
        <Callout
          className={styles.callout}
          target={`#${buttonId}`}
          onDismiss={toggleIsCalloutVisible}
          role="dialog"
          aria-live="assertive"
        >
          <DelayedRender>
            <Text variant="small" block={true}>
              <dl>
                <dt>
                  <b>Definitions</b>
                </dt>
                <dd>
                  <b>Allocated:</b> Buildings that are known to have active employees assigned to
                  that location
                </dd>
                <dd>
                  <b>Unallocated:</b> Buildings that are known to not have active employees assigned
                  to that location
                </dd>
                <dd>
                  <b>GWS Critical Classified:</b> Buildings that are classified by GWS as critical
                  operation facilities
                </dd>
                <dd>
                  <b>Data Centers:</b> Buildings that are categorized as data centers
                </dd>
              </dl>
              <p>
                Facility types are defined and maintained by the MS Space team. Please refer to
                their site for current definitions:{" "}
                <Link
                  href={`https://microsoft.sharepoint.com/teams/REFMSSpacePortal/SitePages/MS%20Space%20Definitions.aspx`}
                  target="_blank"
                >
                  MS Space Definitions
                </Link>
                .
              </p>{" "}
              <p>
                Additional facilities page information can be found on our{" "}
                <Link href={`./Wiki`}>Wiki </Link>
                page.
              </p>
              <p>
                For any questions about facility data, please reach out to{" "}
                <Link href={`mailto:dsreintelplatpm@microsoft.com`}>DSR SIP PM team</Link>.
              </p>
            </Text>
          </DelayedRender>
        </Callout>
      )}
    </>
  );
};

//Services page
export const InsightCallout: React.FunctionComponent = () => {
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
  const buttonId = useId("callout-button");
  const infoIcon: IIconProps = { iconName: "Info" };
  return (
    <>
      <IconButton
        id={buttonId}
        iconProps={infoIcon}
        onClick={toggleIsCalloutVisible}
        text={"More Info"}
        ariaLabel={"More Info"}
      />
      {isCalloutVisible && (
        <Callout
          className={styles.callout}
          target={`#${buttonId}`}
          onDismiss={toggleIsCalloutVisible}
          role="dialog"
          aria-live="assertive"
        >
          <DelayedRender>
            <Text variant="small" block={true}>
              DRI impact identifies the services at various level of risk of interruption due to
              loss of DRI staffing. The interruption may include lack of support, break/fix, or
              incident tickets due to volume, lack of familiarity with high-severity issues.
              <dl>
                <dt>
                  <b>Team</b>
                </dt>
                <dd>
                  <i>The ICM team name.</i>
                </dd>
                <dt>
                  <b>Projection</b>
                </dt>
                <dd>
                  <i>
                    The projected impact risk. High impact means a high chance of being negatively
                    impacted by a crisis.
                  </i>
                </dd>
                <dd>
                  <Icon iconName="StatusCircleInner" style={{ color: theme.palette.green }} />
                  <b>No Impact:</b> 100% of planned DRI capacity available. No interruption to
                  service expected.
                </dd>
                <dd>
                  <Icon iconName="StatusCircleInner" style={{ color: theme.palette.yellow }} />
                  <b>Low Impact:</b> 70-99% of planned DRI capacity available. Risk of service
                  interruption is low.
                </dd>
                <dd>
                  <Icon
                    iconName="StatusCircleInner"
                    style={{ color: theme.palette.orangeLighter }}
                  />
                  <b>Medium Impact:</b> 50-69% of planned DRI capacity available. Risk of service
                  interruption is Medium.
                </dd>
                <dd>
                  <Icon iconName="StatusCircleInner" style={{ color: theme.palette.redDark }} />
                  <b>High Impact:</b> Less than 50% of planned DRI capacity available. Risk of
                  service interruption is High.
                </dd>
                <dt>
                  <b>Efficiency</b>
                </dt>
                <dd>
                  <i>
                    The analysis score based on pre-defined risk factors including team capacity and
                    status. A low score implies low team efficiency and a higher chance of being
                    negatively impacted.
                  </i>
                </dd>
              </dl>
              For additional information, please reach out to{" "}
              <Link href={`mailto:isrmse@microsoft.com`}>DSR Data Intelligence</Link>.
            </Text>
          </DelayedRender>
        </Callout>
      )}
    </>
  );
};

export const ServiceBreakdown: React.FunctionComponent = () => {
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
  const buttonId = useId("callout-button");
  const infoIcon: IIconProps = { iconName: "Info" };
  return (
    <>
      <IconButton
        id={buttonId}
        iconProps={infoIcon}
        onClick={toggleIsCalloutVisible}
        text={"More Info"}
        ariaLabel={"More Info"}
      />
      {isCalloutVisible && (
        <Callout
          className={styles.callout}
          target={`#${buttonId}`}
          onDismiss={toggleIsCalloutVisible}
          role="dialog"
          aria-live="assertive"
        >
          <DelayedRender>
            <Text variant="small" block={true}>
              <dl>
                <dt>
                  <b>Definitions</b>
                </dt>
                <dd>
                  <b>Mission Critical:</b> Services that must recover in 0-4 hours, considered the
                  most important to keep running
                </dd>
                <dd>
                  <b>Critical:</b> Services that must recover in 5-72 hours
                </dd>
                <dd>
                  <b>Important:</b> Services that must recover in 73-168 hours
                </dd>
                <dd>
                  <b>Deferrable:</b> Services that can be recovered in more than 168 hours,
                  considered the least important to keep running
                </dd>
                <dd>
                  <b>Unknown:</b> Services that don’t have an identified RTO Criticality rating but
                  are believed to be active
                </dd>
                <dd>
                  <b>Isolated:</b> Services that only have resources in one Azure region
                </dd>
              </dl>
              <p>
                Additional services page information can be found on our{" "}
                <Link href={`./Wiki`}>Wiki </Link>
                page.
              </p>
              <dl>
                <dt>
                  For any questions about service data, please reach out to the corresponding teams
                  below:
                </dt>{" "}
                <dd>
                  <b>Azure Regions and Data Centers:</b>{" "}
                  <Link href={`mailto:servicetreesupport@microsoft.com`}>Service Tree Support</Link>
                </dd>
                <dd>
                  <b>Service Tree and DRI Team Mapping:</b>{" "}
                  <Link href={`mailto:icmsupport@microsoft.com`}>ICM Support</Link>
                </dd>
                <dd>
                  <b>DRI Insights:</b>{" "}
                  <Link href={`mailto:isrmse@microsoft.com`}>DSR Data Intelligence</Link>
                </dd>
                <dd>
                  <b>BCDR:</b> <Link href={`mailto:wes.sinclair@microsoft.com`}>Eco Manager</Link>
                </dd>
              </dl>
            </Text>
          </DelayedRender>
        </Callout>
      )}
    </>
  );
};

export const PotentialDriTeamImpactLevel: React.FunctionComponent = () => {
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
  const buttonId = useId("callout-button");
  const infoIcon: IIconProps = { iconName: "Info" };
  return (
    <>
      <IconButton
        id={buttonId}
        iconProps={infoIcon}
        onClick={toggleIsCalloutVisible}
        text={"More Info"}
        ariaLabel={"More Info"}
      />
      {isCalloutVisible && (
        <Callout
          className={styles.callout}
          target={`#${buttonId}`}
          onDismiss={toggleIsCalloutVisible}
          role="dialog"
          aria-live="assertive"
        >
          <DelayedRender>
            <Text variant="small" block={true}>
              <dl>
                <dt>
                  <b>Definitions</b>
                </dt>
                <dd>
                  <b>High:</b> Less than 50% of planned DRI capacity available. Risk of service
                  interruption is High.
                </dd>
                <dd>
                  <b>Moderate:</b> 50-69% of planned DRI capacity available. Risk of service
                  interruption is Medium.
                </dd>
                <dd>
                  <b>Low:</b> 70-99% of planned DRI capacity available. Risk of service interruption
                  is low.
                </dd>
                <dd>
                  <b>No Impact:</b> 100% of planned DRI capacity available. No interruption to
                  service expected.
                </dd>
                <dd>
                  <b>No Team History:</b> There is no data to use.
                </dd>
              </dl>
              <p>
                Additional services page information can be found on our{" "}
                <Link href={`./Wiki`}>Wiki </Link>
                page.
              </p>
              <dl>
                <dt>
                  For any questions about service data, please reach out to the corresponding teams
                  below:
                </dt>{" "}
                <dd>
                  <b>Azure Regions and Data Centers:</b>{" "}
                  <Link href={`mailto:servicetreesupport@microsoft.com`}>Service Tree Support</Link>
                </dd>
                <dd>
                  <b>Service Tree and DRI Team Mapping:</b>{" "}
                  <Link href={`mailto:icmsupport@microsoft.com`}>ICM Support</Link>
                </dd>
                <dd>
                  <b>DRI Insights:</b>{" "}
                  <Link href={`mailto:isrmse@microsoft.com`}>DSR Data Intelligence</Link>
                </dd>
                <dd>
                  <b>BCDR:</b> <Link href={`mailto:wes.sinclair@microsoft.com`}>Eco Manager</Link>
                </dd>
              </dl>
            </Text>
          </DelayedRender>
        </Callout>
      )}
    </>
  );
};

export const ServiceGeneral: React.FunctionComponent = () => {
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
  const buttonId = useId("callout-button");
  const infoIcon: IIconProps = { iconName: "Info" };
  return (
    <>
      <IconButton
        id={buttonId}
        iconProps={infoIcon}
        onClick={toggleIsCalloutVisible}
        text={"More Info"}
        ariaLabel={"More Info"}
      />
      {isCalloutVisible && (
        <Callout
          className={styles.callout}
          target={`#${buttonId}`}
          onDismiss={toggleIsCalloutVisible}
          role="dialog"
          aria-live="assertive"
        >
          <DelayedRender>
            <Text variant="small" block={true}>
              <p>
                If the download button is unavailable, there are either no results or the filtered
                data set is too large to successfully download.
              </p>
              <p>
                Additional services page information can be found on our{" "}
                <Link href={`./Wiki`}>Wiki </Link>
                page.
              </p>
              <dl>
                <dt>
                  For any questions about service data, please reach out to the corresponding teams
                  below:
                </dt>
                <dd>
                  <b>Azure Regions and Data Centers:</b>{" "}
                  <Link href={`mailto:servicetreesupport@microsoft.com`}>Service Tree Support</Link>
                </dd>
                <dd>
                  <b>Service Tree and DRI Team Mapping:</b>{" "}
                  <Link href={`mailto:icmsupport@microsoft.com`}>ICM Support</Link>
                </dd>
                <dd>
                  <b>DRI Insights:</b>{" "}
                  <Link href={`mailto:isrmse@microsoft.com`}>DSR Data Intelligence</Link>
                </dd>
                <dd>
                  <b>BCDR:</b> <Link href={`mailto:wes.sinclair@microsoft.com`}>Eco Manager</Link>
                </dd>
              </dl>
            </Text>
          </DelayedRender>
        </Callout>
      )}
    </>
  );
};

//Processes page
export const ProcessCriticality: React.FunctionComponent = () => {
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
  const buttonId = useId("callout-button");
  const infoIcon: IIconProps = { iconName: "Info" };
  return (
    <>
      <IconButton
        id={buttonId}
        iconProps={infoIcon}
        onClick={toggleIsCalloutVisible}
        text={"More Info"}
        ariaLabel={"More Info"}
      />
      {isCalloutVisible && (
        <Callout
          className={styles.callout}
          target={`#${buttonId}`}
          onDismiss={toggleIsCalloutVisible}
          role="dialog"
          aria-live="assertive"
        >
          <DelayedRender>
            <Text variant="small" block={true}>
              <dl>
                <dt>
                  <b>Definitions</b>
                </dt>
                <dd>
                  <b>Mission Critical:</b> Processes that must recover in 0-4 hours, considered the
                  most important to keep running
                </dd>
                <dd>
                  <b>Critical:</b> Processes that must recover in 5-72 hours
                </dd>
                <dd>
                  <b>Important:</b> Processes that must recover in 73-168 hours
                </dd>
                <dd>
                  <b>Deferrable:</b> Processes that can be recovered in more than 168 hours,
                  considered the least important to keep running
                </dd>
                <dd>
                  <b>Unknown:</b> Processes that don’t have an identified RTO Criticality rating but
                  are believed to be active
                </dd>
              </dl>
              <p>
                Additional processes page information can be found on our{" "}
                <Link href={`./Wiki`}>Wiki </Link>
                page.
              </p>
              <p>
                For any questions about process data, please reach out to{" "}
                <Link href={`mailto:egrc@microsoft.com`}>EGRC Support</Link>.
              </p>
            </Text>
          </DelayedRender>
        </Callout>
      )}
    </>
  );
};

export const ProcessGeneral: React.FunctionComponent = () => {
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
  const buttonId = useId("callout-button");
  const infoIcon: IIconProps = { iconName: "Info" };
  return (
    <>
      <IconButton
        id={buttonId}
        iconProps={infoIcon}
        onClick={toggleIsCalloutVisible}
        text={"More Info"}
        ariaLabel={"More Info"}
      />
      {isCalloutVisible && (
        <Callout
          className={styles.callout}
          target={`#${buttonId}`}
          onDismiss={toggleIsCalloutVisible}
          role="dialog"
          aria-live="assertive"
        >
          <DelayedRender>
            <Text variant="small" block={true}>
              <p>
                Additional processes page information can be found on our{" "}
                <Link href={`./Wiki`}>Wiki </Link>
                page.
              </p>
              <p>
                For any questions about process data, please reach out to{" "}
                <Link href={`mailto:egrc@microsoft.com`}>EGRC Support</Link>.
              </p>
            </Text>
          </DelayedRender>
        </Callout>
      )}
    </>
  );
};

const styles = mergeStyleSets({
  callout: {
    width: 320,
    padding: "20px 24px",
  },
});
