import React from "react";
import {
  ConstrainMode,
  DetailsList,
  IColumn,
  SelectionMode,
} from "@fluentui/react";

import { NormalizedEmployeeProfile } from "../Types";
import { copyAndSort } from "./Helpers";

export interface ManagerDirectsDataGridProps {
  filteredEmployeeData: NormalizedEmployeeProfile[];
}

export default function ManagerDirectsDataGrid(
  props: ManagerDirectsDataGridProps
) {
  //data setup
  const [normalizedDirectData, setNormalizedDirectData] = React.useState<
    NormalizedEmployeeProfile[]
  >(props.filteredEmployeeData);

  React.useEffect(() => {
    if (
      props.filteredEmployeeData &&
      props.filteredEmployeeData !== normalizedDirectData
    ) {
      setNormalizedDirectData(props.filteredEmployeeData);
    }
  }, [props.filteredEmployeeData]);

  const onPeopleColumnClick = React.useCallback(
    (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
      const columns = currentColumns;
      const newColumns: IColumn[] = columns.slice();
      const currColumn: IColumn = newColumns.filter(
        (currCol) => column.key === currCol.key
      )[0];
      newColumns.forEach((newCol: IColumn) => {
        if (newCol === currColumn) {
          currColumn.isSortedDescending = !currColumn.isSortedDescending;
          currColumn.isSorted = true;
        } else {
          newCol.isSorted = false;
          newCol.isSortedDescending = true;
        }
      });

      const newItems: NormalizedEmployeeProfile[] = copyAndSort(
        normalizedDirectData,
        currColumn.key!,
        currColumn.isSortedDescending
      );
      setCurrentColumns(newColumns);
      setNormalizedDirectData([...newItems]);
    },
    [normalizedDirectData]
  );

  const simpleColumns: IColumn[] = [
    {
      key: "alias",
      name: "Alias",
      fieldName: "alias",
      ariaLabel: "Alias",
      minWidth: 100,
      maxWidth: 125,
      data: "string",
      isResizable: true,
    },
    {
      key: "fullName",
      name: "Name",
      fieldName: "fullName",
      ariaLabel: "Name",
      minWidth: 100,
      maxWidth: 125,
      data: "string",
      isResizable: true,
      isRowHeader: true,
    },
    {
      key: "jobTitleSummaryDesc",
      name: "Job Title Summary Desc",
      fieldName: "jobTitleSummaryDesc",
      ariaLabel: "Job Title Summary Desc",
      minWidth: 100,
      maxWidth: 125,
      data: "string",
      isResizable: true,
      isRowHeader: true,
    },

    {
      key: "managerAlias",
      name: "Manager",
      fieldName: "managerAlias",
      ariaLabel: "Manager",
      minWidth: 100,
      maxWidth: 125,
      data: "string",
      isResizable: true,
    },
    {
      key: "buildingName",
      name: "Building",
      fieldName: "buildingName",
      ariaLabel: "Building",
      minWidth: 100,
      maxWidth: 175,
      data: "string",
      isResizable: true,
    },
    {
      key: "city",
      name: "City",
      fieldName: "city",
      ariaLabel: "City",
      minWidth: 100,
      maxWidth: 175,
      data: "string",
      isResizable: true,
    },
    {
      key: "country",
      name: "Country",
      fieldName: "country",
      ariaLabel: "Country",
      minWidth: 100,
      maxWidth: 175,
      data: "string",
      isResizable: true,
    },
    {
      key: "l1Manager",
      name: "Div Manager",
      fieldName: "l1Manager",
      ariaLabel: "Div Manager",
      minWidth: 100,
      maxWidth: 200,
      data: "string",
      isResizable: true,
    },
    {
      key: "l2Manager",
      name: "Org Manager",
      fieldName: "l2Manager",
      ariaLabel: "Org Manager",
      minWidth: 100,
      maxWidth: 200,
      data: "string",
      isResizable: true,
    },
    {
      key: "l3Manager",
      name: "SG Manager",
      fieldName: "l3Manager",
      ariaLabel: "SG Manager",
      minWidth: 100,
      maxWidth: 200,
      data: "string",
      isResizable: true,
    },
    {
      key: "l4Manager",
      name: "TG Manager",
      fieldName: "l4Manager",
      ariaLabel: "TG Manager",
      minWidth: 100,
      maxWidth: 200,
      data: "string",
      isResizable: true,
    },
  ];
  //data grid column setup
  const [currentColumns, setCurrentColumns] =
    React.useState<IColumn[]>(simpleColumns);

  return (
    <DetailsList
      setKey="items"
      items={normalizedDirectData}
      columns={currentColumns}
      selectionMode={SelectionMode.none}
      compact={true}
      constrainMode={ConstrainMode.horizontalConstrained}
      onColumnHeaderClick={onPeopleColumnClick}
      isHeaderVisible={true}
    />
  );
}
