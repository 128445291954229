import { AxiosError } from "axios";
import { Dispatch } from "redux";
import apiBase from "../../services/apiBase";
import { appInsights } from "../../services/AppInsights";
import { FacilityAggregate, IFacilityModel } from "../../components/Types";
import { stringify } from "querystring";
import {
  FETCH_FACILITIES_BEGIN,
  FETCH_FACILITIES_FAILURE,
  FETCH_FACILITIES_SUCCESS,
  FETCH_FACILITY_COUNTS_BEGIN,
  FETCH_FACILITY_COUNTS_FAILURE,
  FETCH_FACILITY_COUNTS_SUCCESS,
  RESET_FACILITY_ASSETS,
  SET_ACTIVE_FACILITY_ID,
  SET_FACILITY_ASSET_SEARCH_TERM,
  SET_FACILITY_CITY_FILTER,
  SET_FACILITY_CLASSIFICATION_FILTER,
  SET_FACILITY_COUNTRY_FILTER,
  SET_FACILITY_DIVISION_FILTER,
  SET_FACILITY_GWS_FILTER,
  SET_FACILITY_ID_FILTER,
  SET_FACILITY_MAP_FILTER,
  SET_FACILITY_STATE_FILTER,
  SET_FACILITY_TYPE_FILTER,
} from "./actionTypes";

const facilityApiEndpoint: string = "api/Facilities";
const facilityAggregateApiEndpoint: string = "/api/Facilities/GetAggregatedFacilityCount";

export const fetchFacilitiesBegin = () => ({
  type: FETCH_FACILITIES_BEGIN,
});

export const fetchFacilitiesSuccess = (payload: IFacilityModel[]) => ({
  type: FETCH_FACILITIES_SUCCESS,
  payload,
});

export const fetchFacilitiesFailure = (error: AxiosError | string) => ({
  type: FETCH_FACILITIES_FAILURE,
  payload: error,
});

export const fetchFacilities = (locationsToFetch: string[], isRegion: boolean) => {
  return async (dispatch: Dispatch) => {
    dispatch(fetchFacilitiesBegin());
    try {
      let endpoint;
      let res;
      if (isRegion) {
        endpoint = `${facilityApiEndpoint}/GetFacilitiesByPhysicalRegions`;
        res = await apiBase.get(endpoint, {
          params: {
            region: locationsToFetch,
          },
          paramsSerializer: (params) => {
            return stringify(params);
          },
        });
      } else {
        endpoint = `${facilityApiEndpoint}/GetFacilitiesByCountry`;
        res = await apiBase.get(endpoint, {
          params: {
            countryList: locationsToFetch,
          },
          paramsSerializer: (params) => {
            return stringify(params);
          },
        });
      }

      if (res.data) {
        dispatch(fetchFacilitiesSuccess(res.data));
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        dispatch(
          fetchFacilitiesFailure(
            "Error calling Facility Assets API due to your permissions. Please reach out to watchtower support to confirm your access."
          )
        );
      } else if (err?.response?.status === 404) {
        dispatch(
          fetchFacilitiesFailure(
            "The requested resource could not be found. Please try again later."
          )
        );
      } else if (err?.response?.status === 500) {
        dispatch(
          fetchFacilitiesFailure("There was an internal server error. Please try again later.")
        );
      } else {
        dispatch(fetchFacilitiesFailure(err));
      }
      appInsights.trackException(err);
    }
  };
};

export const fetchFacilityCountsBegin = () => ({
  type: FETCH_FACILITY_COUNTS_BEGIN,
});

export const fetchFacilityCountsSuccess = (payload: FacilityAggregate) => ({
  type: FETCH_FACILITY_COUNTS_SUCCESS,
  payload,
});

export const fetchFacilityCountsFailure = (error: Error | string) => ({
  type: FETCH_FACILITY_COUNTS_FAILURE,
  payload: error,
});

export const fetchFacilityCounts = (locationsToFetch: string[], isRegion: boolean) => {
  return async (dispatch: Dispatch) => {
    dispatch(fetchFacilityCountsBegin());
    try {
      let res;
      if (locationsToFetch?.length > 0 && isRegion) {
        res = await apiBase.get(facilityAggregateApiEndpoint, {
          params: {
            regionQueryList: locationsToFetch,
          },
          paramsSerializer: (params) => {
            return stringify(params);
          },
        });
      } else {
        res = await apiBase.get(facilityAggregateApiEndpoint);
      }
      if (res.data) {
        dispatch(fetchFacilityCountsSuccess(res.data));
      }
    } catch (err) {
      let errorMessage = err;
      if (err?.response?.status) {
        if (err.response.status === 403) {
          errorMessage =
            "Error calling Facility API due to your permissions. Please reach out to watchtower support to confirm your access.";
        } else {
          errorMessage = `Unexpected error occurred while calling Facility API. HTTP status code: ${
            err?.response?.status ?? "unknown"
          }.`;
        }
      }
      dispatch(fetchFacilityCountsFailure(errorMessage));
      appInsights.trackException(err);
    }
  };
};

export const updateFacilityCountryFilter = (payload: string[]) => ({
  type: SET_FACILITY_COUNTRY_FILTER,
  payload,
});

export const updateFacilityStateFilter = (payload: string[]) => ({
  type: SET_FACILITY_STATE_FILTER,
  payload,
});

export const updateFacilityCityFilter = (payload: string[]) => ({
  type: SET_FACILITY_CITY_FILTER,
  payload,
});

export const updateFacilityGwsCriticalityFilter = (payload: string[]) => ({
  type: SET_FACILITY_GWS_FILTER,
  payload,
});

export const updateFacilityTypeFilter = (payload: string[]) => ({
  type: SET_FACILITY_TYPE_FILTER,
  payload,
});

export const updateFacilityClassificationFilter = (payload: string[]) => ({
  type: SET_FACILITY_CLASSIFICATION_FILTER,
  payload,
});

export const updateFacilityIdFilter = (payload: number[]) => ({
  type: SET_FACILITY_ID_FILTER,
  payload,
});

export const updateFacilityDivisionFilter = (payload: string[]) => ({
  type: SET_FACILITY_DIVISION_FILTER,
  payload,
});

export const updateMapFacilityList = (payload: number[]) => ({
  type: SET_FACILITY_MAP_FILTER,
  payload,
});

export const clearMapFacilityList = () => ({
  type: SET_FACILITY_MAP_FILTER,
  payload: [],
});

export const clearFacilityDetails = () => ({
  type: RESET_FACILITY_ASSETS,
});

export const updateFacilityAssetSearchTerm = (payload: string) => ({
  type: SET_FACILITY_ASSET_SEARCH_TERM,
  payload,
});

export const updateActiveFacilityId = (payload: number = -1) => ({
  type: SET_ACTIVE_FACILITY_ID,
  payload,
});
