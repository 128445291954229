import {
  ActionButton,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  FontWeights,
  IContextualMenuItem,
  IContextualMenuProps,
  IIconProps,
  Panel,
  PrimaryButton,
  ScrollablePane,
  SearchBox,
  Stack,
  Text,
  ThemeProvider,
  memoizeFunction,
  mergeStyles,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { DashboardCard } from "@m365-admin/card";
import {
  CountAnnotationBar,
  CountAnnotationBarDirection,
  ICountAnnotationProps,
} from "@m365-admin/count-annotation";
import { M365LightTheme, useM365Theme } from "@m365-admin/customizations";
import { Dashboard } from "@m365-admin/dashboard";
import {
  IRearrangeableGridDefinition,
  IRearrangeableGridGap,
} from "@m365-admin/rearrangeable-grid";
import { CardSizeValues, CardSizes } from "components/Cards.constants";
import { IDownloadDetails, NormalizedEmployeeProfile } from "components/Types";
import { PeopleTree } from "components/charts/people/PeopleTree";
import { WorkforceByEvpCountBar } from "components/charts/people/WorkforceByEvpCountBar";
import { ExportToCSVUtil, ExportToExcelUtil } from "components/datagrids/Helpers";
import ManagerDirectsDataGrid from "components/datagrids/ManagerDirectsDataGrid";
import PeopleHeatMap from "components/mapping/PeopleHeatMap";
import {
  BuildingWorkforceCallout,
  HeatMapPeopleCallout,
  PeopleOverviewCallout,
  TreeMapPeopleCallout,
  WorkforceByDivisionCallout,
} from "components/modals/InsightCallout";
import { PeopleFilter } from "components/panels/filters/PeopleFilter";
import { PeopleFilterTags } from "components/panels/filters/PeopleFilterTags";
import {
  mapToRestrictedPeopleView,
  mapToUnrestrictedPeopleView,
} from "components/pivots/helpers/downloadMappingHelpers";
import { panelScrollablePaneStyles, searchBoxStyles } from "pages/Page.styles";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { batch } from "react-redux";
import { appInsights } from "services/AppInsights";
import {
  applyPeopleFilter,
  clearUnappliedPeopleFilter,
  fetchPeopleAliasData,
  updatePeopleCityFilter,
  updatePeopleDivFilter,
  updatePeopleFacNameFilter,
  updatePeopleJobTitleSummaryDescFilter,
  updatePeopleOrgFilter,
  updatePeopleServiceGroupFilter,
  updatePeopleStateFilter,
  updatePeopleTeamGroupFilter,
} from "store/actions/peopleActions";
import {
  getPeopleCityFilters,
  getPeopleCounts,
  getPeopleDirects,
  getPeopleDirectsLoadingStatus,
  getPeopleDivFilters,
  getPeopleJobTitleSummaryDescFilters,
  getPeopleOrgFilters,
  getPeopleServiceGroupFilters,
  getPeopleStateFilters,
  getPeopleTeamGroupFilters,
  getUnappliedPeopleFilters,
  getWorkforceAllocationCounts,
  peopleFilterDataSelector,
} from "store/selectors/peopleSelector";
import {
  getSelectedPhysicalCountries,
  getSelectedPhysicalRegions,
} from "store/selectors/regionsSelector";
import { getUserDownloadPermissions, getUserIsRestricted } from "store/selectors/usersSelector";
import { useAppDispatch, useAppSelector } from "store/store";
import {
  IPeopleDataDownloadDownloadColumns,
  IRestrictedPeopleDataDownloadColumns,
} from "types/PeopleTypes";

export const PeoplePivot = () => {
  const dispatch = useAppDispatch();
  const peopleDataState = useAppSelector(getPeopleDirects);
  const peopleDataLoading = useAppSelector(getPeopleDirectsLoadingStatus);
  const unappliedPeopleFilters = useAppSelector(getUnappliedPeopleFilters);
  const [peopleData, setPeopleData] = useState<NormalizedEmployeeProfile[]>([]);
  const [aliasToSearch, setAliasToSearch] = useState<string>(null);

  //filtering
  const peopleStateFilters = useAppSelector(getPeopleStateFilters);
  const peopleCityFilters = useAppSelector(getPeopleCityFilters);
  const divFilters = useAppSelector(getPeopleDivFilters);
  const orgFilters = useAppSelector(getPeopleOrgFilters);
  const serviceGroupFilters = useAppSelector(getPeopleServiceGroupFilters);
  const teamGroupFilters = useAppSelector(getPeopleTeamGroupFilters);
  const peopleJobTitleSummaryDescFilters = useAppSelector(getPeopleJobTitleSummaryDescFilters);

  const workspaceAllocationCountState = useAppSelector(getWorkforceAllocationCounts);

  const peopleCounts = useAppSelector(getPeopleCounts);
  const [peopleCountData, setPeopleCountData] = useState<ICountAnnotationProps[]>([]);
  useMemo(() => {
    if (workspaceAllocationCountState) setPeopleCountData(workspaceAllocationCountState);
  }, [workspaceAllocationCountState]);

  //on filter update, update the available results & options
  useEffect(() => {
    let newFilterResults = peopleDataState.filter((person) => {
      if (peopleStateFilters.length > 0 && !peopleStateFilters.includes(person.state)) {
        return false;
      }
      if (peopleCityFilters.length > 0 && !peopleCityFilters.includes(person.city.toUpperCase())) {
        return false;
      }
      if (divFilters.length > 0 && !divFilters.includes(person.l1ManagerEmailName)) {
        return false;
      }
      if (orgFilters.length > 0 && !orgFilters.includes(person.l2ManagerEmailName)) {
        return false;
      }
      if (
        serviceGroupFilters.length > 0 &&
        !serviceGroupFilters.includes(person.l3ManagerEmailName)
      ) {
        return false;
      }
      if (teamGroupFilters.length > 0 && !teamGroupFilters.includes(person.l4ManagerEmailName)) {
        return false;
      }
      if (peopleJobTitleSummaryDescFilters.length > 0 && !peopleJobTitleSummaryDescFilters.includes(person.jobTitleSummaryDesc)) {
        return false;
      }
      return true;
    });

    setPeopleData(newFilterResults);
  }, [
    peopleDataState,
    peopleStateFilters,
    peopleCityFilters,
    divFilters,
    orgFilters,
    serviceGroupFilters,
    teamGroupFilters,
    peopleJobTitleSummaryDescFilters
  ]);

  const clearPeopleFilters = useCallback(() => {
    batch(() => {
      dispatch(clearUnappliedPeopleFilter());
      dispatch(updatePeopleStateFilter([]));
      dispatch(updatePeopleCityFilter([]));
      dispatch(updatePeopleDivFilter([]));
      dispatch(updatePeopleOrgFilter([]));
      dispatch(updatePeopleServiceGroupFilter([]));
      dispatch(updatePeopleTeamGroupFilter([]));
      dispatch(updatePeopleFacNameFilter([]));
      dispatch(updatePeopleJobTitleSummaryDescFilter([]));
    });

    setAliasToSearch(null);
  }, [dispatch]);

  const filterIcon: IIconProps = { iconName: "Filter" };
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);

  const onRenderFooterContent = useCallback(() => {
    const buttonStyles = { root: { margin: 14 } };
    return (
      <div>
        <Stack horizontal>
          <PrimaryButton
            onClick={() => {
              dispatch(
                applyPeopleFilter({
                  ...[
                    "peopleStateFilters",
                    "peopleCityFilters",
                    "peopleDivFilters",
                    "peopleOrgFilters",
                    "peopleServiceGroupFilters",
                    "peopleTeamGroupFilters",
                    "peopleFacNameFilters",
                    "peopleJobTitleSummaryDescFilters"
                  ].reduce(
                    (prev, filter) => ({
                      ...prev,
                      [filter]: unappliedPeopleFilters[filter] ?? [],
                    }),
                    {}
                  ),
                })
              );
            }}
            styles={buttonStyles}
          >
            Apply Filters
          </PrimaryButton>
          <DefaultButton
            text="Clear"
            styles={buttonStyles}
            onClick={() => {
              dismissPanel();
              clearPeopleFilters();
            }}
          />
        </Stack>
      </div>
    );
  }, [clearPeopleFilters, dismissPanel, dispatch, unappliedPeopleFilters]);

  const headerStyles = memoizeFunction(mergeStyles);
  const getCardTitle = (title: string) => {
    const titleClassName = headerStyles([
      theme.fonts.medium,
      { padding: 0, margin: 0, fontWeight: FontWeights.semibold },
    ]);

    return <h3 className={titleClassName}>{title}</h3>;
  };

  const theme = useM365Theme();

  const backgroundStyles = mergeStyles({
    background: theme.semanticColors.navBackground,
    paddingTop: 24,
    paddingBottom: 24,
    paddingRight: 16,
    paddingLeft: 16,
  });

  // store the state of the Dashboard and handle changes within it
  const [map, setMap] = useState<IRearrangeableGridDefinition>({
    peopleBreakdownCard: { cellHeight: 1, cellWidth: 1, row: 0, col: 1 },
    peopleOverviewCard: { cellHeight: 1, cellWidth: 1, row: 0, col: 0 },
    peopleEvpBreakdownCard: { cellHeight: 1, cellWidth: 2, row: 0, col: 2 },
    peopleHeatMapCard: { cellHeight: 1, cellWidth: 2, row: 0, col: 4 },
    employeeDataCard: { cellHeight: 1, cellWidth: 3, row: 1, col: 0 },
    employeeTreeMapCard: { cellHeight: 2, cellWidth: 6, row: 2, col: 0 },
  });

  //handle resizing in the dashboard grid
  const resizeCallback =
    useRef<
      (
        width: number,
        height: number,
        itemKey: string,
        position?: { col: number; row: number }
      ) => void
    >();

  const generateResizeCallback = (key: string) => {
    return (ev: never, item: IContextualMenuItem) => {
      const dimension = CardSizeValues[item.key as CardSizes];

      resizeCallback.current?.(dimension.cellWidth, dimension.cellHeight, key);
    };
  };

  const columnResizeRef =
    useRef<(width: number, height: number, gap: IRearrangeableGridGap) => void>();

  const columnResize = (width: number, height: number, gap: IRearrangeableGridGap) => {
    columnResizeRef.current?.(width, height, gap);
  };

  const onChange = (newMap: IRearrangeableGridDefinition) => {
    setMap(newMap);
  };
  const resizeText = "Resize";
  const moreDetailsText = "Info";

  const generateCallout = (calloutName: string) => {
    switch (calloutName) {
      case "people":
        return (
          <div data-is-focusable>
            <PeopleOverviewCallout />
            <Text>Info</Text>
          </div>
        );
      case "workforce":
        return (
          <div data-is-focusable>
            <BuildingWorkforceCallout />
            <Text>Info</Text>
          </div>
        );
      case "employees":
        return (
          <div data-is-focusable>
            <WorkforceByDivisionCallout />
            <Text>Info</Text>
          </div>
        );
      case "employeeTree":
        return (
          <div data-is-focusable>
            <TreeMapPeopleCallout />
            <Text>Info</Text>
          </div>
        );
      case "peopleHeatMap":
        return (
          <div data-is-focusable>
            <HeatMapPeopleCallout />
            <Text>Info</Text>
          </div>
        );
    }
  };
  // handle download to excel
  const userCanDownload = useAppSelector(getUserDownloadPermissions);
  const userHasViewRestriction = useAppSelector(getUserIsRestricted);

  const generateItemMenu = (key: string, calloutName: string): IContextualMenuProps => {
    let items: IContextualMenuItem[] = [
      {
        iconProps: { iconName: "MiniExpand" },
        key: resizeText,
        text: resizeText,
        subMenuProps: {
          items: [
            { key: CardSizes.small, text: CardSizes.small },
            { key: CardSizes.medium, text: CardSizes.medium },
            { key: CardSizes.mediumTall, text: CardSizes.mediumTall },
            { key: CardSizes.mediumWide, text: CardSizes.mediumWide },
            { key: CardSizes.large, text: CardSizes.large },
            { key: CardSizes.extraLarge, text: CardSizes.extraLarge },
          ],
          onItemClick: generateResizeCallback(key),
        },
      },
    ];
    if (calloutName) {
      items = [
        ...items,
        {
          iconProps: { iconName: "Info" },
          key: moreDetailsText,
          text: moreDetailsText,
          data: generateCallout(calloutName),
          onRender: (item) => item.data,
        },
      ];
      if (userCanDownload && (calloutName === "employeeTree" || calloutName === "employees")) {
        items = [
          ...items,
          {
            key: "downloadPeopleDataCsv",
            iconProps: { iconName: "Download" },
            text: "Download to CSV",
            onClick: () => logAndDownloadCsv(),
            disabled: peopleData.length === 0,
          },
          {
            key: "downloadPeopleDataExcel",
            iconProps: { iconName: "ExcelDocument" },
            text: "Download to Excel",
            onClick: () => logAndDownloadXlsx(),
            disabled: peopleData.length === 0 || peopleData.length > 20000,
          },
        ];
      }
    }

    return {
      items,
    };
  };

  const logAndDownloadXlsx = () => {
    const fileName = "WatchtowerPeopleDataExport";
    appInsights.trackEvent(
      { name: "Download Used" },
      { Source: "People", "restricted user": userHasViewRestriction }
    );
    appInsights.trackMetric(
      { name: "Download Used", average: peopleData.length },
      { Source: "People", "restricted user": userHasViewRestriction }
    );
    let downloadData: IDownloadDetails;
    const timeStamp = new Date(Date.now()).toLocaleDateString("en-US");
    let peopleDataMapped;

    if (userHasViewRestriction) {
      peopleDataMapped = peopleData.map((p) => mapToRestrictedPeopleView(p, timeStamp));
    } else {
      peopleDataMapped = peopleData.map((p) => mapToUnrestrictedPeopleView(p, timeStamp));
    }

    downloadData = {
      fileName: fileName,
      data: [{ dataToExport: peopleDataMapped, sheetName: "Employee Details" }],
    };

    ExportToExcelUtil(downloadData);
  };

  const logAndDownloadCsv = () => {
    const fileName = "WatchtowerPeopleDataExport";
    appInsights.trackEvent(
      { name: "Download Used" },
      { Source: "People", "restricted user": userHasViewRestriction }
    );
    appInsights.trackMetric(
      { name: "Download Used", average: peopleData.length },
      { Source: "People", "restricted user": userHasViewRestriction }
    );
    if (userHasViewRestriction) {
      ExportToCSVUtil(peopleData, IRestrictedPeopleDataDownloadColumns, fileName);
    } else {
      ExportToCSVUtil(peopleData, IPeopleDataDownloadDownloadColumns, fileName);
    }
  };
  // handle user warning for many countries
  const dialogContentProps = {
    type: DialogType.normal,
    title: "Performance Alert",
    subText:
      "Selection will pull entire company hierarchy. This may result in poor page performance. Please confirm to continue.",
  };

  const appliedRegionState = useAppSelector(getSelectedPhysicalRegions);
  const appliedCountryState = useAppSelector(getSelectedPhysicalCountries);
  const sendAliasSearch = (
    searchValue: string,
    regionsList: string[],
    countriesList: string[],
    statesList: string[],
    citiesList: string[]
  ) =>
    dispatch(fetchPeopleAliasData(searchValue, regionsList, countriesList, statesList, citiesList));

  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

  return (
    <ThemeProvider theme={M365LightTheme.settings.theme}>
      <Panel
        isLightDismiss
        isOpen={isOpen}
        onDismiss={dismissPanel}
        hasCloseButton={true}
        headerText="Additional People Filters"
        closeButtonAriaLabel="Close"
        isFooterAtBottom={true}
        onRenderFooter={onRenderFooterContent}
      >
        <PeopleFilter
          isPeopleDataLoading={peopleDataLoading}
          peopleFilterData={useAppSelector(peopleFilterDataSelector)}
          unappliedPeopleFilters={unappliedPeopleFilters}
        />
      </Panel>
      <Dialog
        hidden={hideDialog}
        onDismiss={toggleHideDialog}
        dialogContentProps={dialogContentProps}
        modalProps={{
          isBlocking: true,
          isAlert: true,
        }}
      >
        <DialogFooter>
          <PrimaryButton
            onClick={() => {
              sendAliasSearch(
                aliasToSearch,
                appliedRegionState.map((r) => r.regionDescription),
                appliedCountryState,
                peopleStateFilters,
                peopleCityFilters
              );
              toggleHideDialog();
              setAliasToSearch(null);
            }}
            text="Confirm"
          />
          <DefaultButton
            onClick={() => {
              toggleHideDialog();
            }}
            text="Cancel"
          />
        </DialogFooter>
      </Dialog>
      <Stack horizontal tokens={{ childrenGap: 10, padding: 8 }}>
        <SearchBox
          styles={searchBoxStyles}
          placeholder="Search for an alias"
          onSearch={(newValue: string) => {
            if (newValue.toUpperCase() === "SATYAN") {
              setAliasToSearch(newValue);
              toggleHideDialog();
            } else {
              sendAliasSearch(
                newValue,
                appliedRegionState.map((r) => r.regionDescription),
                appliedCountryState,
                peopleStateFilters,
                peopleCityFilters
              );
            }
          }}
          onClear={() => {}}
        />
        <ActionButton
          iconProps={filterIcon}
          onClick={openPanel}
          disabled={peopleDataLoading || peopleDataState.length === 0}
        >
          More Filters
        </ActionButton>
        <PeopleFilterTags />
      </Stack>
      <div className={backgroundStyles}>
        <Dashboard
          map={map}
          onChange={onChange}
          ariaLabel="Crisis Response People Dashboard"
          ariaDescription="Use the arrow keys to navigate to a card. Press alt-shift-arrow to move a card. Press enter key to enter each item. Press escape to return to the grid when within an item."
          rearrangeableGridProps={{
            resizeHandler: resizeCallback,
            onColumnResize: columnResize,
            minCellWidth: 250,
            minCellHeight: 150,
          }}
        >
          <DashboardCard
            key={"peopleOverviewCard"}
            titleText={getCardTitle("People Details")}
            isLoading={peopleDataLoading}
            moreIconButtonProps={{
              ariaLabel: "More Actions",
              menuProps: generateItemMenu("peopleOverviewCard", "people"),
            }}
          >
            <CountAnnotationBar countAnnotationProps={peopleCounts} />
          </DashboardCard>
          <DashboardCard
            key={"peopleBreakdownCard"}
            titleText={getCardTitle("Workforce Allocation")}
            isLoading={peopleDataLoading}
            moreIconButtonProps={{
              ariaLabel: "More Actions",
              menuProps: generateItemMenu("peopleBreakdownCard", "workforce"),
            }}
          >
            <CountAnnotationBar
              countAnnotationProps={peopleCountData}
              direction={CountAnnotationBarDirection.Vertical}
            />
          </DashboardCard>
          <DashboardCard
            key={"peopleEvpBreakdownCard"}
            titleText={getCardTitle("Workforce by EVP")}
            isLoading={peopleDataLoading}
            moreIconButtonProps={{
              ariaLabel: "More Actions",
              menuProps: generateItemMenu("peopleBreakdownCard", "workforce"),
            }}
          >
            <WorkforceByEvpCountBar
              filteredPeopleData={peopleDataState}
              onBarClick={function (evpAlias: string): void {
                dispatch(updatePeopleDivFilter([evpAlias]));
              }}
            />
          </DashboardCard>
          <DashboardCard
            key={"employeeDataCard"}
            titleText={getCardTitle("Employee Details")}
            isLoading={peopleDataLoading}
            moreIconButtonProps={{
              ariaLabel: "More Actions",
              menuProps: generateItemMenu("employeeDataCard", "employees"),
            }}
          >
            <ScrollablePane styles={panelScrollablePaneStyles} data-is-scrollable="true">
              <ManagerDirectsDataGrid filteredEmployeeData={peopleData} />
            </ScrollablePane>
          </DashboardCard>
          <DashboardCard
            key={"employeeTreeMapCard"}
            titleText={getCardTitle("Employee Tree Map")}
            isLoading={peopleDataLoading}
            moreIconButtonProps={{
              ariaLabel: "More Actions",
              menuProps: generateItemMenu("employeeTreeMapCard", "employeeTree"),
            }}
          >
            <PeopleTree filteredEmployeeData={peopleData} />
          </DashboardCard>
          <DashboardCard
            key={"peopleHeatMapCard"}
            titleText={getCardTitle("People Heat Map")}
            isLoading={peopleDataLoading}
            moreIconButtonProps={{
              ariaLabel: "More Actions",
              menuProps: generateItemMenu("peopleHeatMapCard", "peopleHeatMap"),
            }}
          >
            <PeopleHeatMap />
          </DashboardCard>
        </Dashboard>
      </div>
    </ThemeProvider>
  );
};
