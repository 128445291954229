import { Stack, TagItem } from "@fluentui/react";
import React from "react";
import { updatePeopleCityFilter, updatePeopleDivFilter, updatePeopleFacNameFilter, updatePeopleJobTitleSummaryDescFilter, updatePeopleOrgFilter, updatePeopleServiceGroupFilter, updatePeopleStateFilter, updatePeopleTeamGroupFilter } from "store/actions/peopleActions";
import { getPeopleCityFilters, getPeopleDivFilters, getPeopleFacNameFilters, getPeopleOrgFilters, getPeopleServiceGroupFilters, getPeopleStateFilters, getPeopleTeamGroupFilters, getPeopleJobTitleSummaryDescFilters } from "store/selectors/peopleSelector";
import { useAppDispatch, useAppSelector } from "store/store";

export const PeopleFilterTags = () => {
  const dispatch = useAppDispatch()
  const stateProvinceFilters = useAppSelector(getPeopleStateFilters);
  const cityFilters = useAppSelector(getPeopleCityFilters);
  const divFilters = useAppSelector(getPeopleDivFilters);
  const orgFilters = useAppSelector(getPeopleOrgFilters);
  const serviceGroupFilters = useAppSelector(getPeopleServiceGroupFilters);
  const teamGroupFilters = useAppSelector(getPeopleTeamGroupFilters);
  const facNameFilters = useAppSelector(getPeopleFacNameFilters);
  const peopleJobTitleSummaryDescFilters = useAppSelector(getPeopleJobTitleSummaryDescFilters);

  const filteredFacNameList = facNameFilters
    .map((fac) => (fac === "" ? "Unknown" : fac))
    .join(", ");

  return (
    <Stack
      horizontal
      aria-label="Active Filters"
      horizontalAlign="start"
      verticalAlign="start"
      tokens={{ childrenGap: 10, padding: 4 }}
      role="list"
    >
      {stateProvinceFilters.length > 0 && (
        <TagItem
          maxLength={250}
          title="Active State/Province Filters"
          removeButtonAriaLabel="Remove"
          item={{
            key: "stateProvinceFilters",
            name: `Active State/Province Filters: ${stateProvinceFilters
              .map((loc) => (loc === "" ? "Unknown" : loc))
              .join(", ")}`,
          }}
          index={0}
          onRemoveItem={() => dispatch(updatePeopleStateFilter([]))}
        >
          State/Province:{" "}
          {stateProvinceFilters
            .map((loc) => (loc === "" ? "Unknown" : loc))
            .join(", ")}
        </TagItem>
      )}
      {cityFilters.length > 0 && (
        <TagItem
          removeButtonAriaLabel="Remove"
          item={{
            key: "cityFilters",
            name: `Active City Filters: ${cityFilters
              .map((loc) => (loc === "" ? "Unknown" : loc))
              .join(", ")}`,
          }}
          index={1}
          onRemoveItem={() => dispatch(updatePeopleCityFilter([]))}
        >
          City:{" "}
          {cityFilters.map((loc) => (loc === "" ? "Unknown" : loc)).join(", ")}
        </TagItem>
      )}
      {divFilters.length > 0 && (
        <TagItem
          removeButtonAriaLabel="Remove"
          item={{
            key: "divManagers",
            name: `Active Division Managers: ${divFilters
              .map((alias) => (alias === "" ? "Unknown" : alias))
              .join(", ")}`,
          }}
          index={2}
          onRemoveItem={() => dispatch(updatePeopleDivFilter([]))}
        >
          Division Managers:{" "}
          {divFilters
            .map((alias) => (alias === "" ? "Unknown" : alias))
            .join(", ")}
        </TagItem>
      )}
      {orgFilters.length > 0 && (
        <TagItem
          removeButtonAriaLabel="Remove"
          item={{
            key: "orgManagers",
            name: `Active Organization Managers: ${orgFilters
              .map((alias) => (alias === "" ? "Unknown" : alias))
              .join(", ")}`,
          }}
          index={3}
          onRemoveItem={() => dispatch(updatePeopleOrgFilter([]))}
        >
          Org Managers:{" "}
          {orgFilters
            .map((alias) => (alias === "" ? "Unknown" : alias))
            .join(", ")}
        </TagItem>
      )}
      {serviceGroupFilters.length > 0 && (
        <TagItem
          removeButtonAriaLabel="Remove"
          item={{
            key: "divManagers",
            name: `Active Service Group Managers: ${serviceGroupFilters
              .map((alias) => (alias === "" ? "Unknown" : alias))
              .join(", ")}`,
          }}
          index={4}
          onRemoveItem={() => dispatch(updatePeopleServiceGroupFilter([]))}
        >
          Service Group Managers:{" "}
          {serviceGroupFilters
            .map((alias) => (alias === "" ? "Unknown" : alias))
            .join(", ")}
        </TagItem>
      )}
      {teamGroupFilters.length > 0 && (
        <TagItem
          removeButtonAriaLabel="Remove"
          item={{
            key: "divManagers",
            name: `Active Division Managers:${teamGroupFilters
              .map((alias) => (alias === "" ? "Unknown" : alias))
              .join(", ")}`,
          }}
          index={5}
          onRemoveItem={() => dispatch(updatePeopleTeamGroupFilter([]))}
        >
          Team Group Managers:{" "}
          {teamGroupFilters
            .map((alias) => (alias === "" ? "Unknown" : alias))
            .join(", ")}
        </TagItem>
      )}
      {facNameFilters.length > 0 && (
        <TagItem
          removeButtonAriaLabel="Remove"
          item={{
            key: "buildingName",
            name: `Building Name:${filteredFacNameList}`,
          }}
          index={6}
          onRemoveItem={() => dispatch(updatePeopleFacNameFilter([]))}
        >
          Building Names: {filteredFacNameList}
        </TagItem>
      )}
      {peopleJobTitleSummaryDescFilters.length > 0 && (
        <TagItem
          removeButtonAriaLabel="Remove"
          item={{
            key: "JobTitle",
            name: `Active Job Title Filters: ${peopleJobTitleSummaryDescFilters
              .map((loc) => (loc === "" ? "Unknown" : loc))
              .join(", ")}`,
          }}
          index={7}
          onRemoveItem={() => dispatch(updatePeopleJobTitleSummaryDescFilter([]))}
        >
          Job Titles: {peopleJobTitleSummaryDescFilters.map((loc) => (loc === "" ? "Unknown" : loc)).join(", ")}
        </TagItem>
      )}

    </Stack>
  );
};
