import { CountAnnotationBar, CountAnnotationBarDirection, ICountAnnotationProps, } from "@m365-admin/count-annotation";
import { getDriGeoCounts } from "store/selectors/servicesSelector";
import { useAppSelector } from "store/store";

export interface ServiceDriAggregateCountBarProps { }
export const ServiceDriAggregateCountBar = () => {
  const driGeoResilAggCounts: ICountAnnotationProps[] = useAppSelector(getDriGeoCounts());

  return (
    <CountAnnotationBar
      countAnnotationProps={driGeoResilAggCounts.map(count => ({ ...count, count: `${count.count}` }))}
      direction={CountAnnotationBarDirection.Vertical}
    />
  );
};
