import React from 'react';
import { useAppSelector } from 'store/store';
import { getSelectedPhysicalRegions } from '../../store/selectors/regionsSelector';
import MapController from './MapController';

export default function Map() {
  const [activeRegion, setActiveRegion] = React.useState<string>(null);
  const selectedPhysicalRegion = useAppSelector(getSelectedPhysicalRegions);
  React.useMemo(()=>{
    if (selectedPhysicalRegion.length > 0){
      setActiveRegion(selectedPhysicalRegion[0].regionDescription)
    }
  },[selectedPhysicalRegion])
  return <MapController activeRegion={activeRegion} />;
}
