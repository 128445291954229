import { IComboBoxOption } from "@fluentui/react";
import { ICountAnnotationProps } from "@m365-admin/count-annotation";
import { createSelector } from "reselect";

import { pieChartColorOptions } from "../../components/charts/InfoDonutChart";
import {
  IFacilityModel,
  ProcessAssetDetail,
  ProcessCount,
} from "../../components/Types";
import { ReduxFacilitiesState } from "../reducers/facilitiesReducer";
import { ReduxProcessesState } from "../reducers/processesReducer";
import { facilityFilterHelper } from "./facilitiesSelector";

const processes = (state: any): ReduxProcessesState => state.processes;

const facilities = (state: any): ReduxFacilitiesState => state.facilities;

//process count selectors
export const getProcessCounts = createSelector(
  processes,
  facilities,
  (processes: ReduxProcessesState, facilities: ReduxFacilitiesState) => {
    if (processes) {
      let aggProcessCounts: ProcessCount = processes?.processCounts
        ? processes.processCounts
        : {
            totalCount: -1,
            missionCriticalCount: 0,
            criticalCount: 0,
            importantCount: 0,
            deferrableCount: 0,
            unknown: 0,
          };
      if (
        processes.processAssetDetails.length === 0 &&
        aggProcessCounts !== null
      ) {
        return buildAggregateCounts(aggProcessCounts);
      }

      //return all processes with active facility locations (or that have no location for now)
      const processesToFilter = processes.processAssetDetails ?? [];
      const processDetails = processFilterHelper(processesToFilter, facilities);
      let processCounts = buildProcessCounts(processDetails);
      return buildAggregateCounts(processCounts);
    }
  }
);

export const getProcessCountsLoadingStatus = createSelector(
  processes,
  (processes: ReduxProcessesState) =>
    processes.isProcessCountsLoading || processes.isProcessAssetDetailsLoading
);

//process asset selectors
export const getProcessAssetDetailsLoadingStatus = createSelector(
  processes,
  (processes: ReduxProcessesState) => processes.isProcessAssetDetailsLoading
);

export const getProcessAssetDetailsError = createSelector(
  processes,
  (processes: ReduxProcessesState): string => {
    if (processes.processAssetError) {
      return `Error loading process assets. ${processes.processAssetError}`;
    } else if (processes.processCountError) {
      return `Error loading process counts. ${processes.processCountError}`;
    } else return null;
  }
);

export const getProcessAssetDetails = createSelector(
  processes,
  facilities,
  (processes: ReduxProcessesState, facilities: ReduxFacilitiesState) => {
    if (processes) {
      const processesToFilter = processes.processAssetDetails ?? [];
      let filteredProcessesByActiveLocation = processFilterHelper(
        processesToFilter,
        facilities
      );

      //return all processes with active facility locations (or that have no location for now)
      return filteredProcessesByActiveLocation;
    }
  }
);

export const getProcessAssetDetailsByFacility = createSelector(
  processes,
  facilities,
  (processes: ReduxProcessesState, facilities: ReduxFacilitiesState) => {
    if (processes) {
      let processesToFilter = processes.processAssetDetails ?? [];
      const activeFacilities = facilities.facilities
        .filter((fac) => fac.facilityId === facilities.activeFacilityId)
        .map((f) => f.facilityName);

      if (activeFacilities.length > 0) {
        return processesToFilter.filter((p) =>
          activeFacilities.includes(p.facilityName)
        );
      }
      return [];
    }
  }
);

export const getProcessRTOCriticalityOptions = createSelector(
  processes,
  facilities,
  (
    processes: ReduxProcessesState,
    facilities: ReduxFacilitiesState
  ): IComboBoxOption[] => {
    if (processes) {
      const processesToFilter = processes.processAssetDetails ?? [];
      let filteredProcessesByActiveLocation = processFilterHelper(
        processesToFilter,
        facilities
      );

      return (
        filteredProcessesByActiveLocation
          .reduce(function (critList, process) {
            if (
              critList == null ||
              !critList.includes(process.rtoCriticalityRating)
            ) {
              critList.push(process.rtoCriticalityRating);
            }
            return critList;
          }, [])
          .map((rto) => ({ key: rto, text: rto })) ?? []
      );
    }
  }
);

export const getProcessRTOCriticalityFilters = createSelector(
  processes,
  (processes: ReduxProcessesState): string[] =>
    processes?.processRtoCriticalityFilters ?? []
);

export const getProcessDivisionOptions = createSelector(
  processes,
  facilities,
  (
    processes: ReduxProcessesState,
    facilities: ReduxFacilitiesState
  ): IComboBoxOption[] => {
    if (processes) {
      const processesToFilter = processes.processAssetDetails ?? [];
      let filteredProcessesByActiveLocation = processFilterHelper(
        processesToFilter,
        facilities
      );
      const activeCriticalityFilters =
        processes?.processRtoCriticalityFilters ?? [];
      if (activeCriticalityFilters.length > 0) {
        filteredProcessesByActiveLocation =
          filteredProcessesByActiveLocation.filter((proc) =>
            activeCriticalityFilters.includes(proc.rtoCriticalityRating)
          );
      }

      return (
        filteredProcessesByActiveLocation
          .reduce(function (divList, process) {
            const divisionName = process.fullyQualifiedOrganization
              .split(":")[0]
              .trim();
            if (divList == null || !divList.includes(divisionName)) {
              divList.push(divisionName);
            }
            return divList;
          }, [])
          .map((divisionName) => ({
            key: divisionName,
            text: divisionName?.length > 0 ? divisionName : "Unknown",
          })) ?? []
      );
    }
  }
);

export const getProcessDivisionFilters = createSelector(
  processes,
  (processes: ReduxProcessesState): string[] => {
    return processes.processDivisionFilters ?? [];
  }
);

export const getProcessFacilityOptions = createSelector(
  processes,
  facilities,
  (
    processes: ReduxProcessesState,
    facilities: ReduxFacilitiesState
  ): IComboBoxOption[] => {
    if (processes) {
      const processesToFilter = processes.processAssetDetails ?? [];
      let filteredProcessesByActiveLocation = processFilterHelper(
        processesToFilter,
        facilities
      );
      const activeCriticalityFilters =
        processes?.processRtoCriticalityFilters ?? [];
      if (activeCriticalityFilters.length > 0) {
        filteredProcessesByActiveLocation =
          filteredProcessesByActiveLocation.filter((proc) =>
            activeCriticalityFilters.includes(proc.rtoCriticalityRating)
          );
      }

      const activeDivisionFilters = processes?.processDivisionFilters ?? [];
      if (activeCriticalityFilters.length > 0) {
        filteredProcessesByActiveLocation =
          filteredProcessesByActiveLocation.filter((proc) =>
            activeDivisionFilters.includes(
              proc.fullyQualifiedOrganization.split(":")[0].trim()
            )
          );
      }
      return (
        filteredProcessesByActiveLocation
          .reduce(function (facList, process) {
            if (facList == null || !facList.includes(process.facilityName)) {
              facList.push(process.facilityName);
            }
            return facList;
          }, [])
          .map((facility) => ({
            key: facility,
            text: facility?.length > 0 ? facility : "Unknown",
          })) ?? []
      );
    }
  }
);

export const getProcessFacilityFilters = createSelector(
  processes,
  (processes: ReduxProcessesState): string[] =>
    processes?.processFacilityFilters ?? []
);

const buildAggregateCounts = (
  processAssetCounts: ProcessCount
): ICountAnnotationProps[] => {
  if (processAssetCounts) {
    return [
      {
        annotationText: "Total Processes",
        count: processAssetCounts?.totalCount ?? -1,
        annotationColor: pieChartColorOptions[4],
        size: 1,
      },
      {
        annotationText: "Mission Critical",
        count: processAssetCounts?.missionCriticalCount ?? 0,
        annotationColor: pieChartColorOptions[0],
        size: 1,
      },
      {
        annotationText: "Critical",
        count: processAssetCounts.criticalCount ?? 0,
        annotationColor: pieChartColorOptions[2],
        size: 1,
      },
      {
        annotationText: "Important",
        count: processAssetCounts.importantCount ?? 0,
        annotationColor: pieChartColorOptions[3],
        size: 1,
      },
      {
        annotationText: "Deferrable",
        count: processAssetCounts.deferrableCount ?? 0,
        annotationColor: pieChartColorOptions[5],
        size: 1,
      },
      {
        annotationText: "Unknown",
        count: processAssetCounts.unknown ?? 0,
        annotationColor: pieChartColorOptions[6],
        size: 1,
      },
    ];
  }
};

const buildProcessCounts = (
  processAssetList: ProcessAssetDetail[]
): ProcessCount => {
  return {
    missionCriticalCount: processAssetList.filter((proc) =>
      proc.rtoCriticalityRating.startsWith("Mission Critical")
    ).length,
    criticalCount: processAssetList.filter((proc) =>
      proc.rtoCriticalityRating.startsWith("Critical")
    ).length,
    importantCount: processAssetList.filter((proc) =>
      proc.rtoCriticalityRating.startsWith("Important")
    ).length,
    deferrableCount: processAssetList.filter((proc) =>
      proc.rtoCriticalityRating.startsWith("Deferrable")
    ).length,
    unknown: processAssetList.filter(
      (proc) =>
        proc.rtoCriticalityRating.startsWith("Unknown") ||
        proc.rtoCriticalityRating === ""
    ).length,
    totalCount: processAssetList.length,
  };
};

const processFilterHelper = (
  processAssetDetails: ProcessAssetDetail[],
  facilities: ReduxFacilitiesState
): ProcessAssetDetail[] => {
  const facilitiesInScope: IFacilityModel[] = facilityFilterHelper(facilities);
  const isLocationFilteringActive =
    facilities?.facilityCityFilter?.length > 0 ||
    facilities?.facilityCountryFilter?.length > 0 ||
    facilities?.facilityStateFilter?.length > 0;
  const filteredFacs = facilitiesInScope.map((f) => f.facilityName);
  //return all processes with active facility locations (or that have no location for now)
  if (isLocationFilteringActive) {
    return processAssetDetails
      .filter((proc) => filteredFacs.includes(proc.facilityName))
      .map((proc) => {
        let newProc: ProcessAssetDetail = {
          ...proc,
          facilityCountry: facilitiesInScope.find(
            (f) => f.facilityName === proc.facilityName
          )?.country,
        };
        return newProc;
      });
  }
  return processAssetDetails
    .filter(
      (proc) =>
        filteredFacs.includes(proc.facilityName) || proc.facilityName === null
    )
    .map((proc) => {
      let newProc: ProcessAssetDetail = {
        ...proc,
        facilityCountry: facilitiesInScope.find(
          (f) => f.facilityName === proc.facilityName
        )?.country,
      };
      return newProc;
    });
};

export const getProcessDetailsSearchTerm = createSelector(
  processes,
  (processes: ReduxProcessesState) => processes?.processDetailsSearchTerm ?? ""
);
