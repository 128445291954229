import { CoherenceDataGrid, CoherenceDataGridColumn, } from "@coherence-design-system/controls";
import { IColumnDragDropDetails, IColumnReorderOptions, Link, SelectionMode, } from "@fluentui/react";
import { ProcessAssetDetail } from "components/Types";
import React from "react";
import { getProcessAssetDetailsLoadingStatus } from "store/selectors/processesSelector";
import { useAppSelector } from "store/store";

export interface ProcessDataGridProps {
  processAssetData: ProcessAssetDetail[];
}

export default function ProcessDataGrid({processAssetData}: ProcessDataGridProps) {
  const getProcessAssetDetailsLoading = useAppSelector( getProcessAssetDetailsLoadingStatus);

  const [dataGridColumns, setDataGridColumns] =
    React.useState<CoherenceDataGridColumn<ProcessAssetDetail>[]>(fullColumns);

  const columnReorderOptions: IColumnReorderOptions = {
    frozenColumnCountFromStart: 1,
    onColumnDrop: (dragDropDetails: IColumnDragDropDetails) => {
      const tempColumns = [...dataGridColumns];
      tempColumns.splice(
        dragDropDetails.targetIndex,
        0,
        tempColumns.splice(dragDropDetails.draggedIndex, 1)[0]
      );
      setDataGridColumns(tempColumns);
    },
  };

  return (
    <CoherenceDataGrid
      isLoading={getProcessAssetDetailsLoading}
      listConfig={dataGridColumns}
      data={processAssetData}
      compactMode={"compact"}
      selectionMode={SelectionMode.none}
      isScrollable={true}
      isSortable={true}
      columnReorderOptions={columnReorderOptions}
      ariaLabel={"Processes by selected region and any applied filters."}
      getRowAriaLabel={(s: ProcessAssetDetail) => `${s.relatedProcess} details`}
    />
  );
}

const fullColumns: CoherenceDataGridColumn<ProcessAssetDetail>[] = [
  {
    key: "relatedProcess",
    name: "Process",
    ariaLabel: "Process",
    fieldName: "relatedProcess",
    type: "primaryText",
    minColumnWidth: 150,
    isResizable: true,
    isRowHeader: true,
    onRender: (x: ProcessAssetDetail) => (
      <Link
        aria-label={`Navigate to ${x.relatedProcess}`}
        href={x.ebcmAnalysisUrl}
        target="_blank"
      >
        {x.relatedProcess}
      </Link>
    ),
  },
  {
    key: "rtoCriticalityRating",
    name: "RTO Criticality",
    ariaLabel: "RTO Criticality",
    fieldName: "rtoCriticalityRating",
    type: "text",
    minColumnWidth: 100,
    isResizable: true,
  },
  {
    key: "facilityName",
    name: "Facility Name",
    ariaLabel: "Facility Name",
    fieldName: "facilityName",
    type: "text",
    minColumnWidth: 125,
    isResizable: true,
  },
  {
    key: "facilityCountry",
    name: "Facility Country",
    ariaLabel: "Facility Country",
    fieldName: "facilityCountry",
    type: "text",
    minColumnWidth: 125,
    isResizable: true,
  },
  {
    key: "fullyQualifiedOrganization",
    name: "Division",
    ariaLabel: "Division",
    fieldName: "fullyQualifiedOrganization",
    type: "text",
    minColumnWidth: 250,
    isResizable: true,
    getValue: (item: ProcessAssetDetail) =>
      item.fullyQualifiedOrganization.split(":")[0],
  },
  {
    key: "criticalWorkforcePercentage",
    name: "Critical Workforce Percentage",
    ariaLabel: "Critical Workforce Percentage",
    fieldName: "criticalWorkforcePercentage",
    type: "text",
    minColumnWidth: 200,
    isResizable: true,
  },
  {
    key: "workforceBuildingAccess",
    name: "Workforce Building Access",
    ariaLabel: "Workforce Building Access",
    fieldName: "workforceBuildingAccess",
    type: "text",
    minColumnWidth: 200,
    isResizable: true,
  },
  {
    key: "normalWorkforceHeadcount",
    name: "Normal Workforce Headcount",
    ariaLabel: "Normal Workforce Headcount",
    fieldName: "normalWorkforceHeadcount",
    type: "text",
    minColumnWidth: 200,
    isResizable: true,
  },
  {
    key: "criticalWorkforceHeadcount",
    name: "Critical Workforce Headcount",
    ariaLabel: "Critical Workforce Headcount",
    fieldName: "criticalWorkforceHeadcount",
    type: "text",
    minColumnWidth: 200,
    isResizable: true,
  },
  {
    key: "hierarchyDetails",
    name: "Additional Organization Details",
    ariaLabel: "Additional Organization Details",
    type: "text",
    minColumnWidth: 300,
    isResizable: true,
    getValue: (item: ProcessAssetDetail) =>
      item.fullyQualifiedOrganization.split(":").slice(1).join(":"),
  },
  {
    key: "lastUpdatedDate",
    name: "Last Modified Time",
    ariaLabel: "Last Modified Time",
    fieldName: "lastUpdatedDate",
    type: "date",
    minColumnWidth: 150,
    isResizable: true,
    onRender: (x: ProcessAssetDetail) => {
      return new Date(x.lastUpdatedDate).toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
      });
    },
  },
];
