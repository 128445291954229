import * as React from "react";
import {
  CountAnnotationBar,
  CountAnnotationBarDirection,
  ICountAnnotationProps,
} from "@m365-admin/count-annotation";

import { ServiceBcdrDetailedMetadata } from "../../types/DependencyMappingTypes";
import { pieChartColorOptions } from "../charts/InfoDonutChart";

export interface ServiceDepAnnotationCardProps {
  currentServiceMetadata: ServiceBcdrDetailedMetadata;
}

export const ServiceDepAnnotationCard = (
  props: ServiceDepAnnotationCardProps
) => {
  const ServiceDepSummaryData: ICountAnnotationProps[] = [
    {
      annotationText: "Circular Dependency",
      count: convertBooleanToString(
        props?.currentServiceMetadata?.hasCircularDependency
      ),
      annotationColor: pieChartColorOptions[4],
      size: 1,
    },
    {
      annotationText: "Downstream Recovery Gap",
      count: convertBooleanToString(
        props?.currentServiceMetadata?.hasDownstreamRecoveryGap
      ),
      annotationColor: pieChartColorOptions[0],
      size: 1,
    },
    {
      annotationText: "Upstream Recovery Gap",
      count: convertBooleanToString(
        props?.currentServiceMetadata?.hasUpstreamRecoveryGap
      ),
      annotationColor: pieChartColorOptions[2],
      size: 1,
    },
  ];

  return (
    <CountAnnotationBar
      countAnnotationProps={ServiceDepSummaryData}
      direction={CountAnnotationBarDirection.Horizontal}
    />
  );
};

function convertBooleanToString(input: boolean): string {
  switch (input) {
    case true:
      return "Yes";
    case false:
      return "No";
    default:
      return "Unknown";
  }
}
