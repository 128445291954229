import { Dispatch } from "redux";
import { AxiosError } from "axios";
import apiBase from "../../services/apiBase";
import { AzureRegion, PhysicalRegion } from "../../components/Types";
import { appInsights } from "../../services/AppInsights";
import {
  FETCH_AZURE_REGIONS_BEGIN,
  FETCH_AZURE_REGIONS_FAILURE,
  FETCH_AZURE_REGIONS_SUCCESS,
  FETCH_PHYSICAL_REGIONS_BEGIN,
  FETCH_PHYSICAL_REGIONS_FAILURE,
  FETCH_PHYSICAL_REGIONS_SUCCESS,
  SET_SELECTED_AZURE_REGIONS,
  SET_SELECTED_PHYSICAL_COUNTRIES,
  SET_SELECTED_PHYSICAL_REGIONS,
} from "./actionTypes";

const physicalApiEndpoint: string = "api/Regions/GetPhysicalRegionsWithCountries";
const azureApiEndpoint: string = "api/Regions/Azure";

export const fetchAzureRegionsBegin = () => ({
  type: FETCH_AZURE_REGIONS_BEGIN,
});

export const fetchAzureRegionsSuccess = (payload: AzureRegion[]) => ({
  type: FETCH_AZURE_REGIONS_SUCCESS,
  payload,
});

export const fetchAzureRegionsFailure = (error: AxiosError | string) => ({
  type: FETCH_AZURE_REGIONS_FAILURE,
  payload: error,
});

export const fetchAzureRegions = () => {
  return async (dispatch: Dispatch) => {
    dispatch(fetchAzureRegionsBegin());
    try {
      const res = await apiBase.get(azureApiEndpoint);
      if (res.data) {
        dispatch(fetchAzureRegionsSuccess(res.data));
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        dispatch(
          fetchAzureRegionsFailure(
            "Error calling Azure Region API due to your permissions. Please reach out to watchtower support to confirm your access."
          )
        );
      } else {
        dispatch(fetchAzureRegionsFailure(err));
      }
      appInsights.trackException(err);
    }
  };
};

export const fetchPhysicalRegionsBegin = () => ({
  type: FETCH_PHYSICAL_REGIONS_BEGIN,
});

export const fetchPhysicalRegionsSuccess = (payload: PhysicalRegion[]) => ({
  type: FETCH_PHYSICAL_REGIONS_SUCCESS,
  payload,
});

export const fetchPhysicalRegionsFailure = (error: AxiosError | string) => ({
  type: FETCH_PHYSICAL_REGIONS_FAILURE,
  payload: error,
});

export const fetchPhysicalRegions = () => {
  return async (dispatch: Dispatch) => {
    dispatch(fetchPhysicalRegionsBegin());
    try {
      const res = await apiBase.get(physicalApiEndpoint);
      if (res.data) {
        dispatch(fetchPhysicalRegionsSuccess(res.data));
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        dispatch(
          fetchPhysicalRegionsFailure(
            "Error calling Region API due to your permissions. Please reach out to watchtower support to confirm your access."
          )
        );
      } else {
        dispatch(fetchPhysicalRegionsFailure(err));
      }
      appInsights.trackException(err);
    }
  };
};

export const updateSelectedPhysicalRegions = (physicalRegions: PhysicalRegion[]) => ({
  type: SET_SELECTED_PHYSICAL_REGIONS,
  payload: physicalRegions,
});

export const updateSelectedAzureRegions = (azRegions: AzureRegion[]) => ({
  type: SET_SELECTED_AZURE_REGIONS,
  payload: azRegions,
});

export const updateSelectedCountries = (selectedCountries: string[]) => ({
  type: SET_SELECTED_PHYSICAL_COUNTRIES,
  payload: selectedCountries,
});
