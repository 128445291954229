import { AxiosError } from "axios";
import {
  CLEAR_PROCESS_DETAILS,
  FETCH_PROCESS_COUNTS_BEGIN,
  FETCH_PROCESS_COUNTS_FAILURE,
  FETCH_PROCESS_COUNTS_SUCCESS,
  FETCH_PROCESS_DETAILS_BEGIN,
  FETCH_PROCESS_DETAILS_FAILURE,
  FETCH_PROCESS_DETAILS_SUCCESS,
  SET_PROCESS_CRITICALITY_FILTER,
  SET_PROCESS_DIVISION_FILTER,
  SET_PROCESS_FACILITY_FILTER,
  SET_PROCESS_DETAILS_SEARCH_TERM,
} from "../actions/actionTypes";
import {
  EnhancedProcessAssetDetail,
  ProcessAssetDetail,
  ProcessCount,
} from "../../components/Types";
import { ProcessCriticality } from "components/modals/InsightCallout";

export interface ReduxProcessesState {
  processCounts: ProcessCount;
  isProcessCountsLoading: boolean;
  processCountError: string | AxiosError;
  processAssetDetails: ProcessAssetDetail[];
  isProcessAssetDetailsLoading: boolean;
  processAssetError: string | AxiosError;
  processSearchTerms: string[];
  processRtoCriticalityFilters: string[];
  processDivisionFilters: string[];
  processFacilityFilters: string[];
  processDetailsSearchTerm: string;
}

const processesInitialState: ReduxProcessesState = {
  processCounts: null,
  isProcessCountsLoading: false,
  processCountError: null,
  processAssetDetails: [],
  isProcessAssetDetailsLoading: false,
  processAssetError: null,
  processSearchTerms: [],
  processRtoCriticalityFilters: [],
  processDivisionFilters: [],
  processFacilityFilters: [],
  processDetailsSearchTerm: null,
};

const makeProcessAssetSet = (originalList: EnhancedProcessAssetDetail[]): ProcessAssetDetail[] => {
  return originalList.map((p) => ({
    ...p.process,
    region: p.region,
    country: p.country,
  }));
};

export default function processes(state: ReduxProcessesState = processesInitialState, action: any) {
  switch (action.type) {
    case FETCH_PROCESS_COUNTS_BEGIN:
      return {
        ...state,
        isProcessCountsLoading: true,
        processCountError: null,
      };
    case FETCH_PROCESS_COUNTS_SUCCESS:
      return {
        ...state,
        processCounts: action.payload,
        isProcessCountsLoading: false,
        processCountError: null,
      };
    case FETCH_PROCESS_COUNTS_FAILURE:
      return {
        ...state,
        isProcessCountsLoading: false,
        processCountError: action.payload,
      };
    case FETCH_PROCESS_DETAILS_BEGIN:
      return {
        ...state,
        isProcessAssetDetailsLoading: true,
        processAssetError: null,
      };
    case FETCH_PROCESS_DETAILS_SUCCESS:
      return {
        ...state,
        processAssetDetails: makeProcessAssetSet(action.payload),
        isProcessAssetDetailsLoading: false,
        processAssetError: null,
      };
    case FETCH_PROCESS_DETAILS_FAILURE:
      return {
        ...state,
        isProcessAssetDetailsLoading: false,
        processAssetError: action.payload,
      };
    case CLEAR_PROCESS_DETAILS:
      return {
        ...state,
        processAssetDetails: [],
        isProcessAssetDetailsLoading: false,
        processAssetError: null,
        processDetailsSearchTerm: null,
        ProcessCriticalityFilters: [],
        processDivisionFilters: [],
        processFacilityFilters: [],
      };
    case SET_PROCESS_CRITICALITY_FILTER:
      return {
        ...state,
        processRtoCriticalityFilters: action.payload,
      };
    case SET_PROCESS_DIVISION_FILTER:
      return {
        ...state,
        processDivisionFilters: action.payload,
      };
    case SET_PROCESS_FACILITY_FILTER:
      return {
        ...state,
        processFacilityFilters: action.payload,
      };
    case SET_PROCESS_DETAILS_SEARCH_TERM:
      return {
        ...state,
        processDetailsSearchTerm: action.payload,
      };
    default:
      return state;
  }
}
