import React from "react";
import { DependencyGraphNodeProps } from "./ServiceDependencyGraph.types";
import {
  DownstreamDependencyData,
  UpstreamDependencyData,
} from "../../../types/DependencyMappingTypes";

export function DependencyGraphNode(props: DependencyGraphNodeProps) {
  const node = props.node;
  const rectangle = props.rectangle;
  const lineSpacing = props.rectangle.textMargin * 2;

  // create the string for canary env flags
  const makeCanaryEnvironmentsString = (
    inCanaryCentral: boolean,
    inCanaryEast: boolean
  ): string => {
    const base = "Canary Environments: ";
    if (inCanaryCentral && inCanaryEast) {
      return `${base}Canary Central, Canary East`;
    } else if (inCanaryCentral) {
      return `${base}Canary Central`;
    } else if (inCanaryEast) {
      return base + "Canary East";
    } else return `${base}None`;
  };

  // to do: make this draw based on up and down stream data.
  // currently always draws upstream. :(
  // should check the data type and draw text based on result
  // has to be an array so it injects into the <g> nicely.
  const makeNodeSpecificDetails = (
    data: UpstreamDependencyData | DownstreamDependencyData
  ) => {
    const dataType = data as UpstreamDependencyData;
    if (dataType !== null && dataType !== undefined) {
      return [
        <text
          textAnchor="middle"
          className={props.styleClassNames.rectText}
          y={node.y + lineSpacing * 2}
          x={node.x + rectangle.width / 2}
          key={`${node.id}${props.styleClassNames.rectText}-name`}
        >
          {node.name}
        </text>,
        <text
          textAnchor="start"
          className={props.styleClassNames.rectSubText}
          y={node.y + lineSpacing * 4}
          x={node.x + rectangle.textMargin}
          key={`${node.id}${props.styleClassNames.rectSubText}-rtc`}
        >
          Approved RTC:{" "}
          {(data as UpstreamDependencyData)?.upstreamApprovedRtc?.length > 0
            ? (data as UpstreamDependencyData).upstreamApprovedRtc
            : "None"}
        </text>,
        <text
          textAnchor="start"
          className={props.styleClassNames.rectSubText}
          y={node.y + lineSpacing * 5}
          x={node.x + rectangle.textMargin}
          key={`${node.id}${props.styleClassNames.rectSubText}-rtcViolation`}
        >
          Meets downstream RTC requirements:{" "}
          {(data as UpstreamDependencyData).isViolatingDownstreamRtcRequirements
            ? "No"
            : "Yes"}
        </text>,
        <text
          textAnchor="start"
          className={props.styleClassNames.rectSubText}
          y={node.y + lineSpacing * 6}
          x={node.x + rectangle.textMargin}
          key={`${node.id}${props.styleClassNames.rectSubText}-canary`}
        >
          {makeCanaryEnvironmentsString(
            (data as UpstreamDependencyData).upstreamInCanaryCentral,
            (data as UpstreamDependencyData).upstreamInCanaryEast
          )}
        </text>,
      ];
    }

    return [
      <text
        textAnchor="start"
        className={props.styleClassNames.rectSubText}
        y={node.y + rectangle.height + lineSpacing * 2}
        x={node.x + rectangle.textMargin}
        key={`${node.id}${props.styleClassNames.rectSubText}-rtc`}
      >
        Approved RTC:{" "}
        {(data as DownstreamDependencyData).desiredDependencyServiceRtc}
      </text>,
    ];
  };

  // leverage classnames to allow your base graph to use d3.select for manipulation
  return (
    <g className="rectNode" key={`${node.id}${typeof node.data}`}>
      <rect
        width={rectangle.width + lineSpacing * 4}
        height={rectangle.height + rectangle.textMargin}
        x={node.x}
        y={node.y}
        tabIndex={0}
        data-is-focusable={true}
        className={props.styleClassNames.rectNode}
        stroke={props.theme.palette.themePrimary}
        key={`${node.id}${props.styleClassNames.rectNode}`}
        aria-label={`Dependency node with bcdr related details for ${node.name}}`}
        role="group"
        onClick={(e) => props.handleOnClick(e, node)}
      />
      {makeNodeSpecificDetails(node.data).map((t) => t)}
    </g>
  );
}
