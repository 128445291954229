import * as React from "react";
import {
  Label,
  Persona,
  PersonaInitialsColor,
  PersonaSize,
  Stack,
} from "@fluentui/react";

import { ServiceBcdrDetailedMetadata } from "../../types/DependencyMappingTypes";
import { ContactModalContent } from "../modals/ContactModalContent";

export interface ServiceBcdrContactCardProps {
  currentServiceMetadata: ServiceBcdrDetailedMetadata;
}

export const ServiceBcdrContactCard = (props: ServiceBcdrContactCardProps) => {
  //checking that the arrays are emtpy - but the pm and dev arrays come back
  // with an empty string if there is no data currently.
  const hasSomeData: boolean =
    props?.currentServiceMetadata?.bcdrContact.length > 0 ||
    props?.currentServiceMetadata?.pmContacts[0]?.length > 0 ||
    props?.currentServiceMetadata?.devContacts[0]?.length > 0;
  return hasSomeData ? (
    <Stack horizontal tokens={{ childrenGap: 16 }}>
      <Stack>
        <Label>BCDR Contact</Label>
        <Persona
          size={PersonaSize.size24}
          initialsColor={PersonaInitialsColor.blue}
          text={props?.currentServiceMetadata?.bcdrContact ?? "No data"}
        />
      </Stack>
      <Stack>
        <Label>PM Contact(s)</Label>
        <ContactModalContent
          userList={props?.currentServiceMetadata?.pmContacts ?? []}
          userType={"Project Manager"}
        />
      </Stack>
      <Stack>
        <Label>Dev Contact(s)</Label>
        <ContactModalContent
          userList={props?.currentServiceMetadata?.devContacts ?? []}
          userType={"Developer"}
        />
      </Stack>
    </Stack>
  ) : (
    <div>
      <text>No contacts found for this service.</text>
    </div>
  );
};
