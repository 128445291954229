import { CountAnnotationBar, CountAnnotationBarDirection, ICountAnnotationProps } from "@m365-admin/count-annotation";
import React, { useMemo, useState } from "react";
import { getPeopleCounts } from "store/selectors/peopleSelector";
import { useAppSelector } from "store/store";

export const PeopleAggregateCountBar = () => {
  const [peopleCountProps, setPeopleCountProps] = useState< ICountAnnotationProps[] >([]);
  const peopleAggCounts: ICountAnnotationProps[] = useAppSelector(getPeopleCounts);

  useMemo(() => {
    const peopleCounts = peopleAggCounts;
    if (peopleAggCounts[0]?.count !== peopleCountProps[0]?.count)
      setPeopleCountProps(peopleCounts);
  }, [peopleAggCounts]);

  return (
    <CountAnnotationBar
      countAnnotationProps={peopleAggCounts}
      direction={CountAnnotationBarDirection.Vertical}
    />
  );
};
