import { Depths, Panel, PanelType, ScrollablePane, Stack, TagItem, Text, } from "@fluentui/react";
import { IChartDataPoint } from "@fluentui/react-charting";
import { Collapsible } from "@m365-admin/collapsible";
import { M365Breadcrumb } from "@m365-admin/m365-breadcrumb";
import { MetaDataItem, MetaDataList } from "@m365-admin/metadata";
import { IFacilityModel, NormalizedEmployeeProfile, ProcessAssetDetail } from "components/Types";
import InfoDonutChart, { pieChartColorOptions } from "components/charts/InfoDonutChart";
import FacilityPeopleDataGrid from "components/datagrids/FacilityPeopleDataGrid";
import { convertIntBooleanToString } from "components/datagrids/Helpers";
import ProcessDataGrid from "components/datagrids/ProcessDataGrid";
import { defaultStackTokens, panelScrollablePaneStyles } from "pages/Page.styles";
import React, { useRef } from "react";
import { getPeopleHierarchyByFacility } from "store/selectors/peopleSelector";
import { getProcessAssetDetailsByFacility } from "store/selectors/processesSelector";
import { useAppSelector } from "store/store";

export interface FacilityDetailPanelProps {
  filteredFacilityData: IFacilityModel;
  isFacilityDetailPanelOpen: boolean;
  hidePeopleGrid: boolean;
  dismissFacilityDetailPanel: () => void;
}

export default function FacilityDetailPanel(props: FacilityDetailPanelProps) {
  const divDataDonutRef = useRef(this);
  const [currentFacility, setCurrentFacility] = React.useState<IFacilityModel>(null);
  const [currentPeopleByFacilityData, setCurrentPeopleByFacilityData] = React.useState<NormalizedEmployeeProfile[]>([]);
  const [currentFacProcessData, setCurrentFacProcessData] = React.useState<ProcessAssetDetail[]>([]);
  const [currentFacDivChartData, setCurrentFacDivChartData] = React.useState<IChartDataPoint[]>([]);

  const peopleByFacility = useAppSelector(getPeopleHierarchyByFacility);
  const getProcessData = useAppSelector(getProcessAssetDetailsByFacility);

  const [activeDivFilter, setActiveDivFilter] = React.useState<string>(null);
  const filterPeopleTableData = React.useCallback(
    (divName: string) => {
      let peopleData = peopleByFacility;
      if (divName) {
        peopleData = peopleData.filter((p) => p.l1ManagerEmailName === divName);
        setActiveDivFilter(divName);
      }
      setCurrentPeopleByFacilityData(peopleData);
    },
    [peopleByFacility]
  );

  React.useMemo(() => {
    const makeDataPoints = (
      peopleData: NormalizedEmployeeProfile[]
    ): IChartDataPoint[] => {
      return peopleData
        .reduce(function (orgList, p) {
          //reducer function work
          const div = p?.l1ManagerEmailName ?? "Unknown";
          if (!orgList.includes(div)) {
            orgList.push(div);
          }
          return orgList ?? [];
        }, [])
        .map((div, index): IChartDataPoint => {
          const divPeople = peopleByFacility.filter(
            (p) => p.l1ManagerEmailName === div
          );
          const totalReports = divPeople.length;
          return {
            legend: div,
            data: totalReports,
            color: pieChartColorOptions[index],
            onClick: () => filterPeopleTableData(div),
          };
        });
    };

    if (props.filteredFacilityData) {
      setCurrentFacility(props.filteredFacilityData);
      setCurrentFacProcessData(getProcessData);
      setCurrentPeopleByFacilityData(peopleByFacility);
      const divDataSet = makeDataPoints(peopleByFacility);
      setCurrentFacDivChartData(divDataSet);
    }
  }, [
    props.filteredFacilityData,
    peopleByFacility,
    filterPeopleTableData,
    getProcessData,
  ]);

  return (
    <Panel
      type={PanelType.largeFixed}
      isLightDismiss
      isOpen={props.isFacilityDetailPanelOpen}
      onDismiss={props.dismissFacilityDetailPanel}
      headerText={`${currentFacility?.facilityName}`}
      closeButtonAriaLabel="Close"
      style={{ boxShadow: Depths.depth64 }}
    >
      <Stack tokens={defaultStackTokens}>
        <M365Breadcrumb
          items={[
            {
              text: currentFacility?.facilityRegion,
              key: currentFacility?.facilityRegion,
            },
            {
              text: currentFacility?.country,
              key: currentFacility?.country,
            },
            {
              text: currentFacility?.state,
              key: currentFacility?.state,
            },
            {
              text: currentFacility?.city,
              key: currentFacility?.city,
            },
            {
              text: currentFacility?.facilityName,
              key: currentFacility?.facilityName,
              isCurrentItem: true,
            },
          ]}
        />
        <MetaDataList
          verticalSpacing={8}
          styles={{ root: { paddingBottom: 16 } }}
        >
          <MetaDataItem
            header="Facility Type"
            body={currentFacility?.facilityType}
          />
          <MetaDataItem
            header="GWS Criticality"
            body={currentFacility?.buildingClassification}
          />
          <MetaDataItem
            header="Has Essential Employees"
            body={convertIntBooleanToString(
              currentFacility?.containsEssentials ?? 0
            )}
          />
          <MetaDataItem
            header="Facility Owner"
            body={currentFacility?.ownershipType}
          />
        </MetaDataList>
        <MetaDataItem
          header="Divsions in Building"
          body={
            <InfoDonutChart
              chartTitle={"Divisions in Building"}
              chartData={currentFacDivChartData}
              parentRef={divDataDonutRef?.current}
            />
          }
        />
        <Collapsible
          title="Employees assigned to building"
          defaultIsExpanded={currentPeopleByFacilityData?.length > 0}
        >
          <Stack
            horizontal
            aria-label="Active Filters"
            horizontalAlign="start"
            verticalAlign="start"
            role="list"
          >
            {activeDivFilter && (
              <TagItem
                removeButtonAriaLabel="Remove"
                item={{
                  key: "facDivFilter",
                  name: `Active Division Filter: ${activeDivFilter}`,
                }}
                index={1}
                onRemoveItem={() => {
                  filterPeopleTableData(null);
                  setActiveDivFilter(null);
                }}
              >
                Active division filter: {activeDivFilter}
              </TagItem>
            )}
          </Stack>
          <ScrollablePane
            styles={panelScrollablePaneStyles}
            data-is-scrollable="true"
          >
            {props.hidePeopleGrid ? (
              <Text>
                Due to security requirements, data center employee data has been
                removed for your assigned role.
              </Text>
            ) : (
              <FacilityPeopleDataGrid
                currentPeopleData={currentPeopleByFacilityData}
              />
            )}
          </ScrollablePane>
        </Collapsible>
        <Collapsible
          title={`${currentFacProcessData.length} Processes in Building`}
          defaultIsExpanded={currentFacProcessData?.length > 0}
        >
          <ScrollablePane
            styles={panelScrollablePaneStyles}
            data-is-scrollable="true"
          >
            <ProcessDataGrid processAssetData={currentFacProcessData} />
          </ScrollablePane>
        </Collapsible>
      </Stack>
    </Panel>
  );
}
