import { CoherenceLoading } from "@coherence-design-system/controls";
import { Depths, Facepile, Link, List, OverflowButtonType, Panel, PanelType, PersonaSize, ScrollablePane, Separator, Stack, Text, } from "@fluentui/react";
import { Collapsible } from "@m365-admin/collapsible";
import { M365Breadcrumb } from "@m365-admin/m365-breadcrumb";
import { MetaDataItem, MetaDataList } from "@m365-admin/metadata";
import { IDriTeamData, ServiceAssetDetail } from "components/Types";
import { DriGeoDataGrid } from "components/datagrids/DriGeoDataGrid";
import { convertStringBooleanToString } from "components/datagrids/Helpers";
import { defaultStackTokens, panelScrollablePaneStyles } from "pages/Page.styles";
import React from "react";
import { getSecondaryServiceDependencyDriData, getSecondaryServiceDependencyDriDataLoadErrors, getSecondaryServiceDependencyDriDataLoading, getSecondaryServiceDependencyMappingMetadata, getSecondaryServiceDependencyMappingMetadataIsLoading, getSecondaryServiceDependencyMappingMetadataLoadErrors } from "store/selectors/serviceDependencyMappingSelector";
import { getServiceAssetDetailsByIds, getServiceAssetDetailsByIdsError, getServiceAssetDetailsByIdsLoadingStatus } from "store/selectors/servicesSelector";
import { useAppSelector } from "store/store";
import { DownstreamDependencyData, ServiceBcdrDetailedMetadata, UpstreamDependencyData } from "types/DependencyMappingTypes";

export interface ServiceDependencyDetailPanelProps {
  upstreamDependency?: UpstreamDependencyData;
  downstreamDependency?: DownstreamDependencyData;
  isServiceDependencyDetailPanelOpen: boolean;
  dismissServiceDependencyDetailPanel: () => void;
}

export default function ServiceDependencyDetailPanel(props: ServiceDependencyDetailPanelProps) {
  const [serviceMetadata, setServiceMetadata] = React.useState<ServiceBcdrDetailedMetadata | undefined>(null);
  const [serviceMetadataLoading, setServiceMetadataLoading] = React.useState<boolean>(false);
  const [serviceMetadataError, setServiceMetadataError] = React.useState<string | Error | undefined>(null);
  const [serviceData, setServiceData] = React.useState<ServiceAssetDetail | undefined>(null);
  const [serviceDataLoading, setServiceDataLoading] = React.useState<boolean>(false);
  const [serviceDataError, setServiceDataError] = React.useState<string | undefined>(null);
  const [azRegionData, setAzRegionData] = React.useState<string[]>([]);
  const [driGeoData, setDriGeoData] = React.useState<IDriTeamData[]>([]);
  const [driGeoDataLoading, setDriGeoDataLoading] = React.useState<boolean>(false);
  const [driGeoDataError, setDriGeoDataError] = React.useState<string | Error | undefined>(null);
  const currentServiceMetadata = useAppSelector(getSecondaryServiceDependencyMappingMetadata);
  const currentServiceMetadataLoading = useAppSelector(getSecondaryServiceDependencyMappingMetadataIsLoading);
  const currentServiceMetadataError = useAppSelector(getSecondaryServiceDependencyMappingMetadataLoadErrors);
  const currentServiceData = useAppSelector(getServiceAssetDetailsByIds)[0];
  const currentServiceDataLoading = useAppSelector(getServiceAssetDetailsByIdsLoadingStatus);
  const currentServiceDataError = useAppSelector(getServiceAssetDetailsByIdsError);
  const currentServiceDriData = useAppSelector(getSecondaryServiceDependencyDriData);
  const currentServiceDriDataLoading = useAppSelector(getSecondaryServiceDependencyDriDataLoading);
  const currentServiceDriDataError = useAppSelector(getSecondaryServiceDependencyDriDataLoadErrors);

  React.useEffect(() => {
    setServiceMetadata(currentServiceMetadata);
    setServiceMetadataLoading(currentServiceMetadataLoading);
    setServiceMetadataError(currentServiceMetadataError);

    if (currentServiceData) {
      setServiceData(currentServiceData);
      const azureLocations = currentServiceData.azureRegionData
        .reduce(function (azList, location) {
          if (
            azList == null ||
            !azList.includes(location.azureCloudRegionName)
          ) {
            azList.push(location.azureCloudRegionName);
          }
          return azList;
        }, [])
        .sort();
      setAzRegionData(azureLocations);
    }
    setServiceDataError(currentServiceDataError);
    setServiceDataLoading(currentServiceDataLoading);

    setDriGeoData(currentServiceDriData);
    setDriGeoDataLoading(currentServiceDriDataLoading);
    setDriGeoDataError(currentServiceDriDataError);
  }, [
    props.upstreamDependency,
    props.downstreamDependency,
    currentServiceMetadata,
    currentServiceMetadataLoading,
    currentServiceMetadataError,
    currentServiceData,
    currentServiceDataLoading,
    currentServiceDataError,
    currentServiceDriData,
    currentServiceDriDataLoading,
    currentServiceDriDataError,
  ]);

  return (
    <Panel
      type={PanelType.largeFixed}
      isLightDismiss
      isOpen={props.isServiceDependencyDetailPanelOpen}
      onDismiss={props.dismissServiceDependencyDetailPanel}
      headerText={
        props.upstreamDependency
          ? props.upstreamDependency?.serviceName
          : props.downstreamDependency?.serviceName
      }
      closeButtonAriaLabel="Close"
      style={{ boxShadow: Depths.depth64 }}
    >
      <Stack tokens={defaultStackTokens}>
        <M365Breadcrumb
          items={[
            {
              text: serviceMetadata?.coreServiceData?.divisionName,
              key: serviceMetadata?.coreServiceData?.divisionName,
            },
            {
              text: serviceMetadata?.coreServiceData?.organizationName,
              key: serviceMetadata?.coreServiceData?.organizationName,
            },
            {
              text: serviceMetadata?.coreServiceData?.serviceGroupName,
              key: serviceMetadata?.coreServiceData?.serviceGroupName,
            },
            {
              text: serviceMetadata?.serviceOid,
              key: serviceMetadata?.serviceOid,
              isCurrentItem: true,
            },
          ]}
        />
        {(serviceMetadataLoading ||
          serviceMetadataLoading ||
          driGeoDataLoading) && <CoherenceLoading primaryText="Loading" />}
        {serviceMetadataError && <span>Errors: {serviceMetadataError}</span>}
        {serviceDataError && <span>Errors: {serviceDataError}</span>}
        {driGeoDataError && <span>Errors: {driGeoDataError}</span>}
        {serviceMetadata &&
          serviceData &&
          driGeoData &&
          !serviceMetadataLoading &&
          !serviceDataLoading &&
          !driGeoDataLoading &&
          !serviceMetadataError &&
          !serviceDataError &&
          !driGeoDataError && (
            <>
              <Link
                href={serviceMetadata?.coreServiceData?.serviceTreeLink}
                target="_blank"
              >
                See Service Tree Entry
              </Link>
              <MetaDataList
                verticalSpacing={8}
                styles={{ root: { paddingBottom: 16 } }}
              >
                <MetaDataItem
                  header="RTO Criticality"
                  body={serviceData?.rtoCriticalRating}
                  links={[
                    {
                      href: `${"https://global.azure.com/bcdr/assessment/" +
                        serviceMetadata?.serviceOid
                        }`,
                      children: `See ${serviceMetadata?.coreServiceData?.serviceName} BCDR assessment`,
                    },
                  ]}
                />
                <MetaDataItem
                  header="PM Owners"
                  body={
                    <Facepile
                      personaSize={PersonaSize.size24}
                      personas={serviceData?.pmOwner?.split(";").map((user) => {
                        return {
                          personaName: user,
                          initialsColor: 3,
                        };
                      })}
                      overflowButtonType={OverflowButtonType.descriptive}
                      ariaDescription="To move through the items use left and right arrow keys."
                      ariaLabel={`List of Facepile personas representing service PM owners`}
                    />
                  }
                />
                <MetaDataItem
                  header={"Service Type"}
                  body={serviceData?.serviceType}
                />
                <MetaDataItem
                  header="Dev Owners"
                  body={
                    <Facepile
                      personaSize={PersonaSize.size24}
                      personas={serviceData?.devOwner
                        ?.split(";")
                        .map((user) => {
                          return {
                            personaName: user,
                            initialsColor: 3,
                          };
                        })}
                      overflowButtonType={OverflowButtonType.descriptive}
                      ariaDescription="To move through the items use left and right arrow keys."
                      ariaLabel={`List of Facepile personas representing service dev owners`}
                    />
                  }
                />
                <MetaDataItem
                  header="Lifecycle Status"
                  body={serviceData?.maxLifecycle}
                />

                <MetaDataItem
                  header="Service Classification"
                  body={serviceData?.bcdrRecoveryClassification}
                />
              </MetaDataList>
              <Collapsible title="Resiliency Details">
                <MetaDataList>
                  <MetaDataItem
                    header="External Facing "
                    body={`${convertStringBooleanToString(
                      serviceData?.externalFacing
                    )}`}
                  />
                  <MetaDataItem
                    header="Contains Customer Data"
                    body={`${convertStringBooleanToString(
                      serviceData?.containsCustomerData
                    )}`}
                  />
                  <MetaDataItem
                    header="BCDR Resiliency Description"
                    body={
                      serviceData?.resiliencyDescription ??
                      "No resiliency description found."
                    }
                  />
                  <MetaDataItem
                    header="Known Azure Region Locations (Prod & Non-Prod)"
                    body={
                      <List
                        items={azRegionData}
                        onRenderCell={(item: string): JSX.Element => {
                          return <Text data-is-focusable={true}>{item}</Text>;
                        }}
                      />
                    }
                  />
                  {props.upstreamDependency && (
                    <>
                      <MetaDataItem
                        header="Approved RPC"
                        body={serviceMetadata?.approvedRpc}
                      />
                      <MetaDataItem
                        header="Detection Type"
                        body={serviceMetadata?.detectionType}
                      />
                      <MetaDataItem
                        header="Recovery Automation"
                        body={serviceMetadata?.recoveryAutomation}
                      />
                      <MetaDataItem
                        header="Recovery Decision"
                        body={serviceMetadata?.recoveryDecision}
                      />
                      <MetaDataItem
                        header="Is Violating Expected RTC of Original Service"
                        body={
                          props.upstreamDependency
                            ?.isViolatingDownstreamRtcRequirements
                            ? "Yes"
                            : "No"
                        }
                      />
                    </>
                  )}
                  {props.downstreamDependency && (
                    <>
                      <MetaDataItem
                        header="Expected RPC"
                        body={
                          props.downstreamDependency
                            ?.desiredDependencyServiceRtc
                        }
                      />
                      <MetaDataItem
                        header="Original Service Is Violating Expected RTC"
                        body={
                          props.downstreamDependency
                            ?.isUpstreamViolatingDownstreamRtcRequirements
                            ? "Yes"
                            : "No"
                        }
                      />
                    </>
                  )}
                </MetaDataList>
              </Collapsible>
              <Separator title="DRI Team Geo Displacement Risk"></Separator>
              <ScrollablePane
                styles={panelScrollablePaneStyles}
                data-is-scrollable="true"
              >
                <DriGeoDataGrid
                  currentDriGeoTeamData={driGeoData.flatMap((x) =>
                    x.geoResilienceTeamData.map((c) => {
                      return {
                        ...c,
                        teamId: x.teamId,
                        teamName: x.teamName,
                      };
                    })
                  )}
                />
              </ScrollablePane>
            </>
          )}
      </Stack>
    </Panel>
  );
}
