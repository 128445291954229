import { CoherenceLoading } from "@coherence-design-system/controls";
import { ScrollablePane, ThemeProvider } from "@fluentui/react";
import { M365LightTheme } from "@m365-admin/customizations";
import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import { EmptyDashboardContent } from "components/EmptyDashboardContent";
import { ServiceDependencyPicker } from "components/ServiceDependencyPicker";
import { ServiceDependencyDashboard } from "components/dashboards/ServiceDependencyDashboard";
import { Error } from "pages/Error";
import { _Styles } from "pages/Page.styles";
import React from "react";
import { useSelector } from "react-redux";
import { reactPlugin } from "services/AppInsights";
import { fetchServiceTreeOptions } from "store/actions/serviceDependencyMappingActions";
import { getCurrentServiceDependencyMappingMetadata, getCurrentServiceDependencyMappingMetadataIsLoading, getServiceTreeOptionsIsFirstLoadDone } from "store/selectors/serviceDependencyMappingSelector";
import { useAppDispatch } from "store/store";

export interface ServiceDependenciesProps { isNavCollapsed: boolean; }

export function ServiceDependencies({ isNavCollapsed }: ServiceDependenciesProps) {
  const dispatch = useAppDispatch()
  const currentServiceMetadata = useSelector(getCurrentServiceDependencyMappingMetadata);
  const currentServiceMetadataLoading = useSelector(getCurrentServiceDependencyMappingMetadataIsLoading);
  const serviceTreeOptionsFirstLoad = useSelector(getServiceTreeOptionsIsFirstLoadDone);

  React.useEffect(() => {
    if (!serviceTreeOptionsFirstLoad) {
      dispatch(fetchServiceTreeOptions());
    }
  }, [dispatch, serviceTreeOptionsFirstLoad]);

  return (
    <ThemeProvider theme={M365LightTheme.settings.theme}>
      <AppInsightsErrorBoundary
        onError={(err: Error) => (
          <Error
            isNavCollapsed={isNavCollapsed}
            errorMessage={err.message}
          />
        )}
        appInsights={reactPlugin}
      >
        <ScrollablePane
          className={
            isNavCollapsed
              ? _Styles.scrollablePaneCollapsed
              : _Styles.scrollablePaneExpand
          }
        >
          <h1 className={_Styles.header}>Service Dependencies</h1>
          <ServiceDependencyPicker />
          {currentServiceMetadataLoading && (
            <CoherenceLoading primaryText="Loading" />
          )}
          {!currentServiceMetadata && !currentServiceMetadataLoading && (
            <EmptyDashboardContent />
          )}
          {currentServiceMetadata && !currentServiceMetadataLoading && (
            <ServiceDependencyDashboard />
          )}
        </ScrollablePane>
      </AppInsightsErrorBoundary>
    </ThemeProvider>
  );
}
