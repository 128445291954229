export interface IPeopleDataDownload {
  alias: string;
  fullName: string;
  isActiveFte: boolean;
  isMobile: boolean;
  isDataCenterEmployee: boolean;
  employeeStatusState?: string;
  jobTitle: string;
  jobTitleSummaryDesc: string;
  csCompanyAgencyName: string;
  execOrgSummaryDesc: string;
  execOrgDesc: string;
  execOrgDetailDesc: string;
  execFunctionSummaryDesc: string;
  l0Manager: string;
  l0ManagerEmailName: string;
  l1Manager: string;
  l1ManagerEmailName: string;
  l2Manager: string;
  l2ManagerEmailName: string;
  l3Manager: string;
  l3ManagerEmailName: string;
  l4Manager: string;
  l4ManagerEmailName: string;
  l5Manager: string;
  l5ManagerEmailName: string;
  l6Manager: string;
  l6ManagerEmailName: string;
  l7Manager: string;
  l7ManagerEmailName: string;
  region: string;
  country: string;
  state: string;
  city: string;
  buildingName: string;
  buildingId?: number;
  datetimeStamp: string;
}

export const IPeopleDataDownloadDownloadColumns: string[] = [
  "alias",
  "fullName",
  "isActiveFte",
  "isMobile",
  "isDataCenterEmployee",
  "employeeStatusState?",
  "jobTitle",
  "jobTitleSummaryDesc",
  "csCompanyAgencyName",
  "execOrgSummaryDesc",
  "execOrgDesc",
  "execOrgDetailDesc",
  "execFunctionSummaryDesc",
  "l0Manager",
  "l0ManagerEmailName",
  "l1Manager",
  "l1ManagerEmailName",
  "l2Manager",
  "l2ManagerEmailName",
  "l3Manager",
  "l3ManagerEmailName",
  "l4Manager",
  "l4ManagerEmailName",
  "l5Manager",
  "l5ManagerEmailName",
  "l6Manager",
  "l6ManagerEmailName",
  "l7Manager",
  "l7ManagerEmailName",
  "region",
  "country",
  "state",
  "city",
  "buildingName",
  "buildingId",
  "datetimeStamp",
];

export interface IRestrictedPeopleDataDownload {
  alias: string;
  fullName: string;
  isActiveFte: boolean;
  isMobile: boolean;
  jobTitle: string;
  jobTitleSummaryDesc: string;
  csCompanyAgencyName: string;
  execOrgSummaryDesc: string;
  execOrgDesc: string;
  execOrgDetailDesc: string;
  execFunctionSummaryDesc: string;
  l0Manager: string;
  l0ManagerEmailName: string;
  l1Manager: string;
  l1ManagerEmailName: string;
  l2Manager: string;
  l2ManagerEmailName: string;
  l3Manager: string;
  l3ManagerEmailName: string;
  l4Manager: string;
  l4ManagerEmailName: string;
  l5Manager: string;
  l5ManagerEmailName: string;
  l6Manager: string;
  l6ManagerEmailName: string;
  l7Manager: string;
  l7ManagerEmailName: string;
  region: string;
  country: string;
  state: string;
  city: string;
  buildingName: string;
  datetimeStamp: string;
}

export const IRestrictedPeopleDataDownloadColumns: string[] = [
  "alias",
  "fullName",
  "isActiveFte",
  "isMobile",
  "jobTitle",
  "jobTitleSummaryDesc",
  "csCompanyAgencyName",
  "execOrgSummaryDesc",
  "execOrgDesc",
  "execOrgDetailDesc",
  "execFunctionSummaryDesc",
  "l0Manager",
  "l0ManagerEmailName",
  "l1Manager",
  "l1ManagerEmailName",
  "l2Manager",
  "l2ManagerEmailName",
  "l3Manager",
  "l3ManagerEmailName",
  "l4Manager",
  "l4ManagerEmailName",
  "l5Manager",
  "l5ManagerEmailName",
  "l6Manager",
  "l6ManagerEmailName",
  "l7Manager",
  "l7ManagerEmailName",
  "region",
  "country",
  "state",
  "city",
  "buildingName",
  "datetimeStamp",
];
