import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, useMsalAuthentication } from "@azure/msal-react";
import { AppFrameProvider } from "@coherence-design-system/app-frame";
import { CoherenceTheme } from "@coherence-design-system/styles";
import { ThemeProvider, initializeIcons, loadTheme, setIconOptions } from "@fluentui/react";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { DependencyMappingOverview } from "pages/DependencyMappingOverview";
import { Facilities } from "pages/Facilities";
import { Home } from "pages/Home";
import { People } from "pages/People";
import { Processes } from "pages/Processes";
import { ServiceDependencies } from "pages/ServiceDependencies";
import { Services } from "pages/Services";
import { Wiki } from "pages/Wiki";
import React, { useEffect } from "react";
import { Route, Routes } from "react-router";
import { msalInstance } from ".";
import { loginRequest } from "./authProvider";
import CustomHeader from "./components/nav/Header";
import { LeftNav } from "./components/nav/LeftNav";
import "./custom.css";
import { Error } from "./pages/Error";
import { appInsights, reactPlugin } from "./services/AppInsights";
import { webLightTheme, webDarkTheme, FluentProvider } from "@fluentui/react-components";

loadTheme(CoherenceTheme);
initializeIcons(undefined, { disableWarnings: true });
setIconOptions({ disableWarnings: true });

export function ErrorComponent() {
  return (
    <Error
      isNavCollapsed={false}
      errorMessage={"The Watchtower modeling tool is intended for the ER/Crisis teams only."}
    />
  );
}

export function App() {
  const { login, result, error } = useMsalAuthentication(InteractionType.Silent, loginRequest);

  useEffect(() => {
    if (error) {
      login(InteractionType.Redirect, loginRequest);
    }
  }, [error]);

  useEffect(() => {
    if (result) {
      msalInstance.setActiveAccount(result.account);
      appInsights.setAuthenticatedUserContext(result?.account?.homeAccountId);
      const roles = result?.account?.idTokenClaims?.roles ?? [];

      if (roles) {
        appInsights.trackEvent({
          name: "User Roles",
          properties: { roles: roles.join(",") },
        });
      }
    }
  }, [result]);

  if (!appInsights.appInsights.isInitialized()) {
    appInsights.loadAppInsights();
  }

  return (
    <>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        errorComponent={ErrorComponent}
        loadingComponent={() => <>Loading...</>}
        authenticationRequest={{ scopes: loginRequest.scopes }}
      >
        {result && (
          <AppWithoutAuth
            HeaderComponent={() => (
              <CustomHeader
                account={msalInstance.getAllAccounts()}
                onLogOut={msalInstance.logoutRedirect}
              />
            )}
          />
        )}
      </MsalAuthenticationTemplate>
    </>
  );
}
interface AppWithoutAuth {
  HeaderComponent?: any;
}
export function AppWithoutAuth({ HeaderComponent }: AppWithoutAuth) {
  const [collapsed, setCollapsed] = React.useState<boolean>(false);

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <AppFrameProvider
        availableThemes={{ default: { fluentV8: CoherenceTheme } }}
        defaultThemeKey="default"
      >
        <div slot="header">{HeaderComponent && <HeaderComponent />}</div>
        <div slot="navigation">
          <LeftNav onNavCollapsed={(isCollapsed: boolean) => setCollapsed(isCollapsed)} />
        </div>
        <div slot="main">
          <main slot="mainContent">
            <Routes>
              <Route path="/" element={<Home isNavCollapsed={collapsed} />} />
              <Route path="/facilities" element={<Facilities isNavCollapsed={collapsed} />} />
              <Route path="/people" element={<People isNavCollapsed={collapsed} />} />
              <Route path="/processes" element={<Processes isNavCollapsed={collapsed} />} />
              <Route path="/services" element={<Services isNavCollapsed={collapsed} />} />
              <Route
                path="/dependencyOverview"
                element={<DependencyMappingOverview isNavCollapsed={collapsed} />}
              />
              <Route
                path="/serviceDependencies"
                element={<ServiceDependencies isNavCollapsed={collapsed} />}
              />
              <Route path="/wiki" element={<Wiki isNavCollapsed={collapsed} />} />
              <Route
                element={
                  <Error errorMessage={"Error 404: Page Not Found"} isNavCollapsed={collapsed} />
                }
              />
            </Routes>
          </main>
        </div>
      </AppFrameProvider>
    </AppInsightsContext.Provider>
  );
}
