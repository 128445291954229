import { createSelector } from "reselect";
import { CacheRefresh, ReduxSystemState } from "../reducers/systemReducer";
import { AxiosError } from "axios";

const system = (state: any): ReduxSystemState => state.system;
const systemError = (state: any): string | AxiosError => state.systen.error;

export const getSystemAzureMapKey = createSelector(
  system,
  (system: ReduxSystemState): string => {
    return system.azureMapKey ?? "";
  }
);

export const getSystemAzureMapKeyLoadingStatus = createSelector(
  system,
  (system: ReduxSystemState): boolean => {
    return system.isAzureMapKeyLoading ?? false;
  }
);

export const getSystemCacheRefreshData = createSelector(
  system,
  (system: ReduxSystemState): CacheRefresh[] => {
    return system.cacheRefreshList ?? [];
  }
);

export const getSystemCacheRefreshDataError = createSelector(
  systemError,
  (systemError: AxiosError): string => {
    if (systemError) {
      return `Error loading cache data. ${systemError}`;
    } else return null;
  }
);

export const getCacheRefreshDataLoadingStatus = createSelector(
  system,
  (system: ReduxSystemState) => system.isLoading ?? true
);
