export enum CardSizes {
  small = "small",
  smallWide = "smallWide",
  medium = "medium",
  mediumTall = "mediumTall",
  mediumWide = "mediumWide",
  large = "large",
  extraLarge = "extraLarge",
  mega = "mega",
}

export type SizeMap = {
  [key in CardSizes]: { cellWidth: number; cellHeight: number };
};

export const CardSizeValues: SizeMap = {
  small: { cellWidth: 1, cellHeight: 1 },
  smallWide: { cellWidth: 4, cellHeight: 1 },
  mediumTall: { cellWidth: 1, cellHeight: 2 },
  mediumWide: { cellWidth: 2, cellHeight: 1 },
  medium: { cellWidth: 2, cellHeight: 2 },
  large: { cellWidth: 3, cellHeight: 2 },
  extraLarge: { cellWidth: 4, cellHeight: 2 },
  mega: { cellWidth: 6, cellHeight: 2 },
};
