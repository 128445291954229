import { ScrollablePane } from "@fluentui/react";
import { IChartProps, ILegend, Legends, MultiStackedBarChart } from "@fluentui/react-charting";
import { NormalizedEmployeeProfile } from "components/Types";
import { pieChartColorOptions } from "components/charts/InfoDonutChart";
import { cardScrollablePaneStyles } from "pages/Page.styles";
import { useMemo, useState } from "react";

export interface WorkforceByEvpCountBarProps {
  filteredPeopleData: NormalizedEmployeeProfile[];
  onBarClick: (evpAlias: string, facilityIdList: number[]) => void;
}

export const WorkforceByEvpCountBar = ({
  filteredPeopleData,
  onBarClick,
}: WorkforceByEvpCountBarProps) => {
  const [orgChartData, setOrgChartData] = useState<IChartProps[]>([]);
  useMemo(() => {
    const divsByBuilding = Array.from(
      filteredPeopleData.reduce((orgSet, p) => {
        orgSet.add(p?.l1ManagerEmailName ?? "Unknown");
        return orgSet;
      }, new Set<string>())
    );

    const mappedDivData = divsByBuilding.map((div) => {
      const divPeople = filteredPeopleData.reduce(
        (acc, p) => {
          if (p.l1ManagerEmailName === div) {
            acc.fteCount += p.isActiveFte ? 1 : 0;
            acc.buildingIds.push(p.buildingId);
          }
          return acc;
        },
        { fteCount: 0, buildingIds: [] }
      );

      return {
        name: div,
        fte: divPeople.fteCount,
        nonFte: divPeople.buildingIds.length - divPeople.fteCount,
        buildingIds: divPeople.buildingIds,
      };
    });

    const chartData: IChartProps[] = mappedDivData.map((dpd) => ({
      chartTitle: dpd.name,
      chartDataAccessibilityData: {
        ariaLabel: `Aggregate view of ${dpd.name}'s assigned headcount.`,
      },
      chartData: [
        {
          legend: "Active FTE",
          data: dpd.fte,
          xAxisCalloutData: `${dpd.name} active FTE`,
          yAxisCalloutData: dpd.fte.toString(),
          color: pieChartColorOptions[2],
          onClick: () => {
            onBarClick(dpd.name, dpd.buildingIds);
          },
        },
        {
          legend: "Active Contingent",
          data: dpd.nonFte,
          xAxisCalloutData: `${dpd.name} active Contingent`,
          yAxisCalloutData: dpd.nonFte.toString(),
          color: pieChartColorOptions[3],
          onClick: () => {
            onBarClick(dpd.name, dpd.buildingIds);
          },
        },
      ],
    }));
    chartData.sort((a, b) => a.chartTitle.localeCompare(b.chartTitle));
    setOrgChartData(chartData);
  }, [filteredPeopleData, onBarClick]);

  const legends: ILegend[] = [
    {
      title: "Active FTE",
      color: pieChartColorOptions[2],
    },
    {
      title: "Active Contingent",
      color: pieChartColorOptions[3],
    },
  ];

  return (
    <div>
      <ScrollablePane styles={cardScrollablePaneStyles}>
        <MultiStackedBarChart
          data={orgChartData}
          styles={{
            singleChartRoot: { marginBottom: "6px", marginRight: "6px" },
          }}
        />
      </ScrollablePane>
      <Legends legends={legends} />
    </div>
  );
};
