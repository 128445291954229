import { DefaultPalette } from "@fluentui/react";
import { ICountAnnotationProps } from "@m365-admin/count-annotation";
import { ServiceCount, ServiceAssetDetail } from "../components/Types";
import { criticalityColorOptions } from "../components/charts/InfoDonutChart";

export const buildAggregateCounts = (serviceAssetCounts: ServiceCount): ICountAnnotationProps[] => {
  if (serviceAssetCounts) {
    // iterate through the filtered service data and increment the count for each criticality in the map
    // if the service.rtoCriticalityRating matches the key
    let criticalityList = new Map<string, number>([
      ["Mission Critical", serviceAssetCounts.missionCriticalCount ?? 0],
      ["Critical", serviceAssetCounts.criticalCount ?? 0],
      ["Important", serviceAssetCounts.importantCount ?? 0],
      ["Deferrable", serviceAssetCounts.deferrableCount ?? 0],
      ["Minor", serviceAssetCounts.minorCount ?? 0],
      ["Not Yet Rated", serviceAssetCounts.notYetRatedCount ?? 0],
      ["Missing Parent", serviceAssetCounts.missingParentCount ?? 0],
      ["Unknown", serviceAssetCounts.unknown ?? 0],
    ]);
    let countAnnotations: ICountAnnotationProps[] = [];
    countAnnotations.push({
      annotationText: "Total",
      count: serviceAssetCounts.totalCount ?? 0,
      size: 1, //set to large
      annotationColor: DefaultPalette.purpleLight,
    });
    criticalityList.forEach((value, key) => {
      countAnnotations.push({
        annotationText: key,
        count: value,
        size: 1, //set to large
        annotationColor: criticalityColorOptions.find((i) => i.label === key).color,
      });
    });
    countAnnotations.push({
      annotationText: "Single Region Isolated",
      count: serviceAssetCounts.dcIsolated,
      size: 1, //set to large
      annotationColor: DefaultPalette.magentaDark,
    });
    countAnnotations.push({
      annotationText: "Single DC Isolated",
      count: serviceAssetCounts.dcIsolated,
      size: 1, //set to large
      annotationColor: DefaultPalette.tealDark,
    });
    countAnnotations.push({
      annotationText: "Data Centers in Scope",
      count: serviceAssetCounts.dcsInScope ?? 0,
      size: 1, //set to large
      annotationColor: DefaultPalette.greenDark,
    });
    countAnnotations.push({
      annotationText: "Data Centers in Total",
      count: serviceAssetCounts.dcsInTotal ?? 0,
      size: 1, //set to large
      annotationColor: DefaultPalette.blueDark,
    });
    return countAnnotations;
  }
};

export const buildServiceCounts = (
  serviceAssets: ServiceAssetDetail[],
  activeDcs: number[]
): ServiceCount => {
  let newAggregateCounts: ServiceCount = {
    totalCount: 0,
    missionCriticalCount: 0,
    criticalCount: 0,
    importantCount: 0,
    deferrableCount: 0,
    minorCount: 0,
    notYetRatedCount: 0,
    missingParentCount: 0,
    unknown: 0,
    dcIsolated: 0,
    regionIsolated: 0,
    dcsInScope: 0,
    dcsInTotal: 0,
  };
  if (serviceAssets.length === 0) {
    return newAggregateCounts;
  }
  serviceAssets.forEach((service) => {
    switch (service.rtoCriticalRating) {
      case "MISSIONCRITICAL":
        newAggregateCounts.missionCriticalCount++;
        break;
      case "CRITICAL":
        newAggregateCounts.criticalCount++;
        break;
      case "IMPORTANT":
        newAggregateCounts.importantCount++;
        break;
      case "DEFERRABLE":
        newAggregateCounts.deferrableCount++;
        break;
      case "MINOR":
        newAggregateCounts.minorCount++;
        break;
      case "NOTYETRATED":
        newAggregateCounts.notYetRatedCount++;
        break;
      case "MISSINGPARENT":
        newAggregateCounts.missingParentCount++;
        break;
      case "UNKNOWN":
        newAggregateCounts.unknown++;
        break;
      default:
        newAggregateCounts.unknown++;
        break;
    }
  });

  newAggregateCounts.totalCount = serviceAssets.length;
  //region isolated is the sum of all services that have exactly one unique azure cloud region in their azureRegionData
  newAggregateCounts.regionIsolated = Array.from(
    new Set(
      serviceAssets.flatMap((service) =>
        service.azureRegionData.map((reg) => reg.azureCloudRegionName)
      )
    )
  ).length;
  //dc isolated is the set of all services that have exactly one DC in their azureRegionData
  newAggregateCounts.dcIsolated = serviceAssets.filter(
    (service) => service.azureRegionData.length === 1
  ).length;
  //dcsInScope is the set of all DCs in the filtered services
  newAggregateCounts.dcsInScope = activeDcs.length;
  newAggregateCounts.dcsInTotal = Array.from(
    new Set(
      serviceAssets.flatMap((service) =>
        service.azureRegionData.map((reg) => reg.datacenterCampusId)
      )
    )
  ).length;

  return newAggregateCounts;
};
