import { CountAnnotationBar, CountAnnotationBarDirection, ICountAnnotationProps } from '@m365-admin/count-annotation';
import React from 'react';
import { useAppSelector } from 'store/store';
import { getProcessCounts } from '../../store/selectors/processesSelector';

export interface ProcessAggregateCountBarProps {}
export const ProcessAggregateCountBar = () => {
  const [processCountProps, setProcessCountProps] = React.useState<ICountAnnotationProps[]>([]);
  const processAggCounts: ICountAnnotationProps[] = useAppSelector(getProcessCounts);

  React.useMemo(() => {
    const processCounts = processAggCounts;
    if (processAggCounts[0]?.count !== processCountProps[0]?.count)
      setProcessCountProps(processCounts);
  }, [processAggCounts]);

  return (
    <CountAnnotationBar
      countAnnotationProps={processCountProps}
      direction={CountAnnotationBarDirection.Vertical}
    />
  );
};
