import React from "react";
import { IPersonaProps, Persona } from "@fluentui/react/lib/Persona";
import {
  IBasePickerSuggestionsProps,
  NormalPeoplePicker,
} from "@fluentui/react/lib/Pickers";
import { DefaultButton, IButtonStyles, Stack } from "@fluentui/react";
import { PrimaryButton } from "@fluentui/react/lib/Button";

export interface EmailPeoplePickerProps {
  personasToEmail: IPersonaProps[];
}

const suggestionProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: "Suggested People",
  mostRecentlyUsedHeaderText: "Suggested Contacts",
  noResultsFoundText: "No results found",
  loadingText: "Loading",
  showRemoveButtons: true,
  suggestionsAvailableAlertText: "People Picker Suggestions available",
  suggestionsContainerAriaLabel: "Suggested contacts",
};

export const EmailPeoplePicker = (props: EmailPeoplePickerProps) => {
  const [currentSelectedPeople, setCurrentSelectedPeople] = React.useState<
    IPersonaProps[]
  >([]);

  const peoplePickerOptions: IPersonaProps[] = props.personasToEmail;

  const getPeopleRemaining = React.useCallback(() => {
    return peoplePickerOptions.filter(
      (p) => currentSelectedPeople!.indexOf(p) === -1
    );
  }, [currentSelectedPeople, peoplePickerOptions]);

  const generateEmailStrings = React.useCallback(() => {
    const emailList = currentSelectedPeople
      .map((p) => p.secondaryText)
      .join(",");

    return `mailto:${emailList}`;
  }, [currentSelectedPeople]);

  const defaultButtonStyles: Partial<IButtonStyles> = {
    root: { height: "auto" },
  };
  const picker = React.useRef(null);
  const onFilterChanged = (
    filterText: string,
    currentPersonas: IPersonaProps[],
    limitResults?: number
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText) {
      let filteredPersonas: IPersonaProps[] = filterPersonasByText(filterText);

      filteredPersonas = removeDuplicates(filteredPersonas, currentPersonas);
      filteredPersonas = limitResults
        ? filteredPersonas.slice(0, limitResults)
        : filteredPersonas;
      return filteredPersonas;
    } else {
      return [];
    }
  };

  const filterPersonasByText = (filterText: string): IPersonaProps[] => {
    return peoplePickerOptions.filter((item) => {
      return item?.text?.toLowerCase().indexOf(filterText.toLowerCase()) === 0;
    });
  };

  const onItemsChange = (items: any[]): void => {
    setCurrentSelectedPeople(items);
  };

  return (
    <Stack tokens={{ childrenGap: 8 }}>
      <NormalPeoplePicker
        // eslint-disable-next-line react/jsx-no-bind
        onResolveSuggestions={onFilterChanged}
        getTextFromItem={getTextFromItem}
        pickerSuggestionsProps={suggestionProps}
        className={"ms-PeoplePicker"}
        key={"controlled"}
        selectionAriaLabel={"Selected contacts"}
        removeButtonAriaLabel={"Remove"}
        selectedItems={currentSelectedPeople}
        // eslint-disable-next-line react/jsx-no-bind
        onChange={onItemsChange}
        inputProps={{
          "aria-label": "Contacts",
        }}
        componentRef={picker}
      />
      <label> Click to Add a person </label>
      <Stack>
        {getPeopleRemaining().map((item, index) => (
          <div key={index}>
            <DefaultButton
              styles={defaultButtonStyles}
              onClick={() =>
                setCurrentSelectedPeople(currentSelectedPeople.concat([item]))
              }
            >
              <Persona {...item} />
            </DefaultButton>
          </div>
        ))}
      </Stack>
      <PrimaryButton
        href={generateEmailStrings()}
        target="_blank"
        disabled={currentSelectedPeople.length === 0}
      >
        Contact selected people
      </PrimaryButton>
    </Stack>
  );
};

function removeDuplicates(
  personas: IPersonaProps[],
  possibleDupes: IPersonaProps[]
) {
  return personas.filter(
    (persona) => !listContainsPersona(persona, possibleDupes)
  );
}

function listContainsPersona(
  persona: IPersonaProps,
  personas: IPersonaProps[]
) {
  if (!personas || !personas.length || personas.length === 0) {
    return false;
  }
  return personas.filter((item) => item?.text === persona.text).length > 0;
}

function getTextFromItem(persona: IPersonaProps): string {
  return persona?.text as string;
}
