import { Stack, ComboBox, IComboBoxOption } from "@fluentui/react";
import { buildFilterEventCustomTelemetry } from "components/datagrids/Helpers";
import { _Styles } from "pages/Page.styles";
import { appInsights } from "services/AppInsights";
import {
  getServiceBclOptions,
  getServiceCloudOptions,
  getServiceDivisionOptions,
  getServiceOrganizationOptions,
  getServiceSgOptions,
} from "store/selectors/servicesSelector";
import { useAppSelector } from "store/store";

export interface ServiceFilterState {
  selectedBusinessContinuityLeads: string[];
  selectedClouds: string[];
  selectedDivisions: string[];
  selectedOrgs: string[];
  selectedServiceGroups: string[];
  selectedDataCenters: number[];
}

export interface IServiceFilterProps {
  isLoading: boolean;
  updateFilters: (filterName: string, filterListResult: string[]) => void;
  serviceFilterState: ServiceFilterState;
}
export const ServiceFilterBase = (props: IServiceFilterProps) => {
  const serviceDivisionOptions = useAppSelector(getServiceDivisionOptions);

  const serviceOrgOptions = useAppSelector(getServiceOrganizationOptions);

  const serviceSgOptions = useAppSelector(getServiceSgOptions);

  const serviceBclOptions = useAppSelector(getServiceBclOptions);

  const serviceCloudOptions = useAppSelector(getServiceCloudOptions);
  // Generic function to handle filter changes
  const handleFilterChange = (
    filterKeys: string[],
    option?: IComboBoxOption,
    filterName?: string
  ) => {
    if (option) {
      const updatedKeys = option.selected
        ? [...filterKeys, option.key as string]
        : filterKeys.filter((k) => k !== option.key);
      props.updateFilters(filterName, updatedKeys);
      appInsights.trackEvent(
        { name: "Filter Used" },
        buildFilterEventCustomTelemetry(filterName, option.text)
      );
    }
  };

  // Usage for Service Division
  const onServiceDivisionChange = (_, option?: IComboBoxOption): void => {
    handleFilterChange(props.serviceFilterState.selectedDivisions, option, "Service Division");
  };

  // Usage for Service Organization
  const onServiceOrgChange = (_, option?: IComboBoxOption): void => {
    handleFilterChange(props.serviceFilterState.selectedOrgs, option, "Service Organization");
  };

  // Usage for Service Group
  const onServiceGroupChange = (_, option?: IComboBoxOption): void => {
    handleFilterChange(props.serviceFilterState.selectedServiceGroups, option, "Service Group");
  };

  // Usage for Business Continuity Lead
  const onServiceBclChange = (_, option?: IComboBoxOption): void => {
    handleFilterChange(
      props.serviceFilterState.selectedBusinessContinuityLeads,
      option,
      "Service Business Continuity Lead"
    );
  };

  // Usage for Service Cloud
  const onServiceCloudChange = (_, option?: IComboBoxOption): void => {
    handleFilterChange(props.serviceFilterState.selectedClouds, option, "Service Cloud");
  };

  return (
    <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
      <ComboBox
        multiSelect
        label="Filter by divsion"
        className={_Styles.smallComboBoxStyle}
        options={serviceDivisionOptions}
        disabled={!serviceDivisionOptions.length || props.isLoading}
        onChange={onServiceDivisionChange}
        selectedKey={props.serviceFilterState.selectedDivisions}
      />
      <ComboBox
        multiSelect
        className={_Styles.smallComboBoxStyle}
        label="Filter by organization"
        options={serviceOrgOptions}
        disabled={!serviceOrgOptions.length || props.isLoading}
        onChange={onServiceOrgChange}
        selectedKey={props.serviceFilterState.selectedOrgs}
      />
      <ComboBox
        multiSelect
        className={_Styles.smallComboBoxStyle}
        label="Filter by service group"
        options={serviceSgOptions}
        disabled={!serviceSgOptions.length || props.isLoading}
        onChange={onServiceGroupChange}
        selectedKey={props.serviceFilterState.selectedServiceGroups}
      />
      <ComboBox
        multiSelect
        className={_Styles.smallComboBoxStyle}
        label="Filter by business continuity lead"
        options={serviceBclOptions}
        disabled={!serviceBclOptions.length || props.isLoading}
        onChange={onServiceBclChange}
        selectedKey={props.serviceFilterState.selectedBusinessContinuityLeads}
      />
      <ComboBox
        multiSelect
        className={_Styles.smallComboBoxStyle}
        label="Filter by cloud"
        options={serviceCloudOptions}
        disabled={!serviceCloudOptions.length || props.isLoading}
        onChange={onServiceCloudChange}
        selectedKey={props.serviceFilterState.selectedClouds}
      />
    </Stack>
  );
};
