import {
  FETCH_AZURE_MAP_KEY_BEGIN,
  FETCH_CACHE_REFRESH_DATA_BEGIN,
  SET_AZURE_MAP_KEY_DATA,
  SET_AZURE_MAP_KEY_ERROR,
  SET_CACHE_REFRESH_DATA,
  SET_CACHE_REFRESH_ERROR,
} from "../actions/actionTypes";

export interface CacheRefresh {
  sourceName: string;
  lastCacheRefreshDateAndTime: string;
}

export interface ReduxSystemState {
  cacheRefreshList: CacheRefresh[];
  isLoading: boolean;
  errorMessage: string;

  azureMapKey: string;
  isAzureMapKeyLoading: boolean;
  azureMapKeyErrorMessage: string;
}

const systemInitialState: ReduxSystemState = {
  cacheRefreshList: [],
  isLoading: false,
  errorMessage: null,
  azureMapKey: "",
  isAzureMapKeyLoading: false,
  azureMapKeyErrorMessage: null,
};

export default function system(
  state: ReduxSystemState = systemInitialState,
  action: any
) {
  switch (action.type) {
    case FETCH_CACHE_REFRESH_DATA_BEGIN:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      };
    case SET_CACHE_REFRESH_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload,
      };
    case SET_CACHE_REFRESH_DATA:
      return {
        ...state,
        cacheRefreshList: action.payload,
        isLoading: false,
        errorMessage: null,
      };
    case FETCH_AZURE_MAP_KEY_BEGIN:
      return {
        ...state,
        isAzureMapKeyLoading: true,
        azureMapKeyErrorMessage: null,
      };
    case SET_AZURE_MAP_KEY_DATA:
      return {
        ...state,
        azureMapKey: action.payload,
        isAzureMapKeyLoading: false,
      };
    case SET_AZURE_MAP_KEY_ERROR:
      return {
        ...state,
        isAzureMapKeyLoading: false,
        azureMapKeyErrorMessage: action.payload,
      };
    default:
      return state;
  }
}
