import { InteractionRequiredAuthError } from "@azure/msal-common";
import axios from "axios";

import { apiScopeRequest, loginRequest } from "../authProvider";
import { msalInstance } from "../index";
import { appInsights } from "./AppInsights";

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

apiClient.interceptors.request.use(
  async function (config) {
    let accessToken;
    const request = {
      scopes: apiScopeRequest,
    };

    await msalInstance
      .acquireTokenSilent(request)
      .then((tokenResponse) => {
        accessToken = tokenResponse.accessToken;
      })
      .catch(async (error) => {
        if (error instanceof InteractionRequiredAuthError) {
          await msalInstance.acquireTokenRedirect({
            ...request,
            loginHint: msalInstance.getActiveAccount().username,
            extraScopesToConsent: loginRequest.scopes,
          });
          await msalInstance
            .handleRedirectPromise()
            .then((tokenResponse) => {
              if (!tokenResponse) {
                const accounts = msalInstance.getAllAccounts();
                if (accounts.length === 0) {
                  msalInstance.loginRedirect();
                }
              } else {
                accessToken = tokenResponse.accessToken;
              }
            })
            .catch((error) => {
              appInsights.trackException(error);
            });
        }
      })
      .catch((error) => {
        appInsights.trackException(error);
      });

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
      config.headers["mode"] = "cors";
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default apiClient;
