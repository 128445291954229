import { ITheme } from "@fluentui/react";
import {
  AssessmentStatus,
  ComplianceStatus,
} from "../../types/DependencyMappingTypes";
import { buildIcon } from "../datagrids/Helpers";

export const buildAssessmentStatusIcons = (
  impactScore: AssessmentStatus,
  theme: ITheme
) => {
  switch (impactScore) {
    case AssessmentStatus.Rejected:
      return buildIcon("Rejected", theme.palette.redDark, "StatusErrorFull");
    case AssessmentStatus.PostApprovalRejected:
      return buildIcon(
        "Post Approval Rejected",
        theme.palette.redDark,
        "StatusErrorFull"
      );
    case AssessmentStatus.AwaitingApproval:
      return buildIcon(
        "Awaiting Approval",
        theme.palette.yellow,
        "WarningSolid"
      );
    case AssessmentStatus.ReadyForSubmission:
      return buildIcon(
        "Ready for Submission",
        theme.palette.yellow,
        "WarningSolid"
      );
    case AssessmentStatus.Approved:
      return buildIcon("Approved", theme.palette.green, "CompletedSolid");
    case AssessmentStatus.Unknown:
      return buildIcon("Unknown", theme.palette.black, "UnknownSolid");
    default:
      return buildIcon("No data", theme.palette.black, "UnknownSolid");
  }
};

export const buildComplianceStatusIcons = (
  impactScore: ComplianceStatus,
  theme: ITheme
) => {
  switch (impactScore) {
    case ComplianceStatus.RA:
      return buildIcon("RA", theme.palette.redDark, "StatusErrorFull");
    case ComplianceStatus.RAExpired:
      return buildIcon("RA Expired", theme.palette.redDark, "StatusErrorFull");
    case ComplianceStatus.PostApprovalRejected:
      return buildIcon(
        "Post Approval Rejected",
        theme.palette.redDark,
        "StatusErrorFull"
      );
    case ComplianceStatus.NonCompliant:
      return buildIcon(
        "Non-Compliant",
        theme.palette.redDark,
        "StatusErrorFull"
      );
    case ComplianceStatus.CompliantWithGap:
      return buildIcon(
        "Compliant With Gap",
        theme.palette.yellow,
        "WarningSolid"
      );
    case ComplianceStatus.Compliant:
      return buildIcon("Compliant", theme.palette.green, "CompletedSolid");
    case ComplianceStatus.Unknown:
      return buildIcon("Unknown", theme.palette.black, "UnknownSolid");
    default:
      return buildIcon("No data", theme.palette.black, "UnknownSolid");
  }
};

export const tryBuildDetailString = (
  detail: string,
  notFoundString = "Unknown"
) => {
  return detail?.length > 0 ? detail : notFoundString;
};
