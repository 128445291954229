import { AxiosError } from "axios";
import { createSelector } from "reselect";
import { IDriTeamData } from "../../components/Types";
import {
  ComplianceStatusCount,
  DependencyCount,
  DependencyGapCount,
  ServiceBcdrDetailedMetadata,
  ServiceDepAggCount,
  ServiceTreeObj,
} from "../../types/DependencyMappingTypes";
import { ReduxServiceDependencyMappingState } from "../reducers/serviceDependencyMappingReducer";
import { IPersonaProps } from "@fluentui/react";
import { pieChartColorOptions } from "../../components/charts/InfoDonutChart";
import { Link } from "../../components/charts/dependencyGraph/ServiceDependencyGraph.types";
import { ICountAnnotationProps } from "@m365-admin/count-annotation";

const serviceDependencyData = (
  state: any
): ReduxServiceDependencyMappingState => state.serviceDependencyMapping;

//loading
export const getCurrentServiceDependencyMappingMetadataIsLoading =
  createSelector(
    serviceDependencyData,
    (serviceDependencyData: ReduxServiceDependencyMappingState): boolean => {
      return serviceDependencyData?.primaryActiveServiceDataLoading ?? false;
    }
  );

export const getSecondaryServiceDependencyMappingMetadataIsLoading =
  createSelector(
    serviceDependencyData,
    (serviceDependencyData: ReduxServiceDependencyMappingState): boolean => {
      return serviceDependencyData?.secondaryPanelServiceDataLoading ?? false;
    }
  );

export const getServiceTreeOptionsLoading = createSelector(
  serviceDependencyData,
  (serviceDependencyData: ReduxServiceDependencyMappingState): boolean => {
    return serviceDependencyData?.serviceTreeOptionsLoading ?? false;
  }
);

export const getServiceTreeOptionsIsFirstLoadDone = createSelector(
  serviceDependencyData,
  (serviceDependencyData: ReduxServiceDependencyMappingState): boolean => {
    return (
      (!serviceDependencyData?.serviceTreeOptionsLoading &&
        serviceDependencyData?.serviceTreeOptions?.length > 0 &&
        serviceDependencyData?.primaryActiveServiceDataError === null) ??
      false
    );
  }
);

export const getCurrentServiceDependencyDriDataLoading = createSelector(
  serviceDependencyData,
  (serviceDependencyData: ReduxServiceDependencyMappingState): boolean => {
    return (
      serviceDependencyData?.primaryActiveServiceDriTeamDataLoading ?? false
    );
  }
);

export const getSecondaryServiceDependencyDriDataLoading = createSelector(
  serviceDependencyData,
  (serviceDependencyData: ReduxServiceDependencyMappingState): boolean => {
    return (
      serviceDependencyData?.secondaryPanelServiceDriTeamDataLoading ?? false
    );
  }
);

export const getServiceDepAggCountsLoadingStatus = createSelector(
  serviceDependencyData,
  (serviceDependencyData: ReduxServiceDependencyMappingState) => {
    return serviceDependencyData?.isServiceDepAggCountsLoading ?? false;
  }
);

// data return
export const getCurrentServiceDependencyMappingMetadata = createSelector(
  serviceDependencyData,
  (
    serviceDependencyData: ReduxServiceDependencyMappingState
  ): ServiceBcdrDetailedMetadata => {
    return serviceDependencyData?.primaryActiveServiceData;
  }
);

export const getSecondaryServiceDependencyMappingMetadata = createSelector(
  serviceDependencyData,
  (
    serviceDependencyData: ReduxServiceDependencyMappingState
  ): ServiceBcdrDetailedMetadata => {
    return serviceDependencyData?.secondaryPanelServiceData;
  }
);

export const getCurrentServiceDependencyDriData = createSelector(
  serviceDependencyData,
  (
    serviceDependencyData: ReduxServiceDependencyMappingState
  ): IDriTeamData[] => {
    if (serviceDependencyData?.primaryActiveServiceDriTeamData) {
      return (
        serviceDependencyData?.primaryActiveServiceDriTeamData[0]
          ?.driTeamData ?? []
      );
    }

    return [];
  }
);

export const getSecondaryServiceDependencyDriData = createSelector(
  serviceDependencyData,
  (
    serviceDependencyData: ReduxServiceDependencyMappingState
  ): IDriTeamData[] => {
    if (serviceDependencyData?.secondaryPanelServiceDriTeamData) {
      return (
        serviceDependencyData?.secondaryPanelServiceDriTeamData[0]
          ?.driTeamData ?? []
      );
    }

    return [];
  }
);

export const getCurrentServiceDependencyPeopleData = createSelector(
  serviceDependencyData,
  (
    serviceDependencyData: ReduxServiceDependencyMappingState
  ): IPersonaProps[] => {
    if (serviceDependencyData?.primaryActiveServiceData) {
      const listContainsPersona = (
        persona: IPersonaProps,
        personas: IPersonaProps[]
      ) => {
        if (!personas || !personas.length || personas.length === 0) {
          return false;
        }
        return (
          personas.filter(
            (item) => item?.secondaryText === persona.secondaryText
          ).length > 0
        );
      }
      const removeDuplicates = (
        personas: IPersonaProps[],
        possibleDupes: IPersonaProps[]
      ) => {
        return personas.filter(
          (persona) => !listContainsPersona(persona, possibleDupes)
        );
      }
      let personas = [];
      let bcdrPerson: IPersonaProps = {
        text: serviceDependencyData?.primaryActiveServiceData.bcdrContact,
        secondaryText:
          serviceDependencyData?.primaryActiveServiceData.bcdrContactEmail,
      };
      let pmContactList: IPersonaProps[] =
        serviceDependencyData?.primaryActiveServiceData.pmContacts.map(
          (person, index): IPersonaProps => ({
            text: person,
            secondaryText:
              serviceDependencyData?.primaryActiveServiceData.pmContactsEmail[
              index
              ],
            initialsColor:
              pieChartColorOptions[index % pieChartColorOptions.length],
          })
        );
      pmContactList = removeDuplicates(pmContactList, [bcdrPerson]);

      let devContactList: IPersonaProps[] =
        serviceDependencyData?.primaryActiveServiceData.devContacts.map(
          (person, index): IPersonaProps => ({
            text: person,
            secondaryText:
              serviceDependencyData?.primaryActiveServiceData.devContactsEmail[
              index
              ],
            initialsColor:
              pieChartColorOptions[index % pieChartColorOptions.length],
          })
        );
      devContactList = removeDuplicates(devContactList, [
        ...pmContactList,
        bcdrPerson,
      ]);

      personas = [bcdrPerson, ...pmContactList, ...devContactList];

      return personas;
    }

    return [];
  }
);

export const getServiceTreeOptionsData = createSelector(
  serviceDependencyData,
  (
    serviceDependencyData: ReduxServiceDependencyMappingState
  ): ServiceTreeObj[] => {
    return serviceDependencyData?.serviceTreeOptions ?? [];
  }
);

//errors
export const getCurrentServiceDependencyMappingMetadataLoadErrors =
  createSelector(
    serviceDependencyData,
    (
      serviceDependencyData: ReduxServiceDependencyMappingState
    ): Error | AxiosError | string => {
      return serviceDependencyData?.primaryActiveServiceDataError;
    }
  );

export const getSecondaryServiceDependencyMappingMetadataLoadErrors =
  createSelector(
    serviceDependencyData,
    (
      serviceDependencyData: ReduxServiceDependencyMappingState
    ): Error | AxiosError | string => {
      return serviceDependencyData?.secondaryPanelServiceDataError;
    }
  );

export const getServiceTreeOptionsDataLoadErrors = createSelector(
  serviceDependencyData,
  (
    serviceDependencyData: ReduxServiceDependencyMappingState
  ): Error | AxiosError | string => {
    return serviceDependencyData?.serviceTreeOptionsLoadError;
  }
);

export const getCurrentServiceDependencyDriDataLoadErrors = createSelector(
  serviceDependencyData,
  (
    serviceDependencyData: ReduxServiceDependencyMappingState
  ): Error | AxiosError | string => {
    return serviceDependencyData?.primaryActiveServiceDriTeamDataError;
  }
);

export const getSecondaryServiceDependencyDriDataLoadErrors = createSelector(
  serviceDependencyData,
  (
    serviceDependencyData: ReduxServiceDependencyMappingState
  ): Error | AxiosError | string => {
    return serviceDependencyData?.secondaryPanelServiceDriTeamDataError;
  }
);

export const getCurrentServiceDependencyGraphData = createSelector(
  serviceDependencyData,
  (
    serviceDependencyData: ReduxServiceDependencyMappingState
  ): { links: Link[]; nodes: any[] } => {
    const setRelationshipType = (links: Link[], currentLink) => {
      const circularLink = links.findIndex(
        (l) =>
          l.source === currentLink.target && l.target === currentLink.source
      );
      if (circularLink > -1) {
        return "circular";
      }
      return currentLink.direction;
    };

    if (
      serviceDependencyData?.primaryServiceDependencyLinks &&
      serviceDependencyData?.primaryActiveServiceData
    ) {
      const serviceData = serviceDependencyData?.primaryActiveServiceData;
      const originalLinks =
        serviceDependencyData?.primaryServiceDependencyLinks;

      const links: Link[] = originalLinks.map((link) => ({
        ...link,
        direction: setRelationshipType(originalLinks, link),
      }));
      return {
        links: links ?? [],
        nodes: Array.from(
          new Set(links.flatMap((l) => [l.source, l.target])),
          (id) => buildGraphNodeData(id, serviceData)
        ),
      };
    } else return undefined;
  }
);

const buildGraphNodeData = (
  currentId: string,
  currentServiceData: ServiceBcdrDetailedMetadata
) => {
  const name =
    currentServiceData.serviceOid === currentId
      ? currentServiceData.coreServiceData.serviceName
      : currentServiceData.downstreamDependencies.find(
        (l) => l.serviceOid === currentId
      )?.serviceName ??
      currentServiceData.upstreamDependencies.find(
        (l) => l.serviceOid === currentId
      )?.serviceName ??
      currentId;
  const data =
    currentServiceData.downstreamDependencies.find(
      (x) => x.serviceOid === currentId
    ) ??
    currentServiceData.upstreamDependencies.find(
      (x) => x.serviceOid === currentId
    ) ??
    currentServiceData;
  return {
    id: currentId,
    name: name,
    data: data,
  };
};

export const getServiceDepAggCounts = createSelector(
  serviceDependencyData,
  (
    serviceDependencyData: ReduxServiceDependencyMappingState
  ): ServiceDepAggCount => {
    return serviceDependencyData?.serviceDepAggCounts;
  }
);

export const getComplianceStatusCounts = createSelector(
  serviceDependencyData,
  (
    serviceDependencyData: ReduxServiceDependencyMappingState
  ): ICountAnnotationProps[] => {
    let newAggregateCounts: ComplianceStatusCount = {
      totalServices: -1,
      totalBcdrCompliantServices: 0,
      totalBcdrCompliantWithGapServices: 0,
      totalBcdrNonCompliantServices: 0,
      totalBcdrRaServices: 0,
      totalBcdrRaExpiredServices: 0,
      totalBcdrPostApprovalRejectedServices: 0,
      totalBcdrUnknownServices: 0,
    };
    if (serviceDependencyData?.serviceDepAggCounts) {
      newAggregateCounts = serviceDependencyData?.serviceDepAggCounts;
      return buildComplianceStatusAggCounts(newAggregateCounts);
    }
  }
);

const buildComplianceStatusAggCounts = (
  serviceDepAggCounts: ComplianceStatusCount
): ICountAnnotationProps[] => {
  if (serviceDepAggCounts) {
    return [
      {
        annotationText: "Total",
        count: serviceDepAggCounts?.totalServices ?? -1,
        annotationColor: pieChartColorOptions[4],
        size: 1,
      },
      {
        annotationText: "Compliant",
        count: serviceDepAggCounts?.totalBcdrCompliantServices ?? 0,
        annotationColor: pieChartColorOptions[0],
        size: 1,
      },
      {
        annotationText: "Compliant with Gap",
        count: serviceDepAggCounts.totalBcdrCompliantWithGapServices ?? 0,
        annotationColor: pieChartColorOptions[2],
        size: 1,
      },
      {
        annotationText: "Non-Compliant",
        count: serviceDepAggCounts.totalBcdrNonCompliantServices ?? 0,
        annotationColor: pieChartColorOptions[3],
        size: 1,
      },
      {
        annotationText: "RA",
        count: serviceDepAggCounts.totalBcdrRaServices ?? 0,
        annotationColor: pieChartColorOptions[5],
        size: 1,
      },
      {
        annotationText: "RA Expired",
        count: serviceDepAggCounts.totalBcdrRaExpiredServices ?? 0,
        annotationColor: pieChartColorOptions[6],
        size: 1,
      },
      {
        annotationText: "Post Approval Rejected",
        count: serviceDepAggCounts.totalBcdrPostApprovalRejectedServices ?? 0,
        annotationColor: pieChartColorOptions[1],
        size: 1,
      },
      {
        annotationText: "Unknown",
        count: serviceDepAggCounts.totalBcdrUnknownServices ?? 0,
        annotationColor: pieChartColorOptions[7],
        size: 1,
      },
    ];
  } else {
    return [];
  }
};

export const getDependencyGapCounts = createSelector(
  serviceDependencyData,
  (
    serviceDependencyData: ReduxServiceDependencyMappingState
  ): ICountAnnotationProps[] => {
    let newAggregateCounts: DependencyGapCount = {
      totalServicesWithNoDependenciesUpstream: 0,
      totalServicesWithCircularDependencies: 0,
      totalServicesWithUpstreamDependencyGaps: 0,
      totalServicesWithDownstreamDependencyGaps: 0,
      totalServicesWithSelfReferencingDependencies: 0,
    };
    if (serviceDependencyData?.serviceDepAggCounts) {
      newAggregateCounts = serviceDependencyData?.serviceDepAggCounts;
      return buildDependencyGapAggCounts(newAggregateCounts);
    }
  }
);

const buildDependencyGapAggCounts = (
  serviceDepAggCounts: DependencyGapCount
): ICountAnnotationProps[] => {
  if (serviceDepAggCounts) {
    return [
      {
        annotationText: "Circular",
        count: serviceDepAggCounts?.totalServicesWithCircularDependencies ?? 0,
        annotationColor: pieChartColorOptions[4],
        size: 1,
      },
      {
        annotationText: "Self Referencing",
        count:
          serviceDepAggCounts?.totalServicesWithSelfReferencingDependencies ??
          0,
        annotationColor: pieChartColorOptions[0],
        size: 1,
      },
      {
        annotationText: "Upstream Gaps",
        count: serviceDepAggCounts.totalServicesWithUpstreamDependencyGaps ?? 0,
        annotationColor: pieChartColorOptions[2],
        size: 1,
      },
      {
        annotationText: "Downstream Gaps",
        count:
          serviceDepAggCounts.totalServicesWithDownstreamDependencyGaps ?? 0,
        annotationColor: pieChartColorOptions[3],
        size: 1,
      },
      {
        annotationText: "No Upstream Dependencies",
        count: serviceDepAggCounts.totalServicesWithNoDependenciesUpstream ?? 0,
        annotationColor: pieChartColorOptions[5],
        size: 1,
      },
    ];
  } else {
    return [];
  }
};

export const getTop10ServicesWithMostUpstreamDependencies = createSelector(
  serviceDependencyData,
  (
    serviceDependencyData: ReduxServiceDependencyMappingState
  ): DependencyCount[] => {
    return (
      serviceDependencyData?.serviceDepAggCounts
        ?.top10ServicesWithMostUpstreamDependencies ?? []
    );
  }
);

export const getTop10ServicesWithMostDownstreamDependencies = createSelector(
  serviceDependencyData,
  (
    serviceDependencyData: ReduxServiceDependencyMappingState
  ): DependencyCount[] => {
    return (
      serviceDependencyData?.serviceDepAggCounts
        ?.top10ServicesWithMostDownstreamDependencies ?? []
    );
  }
);

export const getServicesWithCircularDependencies = createSelector(
  serviceDependencyData,
  (
    serviceDependencyData: ReduxServiceDependencyMappingState
  ): DependencyCount[] => {
    return (
      serviceDependencyData?.serviceDepAggCounts
        ?.servicesWithCircularDependencies ?? []
    );
  }
);
