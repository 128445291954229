import { ScrollablePane } from "@fluentui/react";
import { NormalizedEmployeeProfile } from "components/Types";
import { Tree } from "components/charts/Tree";
import * as d3 from "d3";
import { _Styles } from "pages/Page.styles";
import React, { useEffect, useState } from "react";
import { getFacilityCountries } from "store/selectors/facilitiesSelector";
import { useAppSelector } from "store/store";

export interface PeopleTreeProps { filteredEmployeeData: NormalizedEmployeeProfile[]; }
export const PeopleTree = ({filteredEmployeeData}: PeopleTreeProps) => {
  const [treeData, setTreeData] = useState(null);
  const selectedCountries: string[] = useAppSelector(getFacilityCountries);

  useEffect(() => {
    const reduceReportCount = (reportsList): number => {
      return reportsList.reduce((acc, c) => {
        if (c.managerInArea) {
          return acc + c.value + c.managerInArea;
        } else {
          return acc + c.value;
        }
      }, 0);
    };

    const getLocation = (
      currentPerson: NormalizedEmployeeProfile
    ): { location: string; inAreaCount: number } => {
      if (currentPerson) {
        return { location: currentPerson.buildingName, inAreaCount: 1 };
      }
      return { location: "Out of area", inAreaCount: 0 };
    };

    const mapNoReports = (reportsList: NormalizedEmployeeProfile[]) => {
      return reportsList.map((e) => {
        const mappedLocationData = getLocation(e);
        return {
          name: `${
            !e.isActiveFte && e.alias?.toUpperCase().startsWith("V-")
              ? "External: ".concat(e.fullName)
              : e.fullName
          }`,
          data: mappedLocationData.location,
          children: [],
          value: mappedLocationData.inAreaCount,
        };
      });
    };
    const managerEmailMappingList = [
      "l0ManagerEmailName",
      "l1ManagerEmailName",
      "l2ManagerEmailName",
      "l3ManagerEmailName",
      "l4ManagerEmailName",
      "l5ManagerEmailName",
      "l6ManagerEmailName",
      "l7ManagerEmailName",
      "manager",
    ];

    const managerNameMappingList = [
      "l0Manager",
      "l1Manager",
      "l2Manager",
      "l3Manager",
      "l4Manager",
      "l5Manager",
      "l6Manager",
      "l7Manager",
      "manager",
    ];

    const makeSet = (
      currentEmployeeData: NormalizedEmployeeProfile[],
      managerMappingIndex: number
    ): string[] => {
      if (currentEmployeeData.length > 0) {
        const currentManagerEmailNameAttribute =
          managerEmailMappingList[managerMappingIndex];
        const currentManagerNameAttribute =
          managerNameMappingList[managerMappingIndex];
        const managersInScope = currentEmployeeData.filter(
          (e) =>
            e[currentManagerEmailNameAttribute]?.length > 0 ||
            e[currentManagerNameAttribute]?.length > 0
        );

        const results = Array.from(
          new Set(
            managersInScope.map(
              (e) => e[currentManagerEmailNameAttribute]?.toUpperCase() ?? ""
            )
          )
        ).sort();
        return results;
      }
      return [];
    };

    const countDirects = (
      employeeDataToMap: NormalizedEmployeeProfile[],
      managerMappingIndex
    ): any[] => {
      if (managerMappingIndex > managerEmailMappingList.length - 1) {
        return;
      }

      const managers = makeSet(employeeDataToMap, managerMappingIndex).map(
        (m) => m?.toUpperCase()
      );

      if (managers.length === 0) {
        //if there are no more levels, then try to make a manager list
        return mapNoReports(employeeDataToMap);
      }
      //find the direct reports
      const onlyDirectReports = employeeDataToMap.filter(
        (e) =>
          !managers.some(
            (m) =>
              e[managerEmailMappingList[managerMappingIndex]]?.toUpperCase() ===
                m || e.alias === m
          )
      );
      const directReportsMapped = mapNoReports(onlyDirectReports);
      //map the remaining lead's reports
      const findSkipReports: any[] = managers.map((m) => {
        const mReports = employeeDataToMap.filter(
          (e) =>
            e[managerEmailMappingList[managerMappingIndex]].toUpperCase() ===
              m || e.managerAlias.toUpperCase() === m
        );
        // if there are no reports, then they are a direct only and can be mapped.
        if (mReports.length === 0) {
          const currentEmployeeIndex = employeeDataToMap.findIndex(
            (e) => e.alias?.toUpperCase() === m
          );
          if (currentEmployeeIndex > -1) {
            return mapNoReports([employeeDataToMap[currentEmployeeIndex]]);
          }
        }
        //if there are reports, those reports need to be mapped.
        const nextLevelReports = countDirects(
          mReports,
          managerMappingIndex + 1
        );

        const managerLocationMap = getLocation(
          employeeDataToMap.find((e) => e.alias?.toUpperCase() === m)
        );
        return {
          name: m,
          children: nextLevelReports,
          data: managerLocationMap.location,
          managerInArea: managerLocationMap.inAreaCount,
          value: reduceReportCount(nextLevelReports),
        };
      });

      return [...findSkipReports, ...directReportsMapped];
    };

    if (filteredEmployeeData?.length > 0) {
      const treeNodes = countDirects(filteredEmployeeData, 0);

      const ceoRoot = treeNodes.findIndex((e) => e.name === "SATYAN");
      // check for Microsoft Corp and Unknown roots to ensure the employees aren't "lost"
      const msCorpRoot = treeNodes.findIndex(
        (e) => e.name === "MICROSOFT CORPORATION"
      );
      const msUnknownRoot = treeNodes.findIndex((e) => e.name === "UNKNOWN");

      // set root of tree
      let newTreeRoot;
      if (ceoRoot > -1) {
        if (msCorpRoot > -1) {
          // if there is a Microsoft Corp root, add it to the CEO root.
          treeNodes[ceoRoot].children.push(treeNodes[msCorpRoot]);
          treeNodes[ceoRoot].value += treeNodes[msCorpRoot].value;
        }
        if (msUnknownRoot > -1) {
          // if there is an unknown root, add it to the CEO root.
          treeNodes[ceoRoot].children.push(treeNodes[msUnknownRoot]);
          treeNodes[ceoRoot].value += treeNodes[msUnknownRoot].value;
        }

        newTreeRoot = d3
          .hierarchy(treeNodes[ceoRoot], (d) => d.children)
          .sum((d) => d.value);
      } else {
        // in case the tree is missing a CEO entirely, default to assumed tree node.
        newTreeRoot = d3
          .hierarchy(treeNodes[0], (d) => d.children)
          .sum((d) => d.value);
      }

      // update tree data
      setTreeData(newTreeRoot);
    }
  }, [filteredEmployeeData, selectedCountries]);

  return (
    <div className={_Styles.wrapper}>
      <ScrollablePane
        scrollContainerFocus={true}
        scrollContainerAriaLabel={
          "Node graph representing aggregate workforce hierarchy by manager"
        }
        initialScrollPosition={800}
      >
        {treeData?.children?.length > 0 && (
          <Tree treeData={treeData} height={1600} width={2000} />
        )}
      </ScrollablePane>
    </div>
  );
};
