import { AxiosError } from "axios";
import { stringify } from "querystring";
import { Dispatch } from "redux";
import { NormalizedEmployeeProfile, PeopleCount } from "../../components/Types";
import { appInsights } from "../../services/AppInsights";
import apiBase from "../../services/apiBase";
import {
  APPLY_PEOPLE_FILTERS,
  CLEAR_UNAPPLIED_PEOPLE_FILTERS,
  FETCH_PEOPLE_COUNTS_BEGIN,
  FETCH_PEOPLE_COUNTS_FAILURE,
  FETCH_PEOPLE_COUNTS_SUCCESS,
  FETCH_PEOPLE_REGION_BEGIN,
  FETCH_PEOPLE_REGION_FAILURE,
  FETCH_PEOPLE_REGION_SUCCESS,
  RESET_PEOPLE_ASSETS,
  SET_ACTIVE_MANAGER_ALIAS,
  SET_PEOPLE_CITY_FILTER,
  SET_PEOPLE_DIVISION_FILTER,
  SET_PEOPLE_FAC_NAME_FILTER,
  SET_PEOPLE_JOB_TITLE_SUMMARY_DESC_FILTER,
  SET_PEOPLE_ORGANIZATION_FILTER,
  SET_PEOPLE_SERVICE_GROUP_FILTER,
  SET_PEOPLE_STATE_FILTER,
  SET_PEOPLE_TEAM_GROUP_FILTER,
  SET_UNAPPLIED_PEOPLE_FILTERS,
} from "./actionTypes";

const peopleCountAggregatedApiEndpoint: string = "api/People/GetAggregatedPeopleCount";
const peopleByBuildingApiEndpoint: string = "api/People/GetPeople";
const peopleByHierarchyApiEndpoint: string = "api/People/GetPersonHierarchy";

export const fetchAliasDataBegin = () => ({
  type: FETCH_PEOPLE_REGION_BEGIN,
});

export const fetchAliasDataSuccess = (payload: NormalizedEmployeeProfile[]) => ({
  type: FETCH_PEOPLE_REGION_SUCCESS,
  payload,
});

export const fetchAliasDataFailure = (error: AxiosError | string) => ({
  type: FETCH_PEOPLE_REGION_FAILURE,
  payload: error,
});

export const fetchPeopleAliasData = (
  aliasToFetch: string,
  regionsToFilter: string[] = [],
  countriesToFilter: string[] = [],
  statesToFilter: string[] = [],
  citiesToFilter: string[] = []
) => {
  return async (dispatch: Dispatch) => {
    dispatch(fetchPeopleDataBegin());
    try {
      const res = await apiBase.get(peopleByHierarchyApiEndpoint, {
        params: {
          aliasQuery: aliasToFetch,
          regionQueryList: regionsToFilter,
          countryQueryList: countriesToFilter,
          stateQueryList: statesToFilter,
          cityQueryList: citiesToFilter,
        },
        paramsSerializer: (params) => {
          return stringify(params);
        },
      });

      if (res.data) {
        dispatch(fetchPeopleDataSuccess(res.data));
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        dispatch(
          fetchPeopleDataFailure(
            "Error calling People Alias Lookup API due to your permissions. Please reach out to watchtower support to confirm your access."
          )
        );
      } else {
        dispatch(fetchPeopleDataFailure(err));
      }

      appInsights.trackException(err);
    }
  };
};
export const fetchPeopleDataBegin = () => ({
  type: FETCH_PEOPLE_REGION_BEGIN,
});

export const fetchPeopleDataSuccess = (payload: NormalizedEmployeeProfile[]) => ({
  type: FETCH_PEOPLE_REGION_SUCCESS,
  payload,
});

export const fetchPeopleDataFailure = (error: AxiosError | string) => ({
  type: FETCH_PEOPLE_REGION_FAILURE,
  payload: error,
});

export const fetchPeopleLocationData = (
  regionsToFilter: string[] = [],
  countriesToFilter: string[] = [],
  statesToFilter: string[] = [],
  citiesToFilter: string[] = []
) => {
  return async (dispatch: Dispatch) => {
    dispatch(fetchPeopleDataBegin());
    try {
      const res = await apiBase.get(peopleByBuildingApiEndpoint, {
        params: {
          regionQueryList: regionsToFilter,
          countryQueryList: countriesToFilter,
          stateQueryList: statesToFilter,
          cityQueryList: citiesToFilter,
        },
        paramsSerializer: (params) => {
          return stringify(params);
        },
      });

      if (res.data) {
        dispatch(fetchPeopleDataSuccess(res.data));
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        dispatch(
          fetchPeopleDataFailure(
            "Error calling People Manager API due to your permissions. Please reach out to watchtower support to confirm your access."
          )
        );
      } else {
        dispatch(fetchPeopleDataFailure(err));
      }

      appInsights.trackException(err);
    }
  };
};

export const fetchPeopleCountsBegin = () => ({
  type: FETCH_PEOPLE_COUNTS_BEGIN,
});

export const fetchPeopleCountsSuccess = (payload: PeopleCount) => ({
  type: FETCH_PEOPLE_COUNTS_SUCCESS,
  payload,
});

export const fetchPeopleCountsFailure = (error: AxiosError | string) => ({
  type: FETCH_PEOPLE_COUNTS_FAILURE,
  payload: error,
});

export const fetchPeopleCounts = (
  regionsToFilter: string[] = [],
  countriesToFilter: string[] = [],
  statesToFilter: string[] = [],
  citiesToFilter: string[] = []
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(fetchPeopleCountsBegin());
      const res = await apiBase.get(peopleCountAggregatedApiEndpoint, {
        params: {
          regionQueryList: regionsToFilter,
          countryQueryList: countriesToFilter,
          stateQueryList: statesToFilter,
          cityQueryList: citiesToFilter,
        },
        paramsSerializer: (params) => {
          return stringify(params);
        },
      });
      if (res.data) {
        dispatch(fetchPeopleCountsSuccess(res.data));
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        dispatch(
          fetchPeopleCountsFailure(
            "Error calling People Count API due to your permissions. Please reach out to watchtower support to confirm your access."
          )
        );
      } else if (err?.response?.status === 500) {
        dispatch(
          fetchPeopleCountsFailure("Error calling People Count API. Please try again later.")
        );
      } else {
        dispatch(fetchPeopleCountsFailure(err));
      }

      appInsights.trackException(err);
    }
  };
};

export const setActiveManagerAlias = (managerAlias: string) => ({
  type: SET_ACTIVE_MANAGER_ALIAS,
  payload: managerAlias,
});

export const clearUnappliedPeopleFilter = () => ({
  type: CLEAR_UNAPPLIED_PEOPLE_FILTERS,
});

export const applyPeopleFilter = (payload: {}) => ({
  type: APPLY_PEOPLE_FILTERS,
  payload,
});

export const updateUnappliedPeopleFilter = (payload: {}) => ({
  type: SET_UNAPPLIED_PEOPLE_FILTERS,
  payload,
});

export const updatePeopleCityFilter = (payload: string[]) => ({
  type: SET_PEOPLE_CITY_FILTER,
  payload,
});

export const updatePeopleStateFilter = (payload: string[]) => ({
  type: SET_PEOPLE_STATE_FILTER,
  payload,
});

export const updatePeopleDivFilter = (payload: string[]) => ({
  type: SET_PEOPLE_DIVISION_FILTER,
  payload,
});

export const updatePeopleOrgFilter = (payload: string[]) => ({
  type: SET_PEOPLE_ORGANIZATION_FILTER,
  payload,
});

export const updatePeopleServiceGroupFilter = (payload: string[]) => ({
  type: SET_PEOPLE_SERVICE_GROUP_FILTER,
  payload,
});

export const updatePeopleTeamGroupFilter = (payload: string[]) => ({
  type: SET_PEOPLE_TEAM_GROUP_FILTER,
  payload,
});

export const updatePeopleFacNameFilter = (payload: string[]) => ({
  type: SET_PEOPLE_FAC_NAME_FILTER,
  payload,
});

export const updatePeopleJobTitleSummaryDescFilter = (payload: string[]) => ({
  type: SET_PEOPLE_JOB_TITLE_SUMMARY_DESC_FILTER,
  payload,
});

export const clearPeopleDetails = () => ({
  type: RESET_PEOPLE_ASSETS,
});
