import { DetailsList, IColumn, IGroup, SelectionMode } from "@fluentui/react";
import { AzureRegion } from "components/Types";

const sortAzureRegionData = (data: AzureRegion[]) =>
  [...data].sort((a, b) =>
    a.countryRegion.localeCompare(b.countryRegion, undefined, {
      sensitivity: "base",
    })
  );

interface AzureRegionDataGroup {
  key: string;
  name: string;
  startIndex: number;
  count: number;
  totalProductionSubscriptionCount: number;
  totalNonproductionSubscriptionCount: number;
}

const groupByCountryRegion = (data: AzureRegion[]): IGroup[] => {
  const regionMap = data.reduce((map, region, index) => {
    if (!map.has(region.countryRegion)) {
      const group = {
        key: region.countryRegion,
        name: region.countryRegion,
        startIndex: index,
        count: 1,
        totalProductionSubscriptionCount: region.azureProdSubscriptionCount,
        totalNonproductionSubscriptionCount:
          region.azureNonProdSubscriptionCount,
      };
      map.set(region.countryRegion, group);
    } else {
      const group = map.get(region.countryRegion);
      group.count++;
      group.totalProductionSubscriptionCount +=
        region.azureProdSubscriptionCount;
      group.totalNonproductionSubscriptionCount +=
        region.azureNonProdSubscriptionCount;
    }
    return map;
  }, new Map<string, AzureRegionDataGroup>());

  const groups = Array.from(regionMap.values()).map((group) => {
    return {
      key: group.key,
      name: `${group.name}: ${group.totalProductionSubscriptionCount} Prod Subscriptions, ${group.totalNonproductionSubscriptionCount} Non-Prod Subscriptions`,
      startIndex: group.startIndex,
      count: group.count,
    };
  });

  return groups;
};

export interface GroupedAzureRegionDataGridProps {
  azureRegionData: AzureRegion[];
}

export const GroupedAzureRegionDataGrid = ({
  azureRegionData,
}: GroupedAzureRegionDataGridProps) => {
  const sortedAzureRegionData = sortAzureRegionData(azureRegionData);
  const regionGroups = groupByCountryRegion(sortedAzureRegionData);

  const columns: IColumn[] = [
    {
      key: "countryRegion",
      name: "Country",
      fieldName: "countryRegion",
      minWidth: 100,
      maxWidth: 175,
      isResizable: true,
      isGrouped: true,
      isRowHeader: true,
    },
    {
      key: "azureCloudRegionName",
      name: "Cloud Region Name",
      fieldName: "azureCloudRegionName",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: "azureCloudName",
      name: "Azure Cloud",
      fieldName: "azureCloudName",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: "datacenterCampusName",
      name: "Data Center",
      fieldName: "datacenterCampusName",
      minWidth: 100,
      isResizable: true,
    },
    {
      key: "azureProdSubscriptionCount",
      name: "Prod",
      ariaLabel: `Number of azure prod subscriptions in data center`,
      fieldName: "azureProdSubscriptionCount",
      minWidth: 50,
    },
    {
      key: "azureNonProdSubscriptionCount",
      name: "Non-Prod",
      ariaLabel: "Number of azure non-prod subscriptions in data center",
      fieldName: "azureNonProdSubscriptionCount",
      minWidth: 75,
    },
  ];

  const onRenderColumn = (
    item: AzureRegion,
    index: number,
    column: IColumn
  ) => {
    const value =
      item && column && column.fieldName
        ? item[column.fieldName as keyof AzureRegion] || ""
        : "";

    return <div data-is-focusable={true}>{value}</div>;
  };
  return (
    <DetailsList
      items={sortedAzureRegionData}
      groups={regionGroups}
      columns={columns}
      compact={true}
      selectionMode={SelectionMode.none}
      groupProps={{
        showEmptyGroups: false,
      }}
      onRenderItemColumn={onRenderColumn}
    />
  );
};
