import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { stringify } from "querystring";
import { EnhancedProcessAssetDetail, ProcessCount } from "../../components/Types";
import apiBase from "../../services/apiBase";
import { appInsights } from "../../services/AppInsights";
import {
  CLEAR_PROCESS_DETAILS,
  FETCH_PROCESS_COUNTS_BEGIN,
  FETCH_PROCESS_COUNTS_FAILURE,
  FETCH_PROCESS_COUNTS_SUCCESS,
  FETCH_PROCESS_DETAILS_BEGIN,
  FETCH_PROCESS_DETAILS_FAILURE,
  FETCH_PROCESS_DETAILS_SUCCESS,
  SET_PROCESS_CRITICALITY_FILTER,
  SET_PROCESS_DETAILS_SEARCH_TERM,
  SET_PROCESS_DIVISION_FILTER,
  SET_PROCESS_FACILITY_FILTER,
} from "./actionTypes";

const processCountsApiEndpoint: string = "api/Processes/GetAggregatedProcessesCount";
const processDetailsApiEndpoint: string = "api/Processes/GetProcesses";

export const fetchProcessCountsBegin = () => ({
  type: FETCH_PROCESS_COUNTS_BEGIN,
});

export const fetchProcessCountsSuccess = (payload: ProcessCount) => ({
  type: FETCH_PROCESS_COUNTS_SUCCESS,
  payload,
});

export const fetchProcessCountsFailure = (error: AxiosError | string) => ({
  type: FETCH_PROCESS_COUNTS_FAILURE,
  payload: error,
});

export const fetchProcessCounts = () => {
  return async (dispatch: Dispatch) => {
    dispatch(fetchProcessCountsBegin());
    try {
      const res = await apiBase.get(processCountsApiEndpoint);
      if (res.data) {
        dispatch(fetchProcessCountsSuccess(res.data));
      }
    } catch (err) {
      if ((err as AxiosError).response.status === 403) {
        dispatch(
          fetchProcessCountsFailure(
            "Error calling Process API due to your permissions. Please reach out to watchtower support to confirm your access."
          )
        );
      } else {
        dispatch(fetchProcessCountsFailure(err));
      }

      appInsights.trackException(err);
    }
  };
};

export const fetchProcessDetailsBegin = () => ({
  type: FETCH_PROCESS_DETAILS_BEGIN,
});

export const fetchProcessDetailsSuccess = (payload: EnhancedProcessAssetDetail[]) => ({
  type: FETCH_PROCESS_DETAILS_SUCCESS,
  payload,
});

export const fetchProcessDetailsFailure = (error: AxiosError | string) => ({
  type: FETCH_PROCESS_DETAILS_FAILURE,
  payload: error,
});

export const fetchProcessDetails = (locationsToFetch: string[], isRegion: boolean) => {
  return async (dispatch: Dispatch) => {
    dispatch(clearProcessDetails());
    dispatch(fetchProcessDetailsBegin());
    try {
      let params;
      if (isRegion) {
        params = {
          regionQueryList: locationsToFetch,
        };
      } else {
        params = {
          countryQueryList: locationsToFetch,
        };
      }
      const res = await apiBase.get(processDetailsApiEndpoint, {
        params,
        paramsSerializer: (params) => {
          return stringify(params);
        },
      });
      if (res.data) {
        dispatch(fetchProcessDetailsSuccess(res.data));
      }
    } catch (err) {
      if ((err as AxiosError).response.status === 403) {
        dispatch(
          fetchProcessDetailsFailure(
            "Error calling Process Asset API due to your permissions. Please reach out to watchtower support to confirm your access."
          )
        );
      } else {
        dispatch(fetchProcessDetailsFailure(err));
      }

      appInsights.trackException(err);
    }
  };
};

export const clearProcessDetails = () => ({
  type: CLEAR_PROCESS_DETAILS,
});

export const updateProcessCriticalityFilters = (payload: string[]) => ({
  type: SET_PROCESS_CRITICALITY_FILTER,
  payload,
});

export const updateProcessDivisionFilters = (payload: string[]) => ({
  type: SET_PROCESS_DIVISION_FILTER,
  payload,
});

export const updateProcessFacilityFilters = (payload: string[]) => ({
  type: SET_PROCESS_FACILITY_FILTER,
  payload,
});

export const updateProcessDetailsSearchTerm = (payload: string) => ({
  type: SET_PROCESS_DETAILS_SEARCH_TERM,
  payload,
});
